<body>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" i18n-loadingText loadingText="{{ 'common.authenticating' | translate }}"
    type="ball-spin-clockwise">
    <p style="color: white">{{BusyMessage}}</p>
  </ngx-spinner>
  <style>
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
      box-shadow: transparent;
    }
  </style>
  
  <style>
    /* These styles are to prevent pre-loading display issues with bootstrap not loaded yet. */
    @media (max-width: 767px) {
      .visible-xs {
        display: block !important
      }
      @media (max-width: 767px) {
        .hidden-xs {
          display: none !important;
        }
      }
      @media (min-width: 768px) and (max-width:991px) {
        .visible-sm {
          display: block !important
        }
      }
      @media (min-width: 768px) and (max-width:991px) {
        .hidden-sm {
          display: none !important
        }
      }
    }
  </style>
  <div class="login-master">
    <div class="login-main no-padding hidden-xs hidden-sm">
      <div id="login-container" class="login-container no-padding hidden-xs center-screen">
        <div class="col-md-12 login-inner-container">
          <h3 class="center-text">{{loginMessage}}</h3>
          <div class="col-sm-4">
            <div class="login-left-child">
              <div class="col-sm-12 login-logo">
                <!-- <div class="Tlogo-txt">ToxAware®</div> -->
                <span>
                  <img src="/assets/Images/ToxLogo.webp" class="icon-Main-Logo logo-image" i18n-alt alt="ToxAware Logo" />
                </span>
                <div class="text-center">{{ownerName}}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-lg-4 col-md-4" *ngIf="showLoginPage == true">

            <form id="login-form" style="padding-right: 20% " autocomplete="off">
              <div class="form-group mobile-field-align user-field-align">
                <div>
                  <label class="mob-label" for="name" i18n>{{ 'common.label' | translate }}</label>
                </div>
                <div class="field-align">
                  <div class="e-float-input e-float-line textInput dialogTextTop chg-pwd-input">
                    <input autofocus tabindex="1" size=30
                      style="border-top:0; border-left:0; border-right:0; background-color: white !important;"
                      class="form-control login-input" type="username" id="tbxUserName" i18n-placeholder placeholder="{{ 'common.UserName' | translate }}" name="tbxUserName"
                      [(ngModel)]="userName" autocomplete="{{ 'common.UserName' | translate }}" />
                  </div>
                  <span class="errorMessage" *ngIf="userName == '' && loginBtnActive">{{ 'common.EnterUser' | translate }}</span>
                </div>
              </div>
              <div class="form-group mobile-field-align">
                <div>
                  <label class="mob-label" for="pwd" i18n>{{ 'common.Password' | translate }}</label>
                </div>
                <div>
                  <div class="e-float-input e-float-line textInput dialogTextTop chg-pwd-input">
                    <input size=30
                      style="border-top:0; border-left:0; border-right:0; background-color: white !important;"
                      tabindex="2" class="form-control login-input" type="password" id="tbxPassword" i18n-placeholder placeholder="Password"
                      [(ngModel)]="password" name="tbxPassword" autocomplete="{{ 'common.currentPassword' | translate }}" />

                  </div>
                  <span id="login-page-error" class="login-error errorMessage" *ngIf="invalidLoginError"><b>&#9432;</b>
                    {{LoginErrorInfo}}</span>
                  <br />
                  <span *ngIf="invalidLoginError">
                    <!-- <a class="nav-link" style="padding-right: 1%" i18n-title title="Create a new Exposure in a new window" target="_blank" [routerLink]="['/exposure']">Create New Case Offline</a> -->
                    </span>
                </div>

              </div>
                         
              <a class="nav-link login-link" style="padding-right: 1%;" href='javascript:' (click)="forgotPasswordClick()">{{"common.forgotYourPassword" | translate}}</a>
              <button ejs-button  id="btnClearCookie" name="btnReset"
              cssClass="e-warning lockbtn-decorate" type="button"
              style="width: 28px; height: 28px; padding-left: 5px;"
              i18n-title title="{{'common.clearToxawareData' | translate}}"
              (click)="clearCookiesLocalStorageAndIndexedDb()" data-ripple="true">
              <span class="icon-T_CloseRefresh ico-spacing"></span>
             </button>
              <!-- <div class="form-group mobile-field-align">
                <span class="inputLable login-input">
                  <ejs-checkbox
                    #rememberPassword
                    i18n-label
                    label="{{ 'Remember Me' }}"
                    (change)="changeRememberPassword()"
                    [checked]="false"
                  ></ejs-checkbox>
                </span>
              </div>
              <div class="form-group mobile-field-align">
                <span class="inputLable public-computer">
                  <ejs-checkbox
                    #rememberComputer
                    i18n-label                    
                    label="{{ 'Public Computer' }}"
                    (change)="changeRememberComputer()"
                    [checked]="false"
                  ></ejs-checkbox>
                </span>
                <span class="public-computer-info">
                  <sup class="info-decorate" (click)="publicComputerInfoClick()"
                    >&#9432;</sup
                  >
                </span>
                <span
                  id="public-info"
                  *ngIf="publicComputerInfo"
                  class="public-computer-info-text"
                >
                  {{
                    "Purge any saved data on logout for use on public computers."
                  }}
                </span>

              </div> -->
              <div class="form-group login-btn-align">
                <div class="btn-sign-in">
                  <button ejs-button cssClass="e-success e-warning focusableTwo e-button-decorate" id="loginSubmit"
                    (click)="loginClick()" i18n type="submit"> {{ 'common.signIn' | translate }}
                    <!-- <img src="/assets/Images/LoginArrow.png" /> -->
                    <!-- [disabled]=" password == '' && userName == '' ? true : password != '' && userName != '' ? false : true " -->
                  </button>
                </div>
              </div>

              <!-- <button ejs-button cssClass="e-success focusableTwo e-button-decorate" id="btnClearCookie"
              (click)="clearCookiesLocalStorageAndIndexedDb()" i18n>Clear Cookies</button> -->
            </form>
          </div>

          <!-- fake fields are a workaround for chrome autofill getting the wrong fields- from StackOverflow answer -->
          <!-- https://stackoverflow.com/a/15917221/1747491 -->
          <input *ngIf="showLoginPage != true" style="display: none" type="text" name="fakeusernameremembered" />
          <input *ngIf="showLoginPage != true" style="display: none" type="password" name="fakepasswordremembered" />

          <div class="col-sm-4 col-lg-4 col-md-4">
            <!-- Langauge selection -->
            <div class="langauge-selector">
              <ejs-dropdownlist id='languageDD' #languageDD [dataSource]='AvailableLanguages' [value]="currentLanguage" [fields]="languageDDfields"
                placeholder='{{"common.language" | translate}}' floatLabelType="Always" (change)="setLanguageClick($event)"></ejs-dropdownlist>
            </div>
            <h5 class=" text-center "><small>{{ 'common.googleChromeIsRecommendedForBestPerformance' | translate }}.</small>
            </h5>
            <h5 class="text-center"><small>{{ 'common.internetExplorerIsNotFullySuppoetedAtThisTime' | translate }}.</small></h5>
            <h4 class="text-center"><small>{{ 'common.enjoyYourDay' | translate }}</small></h4>
          </div>
          <div class="col-sm-4 col-lg-4 col-md-4 login-right" *ngIf="showSecurityVerificationPage == true">

            <form id="security-questions" class="loginTemplate" autocomplete="off">
              <div class="form-group user-field-align">
                <div>
                  <img src="/assets/Images/Padlock_Gold.png" height="55px" i18n-title title='Padlock'>
                  <label class="loginHeader" i18n>{{ 'common.confirmYourIdentity' | translate }}</label>
                </div>
                <div>
                  <span class="icon-T_Arrow_Left" *ngIf="savedSecondQuestion == clientQuestion"
                    (click)="previousSecurityQuestion()" i18n-title title="{{ 'common.toAnswerFirstQuestion' | translate }}"></span>
                  <label class="mob-label" for="tbxSecurityAnswer">{{
                    clientQuestion
                    }}</label>
                </div>
                <div class="field-align">
                  <div class="textInput e-float-input e-float-line">
                    <input class="form-control e-inputs login-input" type="text" id="tbxSecurityAnswer" (input)="onKeyPressChange($event)"
                      i18n-placeholder placeholder="{{ 'common.Answer' | translate }}" name="tbxSecurityAnswer" [(ngModel)]="securityAnswer"
                      style="width: 230px !important;" />
                  </div>
                  <span class="login-error errorMessage" *ngIf="invalidSecurityQuestion">{{ securityError }}</span>
                </div>
                <div class="form-group login-btn-align">
                  <div class="btn-sign-in">
                    <button ejs-button cssClass="e-success e-button-decorate"
                      [disabled]="securityAnswer == '' ? true : false" id="security-questions-submit"
                      (click)="onSecurityQuestionsSubmit()" i18n type="submit">
                      {{ 'common.Submit' | translate }}
                    </button>
                  </div>
                </div>
                <div class="form-group mobile-field-align">
                  <a class="nav-link login-link" href='javascript:' (click)="backToLoginClick()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                </div>
              </div>
            </form>
          </div>

          <div class="col-sm-4 col-lg-4 col-md-4 login-right" *ngIf="showEmailVerificationPage == true">
            <form id="security-email" class="loginTemplate" autocomplete="off">

              <div class="form-group user-field-align">
                <div>
                  <img src="/assets/Images/Padlock_Gold.png" height="50px" style="vertical-align: top;" alt="Lock">
                  <label class="loginHeader" i18n>{{ 'common.confirmYourIdentity' | translate }}<br/>{{ 'common.emailVerification' | translate }}</label>
                </div>

                <div>
                  <label class="mob-label" for="securityEmail" i18n>{{ 'common.pleaseEnterTheCodeSentViaEmail' | translate }}</label>
                </div>
                <div class="field-align">
                  <div class="textInput e-float-input e-float-line">
                    <input autofocus class="form-control e-inputs login-input" type="text" id="tbxSecurityEmail"
                      (input)="onKeyPressChange($event)" i18n-placeholder placeholder="{{ 'common.enterCode' | translate }}" name="tbxSecurityEmail"
                      [(ngModel)]="securityEmail" />
                  </div>
                  <span class="login-error errorMessage" *ngIf="invalidSecurityQuestion">{{ securityError }}</span>
                </div>
                <div class="form-group login-btn-align">
                  <div class="btn-sign-in">
                    <button ejs-button cssClass="e-success e-button-decorate" type="button"
                      [disabled]="securityEmail == '' ? true : false" id="security-email-submit"
                      (click)="onSecurityEmailSubmit()" i18n>
                      {{ 'common.Submit' | translate }}
                    </button>
                  </div>
                </div>

                <div class="form-group mobile-field-align">
                  <div>
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.verificationCodeWillBeSentToRegisteredEmailAddress' | translate }}"
                        (click)="resendEmailcodeClick()" i18n>{{ 'common.resendCode' | translate }}</a>
                  </div>
                  <div style="margin-top: 8px;">
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.returnBackToLogin' | translate }}" href='javascript:'
                        (click)="loginBack()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                  </div>
                </div>

              </div>
            </form>
          </div>

          <div class="col-sm-4 col-lg-4 col-md-4 login-right" *ngIf="showSmsVerificationCodePage == true">
            <form id="security-sms-code" class="loginTemplate" autocomplete="off">
              <div class="form-group user-field-align">
                <div>
                  <img src="/assets/Images/Padlock_Gold.png" height="50px" style="vertical-align: top;" alt="Lock">
                  <label class="loginHeader" i18n>{{ 'common.confirmYourIdentity' | translate }}<br/>{{ 'common.mobileVerification' | translate }}</label>
                </div>
                <div>
                  <label class="mob-label" for="securitySms" i18n>{{ 'common.pleaseEnterYourOneTimePasswordNumber' | translate }}</label>
                </div>
                <div class="field-align smsmobileinput-align">
                  <div class="">
                    <input autofocus class="e-inputs securityCode" type="text" maxlength="1" id="securitySmscode1"
                      (input)="onKeyPressChange($event)" i18n-placeholder placeholder="{{ 'common.smsCode' | translate }}" name="securitySmscode1"
                      [(ngModel)]="securitySmscode1" />
                    <input class="e-inputs securityCode" type="text" maxlength="1" id="securitySmscode2"
                      (input)="onKeyPressChange($event)" i18n-placeholder placeholder="{{ 'common.smsCode' | translate }}" name="securitySmscode2"
                      [(ngModel)]="securitySmscode2" />
                    <input class="e-inputs securityCode" type="text" maxlength="1" id="securitySmscode3"
                      (input)="onKeyPressChange($event)" i18n-placeholder placeholder="{{ 'common.smsCode' | translate }}" name="securitySmscode3"
                      [(ngModel)]="securitySmscode3" />
                    <input class="e-inputs securityCode" type="text" maxlength="1" id="securitySmscode4"
                      (input)="onKeyPressChange($event)" i18n-placeholder placeholder="{{ 'common.smsCode' | translate }}" name="securitySmscode4"
                      [(ngModel)]="securitySmscode4" />
                  </div>
                  <span class="errorMessage" *ngIf="invalidSecurityQuestion">{{
                    securityError
                    }}</span>
                </div>
                <div class="form-group login-btn-align">
                  <div class="btn-sign-in">
                    <button ejs-button cssClass="e-success e-button-decorate" [disabled]="
                        securitySmscode1 == '' ||
                        securitySmscode2 == '' ||
                        securitySmscode3 == '' ||
                        securitySmscode4 == ''
                          ? true
                          : false
                      " id="security-sms-submit" (click)="onSecuritySmsCodeSubmit()" i18n
                      type="submit">
                      {{ 'common.Submit' | translate }}
                    </button>
                  </div>
                </div>
                <div class="form-group mobile-field-align">
                  <div>
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.verificationCodeWillBeSentToRegisteredMobileNumber' | translate }}"
                        (click)="resendSMScodeClick()" i18n>{{ 'common.resendCode' | translate }}</a>
                  </div>
                  <div style="margin-top: 8px;">
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.returnBackToLogin' | translate }}" href='javascript:'
                        (click)="loginBack()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- /*forgot password page changes*/ -->
          <div class="col-sm-4 col-lg-4 col-md-4" *ngIf="showForgotPasswordPage == true">
            <form id="login-form" autocomplete="off">
              <h2>{{'common.forgotYourPassword'| translate}}</h2>
              <div class="form-group user-field-alignments e-float-input e-float-line">
                <label class="mob-label" for="name" i18n>{{ 'common.pleaseEnterTheEmailAddressLinkedToThisAccount' | translate }}</label>
                <input autofocus tabindex="1" class="form-control e-inputs login-input" [value]='email' type="email" id="email"
                  i18n-placeholder placeholder="{{ 'common.validEmailAddress' | translate }}" maxlength="255" name="email" [(ngModel)]="email"
                  autocomplete="off" (focus)="focusOutFunction(email)" (keyup)="focusOutFunction(email)"
                  style="width: 305px !important;" />
                <div *ngIf="!isValidEmail" class="errorMessage">{{ 'common.pleaseEnterAValidEmailAddress' | translate }}</div>
                <div *ngIf="isMailInValid" class="errorMessage">{{UmsInvalidEmail}}</div>
                <span *ngIf="isFormDirty" class="errorMessage">{{ 'common.pleaseEnterAnEmailAddress' | translate }}</span>
              </div>

              <div class="form-group login-btn-align">
                <div class="btn-sign-in">
                  <button ejs-button cssClass="e-success e-button-decorate" [disabled]="!isValidEmail || email ==''"
                    (click)="emailSubmitClick()" i18n type="submit">
                    {{ 'common.Submit' | translate }}
                  </button>
                </div>
              </div>

              <div class="form-group mobile-field-align">
                <a class="nav-link login-link" href='javascript:' (click)="backToLoginClick()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
              </div>

            </form>
          </div>

          <div class="col-sm-4 col-lg-4 col-md-4 login-right" *ngIf="showCodeVerifyPage == true">
            <form id="login-form" autocomplete="off">
              <h2>{{'common.forgotYourPassword'|translate}}</h2>
              <div class="form-group user-field-alignments">
                <div>
                  <label class="mob-label" for="name" i18n>{{ 'common.pleaseEnterTheCodeSentViaEmail' | translate }} </label>
                </div>
                <div class="field-align">
                  <div class="textInput">
                    <input autofocus tabindex="1" class="e-inputs"
                      style="width: 192px !important;"
                      type="text" id="code" i18n-placeholder placeholder="{{ 'common.code' | translate }}" maxlength="255" name="code" [(ngModel)]="code"
                      autocomplete="code" (focus)="focusOutFunction(code)" (keyup)="focusOutFunction(code)" />
                  </div>
                  <div *ngIf="!isValidcode" class="errorMessage">{{ 'common.invalidResetPasswordCode' | translate }}</div>
                  <span class="errorMessage" *ngIf="isFormDirty">{{ 'common.pleaseEnterResetPasswordCode' | translate }}</span>
                </div>
              </div>

              <div class="form-group login-btn-align">
                <div class="btn-sign-in">
                  <button ejs-button cssClass="e-success e-button-decorate" [disabled]="code == ''  "
                    (click)="codeSubmitClick()" i18n type="submit">
                    {{ 'common.Submit' | translate }}
                  </button>
                </div>
              </div>

              <div class="form-group mobile-field-align"
                style="padding-right: 10%;padding-left: 5%; margin-bottom:-15px">
                <a class="nav-link" style="float:left;" href='javascript:' (click)="resendCodeClick()" i18n>{{ 'common.resendCode' | translate }}</a>
              </div>
              <div class="form-group mobile-field-align" style="padding-right: 20%;padding-left: 60%;">
                <a class="nav-link" style="float:left;" href='javascript:' (click)="backToLoginClick()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
              </div>

            </form>
          </div>

          <!-- forgot password page end -->

        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 login-version hidden-xs">
      <span class="login-version-text">App:{{ applicationVersion }}, API:{{ assemblyVersion }}</span>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-9 login-footer hidden-xs">
      <span class="footer-text">&copy; {{ copyRightYear }}
        <a class="footer-link" target="_blank" href="http://ToxAwareSoftware.com" rel="noopener">ToxAware {{ 'common.softwareLimited' | translate }}
        </a>
      </span>
    </div>

    <!-- Mobile View -->
    <div class="login-mobile-body-div visible-xs visible-sm">
      <div class="login-main no-padding">
        <div id="login-container" class="login-container no-padding">
          <div class="col-sm-12 col-md-12 col-lg-12 loginmobile-inner-container" style="font-size:20px">
            <div class="col-sm-12" style="text-align: center;">
              {{loginMessage}}
            </div>
            <div class="visible-xs visible-sm p-20 mb-login-bg mb-login">
              <div class="mobile-logo-align">
                <img src="/assets/Images/ToxLogo.webp" class="login-img" i18n-alt alt="ToxAware Logo" />

              </div>
              <!-- <div class="mobile-logo-txt">ToxAware</div> -->
              <div class="mobile-login-form" *ngIf="showLoginPage == true">
                <div class="e-float-input e-float-line textInput dialogTextTop chg-pwd-input form-group">
                  <label for="UserName" class="m-b-10 mob-label" i18n>{{ 'common.user' | translate }}</label>
                  <input autofocus type="username" class="form-control m-b-30 login-input" id="tbxMobUserName"
                    name="tbxMobUserName" [(ngModel)]="userName" />
                  <span class="errorMessage" *ngIf="userName == '' && loginBtnActive">{{ 'common.EnterUser' | translate }}</span>
                </div>
                <div class="e-float-input e-float-line textInput dialogTextTop chg-pwd-input form-group">
                  <label for="pwd" class="m-b-10 mob-label" i18n>{{ 'common.Password' | translate }}</label>
                  <input type="password" class="form-control login-input" id="tbxMobPassword" [(ngModel)]="password"
                    name="tbxMobPassword" />
                  <span class="login-error errorMessage" *ngIf="invalidLoginError"><b>&#9432;</b>
                    {{LoginErrorInfo}}</span>
                </div>
                <!-- <div class="form-group">
                  <span class="inputLable">
                    <ejs-checkbox
                    #rememberPasswordMobile
                    i18n-label
                    label="Remember Me"
                    (change)="changeRememberPassword()"
                    [checked]="false"
                    i18n-title title="Remember me the next time I login."></ejs-checkbox>
                  </span>
                </div>
                <div class="form-group">
                  <span class="inputLable public-computer">
                    <ejs-checkbox
                      #rememberComputerMobile
                      cssClass="mobile-checkbox-label"
                      i18n-label
                      label="Public Computer"
                      (change)="changeRememberComputer()"
                      [checked]="false"
                    ></ejs-checkbox>
                  </span>
                  <span class="public-computer-info">
                    <sup
                      class="info-decorate"
                      (click)="publicComputerInfoClick()"
                      >&#9432;</sup
                    >
                  </span>
                  <span
                    id="public-info"
                    *ngIf="publicComputerInfo"
                    class="public-computer-info-text"
                  >Purge any saved data on logout for use on Public computers.
                  </span>
                </div> -->
                <div style="text-align: left;">
                  <a class="nav-link col-sm-12" style=" font-size: 16px;" href='javascript:' (click)="forgotPasswordClick()">{{"common.forgotYourPassword" | translate}}</a>
                  <div class="col-sm-12" >
                    <button ejs-button  id="btnClearCookie2" name="btnReset"
                cssClass="e-warning lockbtn-decorate"
                style="width: 28px; height: 28px; padding-left: 5px; position: relative;
                z-index: 10;"
                i18n-title title="{{'common.clearToxawareData' | translate }}"                
                (click)="clearCookiesLocalStorageAndIndexedDb()" data-ripple="true">
                <span class="icon-T_CloseRefresh ico-spacing"></span>
               </button>
                  </div>
                </div>
                <div class="col-sm-12" style="text-align: left;">
                  <button ejs-button cssClass="e-success focusableTwo e-button-decorate" [disabled]="
                      password == '' && userName == ''
                        ? true
                        : password != '' && userName != ''
                        ? false
                        : true
                    " (click)="loginClick()" i18n> {{ 'common.signIn' | translate }}
                    <!-- {{ "SIGN IN" }} <img src="/assets/Images/LoginArrow.png" /> -->
                  </button>
                </div>
                  <!-- Langauge selection -->
                  <div class="langauge-selector">
                    <ejs-dropdownlist id='languageDD' #languageDD [dataSource]='AvailableLanguages' [value]="currentLanguage" [fields]="languageDDfields"
                    placeholder="{{'common.language' | translate }}" floatLabelType="Always" (change)="setLanguageClick($event)" ></ejs-dropdownlist>
                  </div>
              </div>

              <div class="mobile-login-form" *ngIf="showSecurityVerificationPage == true">
                <div class="form-group smsmobile-align">
                  <img src="/assets/Images/Padlock_Gold.png" height="60px"><label class="mobileLoginHeader" i18n>{{ 'common.confirmYourIdentity' | translate }}: {{ 'common.question' | translate }} </label>
                </div>

                <div class="form-group e-float-input e-float-line">
                  <span class="icon-T_Arrow_Left" *ngIf="savedSecondQuestion == clientQuestion"
                    (click)="previousSecurityQuestion()" i18n-title title="{{ 'common.toAnswerFirstQuestion' | translate }}"></span>
                  <label class="m-b-10 mob-label">{{ clientQuestion }}</label>
                  <input autofocus class="form-control e-inputs login-input" type="text" id="tbxMobSecurityAnswer" i18n-placeholder placeholder="Answer"
                    (input)="onKeyPressChange($event)" name="tbxMobSecurityAnswer" [(ngModel)]="securityAnswer" />
                  <span class="login-error errorMessage" *ngIf="invalidSecurityQuestion">{{ securityError }}</span>
                </div>
                <div class="form-group smsmobilebtn-align">
                  <button ejs-button cssClass="e-success e-button-decorate" [disabled]="securityAnswer == '' ? true : false"
                    id="security-questions-submit2" (click)="onSecurityQuestionsSubmit()" i18n>
                    {{ 'common.Submit' | translate }}
                  </button>
                </div>
                <!-- <div class="form-group security-mobile-loginback">
                  <div class="codeforget-section">
                    <span class="back-login" (click)="loginBack()"><a class="forgetlink-decorate"
                        i18n-title title="Return login">Login</a></span>
                  </div>
                </div> -->
                <div class="form-group mobile-field-align">
                  <a class="nav-link login-link" href='javascript:' (click)="loginBack()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                </div>
              </div>

              <div class="mobile-login-form" *ngIf="showEmailVerificationPage == true">
                <div class="form-group smsmobile-align"><img src="/assets/Images/Padlock_Gold.png" height="60px">
                  <label class="mobileLoginHeader" i18n>{{ 'common.confirmYourIdentity' | translate }}: {{ 'common.emailVerification' | translate }} </label>
                </div>
                <div class="form-group e-float-input e-float-line">
                  <label class="m-b-10 mob-label" for="securityEmail" i18n>{{ 'common.pleaseEnterTheCodeSentViaEmail' | translate }}</label>
                  <input autofocus class="form-control e-inputs login-input" type="text" id="tbxMobSecurityEmail" i18n-placeholder placeholder="{{ 'common.code' | translate }}"
                    (input)="onKeyPressChange($event)" name="tbxMobSecurityEmail" [(ngModel)]="securityEmail" />
                  <span class="login-error errorMessage" *ngIf="invalidSecurityQuestion">{{ securityError }}</span>
                </div>
                <div class="form-group smsmobilebtn-align">
                  <button ejs-button cssClass="e-success e-button-decorate" [disabled]="securityEmail == '' ? true : false"
                    id="security-email-submit-mobile" (click)="onSecurityEmailSubmit()" i18n>
                    {{ 'common.Submit' | translate }}
                  </button>
                </div>
                <div class="form-group forget-emailcode-mobile">
                  <div>
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.verificationCodeWillBeSentToRegisteredEmailAddress' | translate }}"
                        (click)="resendEmailcodeClick()" i18n>{{ 'common.resendCode' | translate }}</a>
                  </div>
                  <div>
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.returnBackToLogin' | translate }}" href='javascript:'
                        (click)="loginBack()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                  </div>
                </div>
              </div>

              <div class="mobile-login-form" *ngIf="showSmsVerificationCodePage == true">
                <div class="form-group smsmobile-align"><img src="/assets/Images/Padlock_Gold.png" height="60px">
                  <label class="mobileLoginHeader" i18n>{{ 'common.confirmYourIdentity' | translate }}: {{ 'common.mobileVerification' | translate }}</label>
                </div>
                <div>
                  <label class="m-b-10 mob-label" for="" i18n>{{ 'common.pleaseEnterYourOneTimePasswordNumber' | translate }}</label>
                </div>
                <div class="form-group smsmobileinput-align">
                  <input autofocus class="form-control securityCode securityCode1" type="text" maxlength="1"
                    (input)="onKeyPressChange($event)" id="mobSecuritySmscode1" i18n-placeholder placeholder="SMS" name="securitySmscode1"
                    [(ngModel)]="securitySmscode1" />
                  <input class="form-control securityCode securityCode1" type="text" maxlength="1"
                    (input)="onKeyPressChange($event)" id="mobSecuritySmscode2" i18n-placeholder placeholder="SMS" name="securitySmscode2"
                    [(ngModel)]="securitySmscode2" />
                  <input class="form-control securityCode securityCode1" type="text" maxlength="1"
                    (input)="onKeyPressChange($event)" id="mobSecuritySmscode3" i18n-placeholder placeholder="SMS" name="securitySmscode3"
                    [(ngModel)]="securitySmscode3" />
                  <input class="form-control securityCode securityCode1" type="text" maxlength="1"
                    (input)="onKeyPressChange($event)" id="mobSecuritySmscode4" i18n-placeholder placeholder="SMS" name="securitySmscode4"
                    [(ngModel)]="securitySmscode4" />
                  <span class="errorMessage" *ngIf="invalidSecurityQuestion" i18n>{{
                    securityError
                    }}</span>
                </div>
                <div class="form-group smsmobilebtn-align">
                  <button ejs-button cssClass="e-success e-button-decorate" [disabled]="
                      securitySmscode1 == '' ||
                      securitySmscode2 == '' ||
                      securitySmscode3 == '' ||
                      securitySmscode4 == ''
                        ? true
                        : false
                    " id="security-sms-submit" (click)="onSecuritySmsCodeSubmit()" i18n>
                    {{ 'common.signIn' | translate }}
                  </button>
                </div>
                <div class="form-group forget-smscode-mobile">
                  <div>
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.verificationCodeWillBeSentToRegisteredMobileNumber' | translate }}"
                        href='javascript:' (click)="resendSMScodeClick()" i18n>{{ 'common.resendCode' | translate }}</a>
                  </div>
                  <div>
                      <a class="nav-link login-link"
                        i18n-title title="{{ 'common.returnBackToLogin' | translate }}"
                        href='javascript:' (click)="loginBack()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                  </div>
                </div>
              </div>

              <div class="mobile-login-form" *ngIf="showForgotPasswordPage == true">
                <div class="e-float-input e-float-line textInput dialogTextTop chg-pwd-input form-group">
                  <label for="UserName" class="m-b-10 mob-label" style="padding-right: 10px;" i18n>{{ 'common.pleaseEnterTheEmailAddressLinkedToThisAccount' | translate }}</label>
                  <input autofocus type="email" class="form-control m-b-30 login-input" id="email" name="email" maxlength="255"
                    autocomplete="off" [(ngModel)]="email" (focus)="focusOutFunction(email)"
                    (keyup)="focusOutFunction(email)" />
                  <span class="login-error errorMessage" *ngIf="isFormDirty " i18n>{{ 'common.pleaseEnterAnEmailAddress' | translate }}</span>
                  <div class="login-error errorMessage" *ngIf=" !isValidEmail " i18n>{{ 'common.pleaseEnterAValidEmailAddress' | translate }}</div>
                  <div class="login-error errorMessage" *ngIf=" isMailInValid ">{{UmsInvalidEmail}}</div>
                </div>

                <div class="form-group login-btn-align">
                  <div class="btn-sign-in">
                    <button ejs-button cssClass="e-success focusableTwo e-button-decorate" [disabled]="!isValidEmail || email ==''"
                      (click)="emailSubmitClick()" i18n>
                      {{ 'common.Submit' | translate }}
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <a class="nav-link login-link" style="color:#2f67a2" href='javascript:'
                    (click)="backToLoginClick()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                </div>
              </div>

              <div class="mobile-login-form" *ngIf="showCodeVerifyPage == true">
                <div class="form-group">
                  <label for="UserName" class="m-b-10 mob-label" i18n>{{ 'common.pleaseEnterTheCodeSentViaEmail' | translate }}</label>
                  <input autofocus type="text" class="form-control m-b-30" id="code" name="code" maxlength="255"
                    [(ngModel)]="code" (focus)="focusOutFunction(code)" (keyup)="focusOutFunction(code)" />
                  <div class="login-error errorMessage" *ngIf=" !isValidcode ">{{ 'common.invalidResetPasswordCode' | translate }}</div>
                  <span class="errorMessage" *ngIf=" isFormDirty">{{ 'common.pleaseEnterResetPasswordCode' | translate }}</span>
                </div>

                <button ejs-button cssClass="login-btn" [disabled]="code == '' " (click)="codeSubmitClick()" i18n>
                  {{ 'common.Submit' | translate }}
                </button>

                <div class="form-group">
                  <a class="nav-link forgot-password-text" style=" color:#2f67a2" href='javascript:'
                    (click)="resendCodeClick()" i18n>{{ 'common.resendCode' | translate }}</a>
                </div>
                <div class="form-group">
                  <a class="nav-link forgot-password-text" style=" color:#2f67a2;margin-right: -21%;"
                    href='javascript:' (click)="backToLoginClick()" i18n>{{ 'common.returnBackToLogin' | translate }}</a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 login-version visible-xs">
          <span class="login-version-text">App:{{ applicationVersion }}, API:{{ assemblyVersion }}</span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 login-footer visible-xs">
          <span class="footer-text">&copy; {{ copyRightYear }}
            <a class="footer-link" target="_blank" href="http://ToxAwareSoftware.com" rel="noopener">ToxAware Software Limited</a>
          </span>
        </div>
      </div>
    </div>

    <!-- Mobile View -->
  </div>
</body>

<div class="modal fade" id="loginChangePasswordModal" role="dialog"
  [ngStyle]="{'display': showPasswordExpiryModal ? 'block' : 'none' ,'opacity': '1'}">
  <div class="modal-dialog login-password-dialog">
    <div class="modal-content login-password-dialog-content">
      <div class="modal-header login-password-header-dialog">
        <h4 class="modal-title mob-modal-title">{{'common.changePassword'|translate}}</h4>
      </div>

      <div class="modal-body login-password-dialog-body">
        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 noPadding chg-pwd-row">
          <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 noPadding">
            <label class="labelLeft required-indicator chg-pwd-label" i18n>{{ 'common.newPassword' | translate }}</label>
          </div>
          <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 noPadding">
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 noPadding e-float-input dialogTextTop chg-pwd-input">
              <input type="password" *ngIf="isNewPwdView == false" class="" id="newPwd" [(ngModel)]="newPwd"
                name="newPwd" [value]='newPwd' (keyup)="NewPwdFocusIn($event)" (focus)="NewPwdFocusIn($event.target)"
                maxlength="255" title="{{ 'common.newPassword' | translate }}">
              <input type="text" *ngIf="isNewPwdView == true" class="" id="newPwd" [(ngModel)]="newPwd" name="newPwd"
                [value]='newPwd' (keyup)="NewPwdFocusIn($event)" (focus)="NewPwdFocusIn($event.target)" maxlength="255"
                title="{{ 'common.newPassword' | translate }} ({{ 'common.again' | translate }})">
              <span class="e-float-line textInput" *ngIf="isNewPwdView == false"></span>
              <span class="icon-T_Show-Password showPassword chg-pwd-eye" *ngIf="isNewPwdView == false"
                (click)="onNewPwdViewClick($event)"></span>
              <span class="icon-T_Show-Password showPassword showPasswordView chg-pwd-eye" *ngIf="isNewPwdView == true"
                (click)="onNewPwdViewClick($event)"></span>
            </div>
            <div id="errMsgNewPwd" class="errorMsg errorMsgDialog"></div>
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs12 noPadding" *ngIf="pwdRequirementShow == true">
              <span class="pwdRequirement">{{ 'common.passwordMustMeetTheFollowingRequirements' | translate }}. {{ 'common.itMustContain' | translate }},</span>
              <div class="errDiv" *ngIf="pwdLengthChecked == false"><span class="icon-T_Close"></span><span
                  class="pwdRequirement falsePwd">{{ 'common.atLeast' | translate }} {{hasLength}} {{ 'common.character' | translate }}{{passwordString}}</span></div>
              <div class="errDiv" *ngIf="pwdLengthChecked == true"><span class="icon-T_Success"></span><span
                  class="pwdRequirement truePwd">{{ 'common.atLeast' | translate }} {{hasLength}} {{ 'common.character' | translate }}{{passwordString}}</span></div>
              <div class="errDiv" *ngIf="pwdUpperCaseChecked == false && hasUpperCase"><span
                  class="icon-T_Close"></span><span class="pwdRequirement falsePwd">1 {{ 'common.uppercase' | translate }}</span></div>
              <div class="errDiv" *ngIf="pwdUpperCaseChecked == true && hasUpperCase"><span
                  class="icon-T_Success"></span><span class="pwdRequirement truePwd">1 {{ 'common.uppercase' | translate }}</span></div>
              <div class="errDiv" *ngIf="pwdLowerCaseChecked == false && hasLowerCase"><span
                  class="icon-T_Close"></span><span class="pwdRequirement falsePwd">1 {{ 'common.lowercase' | translate }}</span></div>
              <div class="errDiv" *ngIf="pwdLowerCaseChecked == true && hasLowerCase"><span
                  class="icon-T_Success"></span><span class="pwdRequirement truePwd">1 {{ 'common.lowercase' | translate }}</span></div>
              <div class="errDiv" *ngIf="pwdNumberChecked == false && hasNumber"><span class="icon-T_Close"></span><span
                  class="pwdRequirement falsePwd">1 numeric</span></div>
              <div class="errDiv" *ngIf="pwdNumberChecked == true && hasNumber"><span
                  class="icon-T_Success"></span><span class="pwdRequirement truePwd">1 {{ 'common.numeric' | translate }}</span></div>
              <div class="errDiv" *ngIf="pwdSpecialCharChecked == false && hasSpecialChar"><span
                  class="icon-T_Close"></span><span class="pwdRequirement falsePwd">1 {{ 'common.specialCharacter' | translate }}</span></div>
              <div class="errDiv" *ngIf="pwdSpecialCharChecked == true && hasSpecialChar"><span
                  class="icon-T_Success"></span><span class="pwdRequirement truePwd">1 {{ 'common.specialCharacter' | translate }}</span></div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 noPadding chg-pwd-row">
          <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 noPadding">
            <label class="labelLeft required-indicator chg-pwd-label" i18n>{{ 'common.confirmNewPassword' | translate }} </label>
          </div>
          <div class="col-lg-6 col-sm-12 col-md-6 col-xs-12 noPadding">
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 e-float-input dialogTextTop chg-pwd-input">
              <input type="password" *ngIf="isConfirmPwdView == false" class="" id="confirmNewPwd"
                [(ngModel)]="confirmNewPwd" name="confirmNewPwd" [value]='confirmNewPwd' title="New Password"
                (keyup)="ConfirmPwdFocusIn($event)" (focus)="ConfirmPwdFocusIn($event.target)" maxlength="255">
              <input type="text" *ngIf="isConfirmPwdView == true" class="" id="confirmNewPwd"
                [(ngModel)]="confirmNewPwd" name="confirmNewPwd" [value]='confirmNewPwd' title="New Password (again)"
                (keyup)="ConfirmPwdFocusIn($event)" (focus)="ConfirmPwdFocusIn($event.target)" maxlength="255">
              <span class="e-float-line textInput"></span>
              <span class="icon-T_Show-Password showPassword chg-pwd-eye" *ngIf="isConfirmPwdView == false"
                (click)="onConfirmPwdViewClick($event)"></span>
              <span class="icon-T_Show-Password showPassword showPasswordView chg-pwd-eye"
                *ngIf="isConfirmPwdView == true" (click)="onConfirmPwdViewClick($event)"></span>
            </div>
            <div id="errConfirmPwd" class="errorMsg errorMsgDialog"></div>
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 chg-pwd-info" i18n>{{ 'common.pleaseNoteAfterChangingYourPasswordYouMustLogBackIn' | translate }}.</div>
      </div>

      <div class="modal-footer login-password-dialog-footer">
        <span class="icon-Back chg-pwd-back-login" (click)="loginBackOnChangePassword()"><a
            i18n-title title="Return login" i18n>{{ 'common.login' | translate }}</a></span>
        <button ejs-button *ngIf='!isForgotPassword' id='login-change-pwd-btn' [disabled]="!(isChangePwd && pwdInfo)"
          type="button" class="commonBtn changePwd-ok-btn" (click)="saveUserPasswordClick()" i18n>{{ 'common.Apply' | translate }}</button>
        <button ejs-button *ngIf='isForgotPassword' id='login-change-pwd-btn' [disabled]="!(isChangePwd && pwdInfo)"
          type="button" class="commonBtn changePwd-ok-btn" (click)="confirmPasswordClick()" i18n>{{ 'common.Apply' | translate }}</button>
      </div>
    </div>
  </div>
</div>
