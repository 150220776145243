import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/timeout';
import { map, timeout } from 'rxjs/operators';
import { AppConstants } from '../app.constants';
import { environment } from './../../environments/environment';
import { OfflineService } from './OfflineService';
import { ToasterService } from './ToasterService';
import { Notice } from '../models/NoticeModel';

declare var require: any;
require('indexeddb-getall-shim');
@Injectable()
export class OnlineService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private offlineService: OfflineService,
    private _cookieService: CookieService,
    public toastService: ToasterService
  ) {

    this.connectionDetectionStatus.subscribe((isOnline) => {
      let newIsOnline = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
      if (newIsOnline == 'true' && isOnline == 'true') {
        if (window.localStorage['SyncStatus'] != undefined && window.localStorage['SyncStatus'] != null) {
          localStorage.removeItem('SyncStatus');
        }
        this.SyncOfflineDataBase();
      } else if (newIsOnline == 'false') {
      } else {
      }
    });
  }

  // set the api and ums URL to the current URL if production, otherwise use the environment
  // public apiUrl: string = isDevMode() ? environment.apiUrl : location.origin + '/';
  // public umsUrl: string = isDevMode() ? environment.apiUrl : location.origin + '/';
  public OnlineTimeout = AppConstants.Defaults.OnlineTimeout;

  public apiUrl: string = window.location.hostname!="localhost"? window.location.origin + "/":environment.apiUrl; // Was coming from environment.apiUrl that was set in environment.
  public umsUrl: string = window.location.hostname!="localhost"? window.location.origin + "/":environment.apiUrl; // Was coming from environment.apiUrl that was set in environment.


  public substanceLookupUrl: string = environment.substanceLookupUrl;

  // BehaviorSubject to store User display name
  private currentUserDisplayNameStore = new BehaviorSubject<string>('');
  private currentUserProfilePicStore = new BehaviorSubject<string>('');
  private IsOnlineNetworkStatusStore = new BehaviorSubject<string>('');
  public connectionDetectionStatusStore = new BehaviorSubject<string>('');

  // Make User display name store Observable
  public currentDisplayName = this.currentUserDisplayNameStore.asObservable();
  public currentUserProfilePic = this.currentUserProfilePicStore.asObservable();
  public IsOnlineNetworkStatus = this.IsOnlineNetworkStatusStore.asObservable();

  public connectionDetectionStatus = this.connectionDetectionStatusStore.asObservable();

  // page permission update in site header
  private homePagePermissionStore = new BehaviorSubject<boolean>(false);
  private exposurePagePermissionStore = new BehaviorSubject<boolean>(false);
  private reportsPagePermissionStore = new BehaviorSubject<boolean>(false);
  private searchPagePermissionStore = new BehaviorSubject<boolean>(false);
  private auditPagePermissionStore = new BehaviorSubject<boolean>(false);
  private locksPagePermissionStore = new BehaviorSubject<boolean>(false);
  private reviewsPagePermissionStore = new BehaviorSubject<boolean>(false);
  private systemSettingsPagePermissionStore = new BehaviorSubject<boolean>(false);
  private infoRequestPagePermissionStore = new BehaviorSubject<boolean>(false);

  private pagePermissionListStore = new BehaviorSubject<any[]>([]);

  public pagePermissionList = this.pagePermissionListStore.asObservable();

  public homePagePermission = this.homePagePermissionStore.asObservable();
  public exposurePagePermission = this.exposurePagePermissionStore.asObservable();
  public reportsPagePermission = this.reportsPagePermissionStore.asObservable();
  public searchPagePermission = this.searchPagePermissionStore.asObservable();
  public auditPagePermission = this.auditPagePermissionStore.asObservable();
  public locksPagePermission = this.locksPagePermissionStore.asObservable();
  public reviewsPagePermission = this.reviewsPagePermissionStore.asObservable();
  public systemSettingsPagePermission = this.systemSettingsPagePermissionStore.asObservable();
  public infoRequestPagePermission = this.infoRequestPagePermissionStore.asObservable();

  // API method url
  public url = {
    // Query Builder URLs
    QueryBuilderSearchUrl: this.apiUrl + 'api/QueryBuilder/GetCases',

    substanceLookupUrl: this.substanceLookupUrl,
    appLoginUrl: this.apiUrl + 'token',
    loginUrl: this.apiUrl + 'api/JwtToken/token',
    logoutUrl: this.apiUrl + 'api/SystemSettings/Logout',
    loginTimeout: 15000,

    // Update Substance Search Sources
    updateSearchSourceUrl: this.apiUrl + 'api/SystemSettings/UpdateSearchSource',

    // MySubstance Methods
    addMySubstanceUrl: this.apiUrl + 'api/MySubstance/AddSubstance',
    editMySubstanceUrl: this.apiUrl + 'api/MySubstance/UpdateSubstance',
    deleteMySubstanceUrl: this.apiUrl + 'api/MySubstance/DeleteSubstance',
    getMySubstancesUrl: this.apiUrl + 'api/MySubstance/GetMySubstances',
    getMySubstanceDetailsUrl: this.apiUrl + 'api/MySubstance/GetSubstance',

    getMySubstanceSearchUrl: this.apiUrl + 'api/common/GetMySubstanceSearchItems',
    getSubstanceSearchUrl: this.apiUrl + 'api/common/GetSubstanceSearchItems',
    getSubstanceSearchUpdatesUrl: this.apiUrl + 'api/common/GetSubstanceSearchUpdatedItems',
    getSubstanceSourcesUrl: this.apiUrl + 'api/common/GetSubstanceSources',

    getLoggedInUmsUserUrl: this.apiUrl + 'api/UMS/GetUserDetailsByUserId',
    updateUmsUserInformationUrl: this.apiUrl + 'api/UserSettings/UpdateProfile',
    updateUmsUserPasswordUrl: this.apiUrl + 'api/UMS/UpdateNewPassword',

    // getLoggedInUserUrl: this.apiUrl + "api/common/GetUserInformation",

    getCasesByIdUrl: this.apiUrl + 'api/common/GetCasesById',
    getActiveUserListUrl: this.apiUrl + 'api/common/GetActiveUsers',
    networkCaseCountUrl: this.apiUrl + 'api/call/GetNetworkCaseCount',
    centreListUrl: this.apiUrl + 'api/common/GetCenters',
    centreCountUrl: this.apiUrl + 'api/call/GetCenterCaseCount',
    userCountUrl: this.apiUrl + 'api/call/GetUserCaseCount',
    networkCaseGridDataUrl: this.apiUrl + 'api/call/GetNetworkCases',
    centerCaseGridDataUrl: this.apiUrl + 'api/call/GetCenterCases',
    userCaseGridDataUrl: this.apiUrl + 'api/call/GetUserCases',
    reviewCaseGridDataUrl: this.apiUrl + 'api/call/GetUserCasesReadyToReview',
    responseCaseGridDataUrl: this.apiUrl + 'api/call/GetUserCasesResponseNeeded',
    exposureByIdUrl: this.apiUrl + 'api/exposure/GetExposureDetails',
    getExposurePageDetailByExposureIdUrl: this.apiUrl + 'api/Exposure/getExposurePageDetailByExposureId',
    infoRequestByIdUrl: this.apiUrl + 'api/infoRequest/GetInfoRequestDetails',
    userCaseByIdUrl: this.apiUrl + 'api/exposure/GetExposureDetails',
    gridColumnObjUrl: this.apiUrl + 'api/common/SaveUserGridConfiguration',
    deleteUserGridConfigurationUrl: this.apiUrl + 'api/common/DeleteUserGridConfiguration',
    gridColumnDetailUrl: this.apiUrl + 'api/common/GetUserGridConfiguration',
    getHistoryDetailUrl: this.apiUrl + 'api/call/GetCaseHistory',
    getCodeValueUrl: this.apiUrl + 'api/common/GetCodeValues',

    getActiveSPIDataUrl: this.apiUrl + 'api/dashboard/GetActiveSPIChartData',
    getUsageDataURL: this.apiUrl + 'api/dashboard/GetUsageChartData',
    getCallerSiteChartDataURL: this.apiUrl + 'api/dashboard/GetCallerSiteChartData',
    getCaseTypeCallVolumeDataURL: this.apiUrl + 'api/dashboard/GetCaseTypeCallVolumeData',

    addExposureUrl: this.apiUrl + 'api/Exposure/AddExposure',
    addInfoRequestUrl: this.apiUrl + 'api/InfoRequest/AddInfoRequest',
    editExposureUrl: this.apiUrl + 'api/Exposure/EditExposure',
    editInfoRequestUrl: this.apiUrl + 'api/InfoRequest/EditInfoRequest',
    addCaseReviewUrl: this.apiUrl + 'api/common/AddCaseReview',
    editCaseReviewUrl: this.apiUrl + 'api/common/EditCaseReview',
    removeCaseReviewUrl: this.apiUrl + 'api/common/RemoveCaseReview',
    addCaseResponseUrl: this.apiUrl + 'api/common/AddCaseResponse',
    editCaseResponseUrl: this.apiUrl + 'api/common/EditCaseResponse',
    removeCaseResponseUrl: this.apiUrl + 'api/common/RemoveCaseResponse',
    addCaseAttachmentUrl: this.apiUrl + 'api/common/AddCaseAttachment',
    deleteCaseAttachmentUrl: this.apiUrl + 'api/common/DeleteCaseAttachment',
    getCaseAttachmentUrl: this.apiUrl + 'api/common/GetCaseAttachment',
    fileUploadSaveUrl: this.apiUrl + 'api/common/FileUploadSave',
    fileUploadDeleteUrl: this.apiUrl + 'api/common/FileUploadDelete',
    getGenericSubstanceUrl: this.apiUrl + 'api/common/GetGenericSubstances',
    syncAddExposureUrl: this.apiUrl + 'api/Exposure/AddOfflineExposures',
    syncEditExposureUrl: this.apiUrl + 'api/Exposure/EditOfflineExposures',
    getSearchList: this.apiUrl + 'api/common/GetSearchList',
    getSearchResultsUrl: this.apiUrl + 'api/Search/GetSearchCases',
    getSSearchResultsUrl: this.apiUrl + 'api/Search/GetSSearchCases',

    getRecallsListUrl: this.apiUrl + 'api/Exposure/GetRecallsList2',
    getPatientListUrl: this.apiUrl + 'api/Exposure/getPatientList',

    getSystemSettingsUrl: this.apiUrl + 'api/SystemSettings/GetSystemSettings',
    getCodeTypesUrl: this.apiUrl + 'api/SystemSettings/GetCodeTypes',
    getCodeValuesByCodeTypeUrl: this.apiUrl + 'api/SystemSettings/GetCodeValuesByCodeType',
    saveSystemSettingsUrl: this.apiUrl + 'api/SystemSettings/SaveSystemSettings',
    saveCodeValuesUrl: this.apiUrl + 'api/SystemSettings/SaveCodeValues',
    getAuthenticationDurationUrl: this.apiUrl + 'api/SystemSettings/GetDurations',

    getNoticesUrl: this.apiUrl + 'api/Notice/GetNotices',
    // getNotices:this.apiUrl + 'api/SystemSettings/GetNotices',
    createNoticeUrl:this.apiUrl + 'api/Notice/AddNotice',
    updateNoticeUrl:this.apiUrl + 'api/Notice/UpdateNotice',
    deleteNoticeUrl:this.apiUrl + 'api/Notice/DeleteNotice',

    getValidationsUrl: this.apiUrl + 'api/SystemSettings/GetValidations',
    editValidationRuleTypeUrl: this.apiUrl + 'api/UserSettings/EditValidationType',
    // editValidationRuleTypeUrl: this.apiUrl + 'api/SystemSettings/SaveSystemSettings',
    editValidationRuleActiveUrl: this.apiUrl + 'api/UserSettings/EditValidationActive',
    editValidationRuleFatalUrl: this.apiUrl + 'api/UserSettings/EditValidationFatal',

    saveProfileImageUrl: this.apiUrl + 'api/common/SaveProfileImage',
    getCenterValueUrl: this.apiUrl + 'api/common/GetCenters',
    getUserInformationUrl: this.apiUrl + 'api/common/GetUserInformation',
    getUserSettingsUrl: this.apiUrl + 'api/UserSettings/GetUserSettings',
    // updateUserInformationUrl: umsUrl + "v1.0/users/{user}",
    // updateUserInformationUrl: this.apiUrl + "api/common/UpdateUserInformation",
    saveUserSettingsUrl: this.apiUrl + 'api/UserSettings/SaveUserSettings',
    getSecurityQuestionsUrl: this.apiUrl + 'api/UserSettings/GetSecurityQuestions',
    getUserSecurityQuestionsUrl: this.apiUrl + 'api/UserSettings/GetUserSecurityQuestions',
    GetUserSecurityQuestionsWithAnswersUrl: this.apiUrl + 'api/UserSettings/GetUserSecurityQuestionsWithAnswers',
    saveUserSecurityQuestionsUrl: this.apiUrl + 'api/UserSettings/SaveUserSecurityQuestions',
    addExposureLockUrl: this.apiUrl + 'api/Locks/AddExposureLock',
    addInfoRequestLockUrl: this.apiUrl + 'api/Locks/AddInfoRequestLock',
    getLockInfoUrl: this.apiUrl + 'api/Locks/GetLockInfo',
    deleteCallLockUrl: this.apiUrl + 'api/Locks/DeleteCallLock',
    deleteExposureLockUrl: this.apiUrl + 'api/Locks/DeleteExposureLock',
    addCallLockUrl: this.apiUrl + 'api/Locks/AddCallLock',
    getAuthenticationTypeUrl: this.apiUrl + 'api/UserSettings/GetAuthenticationTypes',
    getAuditLogsUrl: this.apiUrl + 'api/audits/GetAuditLogs',
    addAuditUrl: this.apiUrl + 'api/audits/AddAuditLog',
    getLogTypesUrl: this.apiUrl + 'api/audits/GetLogTypes',
    getModulesUrl: this.apiUrl + 'api/audits/GetModules',
    getOperationTypesByModuleUrl: this.apiUrl + 'api/audits/GetOperationTypesByModule',
    generalReportActionUrl: this.apiUrl + 'api/Reports/GetReportData',
    getExpoReportDataUrl: this.apiUrl + 'api/Reports/GetExpoReportData',
    getInfoReportDataUrl: this.apiUrl + 'api/Reports/GetInfoReportData',
    getTrainingsUrl: this.apiUrl + 'api/Reports/GetTrainings',
    reportUrl: this.apiUrl + 'api/ReportApi',
    getLocksUrl: this.apiUrl + 'api/Locks/GetLocks',
    getCallDeleteUrl: this.apiUrl + 'api/Locks/DeleteCallLock',
    getExpoureDeleteUrl: this.apiUrl + 'api/Locks/DeleteExposureLock',
    getAllLockDeleteUrl: this.apiUrl + 'api/Locks/DeleteLocks',
    getAssemblyVersionUrl: this.apiUrl + 'api/Accounts/GetAssemblyVersion',
    getLoginMessageUrl: this.apiUrl + 'api/Accounts/GetLoginMessage',
    getOwnerNameUrl: this.apiUrl + 'api/Accounts/GetOwnerName',
    getUserListUrl: this.apiUrl + 'api/common/GetUsers',
    getLockUserListUrl: this.apiUrl + 'api/Locks/GetLockUsers',
    addErrorLogUrl: this.apiUrl + 'api/audits/AddErrorLog',
    getErrorLogUrl: this.apiUrl + 'api/audits/GetErrorLogs',

    getInfoRequestValidationUrl: this.apiUrl + 'api/InfoRequest/getInfoRequestValidation',
    getExposureValidationDetailsUrl: this.apiUrl + 'api/Exposure/GetExposureValidationDetails',

    //sendEmailUrl: this.apiUrl + 'api/Exposure/EmailExposure',
    sendReportByEmailUrl: this.apiUrl + 'api/Reports/SendReportByEmail',
    pdfUrl: this.apiUrl + 'api/Exposure/SaveExposureAsPDF',
    emailVerificationCodeEntryUrl: this.apiUrl + 'api/UserSettings/UpdateEmailVerificationCode',
    twoFactorDurationUrl: this.apiUrl + 'api/SystemSettings/GetDurations',
    emailVerificationUrl: this.apiUrl + 'api/UserSettings/ValidateEmailVerificationCode',
    getUserSecurityQuestionsWithAnswersUrl: this.apiUrl + 'api/UserSettings/ValidateUserSecurityQuestion',
    smsVerificationUrl: this.apiUrl + 'api/UserSettings/ValidateSMSVerificationCode',
    smsVerificationCodeEntryUrl: this.apiUrl + 'api/UserSettings/UpdateSMSVerificationCode',
    twoFactorDateUpdateUrl: this.apiUrl + 'api/UserSettings/UpdateTwoFactorAuthenticationDate',
    getPagePermissionListUrl: this.apiUrl + 'api/common/GetUserPermissions',
    deleteCallAndExposureLockUrl: this.apiUrl + 'api/Locks/DeleteCallAndExposureLock',
    getCookieSettingsUrl: this.apiUrl + 'api/UserSettings/GetCookieSettings',
    getPasswordConfigurationUrl: this.apiUrl + 'api/UMS/GetPasswordConfiguration',
    setPasswordUrl: this.apiUrl + 'api/UMS/UpdatePasswordByUserId',
    setPasswordOnFirstLoginUrl: this.apiUrl + 'api/UMS/UpdatePasswordByUserIdOnFirstLogin',
    isValidEmail: this.apiUrl + 'api/UMS/ValidateUserEmail',
    isValidCode: this.apiUrl + 'api/UMS/ValidateResetPasswordCode',
    forgotPassword: this.apiUrl + 'api/UMS/UpdatePasswordByUserEmail',

    getLoginPageSettingsUrl: this.apiUrl + 'api/UserSettings/GetLoginPageSettings',
    getHomeCaseCountUrl: this.apiUrl + 'api/Call/GetHomePageCasesCount',
    getLoginCodeValueUrl: this.apiUrl + 'api/UserSettings/GetCodeValues',
    saveAppThemeUrl: this.apiUrl + 'api/UserSettings/SaveAppTheme',
    getAppThemeUrl: this.apiUrl + 'api/UserSettings/GetTheme',
    saveThemeIdUrl: this.apiUrl + 'api/UserSettings/SaveTheme',
    getAppSavedThemesUrl: this.apiUrl + 'api/UserSettings/GetSavedThemes',
    getSystemSettingWithUserListUrl: this.apiUrl + 'api/SystemSettings/GetSystemSettingWithUserList',

    getUsersSubscriptionTargetsUrl: this.apiUrl + 'api/UserSettings/GetUsersSubscriptionTargets',
    subscribeUserPushNotificationsUrl: this.apiUrl + 'api/UserSettings/SubscribeUserPushNotifications',
    unsubscribeUserPushNotificationsUrl: this.apiUrl + 'api/UserSettings/UnsubscribeUserPushNotifications',
    sendUserPushNotificationUrl: this.apiUrl + 'api/UserSettings/SendUserPushNotification',
    SendUserTestNotificationUrl: this.apiUrl + 'api/UserSettings/SendUserTestNotification',

    checkOnlineUrl: this.apiUrl + 'api/Accounts/ConnectionExist',
    htmltopdfUrl: this.apiUrl + 'api/Common/HtmlToPdf',
    exportReportToPdfUrl: this.apiUrl + 'api/Reports/ExportReportToPdf',
    exportReportDownloadUrl: this.apiUrl + 'api/Reports/ExportReportDownload',
  };

  private themeWrapper = document.querySelector('body');

  public UserId = this._cookieService.get(AppConstants.CookieName.UserId);
  public isRemembered: string = this._cookieService.get(AppConstants.CookieName.RememberMe);


// methods
CheckIfOnline(AcceptLocalStorage = false) {
  if (AcceptLocalStorage || localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'false') {
    this.doesConnectionExist();
  }
  return localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
}
  
  setConnectionStatus(isOnline: string) {
    this.connectionDetectionStatusStore.next(isOnline);
  }

  // Setter to update User Display Name
  setCurrentUserInfo(userDisplayName: string, userProfileImage: string) {
    this.currentUserDisplayNameStore.next(userDisplayName);
    this.currentUserProfilePicStore.next(userProfileImage);
  }

  pagePermissionUpdate(pagePermissionList: any[]) {
    this.pagePermissionListStore.next(pagePermissionList);
  }

  setPagePermission(
    homePagePermission: boolean,
    exposurePagePermission: boolean,
    reportsPagePermission: boolean,
    searchPagePermission: boolean,
    auditPagePermission: boolean,
    locksPagePermission: boolean,
    reviewsPagePermission: boolean,
    systemSettingsPagePermission: boolean,
    infoRequestPagePermission: boolean
  ) {
    this.homePagePermissionStore.next(homePagePermission);
    this.exposurePagePermissionStore.next(exposurePagePermission);
    this.reportsPagePermissionStore.next(reportsPagePermission);
    this.searchPagePermissionStore.next(searchPagePermission);
    this.auditPagePermissionStore.next(auditPagePermission);
    this.locksPagePermissionStore.next(locksPagePermission);
    this.reviewsPagePermissionStore.next(reviewsPagePermission);
    this.systemSettingsPagePermissionStore.next(systemSettingsPagePermission);
    this.infoRequestPagePermissionStore.next(infoRequestPagePermission);
  }
  // page permission update in site header

  // Theme codes
  setHightContrastDarkTheme(primaryColor: string, primaryFont: string, secondaryColor: string) {
    // app page properties update
    this.themeWrapper.style.setProperty('--whiteColor', '#000');
    this.themeWrapper.style.setProperty('--blackColor', '#FFF');
    this.themeWrapper.style.setProperty('--appPageBgColor', '#212121');
    this.themeWrapper.style.setProperty('--exposurePageBgColor', '#212121');
    this.themeWrapper.style.setProperty('--headerBgColor', '#000');
    this.themeWrapper.style.setProperty('--footerBgColor', '#000');
    this.themeWrapper.style.setProperty('--primaryColor', primaryColor);
    this.themeWrapper.style.setProperty('--primaryFontColor', primaryFont);
    this.themeWrapper.style.setProperty('--secondaryColor', secondaryColor);
    this.themeWrapper.style.setProperty('--btnActiveBg', '#ffd939');
    this.themeWrapper.style.setProperty('--btnHoverBg', '#685708');
    this.themeWrapper.style.setProperty('--highcontrastBg', '#000');

    // report control properties update
    this.themeWrapper.style.setProperty('--reportToolbarBg', '#212121');
    this.themeWrapper.style.setProperty('--reportToolbarBorder', '#FFF');
    this.themeWrapper.style.setProperty('--reportViewerBg', '#212121');
    this.themeWrapper.style.setProperty('--reportForwardColor', '#212121');
    this.themeWrapper.style.setProperty('--rteToolbarBg', '#303030');

    this.themeWrapper.style.setProperty('--itemHoverColor', '');
    this.themeWrapper.style.setProperty('--itemActiveColor', '');
    this.themeWrapper.style.setProperty('--itemHeaderBgColor', '');

    // drop down control properties update
    this.themeWrapper.style.setProperty('--textboxBorderColor', '#a2a2a2');
    this.themeWrapper.style.setProperty('--definedDropDownPopupColor', '#424242');
    this.themeWrapper.style.setProperty('--hoverBgColor', '');
    this.themeWrapper.style.setProperty('--dropDownBgColor', '');

    // grid control properties update
    this.themeWrapper.style.setProperty('--gridBorderColor', '#a2a2a2');
    this.themeWrapper.style.setProperty('--columnChooserBg', '');
    this.themeWrapper.style.setProperty('--gridHeaderBg', '#424242');

    // multiselect control properties update
    this.themeWrapper.style.setProperty('--msChipBgColor', '#424242');
    let isRGBA = false;
    isRGBA = primaryColor.includes('#') ? false : true;
    if (isRGBA) {
      const hex = this.RGBAToHexA(primaryColor);
      var lightColor = this.DarkColorLuminance(hex, -0.5);
    } else {
      var lightColor = this.DarkColorLuminance(primaryColor, -0.5);
    }
    this.themeWrapper.style.setProperty('--gridRowColor', lightColor);
  }

  setDarkTheme(primaryColor: string, primaryFont: string, secondaryColor: string) {
    // app page properties update
    this.themeWrapper.style.setProperty('--whiteColor', '#4E4E4E');
    this.themeWrapper.style.setProperty('--btnActiveBg', '#4E4E4E');
    this.themeWrapper.style.setProperty('--btnHoverBg', '#4E4E4E');
    this.themeWrapper.style.setProperty('--blackColor', '#FFF');
    this.themeWrapper.style.setProperty('--appPageBgColor', '#212121');
    this.themeWrapper.style.setProperty('--exposurePageBgColor', '#212121');
    this.themeWrapper.style.setProperty('--headerBgColor', '#000');
    this.themeWrapper.style.setProperty('--footerBgColor', '#000');
    this.themeWrapper.style.setProperty('--primaryColor', primaryColor);
    this.themeWrapper.style.setProperty('--primaryFontColor', primaryFont);
    this.themeWrapper.style.setProperty('--secondaryColor', secondaryColor);
    this.themeWrapper.style.setProperty('--highcontrastBg', '#212121');

    // report control properties update
    this.themeWrapper.style.setProperty('--reportToolbarBg', '#212121');
    this.themeWrapper.style.setProperty('--reportToolbarBorder', '#FFF');
    this.themeWrapper.style.setProperty('--reportViewerBg', '#212121');
    this.themeWrapper.style.setProperty('--reportForwardColor', '#212121');
    this.themeWrapper.style.setProperty('--rteToolbarBg', '#303030');

    this.themeWrapper.style.setProperty('--itemHoverColor', '#303030');
    this.themeWrapper.style.setProperty('--itemActiveColor', '#4E4E4E');
    this.themeWrapper.style.setProperty('--itemHeaderBgColor', '#6f6f73');

    // drop down control properties update
    this.themeWrapper.style.setProperty('--textboxBorderColor', '#a2a2a2');
    this.themeWrapper.style.setProperty('--definedDropDownPopupColor', '#424242');
    this.themeWrapper.style.setProperty('--hoverBgColor', '#616161');
    this.themeWrapper.style.setProperty('--dropDownBgColor', '');

    // grid control properties update
    this.themeWrapper.style.setProperty('--gridBorderColor', '#a2a2a2');
    this.themeWrapper.style.setProperty('--columnChooserBg', '#303030');
    this.themeWrapper.style.setProperty('--gridHeaderBg', '#424242');

    // multiselect control properties update
    this.themeWrapper.style.setProperty('--msChipBgColor', '#424242');

    let isRGBA = false;
    isRGBA = primaryColor.includes('#') ? false : true;
    if (isRGBA) {
      let hex = this.RGBAToHexA(primaryColor);
      var lightColor = this.DarkColorLuminance(hex, -0.5);
    } else {
      var lightColor = this.DarkColorLuminance(primaryColor, -0.5);
    }
    this.themeWrapper.style.setProperty('--gridRowColor', lightColor);
  }

  setLightTheme(primaryColor: string, primaryFont: string, secondaryColor: string) {
    // app page properties update
    this.themeWrapper.style.setProperty('--whiteColor', '#FFF');
    this.themeWrapper.style.setProperty('--btnActiveBg', '#FFF');
    this.themeWrapper.style.setProperty('--btnHoverBg', '#FFF');
    this.themeWrapper.style.setProperty('--blackColor', '#232729');
    this.themeWrapper.style.setProperty('--appPageBgColor', '#FFF');
    this.themeWrapper.style.setProperty('--exposurePageBgColor', '#eeeeee');
    this.themeWrapper.style.setProperty('--headerBgColor', '#FFF');
    this.themeWrapper.style.setProperty('--footerBgColor', 'whiteSmoke');
    this.themeWrapper.style.setProperty('--primaryColor', primaryColor);
    this.themeWrapper.style.setProperty('--primaryFontColor', primaryFont);
    this.themeWrapper.style.setProperty('--secondaryColor', secondaryColor);
    this.themeWrapper.style.setProperty('--highcontrastBg', '#FFF');

    // report control properties update
    this.themeWrapper.style.setProperty('--reportToolbarBg', '#fafafa');
    this.themeWrapper.style.setProperty('--reportToolbarBorder', '#e0e0e0');
    this.themeWrapper.style.setProperty('--reportViewerBg', '#eee');
    this.themeWrapper.style.setProperty('--reportForwardColor', '#f5f5f5');
    this.themeWrapper.style.setProperty('--rteToolbarBg', '#fafafa');

    this.themeWrapper.style.setProperty('--itemHoverColor', '#e0e0e0');
    this.themeWrapper.style.setProperty('--itemActiveColor', '#eee');
    this.themeWrapper.style.setProperty('--itemHeaderBgColor', '#F0F0F0');

    // dropdown control properties update
    this.themeWrapper.style.setProperty('--textboxBorderColor', 'rgba(0, 0, 0, 0.42)');
    this.themeWrapper.style.setProperty('--definedDropDownPopupColor', '#FFF');
    this.themeWrapper.style.setProperty('--hoverBgColor', '#eee');
    this.themeWrapper.style.setProperty('--dropDownBgColor', '0 0');

    // grid control properties update
    this.themeWrapper.style.setProperty('--columnChooserBg', '#f4f4f4');
    this.themeWrapper.style.setProperty('--gridBorderColor', '#e0e0e0');
    this.themeWrapper.style.setProperty('--gridHeaderBg', '#fafbfc');

    // multiselect control properties update
    this.themeWrapper.style.setProperty('--msChipBgColor', '#eee');

    let isRGBA = false;
    isRGBA = primaryColor.includes('#') ? false : true;
    if (isRGBA) {
      let hex = this.RGBAToHexA(primaryColor);
      var lightColor = this.ColorLuminance(hex, 2);
    } else {
      var lightColor = this.ColorLuminance(primaryColor, 2);
    }

    this.themeWrapper.style.setProperty('--gridRowColor', lightColor);
  }

  RGBAToHexA(rgba) {
    let sep = rgba.indexOf(',') > -1 ? ',' : ' ';
    rgba = rgba.substr(5).split(')')[0].split(sep);

    // Strip the slash if using space-separated syntax
    if (rgba.indexOf('/') > -1) { rgba.splice(3, 1); }

    for (let R in rgba) {
      let r = rgba[R];
      if (r.indexOf('%') > -1) {
        let p = r.substr(0, r.length - 1) / 100;

        if (parseInt(R, 10) < 3) {
          rgba[R] = Math.round(p * 255);
        } else {
          rgba[R] = p;
        }
      }
    }

    // converting RGBA to Hex
    let r = (+rgba[0]).toString(16),
      g = (+rgba[1]).toString(16),
      b = (+rgba[2]).toString(16),
      a = Math.round(+rgba[3] * 255).toString(16);

    if (r.length == 1) { r = '0' + r; }
    if (g.length == 1) { g = '0' + g; }
    if (b.length == 1) { b = '0' + b; }
    if (a.length == 1) { a = '0' + a; }

    return '#' + r + g + b + a;
  }

  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ('ff' + c).substr(c.length);
    }

    return rgb;
  }

  DarkColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
    }

    return rgb;
  }

  materialTheme() {
    let fabricTheme = document.getElementById('fabric-link');
    //fabricTheme["disabled"] = true;
    let bootstraptheme = document.getElementById('bootstrap-link');
    //bootstraptheme["disabled"] = true;
    let highcontrasttheme = document.getElementById('highcontrast-link');
    //highcontrasttheme["disabled"] = true;
    let appFabricCss = document.getElementById('appfabric-link');
    //appFabricCss["disabled"] = true;
    let appBootstrapCss = document.getElementById('appbootstrap-link');
    //appBootstrapCss["disabled"] = true;
    let appHighContrastCss = document.getElementById('apphighcontrst-link');
    //appHighContrastCss["disabled"] = true;

    let materialTheme = document.getElementById('material-link');
    //materialTheme["disabled"] = false;
    let appcommonTheme = document.getElementById('appcommon-link');
    //appcommonTheme["disabled"] = false;
  }

  public saveAppTheme(themeProperties): Observable<any> {
    return this.httpClient.post(this.url.saveAppThemeUrl, themeProperties);
  }

  public getTheme(id): Observable<any> {
    return this.httpClient.get(this.url.getAppThemeUrl + '/' + id);
  }

  public saveTheme(id): Observable<any> {
    return this.httpClient.post(this.url.saveThemeIdUrl + '/' + id, id);
  }

  public getSavedThemes(): Observable<any> {
    return this.httpClient.get(this.url.getAppSavedThemesUrl);
  }

  public connectionExist(): Observable<any> {
    return this.httpClient.get(this.url.checkOnlineUrl);
  }

  doesConnectionExist() {
    const canCheckOnline = this._cookieService.get(AppConstants.CookieName.CanPingOnlineStatus);
    if (canCheckOnline == 'true') {
      this._cookieService.set(AppConstants.CookieName.CanPingOnlineStatus, 'false', 1000, '/', null, true, "Strict");
      this.connectionExist().subscribe(
        (result: any) => {
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'false') {
            localStorage.setItem(AppConstants.LocalStorage.OnLineStatus, 'true');

            // check for IE and return false. Why? because IE does not support online/offline events
            const ua = window.navigator.userAgent;
            let isIE = false;
            const msie = ua.indexOf('MSIE ');
            if (msie > 0) {
              // IE 10 or older => return version number
              isIE = true;
            }
            const trident = ua.indexOf('Trident/');
            if (trident > 0) {
              // IE 11 => return version number
              isIE = true;
            }

            if (isIE == false) {
              this.setConnectionStatus('true');
            }
          }

          this._cookieService.set(AppConstants.CookieName.CanPingOnlineStatus, 'true', 1000, '/', null, true, "Strict");
        },
        (err) => {
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
            localStorage.setItem(AppConstants.LocalStorage.OnLineStatus, 'false');

            const ua = window.navigator.userAgent;
            let isIE = false;
            const msie = ua.indexOf('MSIE ');
            if (msie > 0) {
              // IE 10 or older => return version number
              isIE = true;
            }
            const trident = ua.indexOf('Trident/');
            if (trident > 0) {
              // IE 11 => return version number
              isIE = true;
            }

            if (isIE == false) {
              this.setConnectionStatus('false');
            }
          }

          this._cookieService.set(AppConstants.CookieName.CanPingOnlineStatus, 'true', 1000, '/', null, true, "Strict");
        }
      );
    }
  }

  // exposure page optimized requests

  public htmltopdf(pdfInfo): Observable<any> {
    return this.httpClient.post(this.url.htmltopdfUrl, pdfInfo, { observe: 'response', responseType: 'blob' });
  }

  public getSystemSettingWithUserList(): Observable<any> {
    return this.httpClient.get(this.url.getSystemSettingWithUserListUrl);
  }

  public getUsersSubscriptionTargets(userId):any {
    return this.httpClient.get(this.url.getUsersSubscriptionTargetsUrl,userId);
  }

  public subscribeUserPushNotifications(subscriptionInfo): any {
    return this.httpClient.post(this.url.subscribeUserPushNotificationsUrl, subscriptionInfo);
  }
  public unsubscribeUserPushNotifications(subscriptionInfo): any {
    return this.httpClient.post(this.url.unsubscribeUserPushNotificationsUrl, subscriptionInfo);
  }
  public sendUserPushNotification(notificationInfo): any {
    return this.httpClient.post(this.url.sendUserPushNotificationUrl, notificationInfo);
  }
  public SendUserTestNotification(notificationInfo): any {
    return this.httpClient.post(this.url.SendUserTestNotificationUrl, notificationInfo);
  }

  // home page optimized requests
  public getHomePageCasesCount(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.getHomeCaseCountUrl, caseTypeFilterCriteria);
  }

  // login page optimized requests
  public getLoginPageSetting(id): Observable<any> {
    return this.httpClient.get(this.url.getLoginPageSettingsUrl + '/' + id);
  }
  public GetLoginCodeValues(id): Observable<any> {
    return this.httpClient.get(this.url.getLoginCodeValueUrl + '/' + id);
  }

  // forgot password apis

  // To validate email
  public IsValidEmail(user): Observable<any> {
    return this.httpClient.post(this.url.isValidEmail, user);
  }

  // To validate code
  public IsValidCode(validCode): Observable<any> {
    return this.httpClient.post(this.url.isValidCode, validCode);
  }

  // To set new password
  public ForgotPassword(newPassword): Observable<any> {
    return this.httpClient.post(this.url.forgotPassword, newPassword);
  }

  // ToxAware-UMS apis:

  // set password
  public setPassword(userDetail): Observable<any> {
    return this.httpClient.post(this.url.setPasswordUrl, userDetail);
  }

  // set password on first login
  public setPasswordOnFirstLogin(userDetail): Observable<any> {
    return this.httpClient.post(this.url.setPasswordOnFirstLoginUrl, userDetail);
  }

  // To get password configuration
  public GetPasswordConfiguration(): Observable<any> {
    return this.httpClient.get(this.url.getPasswordConfigurationUrl);
  }

  // Account login
  public login(loginUserCredentials): Observable<any> {
    return this.httpClient.post(this.url.loginUrl, loginUserCredentials).pipe(timeout(this.url.loginTimeout));
  }
  // Account logout
  public logout(): Observable<any> {
    return this.httpClient.post(this.url.logoutUrl, null); // post but nothing in it.
  }

  // Account login user details
  public loggedInUserDetails(id): Observable<any> {
    return this.httpClient.get(this.url.getLoggedInUmsUserUrl + '/' + id);
  }

  // update user info
  public updateUserInformation(userDetails): Observable<any> {
    return this.httpClient.post(this.url.updateUmsUserInformationUrl, userDetails);
  }

  public updateUmsUserPassword(user): Observable<any> {
    return this.httpClient.post(this.url.updateUmsUserPasswordUrl, user);
  }
  // END ToxAware-UMS apis

  public getCookieSettings(id): Observable<any> {
    return this.httpClient.get(this.url.getCookieSettingsUrl + '/' + id);
  }

  // get page permission list
  public getPagePermission(id): Observable<any> {
    return this.httpClient.get(this.url.getPagePermissionListUrl + '/' + id);
  }

  // get user question answer
  public getUserQuestionAnswer(userSecurityQuestion): Observable<any> {
    return this.httpClient.post(this.url.getUserSecurityQuestionsWithAnswersUrl, userSecurityQuestion);
  }

  // Entry for email verification code
  public emailVerificationCodeEntry(userSettingInfo): Observable<any> {
    return this.httpClient.post(this.url.emailVerificationCodeEntryUrl, userSettingInfo);
  }

  // Entry for sms verification code
  public smsVerificationCodeEntry(userSettingInfo): Observable<any> {
    return this.httpClient.post(this.url.smsVerificationCodeEntryUrl, userSettingInfo);
  }

  // get two-factor durations
  public getDurationTypes(): Observable<any> {
    return this.httpClient.get(this.url.twoFactorDurationUrl);
  }

  // Email verification code
  public emailVerificationCode(userSettingInfo): Observable<any> {
    return this.httpClient.post(this.url.emailVerificationUrl, userSettingInfo);
  }

  // sms verification code
  public smsVerificationCode(userSettingInfo): Observable<any> {
    return this.httpClient.post(this.url.smsVerificationUrl, userSettingInfo);
  }

  // two-factor authenticated date update
  public twoFactorDateUpdate(id): Observable<any> {
    return this.httpClient.get(this.url.twoFactorDateUpdateUrl + '/' + id);
  }

  // exposure pdf save
  public savePDF(fd): Observable<any> {
    return this.httpClient.post(this.url.pdfUrl, fd);
  }

  // Email exposure
  // public sendEmail(emailInfo): Observable<any> {
  //   return this.httpClient.post(this.url.sendEmailUrl, emailInfo);
  // }

  // Email exposure
  public SendReportByEmail(emailInfo): Observable<any> {
    return this.httpClient.post(this.url.sendReportByEmailUrl, emailInfo);
  }

  public exportReportToPdf(reportParameters): Observable<any> {
    return this.httpClient.post(this.url.exportReportToPdfUrl, reportParameters);
  }

  public exportReportDownload(filePathObj) {

    return new Promise((resolve) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json' );
      let success = false;

      this.httpClient.post(this.url.exportReportDownloadUrl, filePathObj, {headers : headers, observe: 'response', responseType: 'blob' as 'json'})
        .pipe(map((res) => res))
        .subscribe(
          (blob: any) => {

            let data = {
              image: new Blob([blob.body], {type: blob.headers.get('Content-Type')}),
              filename: blob.headers.get('file-name')
            }

            const objectUrl = URL.createObjectURL(data.image);

            const element = document.createElement('a');
            element.href = URL.createObjectURL(data.image);
            element.target = '_blank';
            element.download = data.filename;
            document.body.appendChild(element);
            element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                window.URL.revokeObjectURL(objectUrl);
                element.remove();
            }, 100);

            success = true;
            resolve(success);

          },
          (error) => {
            resolve(success);
          }
        );
    });
  }


  // Account login
  public applogin(credentials): Observable<any> {
    return this.httpClient.post(this.url.appLoginUrl, credentials);
  }

  // Account login
  public getCasesById(caseId: string, centerId: string, userId: string): Observable<any> {
    var caseLaunchObj = { caseId, centerId, userId };
    return this.httpClient.post(this.url.getCasesByIdUrl, caseLaunchObj);
  }

  // Account login
  public getActiveUserList(): Observable<any> {
    return this.httpClient.get(this.url.getActiveUserListUrl);
  }

  // get users list
  public getUserList(): Observable<any> {
    return this.httpClient.get(this.url.getUserListUrl);
  }

  // get lock users list
  public getLockUserList(): Observable<any> {
    return this.httpClient.get(this.url.getLockUserListUrl);
  }

  // get network case count
  public getNetworkCaseCount(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.networkCaseCountUrl, caseTypeFilterCriteria);
  }

  // get centre lists
  public getCentreList(): Observable<any> {
    return this.httpClient.get(this.url.centreListUrl);
  }

  // get center lists count based on center Id
  public getCentersCount(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.centreCountUrl, caseTypeFilterCriteria);
  }

  // get userscount based on user Id
  public getUsersCount(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.userCountUrl, caseTypeFilterCriteria);
  }

  public getActiveSPIData(): Observable<any> {
    return this.httpClient.get(this.url.getActiveSPIDataUrl);
  }

  public getUsageData(): Observable<any> {
    return this.httpClient.get(this.url.getUsageDataURL);
  }

  public getCallerSiteChartData(): Observable<any> {
    return this.httpClient.get(this.url.getCallerSiteChartDataURL);
  }

  public getCaseTypeCallVolumeData(): Observable<any> {
    return this.httpClient.get(this.url.getCaseTypeCallVolumeDataURL);
  }

  // get network details
  public getNetworkCaseGridDetails(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.networkCaseGridDataUrl, caseTypeFilterCriteria);
  }

  // get center details
  public getCenterCaseGridDetails(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.centerCaseGridDataUrl, caseTypeFilterCriteria);
  }

  // get user details
  public getUserCaseGridDetails(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.userCaseGridDataUrl, caseTypeFilterCriteria);
  }

  // get review cases details
  public getReviewCaseGridDetails(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.reviewCaseGridDataUrl, caseTypeFilterCriteria);
  }

  // get response cases details
  public getResponseCaseGridDetails(caseTypeFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.responseCaseGridDataUrl, caseTypeFilterCriteria);
  }
  // get exposure details
  public getExposureById(caseId, EditMode): Observable<any> {
    const headers = new HttpHeaders().set('EditMode', EditMode.toString());
    return this.httpClient.get(this.url.exposureByIdUrl + '/' + caseId, { headers });
  }

  public getExposurePageDetailByExposureId(caseId, EditMode): Observable<any> {
    const headers = new HttpHeaders().set('EditMode', EditMode.toString());
    return this.httpClient.get(this.url.getExposurePageDetailByExposureIdUrl + '/' + caseId, { headers });
  }

  // get infoRequest details
  public getInfoRequestById(caseId, EditMode): Observable<any> {
    const headers = new HttpHeaders().set('EditMode', EditMode.toString());
    return this.httpClient.get(this.url.infoRequestByIdUrl + '/' + caseId, { headers });
  }

  // post gridColumnObject details
  public SaveGridColumnObject(userGridInfo): Observable<any> {
    return this.httpClient.post(this.url.gridColumnObjUrl, userGridInfo);
  }

  // post gridColumnObject details
  public deleteUserGridConfiguration(id): Observable<any> {
    return this.httpClient.post(this.url.deleteUserGridConfigurationUrl + '/' + id, id);
  }

  // get gridColumnObject details
  public gridColumnObjDetails(userGridInfo): Observable<any> {
    return this.httpClient.post(this.url.gridColumnDetailUrl, userGridInfo);
  }

  // get exposure history details
  public getExposureHistory(exposureId): Observable<any> {
    return this.httpClient.get(this.url.getHistoryDetailUrl + '/' + exposureId);
  }

  // get info reqest history details
  public getInfoRequestHistory(infoRequestId): Observable<any> {
    return this.httpClient.get(this.url.getHistoryDetailUrl + '/' + infoRequestId);
  }

  // Super search
  public getSSearchResults(searchCriteria): Observable<any> {
    return this.httpClient.post(this.url.getSSearchResultsUrl, searchCriteria);
  }

  // search
  public getSearchResults(searchCriteria): Observable<any> {
    return this.httpClient.post(this.url.getSearchResultsUrl, searchCriteria);
  }
  
  // search
  public getSearchDetails(): Observable<any> {
    return this.httpClient.get(this.url.getSearchList);
  }

  // get list of recalls
  public getRecallsList(recallFilterCriteria): Observable<any> {
    return this.httpClient.post(this.url.getRecallsListUrl, recallFilterCriteria);
  }

  // get list of patients by call id
  // called when an exposure regains window focus
  public getPatientList(callId): Observable<any> {
    return this.httpClient.get(this.url.getPatientListUrl + '/' + callId);
  }

  // save profile image in temp path
  public saveProfileImage(id): Observable<any> {
    return this.httpClient.get(this.url.saveProfileImageUrl + '/' + id);
  }
  // get center values
  public getCenters(): Observable<any> {
    return this.httpClient.get(this.url.getCenterValueUrl);
  }
  // get user information
  public getUserInformation(id): Observable<any> {
    return this.httpClient.get(this.url.getUserInformationUrl + '/' + id);
  }
  // get user settings
  public getUserSettings(id): Observable<any> {
    return this.httpClient.get(this.url.getUserSettingsUrl + '/' + id);
  }


  /**
 * save user setting
 * Expects a status object returned.
 * @return an `Observable` of the body as an `Object`.
 */
  public saveUserSettings(userSettingInfo): Observable<any> {
    return this.httpClient.post(this.url.saveUserSettingsUrl, userSettingInfo);
  }

  // search
  public getSystemSettingsAPI(): Observable<any> {
    return this.httpClient.get(this.url.getSystemSettingsUrl);
  }
  // get security questions
  public getSecurityQuestions(id): Observable<any> {
    return this.httpClient.get(this.url.getSecurityQuestionsUrl + '/' + id);
  }
  // get user settings
  public getUserSecurityQuestions(id): Observable<any> {
    return this.httpClient.get(this.url.getUserSecurityQuestionsUrl + '/' + id);
  }
  // get user security question with answer
  public GetUserSecurityQuestionsWithAnswers(id): Observable<any> {
    return this.httpClient.get(this.url.GetUserSecurityQuestionsWithAnswersUrl + '/' + id);
  }
  // save user setting
  public saveUserSecurityQuestions(securityQuestionsList): Observable<any> {
    return this.httpClient.post(this.url.saveUserSecurityQuestionsUrl, securityQuestionsList);
  }
  // save user setting
  public getAuthenticationType(): Observable<any> {
    return this.httpClient.get(this.url.getAuthenticationTypeUrl);
  }

  // report data-source call
  public reportAction(reportSearchCriteria): Observable<any> {
    return this.httpClient.post(this.url.generalReportActionUrl, reportSearchCriteria);
  }

  // single exposure report data-source call
  public getExpoReportData(id, allowPrintPHI): Observable<any> {
    const headers = new HttpHeaders().set('allowPrintPHI', allowPrintPHI.toString());
    return this.httpClient.get(this.url.getExpoReportDataUrl + "/" + id, { headers });
  }

  // single exposure report data-source call
  public getInfoReportData(id, allowPrintPHI): Observable<any> {
    const headers = new HttpHeaders().set('allowPrintPHI', allowPrintPHI.toString());
    return this.httpClient.get(this.url.getInfoReportDataUrl + "/" + id, { headers });
  }

  // get training list
  public getTrainings(): Observable<any> {
    return this.httpClient.get(this.url.getTrainingsUrl);
  }

  // get locks list
  public getLocks(caseBaseInfo): Observable<any> {
    return this.httpClient.post(this.url.getLocksUrl, caseBaseInfo);
  }

  // delete case locks list
  public deleteCallLock(caseLockInfo): Observable<any> {
    return this.httpClient.post(this.url.getCallDeleteUrl, caseLockInfo);
  }

  // delete exposure locks list
  public deleteExposureLock(caseLockInfo): Observable<any> {
    return this.httpClient.post(this.url.getExpoureDeleteUrl, caseLockInfo);
  }

  // delete exposure locks list
  public deleteAllLocks(caseLockInfoList): Observable<any> {
    return this.httpClient.post(this.url.getAllLockDeleteUrl, caseLockInfoList);
  }

  // IndexedDB
  public CodeValueList;
  public IndexedDb;
  public DataSourceList = {
    CallType: [],
    CallSubType: [],
    State: [],
    Country: [],
    TherapyGroup: [],
    Site: [],
    City: [],
    Title: [],
    Age: [],
    AgeUnit: [],
    Gender: [],
    Species: [],
    HCF: [],
    Treatment: [],
    Scenario: [],
    TherapyList: [],
    Effect: [],
    Duration: [],
    EffectList: [],
    ScenarioList: [],
    Outcome: [],
    Relationship: [],
    WeightUnit: [],
    SubstanceUnit: [],
    ManagementAction: [],
    ManagementSite: [],
    HCFType: [],
    PoisonSeverityScore: [],
    CommentType: [],
    LabTempLocation: [],
    LabPulseOxLocation: [],
    Reason: [],
    Consultant: [],
    // Lab: [],
    LabList: [],
    SubstanceRoute: [],
    CaseStatus: [],
    ReviewStatus: [],
    ResponseStatus: [],
    RecallStatus: [],
    RecallPriority: [],
  };

  public Therapy = [];
  public TherapyOption = [];
  public TherapyGroup = [];
  public TherapyList = [];
  public Effect = [];
  public EffectRelation = [];
  public EffectGroup = [];
  public EffectList = [];
  public Scenario = [];
  public ScenarioGroup = [];
  public ScenarioList = [];
  // public Lab = [];
  public LabList = [];
  public LabTest = [];
  public LabCategory = [];
  public LabTestOption = [];
  public CodeValues = [];

  loginGetGenericSubstances(genericList) {
    return new Promise((resolve) => {
      this.CreateGenericSubstanceDB(genericList);
      resolve(genericList);
    });
  }

  createCodeValuesDBPromise(codeTypeList) {
    return new Promise((resolve) => {
      this.CodeValueList = codeTypeList;
      this.CreateCodeValueDB(this.CodeValueList).then(function (result) {
        resolve(result);
      });
    });
  }

  getCodeValueList() {
    return new Promise((resolve) => {
      this.httpClient.get(this.url.getCodeValueUrl).subscribe(
        (data: any) => {
          this.CodeValueList = data;
          this.CreateCodeValueDB(this.CodeValueList).then(function (result) {
            resolve(result);
          });
        },
        (error) => {
          var errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: error.message,
            ErrorMessage: error.message,
            InnerException: error.error.error_description == undefined && error.error.error_description == null ? null : error.error.error_description,
          };
          var $this = this;
          this.AddErrorLog(errorLog).subscribe(function () {
            var toasterValue = {
              type: 'error',
              message: 'Something went wrong retrieving the Code Value List. Please try again',
            };
            $this.toastService.showSpinner(toasterValue);
          });
        }
      );
    });
  }

  CreateCodeValueDB(CodeValueList) {
    return new Promise((resolve) => {
      var $this = this;
      var request = indexedDB.open(AppConstants.CodeValueDBName, 1);

      request.onupgradeneeded = function (evt: any) {
        var objectStore = evt.currentTarget.result.createObjectStore(AppConstants.CodeValueTableName, { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('id', 'id', { unique: true });
        objectStore.createIndex('Type', 'Type', { unique: false });
        objectStore.createIndex('Value', 'Value', { unique: false });
        objectStore.createIndex('Description', 'Description', { unique: false });
        objectStore.createIndex('Code', 'Code', { unique: false });
        objectStore.createIndex('Order', 'Order', { unique: false });
        objectStore.createIndex('Group', 'Group', { unique: false });
        objectStore.createIndex('StartDateTime', 'StartDateTime', { unique: false });
        objectStore.createIndex('EndDateTime', 'EndDateTime', { unique: false });
        objectStore.createIndex('IsActive', 'IsActive', { unique: false });
        objectStore.createIndex('IsDefault', 'IsDefault', { unique: false });
      };

      request.onsuccess = function (evt) {
        let db = request.result;
        if (db.objectStoreNames.contains(AppConstants.CodeValueTableName)) {
          $this.BindCodeValue(CodeValueList).then(function (result) {
            resolve(result);
          });
        } else {
          indexedDB.deleteDatabase(AppConstants.CodeValueDBName);  // Why are we deleting? We just asked if the db existed.
          resolve('No DataBase Exist');
        }
      };
    });
  }

  BindCodeValue(CodeValueList) {
    return new Promise((resolve) => {
      const request = indexedDB.open(AppConstants.CodeValueDBName, 1);
      const $this = this;
      request.onsuccess = function (evt: any) {
        let db = request.result;
        if (db.objectStoreNames.contains(AppConstants.CodeValueTableName)) {
          const transaction = db.transaction(AppConstants.CodeValueTableName, 'readwrite');
          const objectStore = transaction.objectStore(AppConstants.CodeValueTableName);
          const objectStoreRequest = transaction.objectStore(AppConstants.CodeValueTableName).clear();
          objectStoreRequest.onsuccess = function (event) {
            for (var t = 0; t < CodeValueList.length; t++) {
              const IsActive = CodeValueList[t].startDateTime != null ? (new Date(CodeValueList[t].startDateTime) <= new Date() ? true : false) : true &&
                CodeValueList[t].endDateTime != null ? (new Date(CodeValueList[t].endDateTime) >= new Date() ? true : false) : true;
              if (!IsActive) {
                CodeValueList[t].value = CodeValueList[t].value + '(Inactive)';
              }
              objectStore.add({
                id: CodeValueList[t].id,
                Type: CodeValueList[t].type,
                Value: CodeValueList[t].value,
                Description: CodeValueList[t].description,
                Code: CodeValueList[t].code,
                Order: CodeValueList[t].order,
                Group: CodeValueList[t].group,
                StartDateTime: CodeValueList[t].startDateTime,
                EndDateTime: CodeValueList[t].endDateTime,
                IsActive: IsActive,
                IsDefault: CodeValueList[t].isDefault,
              });
            }
            resolve(CodeValueList);
          };
        } else {
          indexedDB.deleteDatabase(AppConstants.CodeValueDBName);
          $this.CreateCodeValueDB(CodeValueList);
        }
      };
      request.onerror = function (err: any) {
        alert("error in BindCodeValue:" + err);
      }
    });
  }


  ClearDataSourceList() {
    this.Therapy = [];
    this.TherapyOption = [];
    this.TherapyGroup = [];
    this.Effect = [];
    this.EffectRelation = [];
    this.EffectGroup = [];
    this.Scenario = [];
    this.ScenarioGroup = [];
    this.LabTest = [];
    this.LabTestOption = [];
    this.LabCategory = [];

    this.DataSourceList = {
      CallType: [],
      CallSubType: [],
      State: [],
      Country: [],
      TherapyGroup: [],
      Site: [],
      City: [],
      Title: [],
      Age: [],
      AgeUnit: [],
      Gender: [],
      Species: [],
      HCF: [],
      Treatment: [],
      Scenario: [],
      TherapyList: [],
      Effect: [],
      Duration: [],
      EffectList: [],
      ScenarioList: [],
      Outcome: [],
      Relationship: [],
      WeightUnit: [],
      SubstanceUnit: [],
      ManagementAction: [],
      ManagementSite: [],
      HCFType: [],
      PoisonSeverityScore: [],
      CommentType: [],
      LabTempLocation: [],
      LabPulseOxLocation: [],
      Reason: [],
      Consultant: [],
      // Lab: [],
      LabList: [],
      SubstanceRoute: [],
      CaseStatus: [],
      ReviewStatus: [],
      ResponseStatus: [],
      RecallStatus: [],
      RecallPriority: [],
    };
  }

  GetDataSourceList(excludeInactiveCodeValues: boolean) {
    return new Promise((resolve) => {
      let $this = this;
      $this.ClearDataSourceList();

      $this.CheckIndexDBHasCodeValues().then((codeValueCount: any) => {
        if (codeValueCount > 0) {
          const request = indexedDB.open(AppConstants.CodeValueDBName, 1);
          request.onsuccess = function (evt) {
            const db = request.result;
            const transactRequest = db.transaction(AppConstants.CodeValueTableName, 'readonly').objectStore(AppConstants.CodeValueTableName);

            const transQueryable = transactRequest.getAll();
            transQueryable.onsuccess = function (codeValueRecords: any) {
              const codeValueTypes = codeValueRecords.target.result.filter(function (ct) {
                return ct.Type.toLowerCase() === 'codetype';
              });
              if (codeValueTypes) {
                const specialLists = [
                  'Effect', 'EffectRelation', 'EffectGroup',
                  'TherapyGroup', 'Therapy', 'TherapyOption',
                  'Scenario', 'ScenarioGroup',
                  'LabTest', 'LabCategory', 'LabTestOption'
                ];
                codeValueTypes.forEach(codeValueType => {
                  const codeValues = codeValueRecords.target.result.filter(function (ct) {
                    return ct.Type === codeValueType.Value;
                  });
                  if (specialLists.includes(codeValueType.Value)) {
                    $this[codeValueType.Value] = $this.SortCodeValues(codeValues, excludeInactiveCodeValues);
                  } else {
                    $this.DataSourceList[codeValueType.Value] = $this.SortCodeValues(codeValues, excludeInactiveCodeValues);
                  }
                });
              }
              $this.GetEffectList();
              $this.GetTherapyList();
              $this.GetScenarioList();
              $this.GetLabTest();

              resolve($this.DataSourceList);
            };
          };
        } else {
          $this.GetLoginCodeValues(parseInt($this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe(
            (codeValueList: any) => {
              console.log('Retrieved CodeValues - ' + codeValueList.codeValueList.count);
              $this.createCodeValuesDBPromise(codeValueList.codeValueList)
                .then(function () {
                  console.log('Created ' + codeValueList.codeValueList.length + ' CodeValues ');                  
                  $this.CheckIndexDBHasCodeValues().then((newCodeValueCount: any) => {
                    if (newCodeValueCount > 0) {
                      $this.GetDataSourceList(excludeInactiveCodeValues);
                    } else {
                      throw new Error('Could not retrieve Code Values, second attempt');
                    }
                })
                .catch((err)=> {
                  const errorLog = {
                    CentreId: null,
                    LoggedByUserId: null,
                    CustomErrorMessage: err.message,
                    ErrorMessage: err.message,
                    InnerException: (err.error === undefined || err.error.error_description == null) ? null : err.error.error_description
                  };
                  this.AddErrorLog(errorLog).subscribe(()=> {
                    this.ShowToastError('Could not retrieve Code Values, second attempt');
                    // TODO: Log Error
                  });
                });
            },
            (err: HttpErrorResponse) => {
              const errorLog = {
                CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                CustomErrorMessage: err.message,
                ErrorMessage: err.message,
                InnerException: (err.error === undefined || err.error.error_description == null) ? null : err.error.error_description
              };
              $this = this;
              if (navigator.onLine) {
                this.AddErrorLog(errorLog).subscribe(function() {
                  this.showToastError('Something went wrong retrieving Code Values. Refresh your browser. If still a problem, Sign Out, close your browser and start again.');
                  // TODO: Log Error!
                });
              } else {
                const toasterValue = {
                  type: 'warning',
                  message: 'You are working offline. Please reload the page to continue'
                };
                $this.toastService.showSpinner(toasterValue);
              }
            });
          });
        }
      });
    });
  }

  CheckIndexDBHasCodeValues() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.CodeValueDBName, 1);
      console.log('Checking on CodeValue in IndexedDB');
      request.onsuccess = function (evt: any) {
        console.log('Found CodeValue in IndexedDB');
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.CodeValueTableName, 'readonly').objectStore(AppConstants.CodeValueTableName);

          const codeValueListCount = transaction.count();
          codeValueListCount.onsuccess = function () {
            resolve(codeValueListCount.result);
          };
        } else {
          const rowCount = 0;
          resolve(rowCount);
        }
      };
      request.onerror = function (evt: any) {
        console.log('CodeValue not in IndexedDB');
      }
    });
  }

  SortCodeValues(codeValueList: any, excludeInactiveCodeValues: boolean) {
    const orderCodeValueList = codeValueList.filter(function (value: any) {
      return value.Order != null;
    });
    const nullOrderList = codeValueList.filter(function (value: any) {
      return value.Order == null;
    });

    if (nullOrderList.length > 0) {
      orderCodeValueList.push.apply(orderCodeValueList, nullOrderList);
    }

    orderCodeValueList.sort(function (a: any, b: any) {
      return a.Order - b.Order;
    });

    const codeValueSortedList = orderCodeValueList.filter(function (value: any) {
      return value.IsActive == true;
    });
    if (!excludeInactiveCodeValues) {
      const inActiveList = orderCodeValueList.filter(function (value: any) {
        return value.IsActive == false;
      });
      codeValueSortedList.push.apply(codeValueSortedList, inActiveList);
    }
    return codeValueSortedList;
  }

  GetEffectList() {
    this.DataSourceList.EffectList.length = 0; // clear list first
    for (let i = 0; i < this.EffectGroup.length; i++) {
      const effect = {
        id: '',
        name: '',
        Effect: [],
        EffectRelation: [],
      };
      effect.id = this.EffectGroup[i].id;
      effect.name = this.EffectGroup[i].Value;
      effect.EffectRelation = this.EffectRelation;

      this.Effect.filter(function (data) {
        if (data.Group === effect.id) { effect.Effect.push(data); }
      });
      this.DataSourceList.EffectList.push(effect);
    }
  }
  GetTherapyList() {
    this.DataSourceList.TherapyList.length = 0; // clear list first
    for (let i = 0; i < this.TherapyGroup.length; i++) {
      const therapy = {
        id: '',
        name: '',
        Therapy: [],
        TherapyOption: [],
      };
      therapy.id = this.TherapyGroup[i].id;
      therapy.name = this.TherapyGroup[i].Value;
      therapy.TherapyOption = this.TherapyOption;

      this.Therapy.filter(function (data) {
        if (data.Group === therapy.id) {
          data['IsRecommended'] = false;
          therapy.Therapy.push(data);
        }
      });
      this.DataSourceList.TherapyList.push(therapy);
    }
  }

  GetScenarioList() {
    this.DataSourceList.ScenarioList.length = 0; // Clear list first
    for (let i = 0; i < this.ScenarioGroup.length; i++) {
      const scenario = {
        id: '',
        name: '',
        Scenario: [],
      };
      scenario.id = this.ScenarioGroup[i].id;
      scenario.name = this.ScenarioGroup[i].Value;

      this.Scenario.filter(function (data) {
        if (data.Group === scenario.id) { scenario.Scenario.push(data); }
      });
      this.DataSourceList.ScenarioList.push(scenario);
    }
  }

  GetLabTest() {
    this.LabCategory = this.LabCategory.filter(function (data) {
      return data.Value !== 'Vital Signs';
    });

    // clear the list first!
    this.DataSourceList.LabList = [];
    for (let i = 0; i < this.LabCategory.length; i++) {
      const lab = {
        id: '',
        name: '',
        Lab: [],
        LabTestOption: [],
      };
      lab.id = this.LabCategory[i].id;
      lab.name = this.LabCategory[i].Value;
      lab.LabTestOption = this.LabTestOption;

      this.LabTest.filter(function (data) {
        if (data.Group === lab.id) { lab.Lab.push(data); }
      });
      this.DataSourceList.LabList.push(lab);
    }
  }

  // get center lists count based on center Id
  public getSubstanceSources(): Observable<any> {
    return this.httpClient.get(this.url.getSubstanceSourcesUrl);
  }

  addExposure(exposureInfo) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url.addExposureUrl, exposureInfo)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  addinfoRequest(infoRequestInfo) {
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.addInfoRequestUrl, infoRequestInfo)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  saveExposure(exposureInfo, citeLocks: boolean = false, reopenCase: boolean = false) {
    const headers = new HttpHeaders().set('citeLocks', citeLocks.toString())
                                     .set('reopenCase', reopenCase.toString());
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.editExposureUrl, exposureInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  editinfoRequest(infoRequestInfo, citeLocks: boolean = false, reopenCase: boolean = false) {
    const headers = new HttpHeaders().set('citeLocks', citeLocks.toString())
                                     .set('reopenCase', reopenCase.toString());
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.editInfoRequestUrl, infoRequestInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  addCaseReview(reviewInfo: any, caseType: string) {
     const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.addCaseReviewUrl, reviewInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  editCaseReview(reviewInfo, caseType: string) {
    const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.editCaseReviewUrl, reviewInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  removeCaseReview(id, caseType: string) {
    const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.removeCaseReviewUrl + '/' + id, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  addCaseResponse(responseInfo: any, caseType: string) {
     const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.addCaseResponseUrl, responseInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  editCaseResponse(responseInfo, caseType: string) {
    const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.editCaseResponseUrl, responseInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  removeCaseResponse(id, caseType: string) {
    const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.removeCaseResponseUrl + '/' + id, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  addCaseAttachment(attachmentInfo: any, caseType: string) {
     const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.addCaseAttachmentUrl, attachmentInfo, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  deleteCaseAttachment(id, caseType: string) {
    const headers = new HttpHeaders().set('caseType', caseType);
    return new Promise((resolve) => {
      this.httpClient
        .post(this.url.deleteCaseAttachmentUrl + '/' + id, { headers })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  public downloadCaseAttachment(attachment, caseType: string) {

    return new Promise((resolve) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json' );
      headers = headers.append('caseType', caseType );
      let success = false;

      this.httpClient.post(this.url.getCaseAttachmentUrl, attachment, {headers : headers, observe: 'response', responseType: 'blob' as 'json'})
        .pipe(map((res) => res))
        .subscribe(
          (blob: any) => {

            let data = {
              image: new Blob([blob.body], {type: blob.headers.get('Content-Type')}),
              filename: blob.headers.get('file-name')
            }

            const objectUrl = URL.createObjectURL(data.image);

            const element = document.createElement('a');
            element.href = URL.createObjectURL(data.image);
            element.target = '_blank';
            element.download = data.filename;
            document.body.appendChild(element);
            element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                window.URL.revokeObjectURL(objectUrl);
                element.remove();
            }, 100);

            success = true;
            resolve(success);

          },
          (error) => {
            resolve(success);
          }
        );
    });
  }

    /**
 * Edit Validation Rule Type
 * Expects the new type id and the validation rule id
 * @return an `Observable` of the body as an `Object`.
 */
public editValidationRuleType(validationType, validationRuleId): Observable<any> {
  const dataToSend = { ValidationType: validationType, ValidationRuleId: validationRuleId, UserId: 0, CtrId: 0};
  return this.httpClient.post(this.url.editValidationRuleTypeUrl, dataToSend);
}
  // editValidationRuleType(validationType, validationRuleId) {
  //   const dataToSend = [ validationType, validationRuleId];
  //   return new Promise((resolve) => {
  //     this.httpClient.post(this.url.editValidationRuleTypeUrl, dataToSend)
  //       .pipe(map((res) => res))
  //       .subscribe(
  //         (data: any) => { resolve(data); },
  //         (error) => { resolve(error); }
  //       );
  //   });
  // }

  editValidationRuleActive(activeValue, validationRuleId) {
    return new Promise((resolve) => {
      const dataToSend = {active: activeValue, validationRuleId};
      this.httpClient.post(this.url.editValidationRuleActiveUrl, dataToSend)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => { resolve(data); },
          (error) => { resolve(error); }
        );
    });
  }
  editValidationRuleFatal(fatalValue, validationRuleId) {
    return new Promise((resolve) => {
      const dataToSend = {fatal: fatalValue, validationRuleId};
      this.httpClient.post(this.url.editValidationRuleFatalUrl, dataToSend)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => { resolve(data); },
          (error) => { resolve(error); }
        );
    });
  }

  GetGenericSubstances() {
    return new Promise((resolve) => {
      this.httpClient
        .get(this.url.getGenericSubstanceUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            this.CreateGenericSubstanceDB(data);
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  CreateGenericSubstanceDB(data) {
    var $this = this;

    var request = indexedDB.open(AppConstants.GenericSubstanceSearchDBName, 1);

    request.onupgradeneeded = function (evt: any) {
      var objectStore = evt.currentTarget.result.createObjectStore(AppConstants.GenericSubstanceSearchTableName, { keyPath: 'id', autoIncrement: true });
      objectStore.createIndex('substanceId', 'substanceId', { unique: true });
      objectStore.createIndex('substanceName', 'substanceName', { unique: false });
      objectStore.createIndex('substanceDescription', 'substanceDescription', { unique: false });
      objectStore.createIndex('substanceGroup', 'substanceGroup', { unique: false });
      objectStore.createIndex('substanceCode', 'substanceCode', { unique: false });
    };

    request.onsuccess = function (evt) {
      let db = request.result;
      if (db.objectStoreNames.length > 0) {
        var transaction = db.transaction('GenericSubstance', 'readwrite');
        var objectStore = transaction.objectStore('GenericSubstance');
        var objectStoreRequest = transaction.objectStore('GenericSubstance').clear();
        objectStoreRequest.onsuccess = function (event) {
          for (var t = 0; t < data.length; t++) {
            objectStore.add({
              substanceId: data[t].substanceId,
              substanceName: data[t].substanceName,
              substanceDescription: data[t].substanceDescription,
              substanceGroup: data[t].substanceGroup,
              substanceCode: data[t].substanceCode,
            });
          }
        };
      } else {
        indexedDB.deleteDatabase(AppConstants.GenericSubstanceSearchDBName);
        $this.CreateGenericSubstanceDB(data);
      }
    };
  }

  GetGenericSubstanceFromIndexedDB() {
    return new Promise((resolve) => {
      var genericData = [];
      var request = indexedDB.open(AppConstants.GenericSubstanceSearchDBName, 1);
      request.onsuccess = function (evt) {
        let db = request.result;
        if (db.objectStoreNames.length > 0) {
          var transactRequest = db.transaction(AppConstants.GenericSubstanceSearchTableName, 'readonly').objectStore(AppConstants.GenericSubstanceSearchTableName);

          var records = transactRequest.getAll();
          records.onsuccess = function (event) {
            genericData = records.result; // event.target.result;
            resolve(genericData);
          };

          // transactRequest.openCursor().onsuccess = function (event) {
          //   var cursor = event.target.result;
          //   if (cursor) {
          //      genericData.push(cursor.value);
          //      cursor.continue();
          //     }
          //   else {
          //     resolve(genericData);
          //     }
          //   }
          // }else {
          //   resolve('Database does not exist');
          // }
        }
      };
    });
  }

  public SyncAddExposure(addExposureData): Observable<any> {
    return this.httpClient.post(this.url.syncAddExposureUrl, addExposureData);
  }

  public SyncEditExposure(editExposureData): Observable<any> {
    return this.httpClient.post(this.url.syncEditExposureUrl, editExposureData);
  }

  //--------------Sync Offline Database ---------------//
  SyncOfflineDataBase() {
    this.offlineService.UpdateExposureSyncStatus().then((response: any) => {
      if (response != undefined && response.length > 0) {
        let indexDbRecords = response;

        let editedCases = [];
        let addedCases = [];
        for (let i = 0; i < indexDbRecords.length; i++) {
          if (indexDbRecords[i].IsTempExposure == true && indexDbRecords[i].IsSyncStatus != AppConstants.SyncStatus.InProgress) {
            const addCaseDetail = JSON.parse(indexDbRecords[i].exposureDetail);
            const offlineCaseId = indexDbRecords[i].TempCaseNo.split(' ')[1].substring(1);
            addCaseDetail.exposureId = parseInt(offlineCaseId, 10);
            addedCases.push(addCaseDetail);
          } else if (indexDbRecords[i].IsSyncStatus != AppConstants.SyncStatus.InProgress) {
            const editexposureDetail = JSON.parse(indexDbRecords[i].exposureDetail); 
            // get the numberic part of the case number to use for exposureId
            let expoId = indexDbRecords[i].TempCaseNo;
            if (isNaN(expoId)) {
              expoId = expoId.substring(1); // remove the centre initial prefix
            }            
            editexposureDetail.exposureId = parseInt(expoId, 10);
            editedCases.push(editexposureDetail);
          }
        }
        // check if there are any offline cases to submit
        if (addedCases.length > 0 || editedCases.length > 0) {
          localStorage.setItem(AppConstants.LocalStorage.SyncStatus, AppConstants.SyncStatus.InProgress);
          // send cases to server
          Promise.all([this.AddOfflineExposure(addedCases), this.SyncEditedOfflineExposure(editedCases)]).then( (response) => {
            const addOfflineSuccess = response[0];
            const editOfflineSuccess = response[1];

            // check responses for success
            if ((addOfflineSuccess && addedCases.length>0) || (editOfflineSuccess && editedCases.length>0)) {
              const toasterValue = {
                type: 'success',
                message: 'All Offline Data Synced Successfully: ' + addedCases.length + ' additions, ' + editedCases.length + ' updates.',
              };
              this.toastService.showSpinner(toasterValue);
              // $this.offlineService.isAnyOfflineExposure = false;
              if (window.localStorage[AppConstants.LocalStorage.SyncStatus] != undefined && window.localStorage[AppConstants.LocalStorage.SyncStatus] != null) {
                localStorage.setItem(AppConstants.LocalStorage.SyncStatus, AppConstants.SyncStatus.Synced);
              }
              this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, 'false', 1, '/',null,true,"Strict");
            } else
              if ((!addOfflineSuccess && addedCases.length > 0) || (!editOfflineSuccess && editedCases.length > 0)) {
              const toasterValue = {
                type: 'warning',
                message: 'Offline Data Synced Partially: ' + (addedCases.length > 0 ? (addOfflineSuccess ? ' added successfully,' : ' unsuccessfull adds') + ' and ' :
                  editedCases.length + (editOfflineSuccess ? ' updated successfully.' : ' unsuccessfull updates.'))
              };
              this.toastService.showSpinner(toasterValue);
              // this.offlineService.isAnyOfflineExposure = true;
              if (window.localStorage[AppConstants.LocalStorage.SyncStatus] != undefined && window.localStorage[AppConstants.LocalStorage.SyncStatus] != null) {
                localStorage.setItem(AppConstants.LocalStorage.SyncStatus, AppConstants.SyncStatus.Synced);
              }
              this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, 'true', 1, '/',null,true,"Strict");
            } else {
              const toasterValue = {
                type: 'error',
                message: 'Offline Data Failed to Sync',
              };
              this.toastService.showSpinner(toasterValue);
              // this.offlineService.isAnyOfflineExposure = true;
              if (window.localStorage[AppConstants.LocalStorage.SyncStatus] != undefined && window.localStorage[AppConstants.LocalStorage.SyncStatus] != null) {
                localStorage.setItem(AppConstants.LocalStorage.SyncStatus, AppConstants.SyncStatus.Synced);
              }
              this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, 'true', 1, '/',null,true,"Strict");
            }
          });
        }
      }
    });

    this.offlineService.UpdateInfoRequestSyncStatus().then((indexDbRecords: any) => {
      if (indexDbRecords != undefined && indexDbRecords.length > 0) {
        let editInfoRequestData = [];
        let addInfoRequestData = [];
        for (let i = 0; i < indexDbRecords.length; i++) {
          if (indexDbRecords[i].IsTempInfoRequest == true && indexDbRecords[i].IsSyncStatus != AppConstants.SyncStatus.InProgress) {
            let addInfoRequestDetail = JSON.parse(indexDbRecords[i].infoRequestDetail);
            let offlineInfoRequestId = indexDbRecords[i].TempCaseNo.split(' ')[1].substring(1);
            addInfoRequestDetail.infoRequestId = parseInt(offlineInfoRequestId, 10);
            addInfoRequestData.push(addInfoRequestDetail);
          } else {
            if (indexDbRecords[i].IsSyncStatus != AppConstants.SyncStatus.InProgress) {
              let editInfoRequestDetail = JSON.parse(indexDbRecords[i].infoRequestDetail);
              editInfoRequestDetail.infoRequestId = parseInt(indexDbRecords[i].TempCaseNo.substring(1), 10);
              editInfoRequestData.push(editInfoRequestDetail);
            }
          }
        }
      }
    });
  }

  AddOfflineExposure(addExposureData) {
    var $this = this;
    return new Promise((resolve) => {
      if (addExposureData.length > 0) {
        this.SyncAddExposure(addExposureData).subscribe((response: any) => {
          let success = true;
          if (response.length > 0) {
            response.forEach((result) => {
              if (result.isSuccess) {
                if (result.tempCaseId.toString().length == 3) {
                  result.tempCaseId = '0' + result.tempCaseId;
                }
                const caseNo = 'Pending ' + $this._cookieService.get(AppConstants.CookieName.CenterInitial) + result.tempCaseId
                console.log('Add Offline Exposure ' + caseNo + ': Requested.');
                $this.offlineService.DeleteOfflineExposure(caseNo);
                if (window.localStorage['OfflineExposureOpenUrlList'] != undefined && window.localStorage['OfflineExposureOpenUrlList'] != null) {
                  const OfflineExposureOpenUrlList = JSON.parse(window.localStorage['OfflineExposureOpenUrlList']);

                  if (OfflineExposureOpenUrlList.OfflineUrlList.length > 0) {
                    for (let i = 0; i < OfflineExposureOpenUrlList.OfflineUrlList.length; i++) {
                      if (
                        'Pending ' + $this._cookieService.get(AppConstants.CookieName.CenterInitial) + result.tempCaseId ==
                        OfflineExposureOpenUrlList.OfflineUrlList[i].TempExposureId.trim()
                      ) {
                        OfflineExposureOpenUrlList.OfflineUrlList[i].ExposureId = result.caseId;
                        OfflineExposureOpenUrlList.OfflineUrlList[i].CallId = result.callId;
                        OfflineExposureOpenUrlList.OfflineUrlList[i].CaseNo = $this._cookieService.get(AppConstants.CookieName.CenterInitial) + result.caseId;
                        OfflineExposureOpenUrlList.OfflineUrlList[i].CentreName = $this._cookieService.get(AppConstants.CookieName.CenterName);
                        OfflineExposureOpenUrlList.OfflineUrlList[i].CentreId = parseInt($this._cookieService.get(AppConstants.CookieName.CenterId), 10);
                      }
                    }
                  }
                  localStorage.setItem(AppConstants.LocalStorage.OfflineExposureOpenUrlList, JSON.stringify(OfflineExposureOpenUrlList));
                }

                if (result.tempCallId != 0) {
                  var localTempCallID = {
                    TempCallIdList: [],
                  };
                  if (window.localStorage['localTempCallID'] != undefined && window.localStorage['localTempCallID'] != null) {
                    localTempCallID = JSON.parse(window.localStorage['localTempCallID']);
                  }
                  if (localTempCallID.TempCallIdList.length > 0) {
                    var list = localTempCallID.TempCallIdList;
                    for (let i = 0; i < list.length; i++) {
                      if (result.tempCallId == list[i].TempCallId) {
                        list[i].CallId = result.callId;
                      }
                    }

                    localStorage.setItem('localTempCallID', JSON.stringify(localTempCallID));
                  }
                }
              } else {
                success = false;
                $this.offlineService.UpdateErrorStatus('Pending ' + $this._cookieService.get(AppConstants.CookieName.CenterInitial) + result.tempCaseId, result.errorMessage);
              }
            });
            resolve(success);
          }
        });
      } else {
        const success = true;
        resolve(success);
      }
    });
  }

  SyncEditedOfflineExposure(editExposureData) {
    return new Promise((resolve) => {
      let success = false;
      if (editExposureData.length > 0) {
        this.SyncEditExposure(editExposureData).subscribe((response: any) => {
          if (response.length > 0) {
            response.forEach((result) => {
              if (result.isSuccess) {
                console.log('Sync Edited Offline Exposure ' + result.caseId + ': Request completed successfully');
                success = true;
                this.offlineService.DeleteOfflineExposure(result.caseId);
              } else {
                success = false;
                this.offlineService.UpdateErrorStatus(result.caseNumber, result.errorMessage);
              }
            });
            resolve(success);
          }
        });
      } else {        
        resolve(success);
      }
    });
  }

  GetSystemSettingByName(settingName: string): any {
    return this.GetSystemSettings().then((result: any) => {
      let settingValue: string = AppConstants.NotFoundValue;
        if (result.length > 0) {
          result.forEach((systemSetting) => {
            if (systemSetting.key === settingName) {
              settingValue = systemSetting.value;
            }
          });
        }
        return String(settingValue);
      });
  }


  GetSystemSettings() {
    return new Promise((resolve) => {
      this.httpClient
        .get(this.url.getSystemSettingsUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetCodeTypes() {
    return new Promise((resolve) => {
      this.httpClient
        .get(this.url.getCodeTypesUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetNotices(centreId = 0): any {
    return new Promise((resolve) => {
      this.httpClient
        .get(this.url.getNoticesUrl, { params: { centreId: centreId.toString() } })
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetAuthenticationDuration() {
    return new Promise((resolve) => {
      this.httpClient
        .get(this.url.getAuthenticationDurationUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  GetCodeValues() {
    var $this = this;

    return new Promise((resolve) => {
      var request = indexedDB.open(AppConstants.CodeValueDBName, 1);
      request.onsuccess = function (evt) {
        let db = request.result;
        var transactrequest = db.transaction(AppConstants.CodeValueTableName, 'readwrite').objectStore(AppConstants.CodeValueTableName);
        var cRequest = transactrequest.openCursor();
        cRequest.onsuccess = function (event) {
          var cursor = this.result;
          if (cursor) {
            $this.CodeValues.push(cursor.value);
            cursor.continue();
          } else {
            resolve($this.CodeValues);
          }
        };
      };
    });
  }

  GetValidations() {
    const $this = this;
    return new Promise((resolve) => {
      this.httpClient
        .get(this.url.getValidationsUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => { resolve(data); },
          (error) => { resolve(error); }
        );
    });
  }

  public GetCodeValuesByCodeType(id): Observable<any> {
    return this.httpClient.get(this.url.getCodeValuesByCodeTypeUrl + '/' + id);
  }

  public SaveSystemSettings(systemSettingDetail): Observable<any> {
    return this.httpClient.post(this.url.saveSystemSettingsUrl, systemSettingDetail);
  }

  public SaveCodeValues(codeValueDetail): Observable<any> {
    return this.httpClient.post(this.url.saveCodeValuesUrl, codeValueDetail);
  }

  public AddExposureLock(lockDetail): Observable<any> {
    return this.httpClient.post(this.url.addExposureLockUrl, lockDetail);
  }

  public AddInfoRequestLock(lockDetail): Observable<any> {
    return this.httpClient.post(this.url.addInfoRequestLockUrl, lockDetail);
  }

  public AddCallLock(lockDetail): Observable<any> {
    return this.httpClient.post(this.url.addCallLockUrl, lockDetail);
  }

  public GetLockInfo(caseBaseInfo): Observable<any> {
    return this.httpClient.post(this.url.getLockInfoUrl, caseBaseInfo);
  }

  public DeleteCallAndExposureLockOnTabclose(caseLockInfo) {
    const blobOfCaseLockInfo = new Blob([JSON.stringify(caseLockInfo)], { type: 'application/json' });
    const sendBeaconStatus = navigator.sendBeacon(this.url.deleteCallAndExposureLockUrl, blobOfCaseLockInfo);
    // return a promise with sendBeacon status, needed by the methods calling.
    return new Promise(function (resolve, reject) {
      resolve(sendBeaconStatus);
    });
  }

  // public DeleteCallLockByTabclose(caseLockInfo) {
  //   return new Promise(resolve => {
  //     let xhr = new XMLHttpRequest();
  //     xhr.open("POST", this.url.deleteCallLockUrl, true);
  //     xhr.setRequestHeader("Content-Type", "application/json");
  //     xhr.send(JSON.stringify(caseLockInfo));
  //   });
  // }

  CreateExposureDB() {
    return new Promise((resolve) => {
      var $this = this;

      var request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onupgradeneeded = function (evt: any) {
        var objectStore = evt.currentTarget.result.createObjectStore(AppConstants.ExposureOfflineTableName, { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('TempCaseNo', 'TempCaseNo', { unique: true });
        objectStore.createIndex('IsTempExposure', 'IsTempExposure', { unique: false });
        objectStore.createIndex('IsSyncStatus', 'IsSyncStatus', { unique: false });
        objectStore.createIndex('exposureDetail', 'exposureDetail', { unique: false });
        resolve(objectStore);
      };

      request.onsuccess = function (evt) {
        let db = request.result;
        if (db.objectStoreNames.contains(AppConstants.ExposureOfflineTableName)) {
          resolve(db);
        } else {
          indexedDB.deleteDatabase(AppConstants.ExposureOfflineDBName);
          $this.CreateExposureDB();
          resolve(db);
        }
      };
    });
  }

  CreateInfoRequestDB() {
    return new Promise((resolve) => {
      var $this = this;

      var request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);

      request.onupgradeneeded = function (evt: any) {
        var objectStore = evt.currentTarget.result.createObjectStore(AppConstants.QueryOfflineTableName, { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('TempCaseNo', 'TempCaseNo', { unique: true });
        objectStore.createIndex('IsTempInfoRequest', 'IsTempInfoRequest', { unique: false });
        objectStore.createIndex('IsSyncStatus', 'IsSyncStatus', { unique: false });
        objectStore.createIndex('infoRequestDetail', AppConstants.QueryOfflineTableName, { unique: false });
        resolve(objectStore);
      };

      request.onsuccess = function (evt) {
        let db = request.result;
        if (db.objectStoreNames.contains(AppConstants.QueryOfflineTableName)) {
          resolve(db);
        } else {
          indexedDB.deleteDatabase(AppConstants.QueryOfflineDBName);
          $this.CreateInfoRequestDB();
          resolve(db);
        }
      };
    });
  }

  // public DeleteExposureLockByTabClose(caseLockInfo) {
  //   return new Promise(resolve => {
  //     let xhr = new XMLHttpRequest();
  //     xhr.open("POST", this.url.deleteExposureLockUrl, true);
  //     xhr.setRequestHeader("Content-Type", "application/json");
  //     xhr.send(JSON.stringify(caseLockInfo));
  //   });
  // }

  public GetAuditLogs(auditSearchCriteria): Observable<any> {
    return this.httpClient.post(this.url.getAuditLogsUrl, auditSearchCriteria);
  }

  public AddAudit(auditLog): Observable<any> {
    return this.httpClient.post(this.url.addAuditUrl, auditLog);
  }

  public GetLogTypes(): Observable<any> {
    return this.httpClient.get(this.url.getLogTypesUrl);
  }

  public GetModules(): Observable<any> {
    return this.httpClient.get(this.url.getModulesUrl);
  }

  public GetOperationTypesByModule(moduleId): Observable<any> {
    return this.httpClient.get(this.url.getOperationTypesByModuleUrl + '/' + moduleId);
  }

  public GetAssemblyVersion(): Observable<any> {
    return this.httpClient.get(this.url.getAssemblyVersionUrl);
  }

  public GetLoginMessage(): Observable<any> {
    return this.httpClient.get(this.url.getLoginMessageUrl);
  }

  public GetOwnerName(): Observable<any> {
    return this.httpClient.get(this.url.getOwnerNameUrl);
  }

  public removeFocussedUrl() {
    if (window.localStorage[AppConstants.LocalStorage.UrlList] != undefined && window.localStorage[AppConstants.LocalStorage.UrlList] != null) {
      var UrlList = JSON.parse(window.localStorage[AppConstants.LocalStorage.UrlList]);

      if (window.name != '') {
        var currentUrlList = { OpenedUrlList: [] };
        if (UrlList.OpenedUrlList.length > 0) {
          for (var i = 0; i < UrlList.OpenedUrlList.length; i++) {
            if (window.name != UrlList.OpenedUrlList[i].toString()) { currentUrlList.OpenedUrlList.push(UrlList.OpenedUrlList[i]); }
          }
          localStorage.setItem(AppConstants.LocalStorage.UrlList, JSON.stringify(currentUrlList));
          UrlList = currentUrlList;
        }
        window.name = '';
      }

      if (window.name == '') {
        var urll = window.location.pathname;
        var index = urll.indexOf('edit') > -1 ? urll.indexOf('edit') : urll.indexOf('view') > -1 ? urll.indexOf('view') : -1;
        var winName = '';
        if (index > -1) {
          winName = urll.substring(index, urll.length);
        }
        window.name = winName;
      }
      if (UrlList.OpenedUrlList.length > 0) {
        if (window.name != '') {
          if (!UrlList.OpenedUrlList.includes(window.name)) {
            UrlList.OpenedUrlList.push(window.name);
          }
        }
      } else {
        UrlList.OpenedUrlList.push(window.name);
      }
      localStorage.setItem(AppConstants.LocalStorage.UrlList, JSON.stringify(UrlList));
    }
  }

  public AddErrorLog(errorLog): Observable<any> {
    return this.httpClient.post(this.url.addErrorLogUrl, errorLog);
  }

  public GetErrorLogs(auditSearchCriteria): Observable<any> {
    return this.httpClient.post(this.url.getErrorLogUrl, auditSearchCriteria);
  }

  public GetInfoRequestValidation(caseId): Observable<any> {
    return this.httpClient.get(this.url.getInfoRequestValidationUrl + '/' + caseId);
  }

  public GetExposureValidationDetails(caseId): Observable<any> {
    return this.httpClient.get(this.url.getExposureValidationDetailsUrl + '/' + caseId);
  }

/**
 * Sets cookie values from server
 * Gets the cookie values from the server.
 * Sets SkipCookieOnLogin, centerID, centerName, centerCountry, and more
 */
public setCookieValueFromServer() {
    if (this._cookieService.get(AppConstants.CookieName.SkipCookieCallOnLogin) != 'true') {
      this.getCookieSettings(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe((cookieSettings: any) => {
        this._cookieService.set(
          AppConstants.CookieName.SkipCookieCallOnLogin,
          'false',
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.CenterId,
          cookieSettings.centreId,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.CenterName,
          cookieSettings.centreName,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.CenterInitial,
          cookieSettings.centreInitial,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.CenterState,
          cookieSettings.centreState,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.CenterCountry,
          cookieSettings.centreCountry,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.TwoFactorDurationType,
          cookieSettings.durationType,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.TwoFactorLastLoginDate,
          new Date(cookieSettings.lastLoginDateTime).toUTCString(),
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.PasswordLastChangedDate,
          cookieSettings.passwordChangedDate,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
        this._cookieService.set(
          AppConstants.CookieName.PasswordExpirationDate,
          cookieSettings.expirationDays,
          this.isRemembered.toLocaleLowerCase() == 'true' ? cookieSettings.remembermeTime : cookieSettings.cookiePersistenceTime,
          '/',null,true,"Strict"
        );
      });
    } else {
      this._cookieService.set(AppConstants.CookieName.SkipCookieCallOnLogin, 'false',
      null,
        '/', null
        , true // Secure
        , "Strict" // Same Site
      );
    }
  }

  public CheckErrorStatus(err: HttpErrorResponse) {
    return (
      err.status === 0 ||
      err.status === 400 ||
      err.status === 403 ||
      err.status === 404 ||
      err.status === 408 ||
      err.status === 500 ||
      err.status === 502 ||
      err.status === 503 ||
      err.status === 504 ||
      err.status === 550
    );
  }

  public updateSearchSource(sourceId: number) {
    return this.httpClient.get(this.url.updateSearchSourceUrl + '/' + sourceId.toString(10));
  }

  deleteNotice(deletedNotice: any) {
    return this.httpClient.post(this.url.deleteNoticeUrl, deletedNotice);
  }
  updateNotice(editedNotice: Notice) {
    return this.httpClient.post(this.url.updateNoticeUrl, editedNotice) as Observable<Notice>;
  }
  createNotice(newNotice: Notice) {
    return this.httpClient.post(this.url.createNoticeUrl, newNotice) as Observable<Notice>;
  }

  /* Check the passed Opened URL List for the window already being open */
  public IsWindowViewAlreadyOpen(caseId: number, UrlList: string[], IsEdit: boolean):boolean {
    let isWindowOpenAlready: boolean = false;
    const type = IsEdit ? 'edit' : 'view';

    for (let i = 0; i < UrlList.length; i++) {
      if (UrlList[i] == type + '/' + caseId) {
        isWindowOpenAlready = true;
      }
    }
    return isWindowOpenAlready;
  }
}
