
<div class="drag">
  <ejs-dialog #genericCaseValidationDialog id="genericValidationDialog" #genericValidationDialog [isModal]='true'
    [visible]="DisplayCaseValidationDialog" allowDragging='true' [animationSettings]='animationSettings'
    showCloseIcon='true' (close)="dialogClose()" (overlayClick)="onValidationCancelButtonClick()" [target]='target' [width]='dialogWidth' height=".90VH">
    <ng-template #header>
     <div  class=" icon-Validate " style="
              margin-left: 1px;
              margin-top: 1px;
              float:left;
              padding-right: 15px; "></div>

      <h4 class="modal-title1">{{validationTitle}}</h4>
    </ng-template>
    <ng-template #content>
      <div *ngIf="DisplayValidationPrompt" style="padding-top: 10px; font-weight: 500;">
        <span style="display: block;">{{validationHeader}}</span>
        <span style="display: block;">{{validationSubHeader}}</span>
      </div>
      <div id="creategenericDialogContent" style="min-height: .5vh">
        <ul *ngIf="ValidationErrors">
          <ng-template ngFor let-item [ngForOf]="ValidationErrors" let-i="index" [ngForTrackBy]="trackByFn">
            <li>
              <span class="icon-warning span-icon" [attr.data-severity]="item.severity"></span>
              {{item.errorMessage}}
            </li>
          </ng-template>
        </ul>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <button ejs-button id="genericCancel" *ngIf="DisplayValidationPrompt"
        class="generic-validation-dialog-btn mob-footer-alignbtn cancel-operation"
        (click)="onValidationCancelButtonClick()" data-ripple="true">Cancel</button>
      <button ejs-button id="genericContinue" *ngIf="DisplayValidationPrompt"
        class="dialog-ok-btn mob-footer-alignbtn save-operation" (click)="onValidationContinueButtonClick()"
        data-ripple="true">Continue</button>
      <button ejs-button id="genericOk" *ngIf="!DisplayValidationPrompt"
        class="dialog-ok-btn mob-footer-alignbtn save-Dlg-Buttons save-operation" (click)="onValidationOkButtonClick()"
        data-ripple="true">Ok</button>
    </ng-template>
  </ejs-dialog>
  <!-- [enableResize]='isDraggable' -->
</div>