<div class="ccontainer" style="display: flex;  flex-direction: column;  align-items: stretch;">

  <div class="count-header">{{Name | translate}}</div>

  <!-- <div style="color: var(--secondaryFontColor); align-items: center; justify-content: center;" class="citem"
     (click)="viewDetails()">
    {{DisplayValue}}
  </div> -->

  <svg viewBox="0 0 56 18" class="count-number" (click)="viewDetails()">
        <text x="50%" text-anchor="middle" style="fill:var(--secondaryFontColor);" y="75%" font
          [attr.font-size]="DisplayValueFontSize()">{{DisplayValue}}</text>
      </svg>
</div>