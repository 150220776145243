import { ApplicationRef, enableProdMode, isDevMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';
// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9RdEVhWHpdcXZURmhY');

if (environment.production) {
  enableProdMode();
}


// Due to Version 7 not properly registering the service worker, we replaced the next two lines with the code below.
// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
platformBrowserDynamic().bootstrapModule(AppModule)
  .then((module) => {
    if (isDevMode())
    enableDebugTools(module.injector.get(ApplicationRef).components[0])
  })   // enable debug tools
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('./sw-master.js');
    }  
  })

    .catch((err) => console.log(err));
