<a style="cursor: pointer; color: var(--primaryFontColor)" title="{{ substanceTitle }}" (click)="onSubstanceClick()">{{ substanceText }}</a>
<ejs-dialog
  #MySubDetail
  [visible]="MySubDetailDialogVisible"
  (open)="getMySubstanceDetails()"
  allowDragging="true" isModal="true"
  [animationSettings]="animationSettings"
  showCloseIcon="true"
  [target]="target"
  [width]="dialogWidth"
  height=".90VH"
  [buttons]="dlgButtons" 
>
  <ng-template #header>
    My Substance:<b>{{ substanceDetails.MYSUB_NAME }}</b>
  </ng-template>
  <ng-template #content>
    <div>
      <div class="row">
        <div class="col-12">
          <div [innerHTML]="substanceDetails?.MYSUB_DESC"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label><b>{{ 'common.AmtEachSubLabel' | translate }}</b></label>
          <div>{{ substanceDetails.MYSUB_AMT_EACH == null ? "N/A" : substanceDetails.MYSUB_AMT_EACH }}</div>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
