import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AutoComplete,FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { DataManager, ODataV4Adaptor, Predicate, Query } from '@syncfusion/ej2-data';
import Fuse from 'fuse.js';
import { AppConstants } from '../../app.constants';
import { OnlineService } from './../../Providers/OnlineService';
import { SubstanceSearchService } from './../../Services/substance-search.service';
import { MySubstance, MySubstanceStatus } from './../../models/mySubstanceModel';
import { TranslateService } from '@ngx-translate/core';
import { RichTextEditor } from '@syncfusion/ej2-angular-richtexteditor';
import { CookieService } from 'ngx-cookie-service';
import { get } from 'jquery';
import { Product } from '../../models/ProductModel';

@Component({
  selector: 'substance-search',
  templateUrl: './substance-search.component.html',
  styleUrls: ['./substance-search.component.scss'],
})
export class SubstanceSearchComponent implements OnInit, AfterViewInit {

  @Output() substanceSelected = new EventEmitter();

  private initialSearchResultSize = 25;

  private searchPredicate: Predicate;
  public minLengthSubstanceAutoComplete = 1;

  public showNPRSwitch: boolean = true;
  public showAMTSwitch: boolean = true;
  public showMySubSwitch: boolean = true;
  public showPDXSwitch: boolean = true;

  // access to the auto-complete
  @ViewChild('SubstanceAutoComplete', { static: false })
  public SubstanceAutoCompleteObj: AutoComplete;

  @ViewChild('substanceAutoCompleteOnline', { static: false })
  public substanceAutoCompleteOnlineObj: AutoComplete;

  // Add Substance Dialog settings
  @ViewChild('confirmDialog', { static: false })
  public confirmDialog: DialogComponent;
  public confirmCloseIcon: Boolean = true;
  public confirmDlgButtons: ButtonPropsModel[] = [
    { click: this.confirmDlgBtnClickNo.bind(this), buttonModel: { content: this.translate.instant('common.Cancel') } },
    { click: this.confirmDlgBtnClickYes.bind(this), buttonModel: { content: this.translate.instant('common.yesAddNewSubstance'), isPrimary: true, cssClass: 'search-Dlg-Buttons' } },
  ];
// viewchild for desc rte in the dialog
  @ViewChild('desc', { static: false })
  public newSubstanceDescriptionRTE: RichTextEditor;

  public includeNPR: boolean = true;
  public includeAMT: boolean = true;
  public includePDX: boolean = false;
  public includeMy: boolean = true;

  public confirmDlgAnimationSettings: Object = { effect: 'fade' };
  public confirmWidth = '882px';
  public confirmHeight = 'auto';
  public confirmIsModal: Boolean = true;
  public confirmDialogDragging: Boolean = true;
  public confirmHeader = " <div class='substance-icon icon-Substance modal-icon'></div>" + this.translate.instant('common.addSubAsNewSub');
  public target: HTMLElement = document.body;
  public visible: Boolean = true;
  public hidden: Boolean = false;
  public newSubstanceDescription: string;
  public newSubstanceAmtEach:number;

  public query: Query = new Query().take(20);

  public SubstanceSearchAutocompleteValue = '';
  public isPrintExposure = false; // not used, but in html. Remove at some point.
  public SearchSubstanceWaterMark = this.translate.instant('common.searchSubWatermark');

  public isSubsLoaded: boolean = false;

  // set the count for displays the suggestion items.
  public suggestionCount = 100;
  // maps the remote data column to fields property
  public remoteFields: Object = { value: 'Prod_Name' };
  // set the placeholder to AutoComplete input
  public remoteWaterMark = 'e.g. Paracetamol';
  // the search text that was entered
  public searchText = '';

  // Substance Search Autocomplete data
  public SubstanceAutoCompleteData = [];
  public SubstancesSearchItemsCount = this.SubstanceAutoCompleteData.length;

  constructor(
    public onlineService: OnlineService,
    public substanceSearchService: SubstanceSearchService,
    public translate: TranslateService,
    private _cookieService: CookieService,
    // private dialogService: DialogService
  ) { 
        // Check if the page is loaded from localhost, if so, use the local URL for the Substance Search Items List
        if (window.location.href.includes('localhost')) {    
          this.onlineSubstananceSearchURL = 'http://localhost:53374/odata/SubstanceSearches';
          this.onlineDataManager.dataSource.url = this.onlineSubstananceSearchURL;
        }
        this.onlineQuery=this.getDefaultOnlineQuery();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    console.log('requesting Substance Search Items List');
    this.substanceSearchService.getSubstanceSearchFromIndexedDB().then(
      (arg: any)=> {
        this.SubstanceAutoCompleteData = arg; // substanceDS;
        this.SubstancesSearchItemsCount = this.SubstanceAutoCompleteData.length;
        console.log('Got Substance Search Items List');
        this.isSubsLoaded = true;
      },
      (err: HttpErrorResponse) => {
        const errorLog = {
          // CentreId: this._cookieService.get("centerId"),
          // LoggedByUserId: this._cookieService.get("userId"),
          CustomErrorMessage: err.message,
          ErrorMessage: err.message,
          InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description,
        };
        if (this.onlineService.CheckErrorStatus(err)) {
          this.onlineService.AddErrorLog(errorLog).subscribe(() => {
            console.log("Error logged:" + errorLog);
          });
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          this.onlineService.AddErrorLog(errorLog).subscribe(() => {
            console.log("Error logged:" + errorLog);            
          });
        }
      }
    );
  }

  // substance search online autocomplete properties and methods
// set the url for the remote data, when localhost, use the VS2022 IIS Express URL
private onlineSubstananceSearchURL = '/odata/SubstanceSearches'; // 

public onlineDataManager: DataManager = new DataManager({
  url: this.onlineSubstananceSearchURL, //AppConstants.ApiEndpoint + 'SubstanceSearch/GetSubstances',
  adaptor: new ODataV4Adaptor,
  crossDomain: true,
  headers: [{ Authorization: 'Bearer ' + this._cookieService.get(AppConstants.CookieName.UserToken) }],
  // headers: [{ Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cG4iOiJkcHIiLCJuYW1laWQiOiIxIiwiZW1haWwiOiJkcndlYm1vbmtleUB0b3hhd2FyZXNvZnR3YXJlLmNvbSIsImZpcnN0X25hbWUiOiJEYXZpZCIsImZhbWlseV9uYW1lIjoiUm9zZSIsImdpdmVuX25hbWUiOiJSb3NlLCBEYXZpZCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiKzY0MjA0MTY5MzY1NSIsImxhc3RfbW9kaWZpZWRfZGF0ZSI6IjIwMjQtMDEtMjBUMTA6NTk6MDYuMDAwMDAwMFoiLCJpc3N1ZWRfZGF0ZSI6IjIwMjQtMDMtMDRUMTM6Mzc6NTYuMDAwMDAwMFoiLCJjZW50cmVfaWQiOjI1LCJuYmYiOjE3MDk2MDYyNzYsImV4cCI6MTcxMDIxMTA3NiwiaWF0IjoxNzA5NjA2Mjc2LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjUzMzc0IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo1MzM3NCJ9.ywVhq6LvpJ6o6ok-xo9Lv_Kyds1tgYXJZUOMzLOlWR8' }]
});
public onlineQuery: Query;

public getDefaultOnlineQuery(): Query {
  return new Query().take(25).select(['Prod_Id', 'Prod_Name', 'Med_Id', 'Med_Name', 'Amt_Each', 'Source']);
}
public onlineFields = { value: 'Prod_Name' };


  dialogOpen() {
    this.newSubstanceDescriptionRTE.focusIn();
    this.newSubstanceDescriptionRTE.refreshUI(); // this is needed to refresh the UI of the RTE since it was created before the dialog was shown and must be refreshed to show properly.
    // alert('Dialog Open!');
  }
  dialogClose() {
    this.SubstanceSearchAutocompleteValue = ''; // clear search text
  }

  onFilteringSubstanceSearch: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    if (e.text != null && e.text.length >= this.minLengthSubstanceAutoComplete) {
      const options: Object = {
        keys: ['Prod_Name', 'Prod_Id'],
        includeMatches: true,
        findAllMatches: true,
        threshold: 0.2,
        ignoreLocation: true,
        ignoreFieldNorm: true,
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: false,
        tokenize: true,
      };
      // save the search text
      this.searchText = e.text;
      // create object from Fuse constructor.
      const fuse = new Fuse(this.SubstanceAutoCompleteData, options);
      // store the search result data based on typed characters.
      const result: any = fuse.search(e.text);
      const data: { [key: string]: Object }[] = [];
      const maxEntries = this.suggestionCount < result.length ? this.suggestionCount : result.length;
      for (let i = 0; i < maxEntries; i++) {
        data.push(result[i].item as any);
      }
      // pass the filter data source to updateData method.
      e.updateData(data, null);
    } else { e.cancel = true; }
  }

  onOpen(args: any) {
    let start: number = 1;
    let end: number = this.initialSearchResultSize - 1;
    const listElement: HTMLElement = (this.SubstanceAutoCompleteObj as any).list; // get the list (dropdown) of the substance AutoComplete
    // Add the scroll event listener
    listElement.addEventListener('scroll', () => {
      if (listElement.scrollTop + listElement.offsetHeight >= listElement.scrollHeight - 1) {
        let filterQuery = new Query().where(this.searchPredicate); // start with the search predicate
        new DataManager(this.SubstanceAutoCompleteData)
          .executeQuery(filterQuery.range(start, end))
          .then((event: any) => {
            start = end;
            end += 10;
            this.SubstanceAutoCompleteObj.addItem(event.result as {
              [key: string]: Object;
            }[]);
          })
          .catch((e: Object) => { });
      }
    });
  }

  onOpenOnline(args: any) {
    let start: number = 1;
    let end: number = this.initialSearchResultSize - 1;
    const listElement: HTMLElement = (this.substanceAutoCompleteOnlineObj as any).list; // get the list (dropdown) of the substance AutoComplete
    // Add the scroll event listener
    listElement.addEventListener('scroll', () => {
      if (listElement.scrollTop + listElement.offsetHeight >= listElement.scrollHeight - 1) {
        let filterQuery = new Query().where(this.searchPredicate); // start with the search predicate
        new DataManager(this.SubstanceAutoCompleteData)
          .executeQuery(filterQuery.range(start, end))
          .then((event: any) => {
            start = end;
            end += 10;
            this.substanceAutoCompleteOnlineObj.addItem(event.result as {
              [key: string]: Object;
            }[]);
          })
          .catch((e: Object) => { });
      }
    });
  }

  // Generic Code picker Event handler
  onGenericSubstanceSelected() { }

  onSearchSubstanceChange() {
  }
  // handle the actual selection of a item from the dropdown
  onSubstanceSelected(arg) {
    this.substanceSelected.emit([
      arg.itemData['Prod_Id'],
      arg.itemData['Prod_Name'],
      arg.itemData['Med_Id'] == 0 ? '': arg.itemData['Med_Id'],
      arg.itemData['Med_Name'],
      this.SubstanceAutoCompleteObj['typedString'],
      arg.itemData['Amt_Each'],
      arg.itemData['Source']
    ]); // return an event!
    arg.cancel = true;
    this.SubstanceSearchAutocompleteValue = ''; // clear search text
  }

  /**
   * Handles the event when a substance is entered.
   * If the substance entered is not in the list, then a dialog is shown to add the substance.
   * If the substance entered is in the list, then the substance is selected.
   * @param substanceEntered - The substance entered by the user.
   */
  /**
   * Handles the event when a substance is entered.
   * @param substanceEntered - The substance entered by the user.
   */
  onSubstanceEntered(substanceEntered: any) {
    // check for entered value of at least on character
    if (substanceEntered.value != null && String(substanceEntered.value).trim().length > 0) {
      // check if the entered substance is the last thing in the list and matches the current search term, add it to the list, otherwise show the dialog to create new substance
      if (substanceEntered.getItems().length === 1 && substanceEntered.listData[0].Prod_Name.trim().toLocaleUpperCase() == substanceEntered.value.trim().toLocaleUpperCase()) {
        this.onSubstanceSelected({ itemData: substanceEntered.listData[0], cancel: true });
      } else {
        this.newSubstanceAmtEach = null;
        this.newSubstanceDescription = '';
        this.confirmDialog.show();
      }
    }
  }

  public confirmDlgBtnClickYes() {

    const newSubstanceToAdd: MySubstance = {
      MYSUB_NAME: this.SubstanceSearchAutocompleteValue, mysuB_ID: AppConstants.SubstanceSearchSources.MySubstances, MYSUB_CREATED: null, MYSUB_CREATOR: null,
      MYSUB_DESC: this.newSubstanceDescription, MYSUB_GENERIC: null, MYSUB_PROD: null, MYSUB_STATUS: MySubstanceStatus.New,
      MYSUB_AMT_EACH: this.newSubstanceAmtEach
    };

    this.substanceSearchService.AddMySubstance(newSubstanceToAdd).subscribe(
      (newSubstance: MySubstance) => {
        if (newSubstance.mysuB_ID === 0) {
          //todo: Better error handling
          alert('Substance Already Exists!');
        } else {
          this.substanceSelected.emit([
            'MY' + newSubstance.mysuB_ID, // substance id
            this.SubstanceSearchAutocompleteValue,  // substance name
            '?', // med id 
            '?', // med name
            this.substanceAutoCompleteOnlineObj['typedString'],// verbatim search text
            this.newSubstanceAmtEach, //  amount each
            AppConstants.SubstanceSearch.Type.MySubstances // source
          ]); // return an event!
          this.confirmDialog.hide(); // hide the dialog
          this.SubstanceSearchAutocompleteValue = ''; // clear the search text
          this.newSubstanceDescription = '';
          this.newSubstanceAmtEach = null;
          // update the autocomplete list and indexeddb          
          this.substanceSearchService.GetSubstanceSearchUpdates(null).then((o) => {
            // loop through the result and add / update the substances
            o.forEach((substance: Product) => { // Fix the type declaration of 'substance'
              if (this.SubstanceAutoCompleteData.find((x) => x.prod_Id === substance.prod_Id) === undefined) {
                this.SubstanceAutoCompleteData.push(substance);
                console.debug('Substance Search Updated ' + substance.prod_Id + ' ' + substance.prod_Name);
              }
              else {
                console.debug('Substance Search Already Exists ' + substance.prod_Id + ' ' + substance.prod_Name);
              }
            });
          });
        }
      }
      , (error) => {
        console.debug('Error: ', error);
      });
  }


  public confirmDlgBtnClickNo() {
    this.confirmDialog.hide();
    this.newSubstanceDescription = '';
  }

  onFiltering(e:FilteringEventArgs) {
    // console.log(e);
    let pred: Predicate;
    let subString: string[];
    let query: Query = new Query();//.sortBy('Prod_Name', 'ascending');
    if (e.text.includes(' ') && e.text.charAt(e.text.length - 1) !== ' ') {
      subString = e.text.split(' ');
      subString.map((value) => {
        if (pred) {
          pred = pred.and('Prod_Name', 'contains', value, true, true);
        } else {
          pred = new Predicate('Prod_Name', 'contains', value, true, true);
        }
        pred = this.addSubstanceSourceFilter(pred);
      });
      query = query.where(pred).take(this.initialSearchResultSize);
    } else {
      this.searchPredicate = new Predicate('Prod_Name', 'contains', e.text, true);
      this.searchPredicate = this.addSubstanceSourceFilter(this.searchPredicate);
      query = (e.text !== '') ? query.where(this.searchPredicate).take(this.initialSearchResultSize) : query;
    }
    e.updateData(this.SubstanceAutoCompleteData, query);
  }

  onFilteringOnline(e: FilteringEventArgs) {
    let pred: Predicate;
    let subString: string[];
    let query: Query = this.getDefaultOnlineQuery();  // start with a new query
    if (e.text.includes(' ') && e.text.charAt(e.text.length - 1) !== ' ') {
      subString = e.text.split(' ');
      subString.map((value) => {
        if (pred) {
          pred = pred.and('Prod_Name', 'contains', value.trim(), true, true);
        } else {
          pred = new Predicate('Prod_Name', 'contains', value.trim(), true, true);
        }
        pred = this.addSubstanceSourceFilter(pred, 'Source');
      });
      query = query.where(pred).take(this.initialSearchResultSize);
    } else {
      this.searchPredicate = new Predicate('Prod_Name', 'contains', e.text.trim(), true);
      this.searchPredicate = this.addSubstanceSourceFilter(this.searchPredicate, 'Source');
      query = (e.text !== '') ? query.where(this.searchPredicate).take(this.initialSearchResultSize) : query;
    }
    e.updateData(this.onlineDataManager, query);
  }

  addSubstanceSourceFilter(pred: Predicate, sourceColumnName: string = 'Source'): Predicate {
    if (!this.includeAMT) { pred = pred.and(sourceColumnName, 'notequal', parseInt(AppConstants.SubstanceSearch.Type.AMT, 10)); }
    if (!this.includeNPR) { pred = pred.and(sourceColumnName, 'notequal', parseInt(AppConstants.SubstanceSearch.Type.NPR, 10)); }
    if (!this.includeMy)  { pred = pred.and(sourceColumnName, 'notequal', parseInt(AppConstants.SubstanceSearch.Type.MySubstances, 10)); }
    if (!this.includePDX) { pred = pred.and(sourceColumnName, 'notequal', parseInt(AppConstants.SubstanceSearch.Type.PDX, 10)); }
    return pred;
  }

  /**
   * Updates the substances in the SubstanceAutoCompleteData array from the server
   */
  UpdateSubstances() {

    this.substanceSearchService.GetSubstanceSearchUpdates(null).then((o) => {

      // loop through the result and add / update the substances
      o.forEach((substance) => {
        // this.SubstanceAutoCompleteData.find(x => ((Object)x).Prod_Id === substance.Prod_Id) === undefined) {          
        // check for added/updated substances to the substanceautocompletedata
        const locatedSubstance = this.SubstanceAutoCompleteData.find((x) => x.prod_Id === substance.prod_Id);
        if (locatedSubstance === undefined) {
          const capitalizedSubstance: { [key: string]: any; } = this.CapitalizeFieldLeadingCharacter(locatedSubstance, substance);        
          this.SubstanceAutoCompleteData.push(capitalizedSubstance);
          console.debug('Added Substance: ' + substance.prod_Id + ' :' + substance.prod_Name);
        } else {
          const existingSubstance = this.SubstanceAutoCompleteData.find((x) => x.Prod_Id === substance.prod_Id);
          existingSubstance.Prod_Name = substance.prod_Name;
          existingSubstance.Med_Id = substance.med_Id;
          existingSubstance.Med_Name = substance.med_Name;
          existingSubstance.Amt_Each = substance.amt_Each;
          existingSubstance.Source = substance.source;
          existingSubstance.Update_Dt = substance.update_Dt;
          existingSubstance.Description = substance.description;
          existingSubstance.Status= substance.status;
          
          console.debug('Substance Already existed: ' + substance.prod_Id + ' :' + substance.prod_Name);
        }
      });
    });

  }


  private CapitalizeFieldLeadingCharacter(locatedSubstance: any, substance: any) {
    const capitalizedSubstance: { [key: string]: any; } = {};
    for (const key in locatedSubstance) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      capitalizedSubstance[capitalizedKey] = substance[key];
    }
    return capitalizedSubstance;
  }
}