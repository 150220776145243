import { Component, OnInit } from '@angular/core';
import { NoticeService } from './../../Providers/notice.service';

@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.scss']
})
export class NoticeListComponent implements OnInit {
  public noticeData: any;
  constructor(private svc: NoticeService) { }

  ngOnInit() {
    this.svc.getNotices().subscribe((data) => {
      this.noticeData = data;
      // walk over data,removing from noticeData if not active or not in date range
      for (let i = data.length - 1; i > -1; i--) {
        const element = this.noticeData[i];
        if (!element.active || new Date(element.start_Dt) > new Date() || (element.end_Dt && new Date(element.end_Dt) < new Date())) {
          this.noticeData.splice(i, 1); // Remove notice from noticedata to be displayed
        }
      }
    },
      err => {
        console.log(err);
        this.noticeData = [{
          notice_Id: 0, notice_Tx: 'Notices are not available offline.', start_Dt: new Date(), End_Dt: null, Active: true,
          Order: 1
        }];
      });
  }
}

