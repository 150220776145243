<div class="navbar navbar-inverse navbar-fixed-top top-bar hidden-xs desktop-toxaware-header" id="site-header">
  <div class="navbar-collapse collapse navigation-menu desktop-toxaware-header">
    <!-- <div class="networkStatus hidden-xs pull-left" [ngClass]="newIsOnline == 'true' ? 'bg-green' : 'bg-red'"
      title="{{ newIsOnline == 'true' ? 'ToxAware Online' : 'ToxAware Offline' }}"></div> -->
    <div class="navbar-brand  app-home " [routerLink]="['/home']" 
      title='{{"common.home" | translate}}'>
      <a [routerLink]="['/home']"><img class="home-logo-toxaware  focusableThree" autofocus tabindex="0" [ngClass]="newIsOnline == 'true' ? 'bg-green' : 'bg-red'"
          src="/assets/Images/ToxAwareSmallLogo.Min.png" alt='{{"common.home" | translate}}' /></a>
    </div>

    <ul class="nav navbar-nav menu-item">
      <li *ngIf="permissionService.isHomePageHasViewPermission" class="nav-item menu-section " [routerLinkActive]="['active']">
        <a tabindex="0" class="nav-link yellow-bar menu-header focusable" title='{{"common.viewMyCases" | translate}}'
          [routerLink]="['/home/mycases']">{{"common.myCases" | translate}}</a>
      </li>
      <li *ngIf="permissionService.isReportsPageHasViewPermission" class="nav-item menu-section  " [routerLinkActive]="['active']">
        <a tabindex="0" class="nav-link yellow-bar menu-header focusable" title='{{"common.viewReports" | translate}}'
          [routerLink]="['/reports']">{{"common.reports" | translate}}</a>
      </li>
      <!-- <li *ngIf="isReviewsPageHasViewPermission" class="nav-item menu-section" [routerLinkActive]="['active']">
        <a class="nav-link menu-header" title="View Reviews of Cases" [routerLink]="['/reviews']">Reviews </a>
      </li> -->
      <li *ngIf="permissionService.isSearchPageHasViewPermission" class="nav-item menu-section  " [routerLinkActive]="['active']">
        <a tabindex="0" class="nav-link yellow-bar menu-header focusable" title='{{"common.viewSearch" |translate }}'
          [routerLink]="['/search']">{{"common.search" | translate}}</a>
      </li>
      <li *ngIf="permissionService.isSearchPageHasViewPermission" class="nav-item menu-section  " [routerLinkActive]="['active']">
        <a tabindex="0" class="nav-link yellow-bar menu-header focusable" title='{{"common.viewRecalls" | translate}}'
          [routerLink]="['/recalls']">{{"common.recalls" | translate}}</a>
      </li>
      <li *ngIf="permissionService.isSearchPageHasViewPermission" class="nav-item menu-section  " [routerLinkActive]="['active']">
        <a tabindex="0" class="nav-link yellow-bar menu-header focusable" title='{{"common.viewDashboard" | translate}}'
          [routerLink]="['/dashboard']">{{"common.dashboard" | translate}}</a>
      </li>
    </ul>

    <div class="pull-right">
      <ul class="nav navbar-nav menu-item">

        <!-- <li *ngIf="isSearchPageHasViewPermission">
          <a class="padding0" title="Search for Cases" [routerLink]="['/search']">
            <button type="button" class="case-btn btn e-control e-btn e-lib">Search</button></a>
        </li> -->
        <li class="nav-item menu-section" [routerLinkActive]="['active']" style="height: 47px;width: 105px;">
          <div style="padding-top: 3px; margin-left: 5px; margin-right: 5px; height: 100%">
            <div id="divCaseLauncher"
              [ngClass]="newIsOnline == 'true' ? 'divCaseLauncher' : 'divCaseLauncher divCaseLauncherDisabled'"
              title='{{"common.viewByCaseId" | translate}} {{ newIsOnline == "true" ? "" : "common.unavailableOffline"|translate}}'>
              <table style="height: 100%">
                <tr>
                  <td style="text-align: right; padding-left: 0px; padding-top: 6px; vertical-align: middle">
                    <div class="e-float-input"
                      style="width: 100px; margin-top: 0px; margin-bottom: 0px; vertical-align: middle">
                      <input id="tbxExpoInfoIdSearch" type="text"
                        title='{{"common.viewByCaseId" | translate}} {{ newIsOnline == "true" ? "" : "common.unavailableOffline"|translate}}'
                        maxlength="15" name="tbxExpoInfoIdSearch" style="background-color: transparent"
                        [value]="caseLauncherInfoExpoId" [(ngModel)]="caseLauncherInfoExpoId"
                        (keydown)="caseLauncher_onKeydown($event)" [disabled]="newIsOnline != 'true'" spellcheck="false"
                        placeholder='{{"common.viewByCaseId"|translate}}' />
                      <span class="e-float-line"></span>
                    </div>
                  </td>                  
                </tr>
              </table>
            </div>
          </div>
          <!-- Multiple Case popup section -->
          <div class="fade" id="CaseLauncherDialog" role="dialog"
            [ngStyle]="{ display: showCaseLauncherDialog ? 'block' : 'none', opacity: '1', position: 'absolute', 'margin-top': '10px' }">
            <div class="case-launcher-dialog">
              <div class="modal-content case-launcher-dialog-content">
                <div class="modal-body case-launcher-dialog-body" style="padding: 0">
                  <div id="caseLauncherDialogMultipleCasesContent">
                    <div class="case-launcher-container" style="display: table !important; vertical-align: middle">
                      <div style="display: table-cell">
                        <label>{{"common.viewByCaseIDMultiple" | translate}}</label>
                        <ejs-grid #launcherMultipleCasesGrid [dataSource]="caseLauncherCaseInfoData"
                          id="launcherMultipleCasesGridId" gridLines="Both" enableAltRow="false"
                          (recordDoubleClick)="caseLauncherDoubleClick($event)">
                          <e-columns>
                            <e-column field="caseNumber" headerText="{{'common.caseId' | translate}}" textAlign="Left"></e-column>
                            <e-column field="caseType" headerText="{{'common.caseType' | translate}}" textAlign="Left"></e-column>
                            <e-column field="primarySubstanceName" headerText="{{'common.primarySubstance' | translate}}" textAlign="Left">
                            </e-column>
                            <e-column field="callDateTime" headerText="{{'common.callDateTime' | translate}}" textAlign="Right"
                              [format]="caseTimeFormatOptions" type="datetime"></e-column>
                            <e-column field="statusName" headerText="{{'common.Status' | translate}}Status" textAlign="Left"></e-column>
                          </e-columns>
                        </ejs-grid>
                      </div>
                      <button type="button" class="close" (click)="selectCaseLauncherCancelClick()"
                        [ngStyle]="{ display: 'block' }"
                        style="display: table-cell; vertical-align: middle; margin-left: -10px" data-dismiss="modal">
                        &times;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="permissionService.isInfoRequestPageHasAddPermission">
          <a target="_blank" class="padding0" title="{{'common.newQueryTitle'|translate}}" [routerLink]="['/query']"
            tabindex="-1"><button type="button" class="case-btn btn animate pop delay-3 e-control e-btn e-lib " tabindex="0"
              [disabled]="hasUserSettings">{{"common.newQuery"|translate}}</button></a>
        </li>

        <li *ngIf="permissionService.isExposurePageHasAddPermission">
          <a target="_blank" class="padding0" title="{{'common.newExposureTitle'|translate}}" [routerLink]="['/exposure']"
            tabindex="-1"><button type="button" class="case-btn btn animate pop delay-2 e-control e-btn e-lib " tabindex="0"
              [disabled]="hasUserSettings">{{"common.newExposure"|translate}}</button></a>
        </li>

        <!-- Menu -->
        <li id='profile-image-section'>
          <button (click)="UserIconClick($event)" class="user-menu btn animate pop delay-1 e-control e-btn e-lib focusableThree"
            title='{{"common.openSettingsMenu"|translate}}'>
            {{ UserDisplayName }}
            <img id="profilePic" src="{{newIsOnline == 'true' ?  profileImageSrc  : '/assets/Images/Profile.png'}}"
              class="img-circle user-image" alt='{{"common.profilePictureTitle"|translate}}' />
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation"
              focusable="false"
              style="position: relative;fill: none; vertical-align: middle; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
              <g fill="none" fill-rule="nonzero">
                <path d="m2 16h28"></path>
                <path d="m2 24h28"></path>
                <path d="m2 8h28"></path>
              </g>
            </svg>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>



<!-- Small menu/Mobile -->
<div class="navbar navbar-inverse navbar-fixed-top top-bar visible-xs mobile-toxaware-header">
  <div class="visible-xs navigation-menu navbar-fixed-top mobile-toxaware-header">
    <div class="icon-menu m-l-15 menu-icon-decorate" (click)="clickedInside($event)" title="{{'common.openSettingsMenu'|translate}}"></div>
    <div class="mb-hamburger" [routerLinkActive]="['active']" title="{{'common.home'|translate}}">
      <div class="mobile-logo-section" [routerLink]="['/home'] " ><img class="mob-home-logo-toxaware"
          src="/assets/Images/ToxAwareSmallLogo.Min.png" alt='{{"common.home"|translate}}' /></div>
    </div>
    
    <div class="networkStatus visible-xs visible-sm pull-right"
      [ngClass]="newIsOnline == 'true' ? 'bg-green' : 'bg-red'"
      title="{{ (newIsOnline == 'true' ? 'common.online' : 'common.offline') | translate }}"></div>
    <!-- </div> -->
    <!-- <div class="col-xs-2" style="right: auto;">
      <ejs-dropdownlist id='languageDD' #languageDD [dataSource]='LanguageChoices' [(value)]='SavedLanguageCode'
       [fields]="{ text: 'text', value: 'value' }" placeholder="{{ 'common.interfaceLanguage' | translate }} " popupHeight='220px' floatLabelType="Always" width="200px"
       (change)="languageChanged($event)"></ejs-dropdownlist>
    </div> -->
    <div id="LeftMenu" class="hide">
      <div class="leftmenu-backdrop" (click)="OpenLeftMenu($event)"></div>
      <ul class="nav navbar-nav mb-menu-item">
       
        <li class="desktop-mid-header" [routerLink]="newIsOnline == 'true' ? ['/profile'] : ['/home']">
          <img src="{{newIsOnline == 'true' ?  profileImageSrc  : '/assets/Images/Profile.png'}}" 
            class="img-circle mb-user-image pointer"  alt="Profile Picture" />
          <span class="mb-user-name" title="{{ UserDisplayName }}">{{ UserDisplayName }} </span>
        </li>
        
        <!-- <li class="nav-item mb-menu-section desktop-mid-header" [routerLinkActive]="['active']">
          <a class="nav-link mb-menu-header" [routerLink]="['/home']">{{'common.home'|translate}}</a>
        </li> -->
        <li id="mbMenuItemCaseLauncher" class="nav-item mb-menu-section desktop-mid-header">

          <div style="padding: 0px; margin: 0px; margin-top: -2px; height: 27px;">
            <div id="divCaseLauncher2"
              [ngClass]="newIsOnline == 'true' ? 'divCaseLauncher' : 'divCaseLauncher divCaseLauncherDisabled'"
              title="{{ newIsOnline == 'true' ? 'View by case id' : 'View by case id (Unavailable Offline)' }}">
              <table>
                <tr>
                  <td style="text-align: right; padding-left: 20px; padding-top: 0px; vertical-align: middle">
                    <div class="e-float-input"
                      style="width: 100px; margin-top: 0px; margin-bottom: 0px; vertical-align: middle">
                      <input id="tbxExpoInfoIdSearch2" type="text"
                      title='{{"common.viewByCaseId" | translate}} {{ newIsOnline == "true" ? "" : "common.unavailableOffline"|translate}}'
                      maxlength="15" name="tbxExpoInfoIdSearch" style="background-color: transparent"
                      [value]="caseLauncherInfoExpoId" [(ngModel)]="caseLauncherInfoExpoId"
                      (keydown)="caseLauncher_onKeydown($event)" [disabled]="newIsOnline != 'true'" spellcheck="false"
                      placeholder='{{"common.viewByCaseId"|translate}}' />                      
                      <span class="e-float-line"></span>
                    </div>
                  </td>
                  <td style="padding-left: 5px; padding-right: 10px">
                    <div style="width: 34px; height: 27px; position: relative">
                      <button ejs-button id="btnCaseLauncher2" iconCss="icon-search header-icon"
                        cssClass="mb-menu-id-search-btn" (click)="launchInfoExpoByIdClick($event)"
                        [disabled]="newIsOnline != 'true'"
                        title='{{"common.viewByCaseId" | translate}} {{ newIsOnline == "true" ? "" : "common.unavailableOffline"|translate}}'></button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </li>

        <!-- Small menu / Mobile -->
        <li *ngIf="permissionService.isExposurePageHasAddPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLink]="['/exposure']">
          <a class="nav-link mb-menu-header" title="{{'common.newExposureTitle'|translate}}">{{'common.newExposure'|translate}}</a>
        </li>

        <li *ngIf="permissionService.isInfoRequestPageHasAddPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLink]="['/query']">
          <a class="nav-link mb-menu-header" title="{{'common.newQueryTitle'|translate}}">{{'common.newQuery'|translate}}</a>
        </li>

       

        <!-- <div class="dropdown user-assets focusableFour">
          <li class="nav-item mb-menu-section desktop-mid-header " title="{{'common.languageTitle'|translate }}" (mouseover)="showLangFlyout()" tabindex="0">
            <span class="nav-link mb-menu-header"></span>{{"common.language"| translate}}
          </li>
          <div class="dropdown-content">
            <div>
              <li *ngFor="let language of LanguageChoices;">
                <span class="dropdown-item" (click)="setLanguageClick(language.value)">{{language.text}}</span>
              </li>
            </div>
          </div>
        </div> -->

        <li *ngIf="permissionService.isSearchPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLinkActive]="['active']">
          <a class="nav-link mb-menu-header" [routerLink]="['/search']" title='{{"common.viewSearch"|translate}}'>{{'common.search'|translate}}</a>
        </li>

        <li *ngIf="permissionService.isReportsPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLinkActive]="['active']">
          <a class="nav-link mb-menu-header" [routerLink]="['/reports']" title='{{"common.viewReports"|translate}}'>{{'common.reports'|translate}}</a>
        </li>

        <li  class="nav-item mb-menu-section desktop-mid-header"
        [routerLinkActive]="['active']">
        <a class="nav-link mb-menu-header" [routerLink]="['/home/mycases']" title='{{"common.viewMyCases"|translate}}'>{{'common.myCases'|translate}}</a>
      </li>
      <li  class="nav-item mb-menu-section desktop-mid-header"
        [routerLinkActive]="['active']">
        <a class="nav-link mb-menu-header" [routerLink]="['/home/centercases']" title='{{"common.viewCentreCases"|translate}}'>{{'common.centreCases'|translate}}</a>
      </li>
      <li  class="nav-item mb-menu-section desktop-mid-header"
        [routerLinkActive]="['active']">
        <a class="nav-link mb-menu-header" [routerLink]="['/home/networkcases']" title='{{"common.viewNetworkCases"|translate}}'>{{'common.networkCases'|translate}}</a>
      </li>
      <li  class="nav-item mb-menu-section desktop-mid-header"
        [routerLinkActive]="['active']">
        <a class="nav-link mb-menu-header" [routerLink]="['/home/reviewcases']" title='{{"common.viewReviewCases"|translate}}'>{{'common.reviewCases'|translate}}</a>
      </li>
      <li  class="nav-item mb-menu-section desktop-mid-header"
        [routerLinkActive]="['active']">
        <a class="nav-link mb-menu-header" [routerLink]="['/home/responcecases']" title='{{"common.viewResponseCases"|translate}}'>{{'common.responseCases'|translate}}</a>
      </li>
      <li  class="nav-item mb-menu-section desktop-mid-header"
        [routerLinkActive]="['active']">
        <a class="nav-link mb-menu-header" [routerLink]="['/home/mycases']" title='{{"common.viewSurveillanceCases"|translate}}'>{{'common.surveillanceCases'|translate}}</a>
      </li>
      


        <li class="nav-item mb-menu-section desktop-mid-header">
          <hr />
        </li>
        <li *ngIf="newIsOnline == 'true'" class="nav-item mb-menu-section desktop-mid-header" [routerLinkActive]="['active']">
          <a tabindex="0" class="nav-link mb-menu-header focusableFour" img src="/assets/Images/myprofile.png"
            [routerLink]="['/profile']" title='{{"common.myProfileTitle" | translate }}'>{{'common.myProfile' | translate}}</a>
        </li>
        <li *ngIf="newIsOnline == 'true'" class="nav-item mb-menu-section desktop-mid-header" [routerLinkActive]="['active']">
          <a tabindex="0" class="nav-link mb-menu-header focusableFour " img src="/assets/Images/myprofile.png"
            [routerLink]="['/recalls']" title='{{"common.viewRecalls"|translate}}'>{{'common.recalls'|translate}}</a>
        </li>

        <li *ngIf="permissionService.isSystemSettingsPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header" [routerLinkActive]="['active']">
          <a tabindex="1" id="tab1-tab" class="nav-link mb-menu-header focusableFour" href="/systemsettings?t=1"
            [ngClass]="{'active' :selectedTab ==1}" title='{{"common.systemConfigurationTitle"|translate}}'>{{'common.systemConfiguration'|translate}}</a>
        </li>
        <li *ngIf="permissionService.isSystemSettingsPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header" id="material-tabs">
          <a tabindex="2" id="tab2-tab" href="/systemsettings?t=2" [ngClass]="{'active' :selectedTab ==2}" title='{{"common.codeValuesTitle"|translate}}'>{{'common.codeValues'|translate}}</a>
        </li>
        <li *ngIf="permissionService.isSystemSettingsPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header"
          id="material-tabs">
          <a tabindex="3" id="tab3-tab" href="/systemsettings?t=3" [ngClass]="{'active' :selectedTab ==3}" title='{{"common.validationsTitle"|translate}}'>{{'common.validations'|translate}}</a>
        </li>
        <!-- <li *ngIf="isSystemSettingsPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header" id="material-tabs">
          <a tabindex="4" id="tab4-tab"   href="/systemsettings?t=4" [ngClass] ="{'active' :selectedTab ==4}"> My Substances</a>
        </li> -->
        <li *ngIf="permissionService.isAuditPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLinkActive]="['active']">
          <a tabindex="4" class="nav-link mb-menu-header focusableFour" [routerLink]="['/audit']" title='{{"common.auditsTitle"|translate}}'>{{'common.audits'|translate}}</a>
        </li>
        <li *ngIf="permissionService.isAuditPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLinkActive]="['active']">
          <a tabindex="4" class="nav-link mb-menu-header focusableFour" [routerLink]="['/data']" title='{{"common.dataTitle"|translate}}'>{{'common.data'|translate}}</a>
        </li>
        <li *ngIf="permissionService.isLocksPageHasViewPermission" class="nav-item mb-menu-section desktop-mid-header"
          [routerLinkActive]="['active']">
          <a tabindex="5" class="nav-link mb-menu-header focusableFour" [routerLink]="['/locks']" target="_blank" title='{{ "common.locksTitle"|translate}}'>{{'common.locks'|translate}}</a>
        </li>
        <!-- to put in when links are added  -- target="_blank" -->
        <li *ngIf="newIsOnline == 'true'" class="nav-item mb-menu-section logout-cls" (click)="Logout()"
          [routerLinkActive]="['active']">
          <a tabindex="0" class="nav-link mb-menu-header focusableThree" title='{{"common.logoutTitle"|translate}}'>{{'common.logout'|translate}}</a>
        </li>
        <!-- language dropdown small menu  -->
        <li class="col-xs-12">
          <ejs-dropdownlist id='languageDD' #languageDD [dataSource]='LanguageChoices' [(value)]='SavedLanguageCode'
            [fields]="{ text: 'text', value: 'value' }" placeholder="{{ 'common.interfaceLanguage' | translate }} "
            popupHeight='220px' floatLabelType="Always" width="200px" (change)="languageChanged($event)" (beforeClose)="onBeforeClose($event)">
          </ejs-dropdownlist>
        </li>
      </ul>

     

    </div>
  </div>
</div>
<div id="UserMenuContainer">
  <ul *ngIf="newIsOnline == 'true'" class="login-menu">
    <li *ngIf="newIsOnline == 'true'" class="m-t-10 user-assets focusableFour "
      title="{{'common.myProfileTitle'|translate }}" tabindex="0" [routerLink]="['/profile']"><span
        class="icon-user1 icon-mainMenu "></span>{{"common.myProfile"|translate}}</li>
    <div class="dropdown user-assets focusableFour">
      <li class=" " title="{{'common.languageTitle'|translate }}" (mouseover)="showLangFlyout()" tabindex="0">
        <span class="icon-language icon-mainMenu "></span>{{"common.language"| translate}}
      </li>
      <div class="dropdown-content">
        <div>
          <li *ngFor="let language of LanguageChoices;">
            <span class="dropdown-item" (click)="setLanguageClick(language.value)">{{language.text}}</span>
          </li>
        </div>
      </div>
    </div>
    <!-- <li *ngIf="newIsOnline == 'true'" class="user-assets focusableFour "  title="View/Process Recalls" tabindex="0" [routerLink]="['/recalls']"><span  class="icon-recalls icon-mainMenu "></span>Recalls</li> -->
    <li *ngIf="permissionService.isSystemSettingsPageHasViewPermission" class="user-assets focusableFour "
      title="{{'common.systemConfigurationTitle'|translate}}" tabindex="0" [routerLink]="['/systemsettings']"><span
        class="icon-settings icon-mainMenu "></span>{{"common.systemConfiguration"|translate}}</li>
    <li *ngIf="permissionService.isAuditPageHasViewPermission" class="user-assets focusableFour" title="{{'common.auditsTitle'|translate}}"
      tabindex="0" [routerLink]="['/audit']"><span class="icon-audit icon-mainMenu "></span>{{"common.audits"|translate}}</li>
      <li *ngIf="permissionService.isAuditPageHasViewPermission" class="user-assets focusableFour" title="{{'common.dataTitle'|translate}}"
      tabindex="0" [routerLink]="['/data']"><span class="icon-data icon-mainMenu "></span>{{"common.data"|translate}}</li>
    <li *ngIf="permissionService.isLocksPageHasViewPermission && newIsOnline == 'true'">
      <!-- title="View and edit case locks" class="user-assets icon-mainMenu" [routerLink]="['/locks']"> -->
      <a class="user-assets focusableFour " [routerLink]="['/locks']" title='{{"common.locksTitle"| translate}}' tabindex="0"
        target="_blank"><span class="icon-locks icon-mainMenu "></span>{{"common.locks"|translate}}</a>
    </li>
    <li *ngIf="newIsOnline == 'true'" class="user-assets focusableFour" title="{{'common.logoutTitle'|translate}}" tabindex="0"
      (click)="Logout()"><span class="icon-leave icon-mainMenu "></span>{{"common.logout"|translate}}</li>
  </ul>
</div>

<ejs-dialog #locksOutDialog [buttons]="locksOutDlgButtons" [visible]=false 
    [isModal]=true [width]="locksOutWidth"
    [allowDragging]=true
    [showCloseIcon]=false [enableResize]=true>
    <ng-template #header>
      <div class="e-dlg-header">{{"common.signOutLocksExist" | translate }}</div>
    </ng-template>
    
    <ng-template #content>
      <h4>{{ "common.locksYouHold" | translate }}</h4>
      <div>
      <ejs-grid [dataSource]='locksOutstanding' #locksOutstandingGrid id="locksOutstandingGrid" height='Auto'
        [allowPaging]=false gridLines='Both' enableAltRow=true>
        <e-columns>
          <e-column headerText="{{ 'common.caseNumber' | translate }}" width='150' textAlign='Center'>
            <ng-template #template let-data>
              <div>{{data.centreInitial}}{{data.exposureId}}</div>                
            </ng-template>
            <ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.caseNumber" | translate }}
              </span>
            </ng-template>
        </e-column>
          <e-column field="lockType" headerText="{{ 'common.lockType' | translate }}">
            <ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.lockType" | translate }}
              </span>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </div>
    </ng-template>
</ejs-dialog>
