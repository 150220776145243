import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
// import { AppInsights } from "applicationinsights-js";
import {
  ApplicationInsights,
  IExceptionTelemetry} from '@microsoft/applicationinsights-web';

// originally sourced from: https://www.c-sharpcorner.com/article/how-to-integrate-azure-application-insights-service-to-an-angular-application/

import {
  Router,
  NavigationEnd
} from '@angular/router';
import {
  filter
} from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;
  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.AppInsights.instrumentationKey
      }
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }
  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }
  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({
      name,
      uri
    });
  }
  logEvent(name: string, properties?: { [key: string]: string }) {
    this.appInsights.trackEvent({ name }, properties);
  }
  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error
    };
    this.appInsights.trackException(exception);
  }
  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties["ApplicationPlatform"] = "Web";
      item.properties["ApplicationName"] = environment.AppInsights.applicationName;
    });
  }
  private createRouterSubscription() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(null, event.urlAfterRedirects);
    });
  }
}
