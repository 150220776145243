import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from '../app.constants';


export class FullThemeSet {
  SetDarkLightColors(isDarkMode: boolean) {
    // App Page Colors
    this.whiteColor = isDarkMode ? '#4E4E4E' : '#FFF';
    this.btnActiveBg = isDarkMode ? '#4E4E4E' : '#FFF';
    this.btnHoverBg = isDarkMode ? '#4E4E4E' : '#FFF';
    this.blackColor = isDarkMode ? '#FFF' : '#232729';
    this.appPageBgColor = isDarkMode ? '#212121' : '#FFF';
    this.exposurePageBgColor = isDarkMode ? '#212121' : '#eeeeee';
    this.headerBgColor = isDarkMode ? '#000' : '#FFF';
    this.highcontrastBg = isDarkMode ? '#212121' : '#FFF';
    // Report Control Colors
    this.reportToolbarBg = isDarkMode ? '#212121' : '#fafafa';
    this.reportToolbarBorder = isDarkMode ? '#FFF' : '#e0e0e0';
    this.reportViewerBg = isDarkMode ? '#212121' : '#eee';
    this.reportForwardColor = isDarkMode ? '#212121' : '#f5f5f5';
    this.rteToolbarBg = isDarkMode ? '#303030' : '#fafafa';

    this.itemHoverColor = isDarkMode ? '#303030' : '#e0e0e0';
    this.itemActiveColor = isDarkMode ? '#4E4E4E' : '#FFF';
    this.itemHeaderBgColor = isDarkMode ? '#6f6f73' : '#eee';
    // drop down control
    this.textboxBorderColor = isDarkMode ? 'a2a2a2' : '#0000006b';
    this.definedDropDownPopupColor = isDarkMode ? '#424242' : '#FFF';
    this.hoverBgColor = isDarkMode ? '#616161' : '#eee';
    this.dropDownBgColor = isDarkMode ? '' : '';
    // grid control
    this.gridBorderColor = isDarkMode ? 'a2a2a2' : '#e0e0e0';
    this.columnChooserBg = isDarkMode ? '#303030' : '#f4f4f4';
    this.gridHeaderBg = isDarkMode ? '#424242' : '#fafbfc';
    // multiselect control
    this.msChipBgColor = isDarkMode ? '#424242' : '#eee';

    const isRBGBA = !this.primaryColor.includes('#');
    const gridRowColor: string = '';
    const hex = isRBGBA ? this.RGBAToHexA(this.primaryColor) : this.primaryColor;

    this.gridRowColor = isDarkMode ? this.DarkColorLuminance(hex, -0.5) : this.ColorLuminance(hex, 0.1);
    }

  constructor(
    public name: string = '',
    //  - Defaults from original code

    // App Properties
    public whiteColor: string = '#FFF',
    public btnActiveBg: string = '#FFF',
    public btnHoverBg: string = '#FFF',
    public blackColor: string = '#232729',
    public appPageBgColor: string = '#FFF',
    public exposurePageBgColor: string = '#eeeeee',
    public headerBgColor: string = '#FFF',
    public footerBgColor: string = '#f5f5f5',
    public primaryColor: string = '#b5b38a',
    public primaryFontColor: string = '#232729',
    public secondaryColor: string = '#f0fee9',
    public secondaryFontColor: string = '#266150',
    public highcontrastBg: string = '#FFF',

    // report control properties update
    public reportToolbarBg: string = '#fafafa',
    public reportToolbarBorder: string = '#e0e0e0',
    public reportViewerBg: string = '#eee',
    public reportForwardColor: string = '#f5f5f5',
    public rteToolbarBg: string = '#fafafa',

    public itemHoverColor: string = '#e0e0e0',
    public itemActiveColor: string = '#eee',
    public itemHeaderBgColor: string = '#F0F0F0',

    // drop down control properties update
    public textboxBorderColor: string = '#0000006b',
    public definedDropDownPopupColor: string = '#FFF',
    public hoverBgColor: string = '#eee',
    public dropDownBgColor: string = '',

    // grid control properties update
    public gridBorderColor: string = '#e0e0e0',
    public columnChooserBg: string = '#f4f4f4',
    public gridHeaderBg: string = '#fafbfc',
    public gridRowColor: string = '#f0ffff',

    // multiselect control properties update
    public msChipBgColor: string = '#eee'
  ) { }
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ('ff' + c).substr(c.length);
    }

    return rgb;
  }

  DarkColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
    }

    return rgb;
  }

  RGBAToHexA(rgba) {
    const sep = rgba.indexOf(',') > -1 ? ',' : ' ';
    rgba = rgba.substr(5).split(')')[0].split(sep);

    // Strip the slash if using space-separated syntax
    if (rgba.indexOf('/') > -1) {
      rgba.splice(3, 1);
    }

    for (const R in rgba) {
      const r = rgba[R];
      if (r.indexOf('%') > -1) {
        const p = r.substr(0, r.length - 1) / 100;

        if (parseInt(R) < 3) {
          rgba[R] = Math.round(p * 255);
        } else {
          rgba[R] = p;
        }
      }
    }

    // converting RGBA to Hex
    let r = (+rgba[0]).toString(16),
      g = (+rgba[1]).toString(16),
      b = (+rgba[2]).toString(16),
      a = Math.round(+rgba[3] * 255).toString(16);

    if (r.length == 1) {
      r = '0' + r;
    }
    if (g.length == 1) {
      g = '0' + g;
    }
    if (b.length == 1) {
      b = '0' + b;
    }
    if (a.length == 1) {
      a = '0' + a;
    }

    return '#' + r + g + b + a;
  }
}

// ToxAware Theme
export class ColorTheme {
  constructor(
    public themeId: number = 0,
    public themeName: string = 'Default',
    public themeModeDark = false,
    public primaryColor: string = '#DDAF94',
    public secondaryColor: string = '#E8CEBF',
    public primaryFontColor: string = '#4F4846',
    public secondaryFontColor: string = '#266150'
  ) { }
}


@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  constructor(
    private _cookieService: CookieService,
  ) {

  }



  ApplyTheme(themeWrapper, theme: ColorTheme, saveToCookie: boolean) {
    if (theme == null) {
      this.applyThemeDefault(themeWrapper);
    } else {
      const colorTheme: ColorTheme
        = new ColorTheme(theme.themeId, theme.themeName, theme.themeModeDark
          , theme.primaryColor, theme.secondaryColor, theme.primaryFontColor, theme.secondaryFontColor,);
      const fullThemeSet = this.FullThemeFromColorTheme(colorTheme);
      this.setThemeStyleVariables(themeWrapper, fullThemeSet);
      if (saveToCookie) {
        this._cookieService.set(AppConstants.CookieName.ColorTheme, JSON.stringify(colorTheme), 1000, '/',null,true,"Strict");
      }
    }
  }

  ApplyThemeFromCookie(themeWrapper, cookie: any) {
    if (cookie != null && cookie !== '') {
      const colorTheme: ColorTheme = JSON.parse(cookie);
      this.ApplyTheme(themeWrapper, colorTheme, false); // We just got from the cookie, no need to save again.
    } else { this.applyThemeDefault(themeWrapper);
    }
  }
  applyThemeDefault(themeWrapper) {
    this.setThemeStyleVariables(themeWrapper, new FullThemeSet());
  }

  setThemeStyleVariables(themeWrapper, fullThemeSet: FullThemeSet) {
    // app page properties update
    themeWrapper.style.setProperty('--whiteColor', fullThemeSet.whiteColor);
    themeWrapper.style.setProperty('--btnActiveBg', fullThemeSet.btnActiveBg);
    themeWrapper.style.setProperty('--btnHoverBg', fullThemeSet.btnHoverBg);
    themeWrapper.style.setProperty('--blackColor', fullThemeSet.blackColor);
    themeWrapper.style.setProperty('--primaryColor', fullThemeSet.appPageBgColor);
    themeWrapper.style.setProperty('--exposurePageBgColor', fullThemeSet.exposurePageBgColor);
    themeWrapper.style.setProperty('--headerBgColor', fullThemeSet.headerBgColor);
    themeWrapper.style.setProperty('--footerBgColor', fullThemeSet.footerBgColor);
    themeWrapper.style.setProperty('--primaryColor', fullThemeSet.primaryColor);
    themeWrapper.style.setProperty('--primaryFontColor', fullThemeSet.primaryFontColor);
    themeWrapper.style.setProperty('--secondaryColor', fullThemeSet.secondaryColor);
    themeWrapper.style.setProperty('--highcontrastBg', fullThemeSet.highcontrastBg);
    themeWrapper.style.setProperty('--secondaryFontColor', fullThemeSet.secondaryFontColor);


    // report control properties update
    themeWrapper.style.setProperty('--reportToolbarBg', fullThemeSet.reportToolbarBg);
    themeWrapper.style.setProperty('--reportToolbarBorder', fullThemeSet.reportToolbarBorder);
    themeWrapper.style.setProperty('--reportViewerBg', fullThemeSet.reportViewerBg);
    themeWrapper.style.setProperty('--reportForwardColor', fullThemeSet.reportForwardColor);
    themeWrapper.style.setProperty('--rteToolbarBg', fullThemeSet.rteToolbarBg);

    themeWrapper.style.setProperty('--itemHoverColor', fullThemeSet.itemHoverColor);
    themeWrapper.style.setProperty('--itemActiveColor', fullThemeSet.itemActiveColor);
    themeWrapper.style.setProperty('--itemHeaderBgColor', fullThemeSet.itemHeaderBgColor);

    // drop down control properties update
    themeWrapper.style.setProperty('--textboxBorderColor', fullThemeSet.textboxBorderColor);
    themeWrapper.style.setProperty('--definedDropDownPopupColor', fullThemeSet.definedDropDownPopupColor);
    themeWrapper.style.setProperty('--hoverBgColor', fullThemeSet.hoverBgColor);
    themeWrapper.style.setProperty('--dropDownBgColor', fullThemeSet.dropDownBgColor);

    // grid control properties update
    themeWrapper.style.setProperty('--gridBorderColor', fullThemeSet.gridBorderColor);
    themeWrapper.style.setProperty('--columnChooserBg', fullThemeSet.columnChooserBg);
    themeWrapper.style.setProperty('--gridHeaderBg', fullThemeSet.gridHeaderBg);

    // multiselect control properties update
    themeWrapper.style.setProperty('--msChipBgColor', fullThemeSet.msChipBgColor);
    themeWrapper.style.setProperty('--gridRowColor', fullThemeSet.gridRowColor);
  }

  FullThemeFromColorTheme(colorTheme: ColorTheme) {
    const fullThemeSet = new FullThemeSet();
    fullThemeSet.name = colorTheme.themeName.trim();
    fullThemeSet.primaryColor = colorTheme.primaryColor.trim();
    fullThemeSet.secondaryColor = colorTheme.secondaryColor.trim();
    fullThemeSet.primaryFontColor = colorTheme.primaryFontColor.trim();
    fullThemeSet.secondaryFontColor = colorTheme.secondaryFontColor.trim();
    fullThemeSet.SetDarkLightColors (colorTheme.themeModeDark);
    return fullThemeSet;
  }

}
