import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnlineService } from "../Providers/OnlineService";

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  constructor(private http: HttpClient,public onlineService:OnlineService) { }

  getNotices(): Observable<any> {
    const apiUrl = this.onlineService.apiUrl;
    const getNoticesUrl = apiUrl + 'api/common/GetNotices';
    return this.http.get<any>(getNoticesUrl);
  }

  getNoticesList() {
    return new Promise((resolve) => {
      this.http
        .get(this.onlineService.url.getNoticesUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => { resolve(data); },
          (error) => { resolve(error); }
        );
    });
  }
}
