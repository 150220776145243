import { DatePipe, NgPlural } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Query } from '@syncfusion/ej2-data/src/query';
import { CheckBox } from '@syncfusion/ej2-angular-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CommandModel, EditService, EditSettingsModel, Grid, GridComponent, IEditCell, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-inputs';
import { EventHandler } from '@syncfusion/ej2-base';
import { Tooltip, TooltipEventArgs } from '@syncfusion/ej2-angular-popups';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';

import { Observable, Subscription, fromEvent } from 'rxjs';

import { AppConstants } from '../../../app.constants';
import { ComponentCanDeactivate } from '../../../auth/Pending-Changes-Guard';
import { OnlineService } from './../../../Providers/OnlineService';
import { ToasterService } from './../../../Providers/ToasterService';
import { PingApiService } from "./../../../Services/pingApi.service";
import { SubstanceSearchService } from './../../../Services/substance-search.service';
import { MySubstance} from './../../../models/mySubstanceModel';

import { TranslateService } from '@ngx-translate/core';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';


enum SystemSettingType {
  "number" = 1,
  "dropdown" = 2,
  "boolean" = 3,
  "string" = 4,
  "longString" = 5
}

enum SystemSettingTab {
  "systemSettings" = 1,
  "mySubstances" = 5,
  "notices" = 4,
  "validations" = 3,
  "codeValues" = 2
}



@Component({
  selector: 'app-systemsettings',
  templateUrl: './systemsettings.component.html',
  styleUrls: ['./systemsettings.component.scss'],
  providers: [EditService]
  
})
export class SystemsettingsComponent implements ComponentCanDeactivate, OnInit, OnDestroy, AfterViewInit {

  public AppConstants = AppConstants;
  public centres = []; // list of centres
  public centresForDropdown = []; // list of centres for dropdown

  public validationEditSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };
  public validationsGridToolbarSettings = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
  public validationsGridTypeEditParms = { params: { popupHeight: '300px' } };
  public validationTypeEditParams = { params: { popupHeight: '300px' } };
  public validationIdRules = { required: true, number: true };
  public validationTypeData = [{}, {}, {}];

  public isValidationBusy: boolean = false;
  public BusyMessage: string = this.translate.instant('common.loading');
  public CodeValueWaterMark: string = this.translate.instant('common.codeType'); 
  public autoSaveTime: number = 0;
  public setOnlinePeriod: number = 60;

  @ViewChild('codeValueDropdown', { static: false })
  public codeValueDropdownObj: DropDownListComponent;
  public pageSettings: PageSettingsModel = { pageSize: 15 };

  public MySubstancesEditSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' ,
    allowEditOnDblClick: true
  };
  public mySubsCommands: CommandModel[] = [
    {
      type: 'Edit',
      buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' }
    },
    {
      type: 'Delete',
      buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' }
    },
    {
      type: 'Save',
      buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' }
    },
    {
      type: 'Cancel',
      buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' }
    }
  ];
  public mySubsGroupSettings = { showGroupedColumn: true, columns: ['mysuB_STATUS'] };
  public mySubsIncludeNew: boolean=true;
  public mySubsIncludeAccepted: boolean=true;
  public mySubsIncludeReplaced: boolean=true;
  public mySubsIncludeDeleted: boolean=false;
  
    @ViewChild('mySubstancesGrid', { static: false })
  public mySubstancesGridObj: GridComponent;
  public mySubstancesData = []; // All my substances
  public mySubstancesGridData = []; // my substances shown in grid

  public mySubsStatuses: Object[] = [{ field: 1, value: 'New' }, { field: -1, value: 'Deleted'}, { field: -2, value: 'Error'}, {field: 0, value: 'OK'}];

  public mySubsDescriptionRTEToolBar: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',        
        'Formats', 'Alignments', '|', 'FormatPainter', 'ClearFormat',
        'EmojiPicker','|', 'Undo', 'Redo']
};
//  " {enable: true,enableFloating: true,type: ToolbarType.Expand,items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'OrderedList','UnorderedList', '|', 'CreateLink', '|',  'Undo', 'Redo'],itemConfigs: {}}"
  
  // System Settings Grid
  @ViewChild('systemSettingsGrid') systemSettingsGridObj: GridComponent;
  public systemSettingsGridData: any = [];
  public systemSettingsEditSettings: EditSettingsModel = {allowAdding:false,allowEditing:true,allowDeleting:false,allowEditOnDblClick:false};

  @ViewChild('validationsGrid', { static: false }) public validationsGridObj: GridComponent;
  public validationsGridData: any = [];
  public validationRuleTypeData: object[] = [
    { description: 'All Case Types', id: -1, tooltip: 'Validate All Case types' },
    { description: 'Exposures Only', id: 0, tooltip: 'Validate Exposure Only' },
    { description: 'Queries Only', id: -2, tooltip: 'Validate Queries Only' }
  ];
  private validationRowSelectedData: any; // Selected row - set on select row for dropdown and checkboxes to have access.

  public AuthenticationWaterMark: string = 'Two Factor Authentication Duration';
  public Authenticationfields: Object = { text: 'durationName', value: 'durationId' };
  @ViewChild('AuthenticationDropdown', { static: false })
  public authenticationDropdownObj: DropDownListComponent;
  public cookiePersistenceTime: number = 1;
  public rememberMe: number = 365;
  public systemSettingSaveButtonDisabled: boolean = true;
  public cookiePersistenceTimeValidation: string = '';
  public cookiePersistenceError: boolean = false;
  public isSystemSettingFormDirty: boolean = false;
  public onlinePeriodError: boolean = false;
  public onlineTimePeriodValidation: string = '';

  /* CodeValue Dialog variables  */
  @ViewChild('codeValueForm', { static: true }) codeValueFormObj: any;
  @ViewChild('codeValueGrid', { static: false })
  public codeValueGridObj: GridComponent;
  public codeValueGridData = [];

  public codeTypeTooltip: Tooltip;
  public isCodeValueFormDirty: boolean = false;

  public CodeGroupfields: Object = { text: 'Value', value: 'id', tooltip: 'id' };
  public CodeTypeFields: Object = { text: 'description', value: 'value', tooltip: 'id' };
  public ValidationTypefields: Object = { text: 'description', value: 'id', tooltip: 'tooltip' };

  public codeValueModalDataUnSavedSkip: boolean = true;
  public showCodeValueDataUnsavedDailog: boolean = false;

  public showCodeValueModal: boolean = false;
  public IsEditCodeValue: boolean = false;
  public CodeGroupWaterMark: string = this.translate.instant('Code Group');
  public CodeValueTitle: string = '';
  @ViewChild('codeGroupDropdown', { static: false })
  public codeGroupDropdownObj: DropDownListComponent;
  public tempCodeId: number = 0;
  public codeId: string = null;
  public codeValue: string = '';
  public codeGroup: string = '';
  public codeDescription: string = '';
  public code: string = '';
  public codeStartDateTime: Date = null;
  public codeEndDateTime: Date = null;
  public DateFormat: string = 'dd/MM/yyyy';
  public codeValueViewIsDefault: string = 'No';
  public codeValueIsDefault: boolean = false;
  public showIsDefaultWarningModal: boolean = false;
  public codeType: string = null;
  public IsActiveCodeValue: boolean = true;
  @ViewChild('EndDate', { static: false })
  public EndDateObj: DatePickerComponent;
  public showEndDateWarningDialog: boolean = false;
  public savedactiveEndDate: Date = null;
  public isValidStartDate: boolean = true;
  public isValidEndDate: boolean = true;
  public isGreaterEndDate: boolean = false;
  public isLessStartDate: boolean = false;

  public isRemembered: string = '';
  public cookieExpirationMaximumInDays: number = 365;
  public cookieExpirationMinimumInDays: number = 1;

  public isSystemSettingsPageHasViewPermission: boolean = false;
  public isSystemSettingsPageHasAddPermission: boolean = false;
  public isSystemSettingsPageHasEditPermission: boolean = false;
  public isSystemSettingsPageHasDeletePermission: boolean = false;
  public UserId = this._cookieService.get(AppConstants.CookieName.UserId);

  public showPermissionDenied: boolean = false;
  public permisionTitle: string = '';
  public permisionAlertContent: string = '';

  public tooltip: Tooltip;
  // @ViewChild('systemSettingForm', { static: false }) systemSettingFormObj: any;
  public isSystemSettingUnSaved: boolean = true;
  public unSaveChangeDetectionInGrid: boolean = false;
  public selectedTab: number = 1;

  public editSettings: Object = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Normal',
    allowEditOnDblClick: false
  };
  // Add a new property to track if the data is dirty (has unsaved changes)
  public isSystemSettingsDataDirty: boolean = false;

  constructor(
    public onlineService: OnlineService,
    private spinner: NgxSpinnerService,
    public toastService: ToasterService,
    private _cookieService: CookieService,
    private titleService: Title,
    private router: Router,
    private pingApiService: PingApiService,
    private substanceSearchService: SubstanceSearchService,
    public translate: TranslateService,
  ) {
    this.navigationServiceInstance = this.onlineService.connectionDetectionStatus.subscribe((onlineStatus) => {
      this.newIsOnline = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
      if (this.newIsOnline == 'true' && onlineStatus == 'true') {
        this.showOnlineInfoDialog = false;
        this.onlineService.setConnectionStatus('');
      } else if (this.newIsOnline == 'false' && onlineStatus == 'false') {
        this.showOnlineInfoDialog = true;
        this.onlineService.setConnectionStatus('');
      } else {
      }
    });

    this.subscription = fromEvent(window, 'storage').subscribe((e) => {
      this.newIsOnline = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
      if (this.newIsOnline == 'true' && e['key'] == AppConstants.LocalStorage.OnLineStatus && e['newValue'] == 'true') {
        this.showOnlineInfoDialog = false;
      } else if (this.newIsOnline == 'false' && e['key'] == AppConstants.LocalStorage.OnLineStatus && e['newValue'] == 'false') {
        this.showOnlineInfoDialog = true;
      } else {
      }
    });

    // check for dirty data on exit
    window.onbeforeunload = (event) => {
      if (this.isSystemSettingsDataDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
  }

  // Navigation changes
  public subscription: Subscription;
  public navigationServiceInstance: any = '';
  public newIsOnline: string = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
  public offlinePageName: string = window.location.pathname.split('/')[1].toLocaleLowerCase() == 'systemsettings' ? 'System Settings' : '';

  public showOnlineInfoDialog: boolean = false;
  
  systemSettingActionComplete(args: any) {
    if (args.action === 'edit' && args.name === 'actionComplete') {
      if (args.data.value != args.previousData.value) {
        this.systemSettingSaveButtonDisabled=false; // data has changed, enable the save button!
        this.isSystemSettingsDataDirty = true; // mark data as dirty

      }
    }    
  }

// My Substances
public mySubstanceUpdateFilter(): void {
  //this.mySubstancesGridData = this.mySubstancesData.filter(this.isMySubstanceSelected, this);
  // set this.mySubstancesGridData to the filtered data using the filter function

  this.mySubstancesGridData = this.mySubstancesData.filter((substance)=>(substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.New && this.mySubsIncludeNew) ||
  (substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.Deleted && this.mySubsIncludeDeleted) ||
  (substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.Error && this.mySubsIncludeAccepted) ||
  (substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.Duplicate && this.mySubsIncludeReplaced));

}
  mySubsGridActionBegin(args: any) {
    // do nothing
  }

  mySubsFilterChanged(args: any) {
    // do nothing
  }

  mySubsResetFilters() {
    this.mySubsIncludeAccepted = true;
    this.mySubsIncludeNew = true;
    this.mySubsIncludeReplaced = true;
    this.mySubsIncludeDeleted = false;
  }
  // My Substances Grid Handler for add,edit,save
  mySubsGridActionComplete(args: any) {
    let editedMySub: MySubstance = null;
    if (args && args.data && args.data.length>0) {
      editedMySub = <MySubstance>{
        MYSUB_CREATED: args.data[0]['mysuB_CREATED'],
        MYSUB_CREATOR: args.data[0]['mysuB_CREATOR'],
        MYSUB_DESC: args.data[0]['mysuB_DESC'],
        MYSUB_GENERIC: args.data[0]['mysuB_GENERIC'],
        MYSUB_NAME: args.data[0]['mysuB_NAME'],
        MYSUB_PROD: args.data[0]['mysuB_PROD'],
        MYSUB_STATUS: args.data[0]['mysuB_STATUS'],
        MYSUB_AMT_EACH: args.data[0]['mysuB_AMT_EACH'],
        mysuB_ID: args.data[0]['mysuB_ID']
      };
    }

    switch (args.requestType) {
      case 'beginEdit':
      case 'add':
        // Set the Dialog options
        if (args.dialog) {
          args.dialog.header = 'My Substance:' + args.rowData['mysuB_NAME'].toString();
        }
        break;
      case 'save':
        if (args.requestType === 'save') {
          const editedMySub: MySubstance = <MySubstance>{
            MYSUB_CREATED: args.data['mysuB_CREATED'],
            MYSUB_CREATOR: args.data['mysuB_CREATOR'],
            MYSUB_DESC: args.data['mysuB_DESC'],
            MYSUB_AMT_EACH: args.data['mysuB_AMT_EACH'],
            MYSUB_GENERIC: args.data['mysuB_GENERIC'],
            MYSUB_NAME: args.data['mysuB_NAME'],
            MYSUB_PROD: args.data['mysuB_PROD'],
            MYSUB_STATUS: args.data['mysuB_STATUS'],
            mysuB_ID: args.data['mysuB_ID']
          };
          this.substanceSearchService.EditMySubstance(editedMySub).subscribe((result: any) => {
            if (result.mysuB_STATUS !== AppConstants.MySubstance.Status.Error) {
              this.toastService.showSpinner({ type: 'success', message: 'Substance ' + result.mysuB_NAME + ' updated.' });
              // update the grid data
              this.BusyMessage='Updating My Substances...';  
              this.spinner.show();
              this.substanceSearchService.GetMySubstances().then((mySubstances: any) => {
                this.mySubstancesData = mySubstances;
                this.mySubstancesGridData = this.mySubstancesData.filter(this.isMySubstanceSelected, this);
                this.spinner.hide();
              });             
            }
          },
            (err: HttpErrorResponse) => {
              this.toastService.showSpinner({ type: 'error', message: 'Substance ' + editedMySub.MYSUB_NAME + ' was not updated.' });
              alert(err);
            });
        }
        break;
      case 'delete':
        this.substanceSearchService.DeleteMySubstance(editedMySub).subscribe((result: any) => {
          if (result.mysuB_STATUS !== AppConstants.MySubstance.Status.Error) {
            this.toastService.showSpinner({ type: 'success', message: 'Substance ' + result.mysuB_NAME + ' deleted.' });
          } else {
            this.toastService.showSpinner({ type: 'error', message: 'Substance ' + editedMySub.MYSUB_NAME + ' was not deleted.' });
            this.mySubstancesGridObj.refresh();
          }
        },
          (err: HttpErrorResponse) => {
            this.toastService.showSpinner({ type: 'error', message: 'Substance ' + editedMySub.MYSUB_NAME + ' was not deleted.' });
            alert(err);
            this.mySubstancesGridObj.refresh();
          });
        break;
      default:
        break;
    }
  }

  // Search Source Updates
  updateSearchSourceNPR() {
    this.onlineService.updateSearchSource(parseInt(AppConstants.SubstanceSearch.Type.NPR, 10)).subscribe(function (result) {
    },
      (err: HttpErrorResponse) => {
        alert(err);
      }
    );
  }
  updateSearchSourceAMT() {

  }
  updateSearchSourceMySub() {
    this.onlineService.updateSearchSource(parseInt(AppConstants.SubstanceSearch.Type.MySubstances, 10)).subscribe(function (result) {
    },
      (err: HttpErrorResponse) => {
        alert(err);
      }
    );
  }
  updateSearchSourcePDX() {

  }

  
  
  // Validations
  validationsRowSelected($event) {
    // This is important, as it selects the row data for the checkboxes.
    this.validationRowSelectedData = $event.data;
  }

  validationFatalChanged($event, selectedId) {
    const $this = this;
    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'false') {
      $this.onlineService.doesConnectionExist();
    }
    const validationFatalValue = $event.checked;
    this.BusyMessage = 'Validation Required Status changed...';
    this.spinner.show();
    this.isValidationBusy = true;
    $this.onlineService.editValidationRuleFatal(validationFatalValue, selectedId).then(
      function (status: any) {
        if (status != null && status.isSuccess) {
          $this.toastService.showSpinner({ type: 'success', message: 'Validation Required Updated' });
          $this.spinner.hide();
        } else {
          $this.toastService.showSpinner({ type: 'error', message: 'Unable to Update Validation Required due to missing values. Refresh the page and try again.' });
          $this.spinner.hide();
        }
        $this.isValidationBusy = false;
        $this.spinner.hide();
      },
      (err: HttpErrorResponse) => {
        if (this.onlineService.CheckErrorStatus(err)) {
          this.spinner.hide();
          this.showOnlineInfoDialog = true;
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description,
          };

          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            const toasterValue = {
              type: 'error',
              message: 'Something went wrong changing validation Active Status. Please try again',
            };
            $this.toastService.showSpinner(toasterValue);
            $this.spinner.hide();
          });
        }
        $this.isValidationBusy = false;
        $this.spinner.hide();
      }
    );

    return true;
  }
  validationActiveChanged($event, selectedId) {
    const $this = this;
    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'false') {
      $this.onlineService.doesConnectionExist();
    }
    const validationActiveValue = $event.checked;
    $this.BusyMessage = 'Validation Active Status changed...';
    this.spinner.show();
    $this.isValidationBusy = true;
    // $this.onlineService.editValidationRuleActive(validationActiveValue, $this.validationRowSelectedData.id).then(
    $this.onlineService.editValidationRuleActive(validationActiveValue, selectedId).then(
      function (status: any) {
        if (status != null && status.isSuccess) {
          $this.toastService.showSpinner({ type: 'success', message: 'Validation Active Updated' });
          $this.spinner.hide();
        } else {
          $this.toastService.showSpinner({ type: 'error', message: 'Unable to Update Active Status due to missing values. Refresh the page and try again.' });
          $this.spinner.hide();
        }
        $this.isValidationBusy = false;
      },
      (err: HttpErrorResponse) => {
        if (this.onlineService.CheckErrorStatus(err)) {
          this.spinner.hide();
          this.showOnlineInfoDialog = true;
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description,
          };

          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            const toasterValue = {
              type: 'error',
              message: 'Something went wrong changing validation Active Status. Please try again',
            };
            $this.toastService.showSpinner(toasterValue);
            $this.spinner.hide();
          });
        }
        $this.isValidationBusy = false;
        $this.spinner.hide();
      }
    );
    return true;
  }

  ValidationTypeValueChangeEvent(arg, selectedId) {
    const $this = this;
    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'false') {
      $this.onlineService.doesConnectionExist();
    }
    const validationTypeValue = arg.itemData === undefined ? arg : arg.itemData.id;
    $this.isValidationBusy = true;
    $this.BusyMessage = 'Validation Type Values changed...';
    this.spinner.show();
    $this.onlineService.editValidationRuleType(validationTypeValue, selectedId).subscribe(
      function (validationRules) {
        if (validationRules != null && validationRules.isSuccess) {
          $this.toastService.showSpinner({ type: 'success', message: 'Validation Type Updated' });
          $this.spinner.hide();
        } else {
          $this.toastService.showSpinner({ type: 'error', message: 'Unable to Update Validation Type due to missing values. Refresh the page and try again.' });
          $this.spinner.hide();
        }
        $this.isValidationBusy = false;
      },
      (err: HttpErrorResponse) => {
        if (this.onlineService.CheckErrorStatus(err)) {
          this.spinner.hide();
          this.showOnlineInfoDialog = true;
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description,
          };

          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            const toasterValue = {
              type: 'error',
              message: 'Something went wrong changing validation rule type. Please try again',
            };
            $this.toastService.showSpinner(toasterValue);
            $this.spinner.hide();
          });
        }
        $this.isValidationBusy = false;
        $this.spinner.hide();
      }
    );
  }

  tabClicked(tab) {
    this.selectedTab = tab;
    switch (tab) {
      case SystemSettingTab.validations: // validations    
      this.validationsGridObj.refresh(); // Moved from an earlier time due to AOT causing a error.
      break;
      case SystemSettingTab.notices: // notices
      // this.populateNotices();
      // this.noticesGridObj.refresh(); // load some data!
      break;
    }
  }

  onlineInfoCancelClick() {
    this.showOnlineInfoDialog = false;
  }

  onlineInfoOkClick() {
    this.showOnlineInfoDialog = false;
    this.router.navigate(['/home']);
  }

  // Navigation changes
  // Angular Event Handlers
  ngOnInit() {
    this.BusyMessage = 'Getting Page Permissions...';
    this.spinner.show();
    this.titleService.setTitle('System Settings - ToxAware');
    this.isRemembered = this._cookieService.get(AppConstants.CookieName.RememberMe);
  }

  ngOnDestroy() {
    this.navigationServiceInstance.unsubscribe();
    this.subscription.unsubscribe();
    if (this.pingApiService.pingAPIIntervalId) {
      clearInterval(this.pingApiService.pingAPIIntervalId);
    }
    this.tooltip.destroy();
    this.codeTypeTooltip.destroy();
  }


  ngAfterViewInit() {
    this.pingApiService.pingAPI();
    this.spinner.show(); 
    
    this.mySubsResetFilters();

    const $this = this; // needed for isMySubstanceSelected

    const urlParams = new URLSearchParams(window.location.search);
    const tab = parseInt(urlParams.get('t'), 10);
    if (tab != undefined && tab > 0 && tab < 5) {
      this.selectedTab = tab;
    }

    this.onlineService.getPagePermission(parseInt(this.UserId, 10)).subscribe((pagePermissionList: any) => {
      if (pagePermissionList !== undefined && pagePermissionList != null && pagePermissionList.length > 0) {
        this.onlineService.pagePermissionUpdate(pagePermissionList);
      }
      pagePermissionList.forEach((specificPagePermission) => {
        if (specificPagePermission.moduleName === 'Code Admin') {
          if (specificPagePermission['isVisible']) {
            this.isSystemSettingsPageHasViewPermission = true;
          } else {
            this.isSystemSettingsPageHasViewPermission = false;
          }
          this.isSystemSettingsPageHasAddPermission = specificPagePermission['canAdd'] != null ? specificPagePermission['canAdd'] : false;
          this.isSystemSettingsPageHasEditPermission = specificPagePermission['canEdit'] != null ? specificPagePermission['canEdit'] : false;
          this.isSystemSettingsPageHasDeletePermission = specificPagePermission['canDelete'] != null ? specificPagePermission['canDelete'] : false;
        }
      });

      if (this.isSystemSettingsPageHasViewPermission) {
        this.onlineService.removeFocussedUrl();
        this.BusyMessage = 'Getting System Settings...';
        Promise.all([this.onlineService.GetSystemSettings()
          , this.onlineService.GetCodeTypes()
          , this.onlineService.GetAuthenticationDuration()
          , this.onlineService.GetValidations()
          , this.substanceSearchService.GetMySubstances()
          , this.onlineService.GetNotices()
          , this.onlineService.getCenters().subscribe((centres: any) => {
            const commonData = { centreId: 0, centreName: 'All', };
            centres.unshift(commonData);
            this.centres = centres; 
            this.centresForDropdown = centres.map((centre: any) => {
              return {
                centreId: centre.centreId,
                centreName: centre.centreName
              };
            }); 
          })
        ]).then(
          (values: any) => {
            setTimeout( () => {
              this.BusyMessage = 'Getting Code Value Types...';
              this.codeValueDropdownObj.dataSource = values[1];
              this.codeValueDropdownObj.value = values[1][0].id;
              const codeValueType = values[1][0].value;
              // system settings
              this.BusyMessage = 'Getting System Settings...';
              // We don't need to watch for value changes on System Settings anymore.
              // if (values[0].length > 0) {                
              //   this.SubscribeValueChange();
              // }
              this.systemSettingsGridData = values[0];
              this.systemSettingsGridObj.refresh();
              this.BusyMessage = 'Getting Validations...';
              this.validationsGridData = values[3];
              this.BusyMessage = 'Getting My Substances...';
              this.mySubstancesData = values[4];
              // populate the grid data filtered by selection
              this.mySubstancesGridData = this.mySubstancesData.filter((substance)=>(substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.New && this.mySubsIncludeNew) ||
              (substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.Deleted && this.mySubsIncludeDeleted) ||
              (substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.Error && this.mySubsIncludeAccepted) ||
              (substance.mysuB_STATUS == this.AppConstants.MySubstance.Status.Duplicate && this.mySubsIncludeReplaced));
              this.BusyMessage = 'Getting Code Values...';

              this.onlineService.GetCodeValues().then(
                (arg: any) => {
                  const codeValueNotBelongsToCodeType = [];
                  arg.forEach((codeDetails) => {
                    if (codeDetails.Type != 'CodeType') {
                      codeValueNotBelongsToCodeType.push(codeDetails);
                    }
                  });
                  this.codeGroupDropdownObj.dataSource = codeValueNotBelongsToCodeType;
                  this.CodeValueChangeEvent(codeValueType);
                },
                (err: HttpErrorResponse) => {
                  if (this.onlineService.CheckErrorStatus(err)) {
                    this.spinner.hide();
                    this.showOnlineInfoDialog = true;
                    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
                      this.onlineService.doesConnectionExist();
                    }
                  } else {
                    const errorLog = {
                      CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                      LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                      CustomErrorMessage: err.message,
                      ErrorMessage: err.message,
                      InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
                    };
                    this.onlineService.AddErrorLog(errorLog).subscribe( ()=> {
                      const toasterValue = {
                        type: 'error',
                        message: 'Something went wrong adding to error log. Please try again',
                      };
                      this.toastService.showSpinner(toasterValue);
                      this.spinner.hide();
                    });
                  }
                }
              );
            }, 100);
          },
          (err: HttpErrorResponse) => {
            if (this.onlineService.CheckErrorStatus(err)) {
              this.spinner.hide();
              this.showOnlineInfoDialog = true;
              if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
                this.onlineService.doesConnectionExist();
              }
            } else {
              const errorLog = {
                CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                CustomErrorMessage: err.message,
                ErrorMessage: err.message,
                InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
              };
              this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                this.toastService.showSpinner({
                  type: 'error',
                  message: 'Something went wrong retrieving System Settings. Please close your browser and try again.',
                });
                this.spinner.hide();
              });
            }
          }
        );
      } else {
        this.router.navigateByUrl(AppConstants.Pages.PermissionDenied, { skipLocationChange: true });
      }
    });

    this.tooltip = new Tooltip({
      content: 'Loading...',
      target: '#ddltooltip_popup .e-list-item, #ddltooltip',
      position: 'TopCenter',
      beforeRender: this.onBeforeRender,
    });
    this.tooltip.appendTo('body');
    this.codeTypeTooltip = new Tooltip({
      content: 'Loading...',
      target: '#authenticationDropdown',
      position: 'TopCenter',
      beforeRender: this.onCodeTypeBeforeRender,
    });
    this.codeTypeTooltip.appendTo('body');
  }

      
   isMySubstanceSelected(element, index, array) {
    let isIncluded: boolean = false;
    switch (element.mysuB_STATUS) {
      case AppConstants.MySubstance.Status.New:
        isIncluded = this.mySubsIncludeNew;
        break;
        case AppConstants.MySubstance.Status.Deleted:
          isIncluded = this.mySubsIncludeDeleted;
        break;
        case AppConstants.MySubstance.Status.Error:
          isIncluded = this.mySubsIncludeAccepted;
        break;
        case AppConstants.MySubstance.Status.Duplicate:
          isIncluded = this.mySubsIncludeReplaced;
        break;
      case 0:
        isIncluded = this.mySubsIncludeAccepted;
        break;
      default:
        break;
    }
    return isIncluded; // this is where the
  }

  onBeforeRender(args: TooltipEventArgs): void {
    var dd: any = this;
    let dropdownId: any = document.getElementById('ddltooltip');
    let i: number;
    let isPopupList = true;
    isPopupList = args.target.getAttribute('class').includes('e-list-item');
    if (isPopupList) {
      if (dropdownId != null && dropdownId != undefined) {
        var codeGrouDropDown = dropdownId.ej2_instances[0].dataSource;
        for (i = 0; i < codeGrouDropDown.length; i++) {
          if (codeGrouDropDown[i].Value === args.target.textContent) {
            dd.content = codeGrouDropDown[i].Description != null ? codeGrouDropDown[i].Description : codeGrouDropDown[i].Value;
            dd.dataBind();
            break;
          }
        }
      }
    } else {
      let dropDownId: any = document.getElementById(args.target.id);
      if (dropDownId != null && dropDownId != undefined) {
        var codegroupDropdown = dropDownId.ej2_instances[0];
        dd.content = codegroupDropdown.text != '' && codegroupDropdown.text != null ? codegroupDropdown.text : codegroupDropdown.placeholder;
        dd.dataBind();
      }
    }
  }

  onCodeTypeBeforeRender(args: TooltipEventArgs): void {
    var dd: any = this;
    let dropdownId: any = document.getElementById('code-value');
    let i: number;
    let isPopupList = true;
    isPopupList = args.target.getAttribute('class').includes('e-list-item');
    if (isPopupList) {
      if (dropdownId != null && dropdownId != undefined) {
        var codeTypeDropDown = dropdownId.ej2_instances[0].dataSource;
        for (i = 0; i < codeTypeDropDown.length; i++) {
          if (codeTypeDropDown[i].value === args.target.textContent) {
            dd.content = codeTypeDropDown[i].description != null ? codeTypeDropDown[i].description : codeTypeDropDown[i].value;
            dd.dataBind();
            break;
          }
        }
      }
    } else {
      let dropDownId: any = document.getElementById(args.target.id);
      if (dropDownId != null && dropDownId != undefined) {
        var codeTypeDropdown = dropDownId.ej2_instances[0];
        dd.content = codeTypeDropdown.text != '' && codeTypeDropdown.text != null ? codeTypeDropdown.text : codeTypeDropdown.placeholder;
        dd.dataBind();
      }
    }
  }

  onClose(e: any) {
    this.tooltip.close();
    this.codeTypeTooltip.close();

    const closeTimer = setInterval(() => {
      if ((this.tooltip as any).tooltipEle) (this.tooltip as any).tooltipEle.style.display = 'none';

      if ((this.codeTypeTooltip as any).tooltipEle) (this.codeTypeTooltip as any).tooltipEle.style.display = 'none';
    }, 100);

    setTimeout(() => {
      clearInterval(closeTimer);
    }, 700);
  }

  // Notification subscription
  SubscribeValueChange() {
    setTimeout(() => {
      this.isSystemSettingUnSaved = true;
      this.subscribeFormChange();
    }, 2000);
  }

  subscribeFormChange() {
    // this.systemSettingFormObj.form.markAsPristine();
  }

  canDeactivate(): Observable<boolean> | boolean {    
    console.log("canDeactivate():systemSettingsGridObj");
    console.log(this.systemSettingsGridObj);
    if (
      (this.systemSettingsGridObj && this.unSaveChangeDetectionInGrid && this.codeValueFormObj) &&
      (this.systemSettingsGridObj || this.unSaveChangeDetectionInGrid || this.codeValueFormObj.dirty)
    ) {
      this.isSystemSettingUnSaved = false;
    } else {
      this.isSystemSettingUnSaved = true;
    }
    return this.isSystemSettingUnSaved;
  }

  // Code Valuescode-value
  onCreateCodeValueClick() {
    this.ClearCodeValues();
    this.showCodeValueModal = true;
    this.CodeValueTitle = this.translate.instant('common.addCodeValue');
    this.codeValueModalDataUnSavedSkip = false;
    const $this = this;
    setTimeout(() => {
      let inputElement = document.getElementById('codeNameField');
      if (inputElement != null && inputElement != undefined) {
        inputElement.focus();
      }
      $this.codeValueFormObj.form.markAsPristine();
    }, 100);
    document.getElementsByClassName('code-value-text')[0].classList.remove('e-error');
  }

  onCancelCodeValueClick() {
    if (!this.codeValueModalDataUnSavedSkip) {
      if (this.codeValueFormObj != undefined && this.codeValueFormObj.pristine) {
        this.showCodeValueDataUnsavedDailog = false;
        this.showCodeValueModal = false;
        this.IsEditCodeValue = false;
        var startDatePicker: any = document.getElementById('CodeStartDatePicker');
        startDatePicker.ej2_instances[0].clear();
        var endDatePicker: any = document.getElementById('CodeEndDatePicker');
        endDatePicker.ej2_instances[0].clear();
        this.codeValueFormObj.form.markAsPristine();
        this.isCodeValueFormDirty = false;
      } else {
        this.showCodeValueDataUnsavedDailog = true;
        setTimeout(() => {
          let inputElement = document.getElementById('codevalue-leave-btnid');
          if (inputElement != null && inputElement != undefined) {
            inputElement.focus();
          }
        }, 100);
      }
    } else {
      this.showCodeValueModal = false;
      this.IsEditCodeValue = false;
      var startDatePicker: any = document.getElementById('CodeStartDatePicker');
      startDatePicker.ej2_instances[0].clear();
      var endDatePicker: any = document.getElementById('CodeEndDatePicker');
      endDatePicker.ej2_instances[0].clear();
      this.codeValueFormObj.form.markAsPristine();
      this.showCodeValueDataUnsavedDailog = false;
      this.isCodeValueFormDirty = false;
    }
  }

  CodeValueUnSavedWarningCancelClick() {
    this.showCodeValueDataUnsavedDailog = false;
  }

  CodeValueUnSavedWarningYesClick() {
    this.codeValueModalDataUnSavedSkip = true;
    this.showCodeValueModal = false;
    this.showCodeValueDataUnsavedDailog = false;
    this.IsEditCodeValue = false;
    var startDatePicker: any = document.getElementById('CodeStartDatePicker');
    startDatePicker.ej2_instances[0].clear();
    var endDatePicker: any = document.getElementById('CodeEndDatePicker');
    endDatePicker.ej2_instances[0].clear();
    this.codeValueFormObj.form.markAsPristine();
    this.isCodeValueFormDirty = false;
  }
  ClearCodeValues() {
    this.codeId = null;
    this.tempCodeId = 0;
    this.codeValue = null;
    this.codeDescription = null;
    this.codeGroupDropdownObj.value = null;
    this.code = null;
    this.codeStartDateTime = null;
    this.codeEndDateTime = null;
    this.codeValueIsDefault = false;
    this.codeValueViewIsDefault = 'No';
    document.getElementsByClassName('code-value-text')[0].classList.remove('e-error');
    this.isValidStartDate = true;
    this.isValidEndDate = true;
    this.isGreaterEndDate = false;
    this.isLessStartDate = false;
  }

  saveCodeValueClick() {
    var CodeObj = {
      id: null,
      tempCodeId: 0,
      type: null,
      value: null,
      description: null,
      code: null,
      startDateTime: null,
      endDateTime: null,
      order: null,
      group: null,
      groupValue: null,
      IsLastRow: null,
      IsFirstRow: null,
      isDefault: false,
    };
    if (this.codeValue == '' || this.codeValue == null) {
      document.getElementsByClassName('code-value-text')[0].classList.add('e-error');
    } else if (
      document.getElementById('CodeEndDatePicker').querySelector('.e-error') == null &&
      document.getElementById('CodeStartDatePicker').querySelector('.e-error') == null &&
      document.getElementsByClassName('code-value-text')[0].classList.contains('e-error') == false
    ) {
      // check for a codevalue that is already selected as default!
      var IsDefaultAlreadyExistData = this.codeValueGridData.filter(function (value: any) {
        return value.isDefault == true;
      });

      if (IsDefaultAlreadyExistData.length > 0 && this.codeValueIsDefault) {
        // Another default exists!
        this.showCodeValueModal = false;
        this.showIsDefaultWarningModal = true;
      } else {
        // No other defaults selected

        // determine the max order value
        var maxOrder;
        if (this.codeValueGridData.length > 0) {
          maxOrder = Math.max.apply(
            Math,
            this.codeValueGridData.map(function (value: any) {
              return value.order;
            })
          );
        } else {
          maxOrder = 0;
        }

        var tempCodeId;

        if (this.codeValueGridData.length > 0) {
          tempCodeId = Math.max.apply(
            Math,
            this.codeValueGridData.map(function (value: any) {
              return value.tempCodeId;
            })
          );
        }

        if (this.codeValueGridData.length > 1) {
          var beforeRecord = this.codeValueGridData[this.codeValueGridData.length - 1];
          beforeRecord['IsLastRow'] = false;
        }

        CodeObj.id = null;
        CodeObj.tempCodeId = this.codeValueGridData.length == 0 ? 0 : tempCodeId + 1;
        CodeObj.type = CodeObj.type = this.codeValueDropdownObj.value;
        CodeObj.value = this.codeValue;
        CodeObj.description = this.codeDescription;
        CodeObj.code = this.code;
        CodeObj.startDateTime = new DatePipe('en-US').transform(this.codeStartDateTime, 'dd/MM/yyyy');
        CodeObj.endDateTime = new DatePipe('en-US').transform(this.codeEndDateTime, 'dd/MM/yyyy');
        CodeObj.order = maxOrder + 1;
        CodeObj.group = this.codeGroupDropdownObj.value;
        CodeObj.groupValue = this.codeGroupDropdownObj.text;
        CodeObj.IsLastRow = this.codeValueGridData.length >= 1 ? true : false;
        CodeObj.IsFirstRow = this.codeValueGridData.length == 0 ? true : false;
        CodeObj.isDefault = this.codeValueIsDefault;
        this.showCodeValueModal = false;
        this.codeValueGridData.push(CodeObj);
        this.codeValueGridObj.refresh();
        this.unSaveChangeDetectionInGrid = true;
      }
    } else {
      if (document.getElementById('CodeEndDatePicker').querySelector('.e-error') != null) {
        this.isValidEndDate = false;
      } else {
        if (document.getElementById('CodeStartDatePicker').querySelector('.e-error') != null) {
          this.isValidStartDate = false;
        }
      }
    }
  }

  updateCodeValueClick() {
    if (document.getElementsByClassName('code-value-text')[0].classList.contains('e-error') == false) {
      var IsDefaultAlreadyExistData = this.codeValueGridData.filter(function (value: any) {
        return value.isDefault == true;
      });
      var isActive = this.codeEndDateTime != null ? (this.codeEndDateTime >= new Date() ? true : false) : true;

      if (IsDefaultAlreadyExistData.length > 0 && this.codeValueIsDefault && isActive == false && this.tempCodeId == IsDefaultAlreadyExistData[0].tempCodeId) {
        this.showEndDateWarningDialog = true;
        this.showCodeValueModal = false;
      } else {
        if (IsDefaultAlreadyExistData.length > 0 && this.codeValueIsDefault && this.tempCodeId != IsDefaultAlreadyExistData[0].tempCodeId) {
          this.showCodeValueModal = false;
          this.showIsDefaultWarningModal = true;
        } else {
          let selecteddata = this.codeValueGridData[this.tempCodeId];
          selecteddata['value'] = this.codeValue;
          selecteddata['description'] = this.codeDescription;
          selecteddata['code'] = this.code;
          selecteddata['startDateTime'] = new DatePipe('en-US').transform(this.codeStartDateTime, 'dd/MM/yyyy');
          selecteddata['endDateTime'] = new DatePipe('en-US').transform(this.codeEndDateTime, 'dd/MM/yyyy');
          selecteddata['group'] = this.codeGroupDropdownObj.value;
          selecteddata['groupValue'] = this.codeGroupDropdownObj.text;
          selecteddata['isDefault'] = this.codeValueIsDefault;
          this.showCodeValueModal = false;
          this.IsEditCodeValue = false;
          this.codeValueGridObj.refresh();
          this.unSaveChangeDetectionInGrid = true;
          this.codeValueFormObj.form.markAsPristine();
        }
      }
    }
  }

  CodeValueDoubleClick($event) {
    if (this.isSystemSettingsPageHasEditPermission) {
      this.ClearCodeValues();
      let selectedData = $event.rowData;
      this.codeValueModalDataUnSavedSkip = false;
      if (selectedData != undefined) {
        this.codeId = selectedData.id;
        this.tempCodeId = selectedData.tempCodeId;
        this.codeValue = selectedData.value;
        this.codeDescription = selectedData.description;
        this.codeGroupDropdownObj.value = selectedData.group;
        this.code = selectedData.code;
        this.codeStartDateTime = selectedData.startDateTime == null ? null : this.FormatDate(selectedData.startDateTime);
        this.codeEndDateTime = selectedData.endDateTime == null ? null : this.FormatDate(selectedData.endDateTime);
        this.codeGroup = selectedData.groupValue == undefined ? null : selectedData.groupValue;
        this.codeValueViewIsDefault = selectedData.isDefault ? 'Yes' : 'No';
        this.codeValueIsDefault = selectedData.isDefault;
        this.codeType = this.codeValueDropdownObj.text;
        this.IsActiveCodeValue = this.codeEndDateTime != null ? (this.codeEndDateTime >= new Date() ? true : false) : true;
      }
      this.CodeValueTitle = this.translate.instant('common.editCodeValue');
      this.showCodeValueModal = true;
      this.IsEditCodeValue = true;

      setTimeout(() => {
        let inputElement = document.getElementById('codeNameField');
        if (inputElement != null && inputElement != undefined) {
          inputElement.focus();
        }
      }, 100);
    } else {
      this.showPermissionDenied = true;
      this.permisionTitle = this.translate.instant('common.permissionDenied');
      this.permisionAlertContent = this.translate.instant('common.permissionDeniedToEditCodeValue !');
    }
  }

  permissionDeniedCancelClick() {
    this.showPermissionDenied = false;
  }

  FormatDate(date) {
    let FormattedDate = date;
    if (FormattedDate instanceof Date == false) {
      FormattedDate = FormattedDate.match(/(\d+)\/(\d+)\/(\d+)/);
      FormattedDate = new Date(FormattedDate[3], FormattedDate[2] - 1, FormattedDate[1]); 
    }
    return FormattedDate;
  }

  orderUpArrowClick($event) {
    var order = parseInt($event.order, 10);
    var selectedRecordData: any = this.codeValueGridData.filter(function (value: any) {
      return value.order == order;
    });
    var selectedRecord = selectedRecordData[0];
    var beforeRecordData: any = this.codeValueGridData.filter(function (value: any) {
      return value.order == order - 1;
    });
    var beforeRecord = beforeRecordData[0];

    selectedRecord['order'] = beforeRecord.order;
    beforeRecord['order'] = $event.order;

    if (selectedRecordData.length > 0 && beforeRecordData.length > 0) {
      this.codeValueGridData.sort(function (a: any, b: any) {
        return a.order - b.order;
      });
      this.unSaveChangeDetectionInGrid = true;
      this.codeValueGridObj.refresh();

      for (var i = 0; i < this.codeValueGridData.length; i++) {
        if (i == 0) {
          this.codeValueGridData[i].IsLastRow = false;
          this.codeValueGridData[i].IsFirstRow = true;
        } else if (i == this.codeValueGridData.length - 1) {
          this.codeValueGridData[i].IsLastRow = true;
          this.codeValueGridData[i].IsFirstRow = false;
          this.spinner.hide();
        } else {
          this.codeValueGridData[i].IsLastRow = false;
          this.codeValueGridData[i].IsFirstRow = false;
        }
      }
    }
  }

  orderDownArrowClick($event) {
    var order = parseInt($event.order, 10);
    var selectedRecordData: any = this.codeValueGridData.filter(function (value: any) {
      return value.order == order;
    });
    var selectedRecord = selectedRecordData[0];
    var beforeRecordData: any = this.codeValueGridData.filter(function (value: any) {
      return value.order == order + 1;
    });
    var beforeRecord = beforeRecordData[0];

    if (selectedRecordData.length > 0 && beforeRecordData.length > 0) {
      selectedRecord['order'] = beforeRecord.order;
      beforeRecord['order'] = $event.order;
      this.codeValueGridData.sort(function (a: any, b: any) {
        return a.order - b.order;
      });

      this.unSaveChangeDetectionInGrid = true;
      this.codeValueGridObj.refresh();

      for (var i = 0; i < this.codeValueGridData.length; i++) {
        if (i == 0) {
          this.codeValueGridData[i].IsLastRow = false;
          this.codeValueGridData[i].IsFirstRow = true;
        } else if (i == this.codeValueGridData.length - 1) {
          this.codeValueGridData[i].IsLastRow = true;
          this.codeValueGridData[i].IsFirstRow = false;
          this.spinner.hide();
        } else {
          this.codeValueGridData[i].IsLastRow = false;
          this.codeValueGridData[i].IsFirstRow = false;
        }
      }
    }
  }

  IsNullField(target: any) {
    if (
      target.name == 'rememberMe' ||
      target.name == 'cookiePersistenceTime' ||
      target.name == 'setOnlinePeriod'
    ) {
      this.isSystemSettingFormDirty = true;
    }
    if (target.name == 'codeValue') {
      this.isCodeValueFormDirty = true;
    }
    if (target.validationMessage != '') {
      if (target.name == 'cookiePersistenceTime' && target.validationMessage != this.translate.instant('common.pleaseFillOutThisField')('.')) {
        this.systemSettingSaveButtonDisabled = true;
        this.cookiePersistenceError = true;
        this.cookiePersistenceTimeValidation = 'Cookie Persistence Time should be less than Remember Me';
      } else if (target.name == 'cookiePersistenceTime' && target.validationMessage == 'Please fill out this field.') {
        this.systemSettingSaveButtonDisabled = true;
        this.cookiePersistenceError = false;
        this.cookiePersistenceTimeValidation = 'Please fill Cookie Persistence Time';
      }

      if (target.name == 'setOnlinePeriod' && target.validationMessage != 'Please fill out this field.') {
        this.systemSettingSaveButtonDisabled = true;
        this.onlinePeriodError = true;
        this.onlineTimePeriodValidation = 'Ping Frequency must be between 15 and 300';
      } else if (target.name == 'setOnlinePeriod' && target.validationMessage == 'Please fill out this field.') {
        this.systemSettingSaveButtonDisabled = true;
        this.onlinePeriodError = false;
        this.onlineTimePeriodValidation = 'Please fill Ping Frequency in secs';
      }

      target.parentElement.classList.add('e-error');
    } else {
      this.cookiePersistenceError = false;
      this.onlinePeriodError = false;
      this.systemSettingSaveButtonDisabled = false;
      target.parentElement.classList.remove('e-error');
    }
    if (target.type == 'textarea') {
      if (target.value == '' || target.value == null) {
        target.parentElement.parentElement.firstElementChild.style.color = '#FF0000';
        target.parentElement.parentElement.firstElementChild.classList.add('e-error');
        target.style.border = 'solid 1px #FF0000';
        target.classList.add('e-error');
      } else {
        target.parentElement.parentElement.firstElementChild.style.color = '#00BFD8';
        target.parentElement.parentElement.firstElementChild.classList.remove('e-error');
        target.style.border = 'solid 1px #A9A9A9';
        target.classList.remove('e-error');
      }
    }
  }

  onCancelIsDefaultClick() {
    this.showIsDefaultWarningModal = false;
    this.codeValueIsDefault = false;
    this.showCodeValueModal = true;
  }

  CodeValueStartDateChange($event) {
    if ($event.value != null) {
      var startDate = Date.parse($event.value);

      if (isNaN(startDate)) {
        this.isValidStartDate = false;
      } else if (this.codeEndDateTime != null) {
        this.isValidStartDate = true;
        this.isLessStartDate = $event.value <= this.codeEndDateTime ? false : true;
        if (this.isValidStartDate) {
          if (document.getElementById('CodeEndDatePicker').querySelector('.e-error') != null) {
            document.getElementById('CodeEndDatePicker').querySelector('.e-error').classList.remove('e-error');
          }
          this.isGreaterEndDate = false;
        }
      } else {
        this.isValidStartDate = true;
      }
    } else {
      if ($event.event != null && $event.event != undefined && $event.event.type != 'blur') {
        this.isValidStartDate = true;
        this.isLessStartDate = false;
      }
    }
  }

  @HostListener('document:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    var target: any = event.target;
    if (target.id == 'CodeStartDatePicker_input') {
      if (target.value != null && target.value != '') {
        var date = target.value.split('/');
        var isInValidDate = false;
        for (var i = 0; i < date.length; i++) {
          if (date[i] == '' || date[i] == null) {
            isInValidDate = true;
          } else {
            isInValidDate = isNaN(date[i]);
          }
          if (isInValidDate) {
            break;
          }
        }
        if (isInValidDate == false) {
          var date = target.value.split('/');
          if (date.length == 3) {
            isInValidDate = isNaN(Date.parse(date[2] + '/' + date[1] + '/' + date[0]));
          }
        }

        if (isInValidDate) {
          this.isValidStartDate = false;
          this.isLessStartDate = false;
        } else if (this.codeEndDateTime != null) {
          this.isValidStartDate = true;
          this.isLessStartDate = new Date(date[2] + '/' + date[1] + '/' + date[0]) <= this.codeEndDateTime ? false : true;
          if (this.isLessStartDate) {
            if (document.getElementById('CodeEndDatePicker').querySelector('.e-error') != null) {
              document.getElementById('CodeEndDatePicker').querySelector('.e-error').classList.remove('e-error');
            }
          }
        } else {
          this.isValidStartDate = true;
          this.isLessStartDate = false;
        }
      } else {
        this.isValidStartDate = true;
        this.isLessStartDate = false;
      }
    }

    if (target.id == 'CodeEndDatePicker_input') {
      if (target.value != null && target.value != '') {
        var date = target.value.split('/');
        var isInValidDate = false;
        for (var i = 0; i < date.length; i++) {
          if (date[i] == '' || date[i] == null) {
            isInValidDate = true;
          } else {
            isInValidDate = isNaN(date[i]);
          }
          if (isInValidDate) {
            break;
          }
        }
        if (isInValidDate == false) {
          var date = target.value.split('/');
          if (date.length == 3) {
            isInValidDate = isNaN(Date.parse(date[2] + '/' + date[1] + '/' + date[0]));
          }
        }
        if (isInValidDate) {
          this.isValidEndDate = false;
          this.isGreaterEndDate = false;
        } else if (this.codeEndDateTime != null) {
          this.isValidEndDate = true;
          this.isGreaterEndDate = new Date(date[2] + '/' + date[1] + '/' + date[0]) > this.codeStartDateTime ? false : true;
          if (this.isGreaterEndDate) {
            if (document.getElementById('CodeStartDatePicker').querySelector('.e-error') != null) {
              document.getElementById('CodeStartDatePicker').querySelector('.e-error').classList.remove('e-error');
            }
          }
        } else {
          this.isValidEndDate = true;
          this.isGreaterEndDate = false;
        }
      } else {
        this.isValidEndDate = true;
        this.isGreaterEndDate = false;
      }
    }

    if (event.keyCode == 27) {
      if (this.showCodeValueModal) {
        this.onCancelCodeValueClick();
      }
      if (this.showIsDefaultWarningModal) {
        this.onCancelIsDefaultClick();
      }
      if (this.showEndDateWarningDialog) {
        this.CancelEndDateWarningClick();
      }
      if (this.showPermissionDenied) {
        this.permissionDeniedCancelClick();
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = 'You have unsaved changes! If you leave, your changes will be lost.';
    }
  }

  CodeValueEndDateChange($event) {
    var IsDefaultAlreadyExistData = this.codeValueGridData.filter(function (value: any) {
      return value.isDefault == true;
    });
    var isActive = $event.value != null ? ($event.value >= new Date() ? true : false) : true;

    if (IsDefaultAlreadyExistData.length > 0 && this.codeValueIsDefault && isActive == false && this.tempCodeId != IsDefaultAlreadyExistData[0].tempCodeId) {
      this.codeValueIsDefault = false;
    }
    this.savedactiveEndDate = this.codeEndDateTime;
    this.IsActiveCodeValue = $event.value != null ? ($event.value >= new Date() ? true : false) : true;

    if ($event.value != null) {
      var endDate = Date.parse($event.value);
      if (isNaN(endDate)) {
        this.isValidEndDate = false;
      } else if (this.codeEndDateTime != null) {
        this.isValidEndDate = true;
        this.isGreaterEndDate = $event.value >= this.codeStartDateTime ? false : true;
        if (this.isValidEndDate) {
          if (document.getElementById('CodeStartDatePicker').querySelector('.e-error') != null) {
            document.getElementById('CodeStartDatePicker').querySelector('.e-error').classList.remove('e-error');
          }
          this.isLessStartDate = false;
        }
      } else {
        this.isValidEndDate = true;
      }
    } else {
      if ($event.event != null && $event.event != undefined && $event.event.type != 'blur') {
        this.isValidEndDate = true;
        this.isGreaterEndDate = false;
      }
    }
  }

  saveInactiveEndDateClick() {
    this.codeValueIsDefault = false;
    this.updateCodeValueClick();
    this.showEndDateWarningDialog = false;
  }

  CancelEndDateWarningClick() {
    this.showCodeValueModal = true;
    this.IsActiveCodeValue = true;
    this.codeEndDateTime = this.savedactiveEndDate;
    this.showEndDateWarningDialog = false;
  }

  saveIsDefaultClick() {
    this.showIsDefaultWarningModal = false;
    var IsDefaultAlreadyExistData = this.codeValueGridData.filter(function (value: any) {
      return value.isDefault == true;
    });
    this.codeValueGridData[IsDefaultAlreadyExistData[0].tempCodeId]['isDefault'] = false;
    if (this.IsEditCodeValue) {
      this.updateCodeValueClick();
    } else {
      this.saveCodeValueClick();
    }
  }

  CodeValueChangeEvent(arg) {
    if (this.codeValueDropdownObj.index === null) {
      this.codeValueDropdownObj.index = 0;
    }
    const $this = this;
    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'false') {
      $this.onlineService.doesConnectionExist();
    }
    var codeTypeValue = arg.itemData == undefined ? arg : arg.itemData.value;
    $this.BusyMessage = 'Getting Code Values for selected Code Type...';
    this.onlineService.GetCodeValuesByCodeType(codeTypeValue).subscribe(
      function (result) {
        if (result != null) {
          var codeValueList = result;

          var orderCodeValueList = codeValueList.filter(function (value: any) {
            return value.order != null;
          });

          var nullOrderList = codeValueList.filter(function (value: any) {
            return value.order == null;
          });

          orderCodeValueList.sort(function (a: any, b: any) {
            return a.order - b.order;
          });

          orderCodeValueList.push.apply(orderCodeValueList, nullOrderList);

          for (var i = 0; i < orderCodeValueList.length; i++) {
            orderCodeValueList[i]['tempCodeId'] = i;
            orderCodeValueList[i]['groupValue'] = orderCodeValueList[i].groupName;
            orderCodeValueList[i]['IsActive'] =
              orderCodeValueList[i].endDateTime != null ? (new Date(orderCodeValueList[i].endDateTime) >= new Date() ? true : false) : true;
            orderCodeValueList[i].startDateTime = new DatePipe('en-US').transform(orderCodeValueList[i].startDateTime, 'dd/MM/yyyy');
            orderCodeValueList[i].endDateTime = new DatePipe('en-US').transform(orderCodeValueList[i].endDateTime, 'dd/MM/yyyy');

            if (i == 0) {
              if (orderCodeValueList.length == 1) {
                orderCodeValueList[i]['IsFirstRow'] = true;
                orderCodeValueList[i]['IsLastRow'] = true;
              } else {
                orderCodeValueList[i]['IsFirstRow'] = true;
                orderCodeValueList[i]['IsLastRow'] = false;
              }
            } else if (i == orderCodeValueList.length - 1) {
              orderCodeValueList[i]['IsFirstRow'] = false;
              orderCodeValueList[i]['IsLastRow'] = true;
            } else {
              orderCodeValueList[i]['IsFirstRow'] = false;
              orderCodeValueList[i]['IsLastRow'] = false;
            }
          }

          $this.codeValueGridData = [];
          $this.codeValueGridData = orderCodeValueList;
          $this.codeValueGridObj.dataSource = orderCodeValueList;
          $this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (this.onlineService.CheckErrorStatus(err)) {
          this.spinner.hide();
          this.showOnlineInfoDialog = true;
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          var errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
          };
          const $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            var toasterValue = {
              type: 'error',
              message: 'Something went wrong saving code value. Please try again',
            };
            $this.toastService.showSpinner(toasterValue);
            $this.spinner.hide();
          });
        }
      }
    );
  }

  saveSystemSettingsClick() {
    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'false') {
      this.onlineService.doesConnectionExist();
    }

      this.BusyMessage = "Saving System Settings...";      
      this.spinner.show();

      var systemSettingList = [];


      const systemSettingDetail = {
        systemSettingList: this.systemSettingsGridData,
        UserId: this._cookieService.get(AppConstants.CookieName.UserId),
        CentreId: parseInt(this._cookieService.get(AppConstants.CookieName.CenterId), 10),
        AuditData: JSON.stringify(this.systemSettingsGridData),
      };

      this.BusyMessage = 'Saving System Settings...';
      this.onlineService.SaveSystemSettings(systemSettingDetail).subscribe(
        (result)=> {
          if (result.isSuccess) {
            this.isSystemSettingsDataDirty = false; // reset dirty flag
            if (this.cookiePersistenceTime == 0) {
              this.cookiePersistenceTime = this.cookieExpirationMinimumInDays;
            }

            if (this.rememberMe == 0) {
              this.rememberMe = this.cookieExpirationMaximumInDays;
            }

            this._cookieService.set(AppConstants.CookieName.TwoFactorDurationType, this.systemSettingsGridData.filter(el=>el.key=='TwoFactorAuthenticationDuration')[0].value,
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict");
            
            this._cookieService.set(AppConstants.CookieName.RememberMe,
              this._cookieService.get(AppConstants.CookieName.RememberMe),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.CenterId,
              this._cookieService.get(AppConstants.CookieName.CenterId),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.CenterName,
              this._cookieService.get(AppConstants.CookieName.CenterName),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.CenterInitial,
              this._cookieService.get(AppConstants.CookieName.CenterInitial),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.CenterCountry,
              this._cookieService.get(AppConstants.CookieName.CenterCountry),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.CenterState,
              this._cookieService.get(AppConstants.CookieName.CenterState),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.LoginUserName,            
              this._cookieService.get(AppConstants.CookieName.LoginUserName),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.UMSUserToken,
              this._cookieService.get(AppConstants.CookieName.UMSUserToken),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.UserId,
              this._cookieService.get(AppConstants.CookieName.UserId),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.UserDisplayName,          
              this._cookieService.get(AppConstants.CookieName.UserDisplayName),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.UserToken,
              this._cookieService.get(AppConstants.CookieName.UserToken),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.HasUserSettingsFlag,
              this._cookieService.get(AppConstants.CookieName.HasUserSettingsFlag),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.PublicComputer,
              this._cookieService.get(AppConstants.CookieName.PublicComputer),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.TwoFactorDurationType,
              this._cookieService.get(AppConstants.CookieName.TwoFactorDurationType),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            this._cookieService.set(AppConstants.CookieName.TwoFactorLastLoginDate,
              this._cookieService.get(AppConstants.CookieName.TwoFactorLastLoginDate),
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );
            const actualOnlineTimePeriod = this.setOnlinePeriod * 1000;
            this._cookieService.set(AppConstants.CookieName.NavigationPeriod,
              actualOnlineTimePeriod.toString() != '' ? actualOnlineTimePeriod.toString() : '15000',
              this.isRemembered.toLocaleLowerCase() == 'true' ? this.rememberMe : this.cookiePersistenceTime,
              '/',null,true,"Strict"
            );

            const toasterValue = {
              type: 'success',
              message: 'Saved system settings successfully',
            };
            this.toastService.showSpinner(toasterValue);
            this.isSystemSettingUnSaved = true;
            this.systemSettingSaveButtonDisabled = true;
            this.subscribeFormChange();
          } else {
            const toasterValue = {
              type: 'error',
              message: 'Something went wrong. Please try again',
            };
            this.toastService.showSpinner(toasterValue);
          }
          this.spinner.hide();
        },
        (err: HttpErrorResponse) => {
          if (this.onlineService.CheckErrorStatus(err)) {
            this.spinner.hide();
            this.showOnlineInfoDialog = true;
            if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
              this.onlineService.doesConnectionExist();
            }
          } else {
            const errorLog = {
              CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
              LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
              CustomErrorMessage: err.message,
              ErrorMessage: err.message,
              InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
            };
            this.onlineService.AddErrorLog(errorLog).subscribe( ()=> {
              const toasterValue = {
                type: 'error',
                message: 'Something went wrong adding error to error log.',
              };
              this.toastService.showSpinner(toasterValue);
              this.spinner.hide();
            });
          }
        }
      );
    }

  private updateSystemSettingsGridData(keyName: string, value: string) {
    const item = this.systemSettingsGridData.find((setting) => setting.key == keyName);
    if (item != null) {
      item.value = value;
    }
  }

  saveCodeValuesClick() {
    this.spinner.show();

    if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'false') {
      this.onlineService.doesConnectionExist();
    }

    var codeValueList = this.codeValueGridData;
    // Format the dates
    for (var i = 0; i < codeValueList.length; i++) {
      codeValueList[i].startDateTime = codeValueList[i].startDateTime == null ? null : this.FormatDate(codeValueList[i].startDateTime);
      codeValueList[i].endDateTime = codeValueList[i].endDateTime == null ? null : this.FormatDate(codeValueList[i].endDateTime);
    }

    var codeValueDetail = {
      codeValueList: codeValueList,
      UserId: this._cookieService.get(AppConstants.CookieName.UserId),
      CentreId: parseInt(this._cookieService.get(AppConstants.CookieName.CenterId), 10),
      AuditData: JSON.stringify(codeValueList),
    };

    const $this = this;
    $this.BusyMessage = 'Saving Code Values...';
    $this.onlineService.SaveCodeValues(codeValueDetail).subscribe(
      function (result) {
        if (result != null && result.isSuccess) {
          $this.CodeValueChangeEvent($this.codeValueDropdownObj.value);
          const toasterValue = {
            type: 'success',
            message: 'Saved code values successfully',
          };
          $this.toastService.showSpinner(toasterValue);
          $this.onlineService.getCodeValueList();
          $this.unSaveChangeDetectionInGrid = false;
          $this.codeValueFormObj.form.markAsPristine();
        } else {
          const toasterValue = {
            type: 'error',
            message: 'Something went wrong. Please try again',
          };
          $this.toastService.showSpinner(toasterValue);
          $this.spinner.hide();
        }
      },
      (err: HttpErrorResponse) => {
        if (this.onlineService.CheckErrorStatus(err)) {
          this.spinner.hide();
          this.showOnlineInfoDialog = true;
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
          };
          this.onlineService.AddErrorLog(errorLog).subscribe( ()=> {
            const toasterValue = {
              type: 'error',
              message: 'Something went wrong. Please try again',
            };
            this.toastService.showSpinner(toasterValue);
            this.spinner.hide();
          });
        }
      }
    );
  }

  // properties for the SystemSettings Value Edit
  public systemSettingValueEditElement;
  public systemSettingValueNumberObj;
  public systemSettingValueTextBoxObj;
  public systemSettingValueBooleanObj;
  public systemSettingValueType;
  public systemSettingValueTypeOptions;
  public systemSettingsGridCommands=[{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];

  public systemSettingsValueEditOptions = 
    {
      create: this.systemSettingValueCreate,
      read : this.systemSettingValueRead,
      write: this.systemSettingValueWrite,
      destroy: this.systemSettingValueDestroy
      }        

  getSystemSettingColumnTypeForEdit(columnType){
    return SystemSettingType[columnType];
  }
  systemSettingValueDestroy() {
    // destroy the control based upon the type
    switch (this.systemSettingValueType) {
      case SystemSettingType.number:
        this.systemSettingValueNumberObj.destroy();
        break;
      case SystemSettingType.string:
      case SystemSettingType.longString:
        case SystemSettingType.dropdown:        
        this.systemSettingValueTextBoxObj.destroy();
        break;
      case SystemSettingType.boolean:
        this.systemSettingValueBooleanObj.destroy();
        break;
      default:
        break;
    }
    
  }
  systemSettingValueWrite(args) {
    // Save the type for the read and destroy
    this.systemSettingValueType = args.rowData['type'];
    // parse the options
    this.systemSettingValueTypeOptions = JSON.parse(args.rowData['options']);
    // create the control based upon the type
    switch (this.systemSettingValueType) {
      case SystemSettingType.number:
        this.systemSettingValueNumberObj = new NumericTextBox();
        this.systemSettingValueNumberObj.value = args.rowData[args.column.field];

        // Set properties based on attributes
        if (this.systemSettingValueTypeOptions != null) {
          Object.keys(this.systemSettingValueTypeOptions).forEach((key) => {
            if (key in this.systemSettingValueNumberObj) {
              this.systemSettingValueNumberObj[key] = this.systemSettingValueTypeOptions[key];
            }
          });
        }
        // append to input control
        this.systemSettingValueNumberObj.appendTo(this.systemSettingValueEditElement);     
        break;
     
      case SystemSettingType.dropdown:
      case SystemSettingType.string:
        this.systemSettingValueTextBoxObj = new TextBox();
        this.systemSettingValueTextBoxObj.value = args.rowData[args.column.field];

        // Set properties based on attributes
        if (this.systemSettingValueTypeOptions != null) {
          Object.keys(this.systemSettingValueTypeOptions).forEach((key) => {
            if (key in this.systemSettingValueTextBoxObj) {
              this.systemSettingValueTextBoxObj[key] = this.systemSettingValueTypeOptions[key];
            }
          });
        }
        // append to input control
        this.systemSettingValueTextBoxObj.appendTo(this.systemSettingValueEditElement);
        break;
        case SystemSettingType.longString:
          this.systemSettingValueTextBoxObj = new TextBox({multiline:true});
          this.systemSettingValueTextBoxObj.value = args.rowData[args.column.field];
  
          // Set properties based on attributes
          if (this.systemSettingValueTypeOptions != null) {
            Object.keys(this.systemSettingValueTypeOptions).forEach((key) => {
              if (key in this.systemSettingValueTextBoxObj) {
                this.systemSettingValueTextBoxObj[key] = this.systemSettingValueTypeOptions[key];
              }
            });
          }
          // append to input control
          this.systemSettingValueTextBoxObj.appendTo(this.systemSettingValueEditElement);
          break;
      case SystemSettingType.boolean:
        this.systemSettingValueBooleanObj = new CheckBox();
        this.systemSettingValueBooleanObj.checked = args.rowData[args.column.field]=='true';

        // Set properties based on attributes
        if (this.systemSettingValueTypeOptions != null) {
          Object.keys(this.systemSettingValueTypeOptions).forEach((key) => {
            if (key in this.systemSettingValueBooleanObj) {
              this.systemSettingValueBooleanObj[key] = this.systemSettingValueTypeOptions[key];
            }
          });
        }
        // append to input control
        this.systemSettingValueBooleanObj.appendTo(this.systemSettingValueEditElement);
        break;
        break;
      default:
        break;
    }
    
  }
  systemSettingValueCreate() {
    this.systemSettingValueEditElement = document.createElement('input');
    return this.systemSettingValueEditElement;
  }
  systemSettingValueRead() {
    let valueToReturn=null;   
    // create the control based upon the type
    switch (this.systemSettingValueType) {
      case SystemSettingType.number:
        this.systemSettingValueNumberObj.focusOut();
        valueToReturn = this.systemSettingValueNumberObj.value;
        break;
      case SystemSettingType.string:
      case SystemSettingType.longString:
        case SystemSettingType.dropdown:
        this.systemSettingValueTextBoxObj.focusOut();
        valueToReturn = this.systemSettingValueTextBoxObj.value;
        break;
        
      case SystemSettingType.boolean:
        valueToReturn = this.systemSettingValueBooleanObj.checked;
        break;
      default:
        break;
    }
    return valueToReturn;
  }
  loadCodeValues(arg) {
    (<any>this.codeValueGridObj).defaultLocale.EmptyRecord = 'No code values to display';
    this.touchStartEvent();
  }
  loadValidations(arg) {
    (<any>this.validationsGridObj).defaultLocale.EmptyRecord = 'No Validations to display';
    this.touchStartEvent();
  }
  loadMySubstances(arg) {
    (<any>this.mySubstancesGridObj).defaultLocale.EmptyRecord = 'No My Substances to display';
    this.touchStartEvent();
  }


  touchStartEvent() {
    const comp = this;
    const oldwireEvents = Grid.prototype.wireEvents; // override the default method
    Grid.prototype.wireEvents = function () {
      EventHandler.add(this.getContent(), 'touchstart', dblTap, this);
      return oldwireEvents.apply(this, []);
    };

    const oldUnwireEvents = Grid.prototype.unwireEvents; // override the default method
    Grid.prototype.unwireEvents = function () {
      EventHandler.remove(this.getContent(), 'touchstart', dblTap);
      return oldUnwireEvents.apply(this, []);
    };

    let timer = null;
    
    function dblTap(args) {
      if (getUserAgent()) {
        if (!timer) {
          timer = setTimeout(function () {
            timer = null;
          }, 300);
        } else {
          clearTimeout(timer);
          timer = null;
          const rowInfo = this.getRowInfo(args.target);
          if (this.element.id == 'codeValueGrid') {
            comp.CodeValueDoubleClick(rowInfo);
          }
        }
      }
    }
  
    function getUserAgent() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipod|ipad/.test(userAgent);
    }

  }
}
