import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppInsightsService } from '../../Services/app-insights.service';
import { OnlineService } from './../../Providers/OnlineService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    appInsightsService: AppInsightsService, public onlineService: OnlineService,
    public translate: TranslateService) {
    appInsightsService.logPageView('PageNotFound');
   }

  ngAfterViewInit() {
    this.titleService.setTitle('ToxAware - ' + this.translate.instant('common.pageNotFoundTitle'));
    this.onlineService.removeFocussedUrl();
  }

  ngOnInit() {
  }

}
