
export class AppConstants {

  public static SubstanceSearchSources = {
    MySubstances: 1,
  }
  public static SystemSetting = {
    OnlineTimeout: "OnlineTimeout"
  }

  public static SyncStatus = {
    InProgress: 'InProgress',
    Synced: 'Synced',
    Updated: 'Updated',
    New: 'New',
    Error: 'Error'
  }
  
  public static Defaults = {
    NavigationPeriod: 45000,
    OnlineTimeout: 2000,
    DateFormat: 'dd/MM/yyyy HH:mm'
  }
  public static SearchDepthList = [
    { Id: '1', DepthSearch: 'All Gates', Content: 'All Dates0' },
    { Id: '2', DepthSearch: 'Today', Content: 'Today0' },
    { Id: '3', DepthSearch: '24 hours', Content: '24 hours0' },
    { Id: '4', DepthSearch: '48 hours', Content: '48 hours0' },
    { Id: '5', DepthSearch: '1 week', Content: '1 week0' },
    { Id: '6', DepthSearch: '2 weeks', Content: '2 weeks0' },
    { Id: '7', DepthSearch: '1 month', Content: '1 month0' },
    { Id: '8', DepthSearch: '2 months', Content: '2 months0' },
    { Id: '9', DepthSearch: '3 months', Content: '3 months0' },
    { Id: '10', DepthSearch: '6 months', Content: '6 months0' },
    { Id: '11', DepthSearch: 'Year To Date', Content: 'Year To Date0' },
  ];
  
  public static Urls = {
    RootLogin: '/login',
    Login: 'login',
    Home: 'home',
    Exposure: 'exposure',
    Query: 'query',
    RootProfile: '/profile',
  }

  public static ConfigurableGridIds = {
    MyCasesGrid: 1,
    CenterCasesGrid: 2,
    NetworkCasesGrid: 3,
    AuditGrid: 4,
    SearchCasesGrid: 5,
    SurveillanceCasesGrid: 6,
    ReviewCasesGrid: 7,
    ResponseCasesGrid: 8
  }

  public static CommentGridMaxHeight = 500; // Max Height of comment grid
  public static Pages = {
    PermissionDenied: 'permissionDenied'
  }
  public static CaseType = {
    Exposure: 0,
    Query: -2
  }
  public static CaseTypeName = {
    Exposure: 'Exposure',
    Query: 'Query'
  }
  public static Profile = {
    MaxProfileImageSize: 1048576, // 1MB
    ProfileImageTypes: ['png', 'jpg', 'jpeg', 'gif', 'tiff']
  }
  public static Messages = {
    PopupBlockerEnabled: 'You have a pop-up blocker preventing opening new windows. Please turn it off.'
  }

  public static ReviewStatus = {
    NoProblemFound: '1154'
  }
  public static Toasts = {
    SuccessTimeToDisplay: 3000,
    InfoTimeToDisplay: 5000,
    ErrorTimeToDisplay: 0, // Zero means don't automatically hide toasts (they must be clicked)
    WarningTimeToDisplay: 0, // Zero means don't automatically hide toasts (they must be clicked)
  }
  public static NotFoundValue = 'Error:Not Found';
  public static EditPath = 'edit';
  public static ViewPath = 'view';

  public static SubstanceSearchDBName = 'ToxAwareSubDB';
  public static GenericSubstanceSearchDBName = 'GenericSubstanceDB';
  public static GenericSubstanceSearchTableName = 'GenericSubstance';
  public static ExposureOfflineDBName = 'ExposureDB';
  public static QueryOfflineDBName = 'QueryDB';

  public static ExposureOfflineTableName = 'Exposure';
  public static QueryOfflineTableName = 'InfoRequest';

  public static CodeValueDBName: string = 'ToxAwareDB';
  public static CodeValueTableName: string = 'CodeValue';

  public static MySubstanceSearchDBName = 'ToxAwareMySubDB';
  public static SubstanceSearchTableName = 'SubstanceSearch';
  public static SubstanceSourceTableName = 'SubstanceSources';
  public static MySubstanceSearchTableName = 'MySubstanceSearch';

  public static MySubstance = {
    Status: {
      Error: -2,
      Deleted: -1,
      New: 1,
      Duplicate: 2
    }
  };

  public static CaseStatus = {
    Deleted: 1286,
    Open: 1,
    Closed: 2
  };

  public static CaseStatusName = {
    Deleted: 'Deleted',
    Open: 'Open',
    Closed: 'Closed'
  };

  public static KEY_CODE = {
    RIGHT_ARROW: 39,
    LEFT_ARROW: 37,
    RETURN_KEY: 13,
    NumpadEnter: 176,
  };

  public static CookieName = {
    SkipCookieCallOnLogin: 'skipCookieCallOnLogin',
    UserAuthenticationToken: 'userToken',
    UserId: 'userId',
    UserDisplayName: 'userName',
    UserToken: 'userToken',
    LoginUserName: 'loginUserName',

    UMSUserToken: 'umsUserToken',
    AppTheme: 'appTheme',
    AppThemeMode: 'appThemeMode',
    CenterId: 'centerId',
    CenterName: 'centerName',
    CenterInitial: 'centerInitial',
    CenterCountry: 'centerCountry',
    CenterState: 'centerState',

    CanPingOnlineStatus: 'canCheckOnline',

    CookiePersistenceTime: 'CookiePersistenceTime',
    HasUserSettingsFlag: 'hasUserSettings',
    TwoFactorLastLoginDate: 'twoFactorLastLoginDate',
    TwoFactorDurationType: 'twoFactorDurationType',
    TwoFactorAuthenticationDuration: 'TwoFactorAuthenticationDuration',
    
    PasswordLastChangedDate: 'lastPasswordChangedDate',
    PasswordExpirationDate: 'passwordExpiration',
    RememberMe: 'rememberMe',
    PublicComputer: 'publicComputer',
    NavigationPeriod: 'navigationPeriod',
    OnlineTime: 'OnlineTime',

    AnyOfflineExpo: 'isAnyOfflineExposure',
    AnyOfflineQuery: 'isAnyOfflineInfoRequest',
    ColorTheme: 'colorTheme',
  };

  public static SubstanceSearch = {
    Type: {
      All: '',
      NPR: '0',
      AMT: '2',
      PDX: '3',
      MySubstances: '1'
    }
  };

  public static Module = {
    CaseList: 'Case List',
    Exposure: 'Exposure',
    Query: 'Query',
    Reports: 'Reports',
    Reviews: 'Reviews',
    Search: 'Search',
    Audit: 'Audit',
    Locks: 'Locks',
    CodeAdmin: 'Code Admin',
    Login: 'Login',
  };

  public static Permission = {
    View: 'isVisible',
    Add: 'canAdd',
    Edit: 'canEdit',
    Delete: 'canDelete'
  }
  public static PermissionsMaxAgeBeforeReload = 15000; // time in ms.
  
  public static RecallPriority = class {
    public static Low = 5277;
    public static Medium = 5278;
    public static High = 5279;
  };

  public static RecallStatus = class {
    public static Pending = 5275;
    public static Closed = 5276;
    public static Notified = 5282;
    public static InProgress = 5283;
  };
  public static LocalStorage = class {
    public static OnLineStatus = 'appOnline';
    public static UrlList = 'UrlList';
    public static LocalTempCallId = 'localTempCallID';
    public static OfflineExposureOpenUrlList = 'OfflineExposureOpenUrlList';
    public static ExposureStorage = 'exposureStorage';
    public static SyncStatus = 'SyncStatus';
    public static Language = 'Language';
  };

  public static Headers = {
    Authorization: 'Authorization',
    UserId: 'userId',
    CentreId: 'centreId'
  }

  public static HcfType_Hospital = 1137;
  public static AgeUnit_Days = 50;
  public static AgeUnit_Months = 49;
  public static AgeUnit_Years = 48;

  public static AddReviewTitle = '<div class=\'icon-Review modal-icon\' ></div><div class=\'modal-title1\'>Add Review</div>';
  public static AddEditReviewTitle = '<div class=\'icon-Review modal-icon\' ></div><div class=\'modal-title1\'>Edit Review</div>';
  public static AddResponseTitle = '<div class=\'icon-Review modal-icon\' ></div><div class=\'modal-title1\'>Add Review Response</div>';
  public static AddEditResponseTitle = '<div class=\'icon-Review modal-icon\' ></div><div class=\'modal-title1\'>Edit Review Response</div>';
  // olive
  // public static DefaultPrimaryColor = " #658865";
  // public static DefaultSecondaryColor = "#DADED4";
  // public static DefaultPrimaryFontColor ="#3C403D";
  // public static DefaultSecondaryFontColor = "#A3BCB6";

  // Tan
  // public static DefaultPrimaryColor = " #DDAF94";
  // public static DefaultSecondaryColor = "#E8CEBF";
  // public static DefaultPrimaryFontColor ="#4F4846";
  // public static DefaultSecondaryFontColor = "#266150";

  // Blue By
  // public static DefaultPrimaryColor = " #007CC7";
  // public static DefaultSecondaryColor = "#4DA8DA";
  // public static DefaultPrimaryFontColor ="#12232E";
  // public static DefaultSecondaryFontColor = "#EEFBFB";

  // Grey+
  public static DefaultPrimaryColor = '#707070';
  public static DefaultSecondaryColor = '#FFD6C';
  public static DefaultPrimaryFontColor = '#3F3F3F';
  public static DefaultSecondaryFontColor = '#F1B24A';

  public static DefaultProfileImage = '/assets/Images/Profile.png';

  public static FALSE = 'false';
  public static TRUE = 'true';
  public static NULL = 'null';

  public static TwoFactorPeriods = {
    Yearly: 'yearly',
    Monthly: 'monthly',
    Weekly: 'weekly',
    Daily: 'daily',
  }
  
  // Translate
  public static DefaultLangaugeCode = 'en';
  public static LanguageChoices = [
    { value: 'en', text: 'English' },
    { value: 'de', text: 'Deutsch' },
    { value: 'fr', text: 'Français' },
    { value: 'it', text: 'Italiano' },
    { value: 'es', text: 'Español'},
  ];

  // Log type constants
  public static LogTypes = {
    History: 'History',
    Error: 'System Error'
  };
}

