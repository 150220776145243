import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CookieService } from 'ngx-cookie-service';
import { ToasterService } from '../../Providers/ToasterService';
import { OnlineService } from './../../Providers/OnlineService';

@Component({
  selector: 'case-nolocks-dialog',
  templateUrl: './case-nolocks-dialog.component.html',
  styleUrls: ['./case-nolocks-dialog.component.scss']
})
export class CaseNoLocksDialogComponent implements OnInit, OnChanges {
  @Input() NoLockErrorCode = 0;
  @Input() CurrentProcess = '';
  @Input() OtherUsersName = '';
  @Input() DisplayNoLocksDlg = false;
  @Output() DisplayNoLocksDlgChange = new EventEmitter<any>();

  @Output() ViewLatestCaseEvent = new EventEmitter<string>();
  @Output() CiteProcessEvent = new EventEmitter<string>();

  @ViewChild('NoLocksDlg', { static: false })
  NoLocksDlg: DialogComponent;

  // Create element reference for dialog target element.
  @ViewChild('container', { static: false }) container: ElementRef;

  public animationSettings: Object = { effect: 'Zoom', duration: 100, delay: 0 };
  public target: string = 'body';
  public isDraggable: boolean = true;
  public dialogWidth: string = '650';

  public showNoLocksDlgCaseClosed: boolean = false;
  public showNoLocksDlgOtherUserHasLock: boolean = false;
  public showNoLocksDlgLocksFree: boolean = false;
  public noLocksDlgProcessName: string = '';
  public noLocksDlgOtherUsersName: string = '';

  constructor(public onlineService: OnlineService, public toastService: ToasterService, private _cookieService: CookieService) {}

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
     for (const propName in changes) {
       // const chng = changes[propName];
       // console.log(propName + ' change value: ' + chng);
       if (propName === 'DisplayNoLocksDlg' && this.DisplayNoLocksDlg === true) {
        this.showNoLocksDlgCaseClosed = false;
        this.showNoLocksDlgOtherUserHasLock = false;
        this.showNoLocksDlgLocksFree = false;

        if (this.NoLockErrorCode === 4) {
          // Locks have been removed and exposure has been updated with a closed status
          this.showNoLocksDlgCaseClosed = true;
        } else if (this.NoLockErrorCode === 3) {
          // Locks have been removed and exposure now has new user locks
          this.noLocksDlgOtherUsersName = (this.OtherUsersName === '') ? 'someone' : this.OtherUsersName;
          this.showNoLocksDlgOtherUserHasLock = true;
        } else {
          // Locks have been removed and exposure may have been updated but now the locks are free to reestablish
          this.showNoLocksDlgLocksFree = true;
        }
        this.noLocksDlgProcessName = this.CurrentProcess;
       }
     }
  }

  ViewBtnClick() {
    this.cleanNoLocksComponent();
    this.ViewLatestCaseEvent.emit();
  }

  CiteBtnClick(){
    this.cleanNoLocksComponent();
    this.CiteProcessEvent.emit();
  }

  dialogClose() {
    this.cleanNoLocksComponent();
  }

  private cleanNoLocksComponent() {
    this.DisplayNoLocksDlg = false;
    this.showNoLocksDlgCaseClosed = false;
    this.showNoLocksDlgOtherUserHasLock = false;
    this.showNoLocksDlgLocksFree = false;

    this.DisplayNoLocksDlgChange.emit(this.DisplayNoLocksDlg);
  }
}
