<div>
    <app-header></app-header>
</div>
<div>    
    <div class="body-content">
        <router-outlet></router-outlet>
    </div>
</div>
<div class="layout-footer col-xs-12">
  <app-footer ></app-footer>
</div>    