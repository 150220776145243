<div class="drag">
  <ejs-dialog #genericCaseValidationDialog id="caseNoLocksDialog" #genericValidationDialog [isModal]='true'
    [visible]="DisplayNoLocksDlg" allowDragging='true' [animationSettings]='animationSettings'
    showCloseIcon='true' (close)="dialogClose()" [target]='target' [width]='dialogWidth' height=".90VH">
    <ng-template #header>
      <div class=" icon-Validate " style="
              margin-left: 1px;
              margin-top: 1px;
              float:left;
              padding-right: 15px; "></div>
      <h4>No Case Locks</h4>
    </ng-template>
    <ng-template #content>
      <div style="padding-top: 10px; padding-bottom: 10px; font-weight: 500;">
        <span style="display: block;">This Case's locks have been manually deleted.</span>
      </div>
      <div id="creategenericDialogContent" style="min-height: .5vh">
        <div [ngStyle]="{'display': showNoLocksDlgCaseClosed ? 'block' : 'none' ,'opacity': '1'}">
          <p>
            Presently the Case is closed, so a {{ noLocksDlgProcessName }} is not possible.
          </p>
          <p>
            The following choices include:
          </p>
          <ul style="list-style: circle;">
            <li>
              {{ showNoLocksDlgCaseClosed && noLocksDlgProcessName == 'Close' ? "If there are changes to be made to the case you may do the following: " : "" }}
              Leave this tab open and wait for someone to reopen the case, or,
            </li>
            <li>
              Close this tab and any data changes will be lost.
            </li>
          </ul>
          <p>
            With the first choice, if you have permission to reopen the Case, click the 'View' button below to open the same case
            in a new tab with View mode, a 'Reopen' button will allow you to reopen it.
            When complete, close the view window, in this window click '{{ noLocksDlgProcessName }}' again, this dialog will reappear, you will have 'View'
            and '{{ noLocksDlgProcessName }}' buttons appear.
          </p>
          <ul style="list-style:circle;">
            <li>
              The 'View' button will allow you to open the latest version of the same Case in a new tab and compare differences in this one,
              avoiding the potential to overwrite other user's changes.
            </li>
            <li>
              The '{{ noLocksDlgProcessName }}' button will re-establish the locks and continue the {{ noLocksDlgProcessName }} process.
            </li>
          </ul>
        </div>
        <div [ngStyle]="{'display': showNoLocksDlgOtherUserHasLock ? 'block' : 'none' ,'opacity': '1'}">
          <p>
            Currently {{ noLocksDlgOtherUsersName }} has the Case's locks, so a {{ noLocksDlgProcessName }} is not possible.
          </p>
          <p>
            The following choices include:
          </p>
          <ul style="list-style:circle;">
            <li>
              Leave this tab open, wait until they have finshed and try the {{ noLocksDlgProcessName }} again at a later time, or,
            </li>
            <li>
              Close this tab and revisit the Case at a later time when the locks are free, then reapply any changes and {{ noLocksDlgProcessName }}.
            </li>
          </ul>
          <p>
            With the first choice when the locks are free and the {{ noLocksDlgProcessName }} can be performed again, this dialog will reappear,
            you will have 'View' and '{{ noLocksDlgProcessName }}' buttons appear.
          </p>
          <ul style="list-style:circle;">
            <li>
              The 'View' button will allow you to open the latest version of the same Case in a new tab and compare differences in this one,
              avoiding the potential to overwrite the other user's changes.
            </li>
            <li>
              The '{{ noLocksDlgProcessName }}' button will re-establish the locks and the {{ noLocksDlgProcessName }} can be performed again.
            </li>
          </ul>
        </div>
        <div [ngStyle]="{'display': showNoLocksDlgLocksFree ? 'block' : 'none' ,'opacity': '1'}">
          <p>
            Currently the Case locks are free and can be reapplied, so a {{ noLocksDlgProcessName }} is possible.
          </p>
          <p>
            The following choices include:
          </p>
          <ul style="list-style:circle;">
            <li>
              Click 'Cancel' and refresh the page to get the latest version of the Case and reapply any changes.
            </li>
            <li>
              Click '{{ noLocksDlgProcessName }}' re-establish the locks and perform the {{ noLocksDlgProcessName }} again, or,
            </li>
          </ul>
          <p>
            With the second choice, before you click '{{ noLocksDlgProcessName }}', click the 'View' button, this will open
            the latest version of the same Case in a new tab, allowing the comparison of differences in this one, avoiding the
            potential to overwrite the other user's changes.
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <button ejs-button id="viewBtn" *ngIf="showNoLocksDlgCaseClosed || showNoLocksDlgLocksFree" class="generic-validation-dialog-btn mob-footer-alignbtn cancel-operation" (click)="ViewBtnClick()" data-ripple="true" title="Open this case in a new window in View only">View</button>
      <button ejs-button id="citeBtn" *ngIf="showNoLocksDlgLocksFree" style="width: auto; min-width: 0;" class="generic-validation-dialog-btn mob-footer-alignbtn cancel-operation" (click)="CiteBtnClick()" data-ripple="true">{{ noLocksDlgProcessName }}</button>
      <button ejs-button id="closeBtn" class="dialog-ok-btn mob-footer-alignbtn save-operation" (click)="dialogClose()" data-ripple="true" title="Close this dialog and return to the unsaved case">Close</button>
    </ng-template>
  </ejs-dialog>
  <!-- [enableResize]='isDraggable' -->
</div>
