import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
// import { DialogComponent } from '../Components/dialog/dialog.component';
import { DialogService } from '../Services/dialog.service';
@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  constructor(private readonly updates: SwUpdate, private dialogService: DialogService) {
    // Update was deprecated.
    // this.updates.available.subscribe(event => {
    //   this.showAppUpdateAlert();
    // });

    this.updates.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe((event) => {
        this.showAppUpdateAlert();
      });
  }
  showAppUpdateAlert() {
    const header = 'App Update available';
    const message = 'Choose Ok to update';
    const action = this.doAppUpdate;
    const caller = this;
    const dialogOptions = {
      title: 'ToxAware® Update available',
      message: 'A New Version is available!',
      cancelText: 'CONFIRM.DOWNLOAD.JOB.CANCELTEXT',
      confirmText: 'A New Version is available'
    };
    // Show the message
    alert('ToxAware® Update Available. Please close all ToxAware windows and/or tabs to upgrade. The new version will be installed when you open ToxAware again.');
    // this.dialogService.open(dialogOptions);
    //const modalDialog = this.matDialog.open(DialogComponent, dialogConfig);
    // Use MatDialog or ionicframework's AlertController or similar
    // presentAlert(header, message, action, caller);
  }
  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
