import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './Layout/layout/layout.component';
import { LoginComponent } from './Pages/lazylogin/login/login.component';
import { PageNotFoundComponent } from './Pages/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './Pages/permissiondenied/permissiondenied.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { SystemsettingsComponent } from './Pages/lazysystemsettings/systemsettings/systemsettings.component';

import { TestQBComponent } from './test-qb/test-qb.component';
// import { TestGridFKComponent } from './Pages/test-grid-fk/test-grid-fk.component';

const baseRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // Testing pages
  // { path: 'TGFK', component: TestGridFKComponent },
  // { path: 'TestGridFK', component: LayoutComponent, loadChildren: () => import('./Pages/test-grid-fk/test-grid-fk.module').then((m) => m.TestGridFkModule) }, //component: TestGridFKComponent },

  // paths for testing components
  {path:'notices', component: SystemsettingsComponent},

  // all user pages
  { path: 'login', component: LoginComponent },
  { path: 'home', component: LayoutComponent, loadChildren: () => import('./Pages/lazylogin/lazylogin.module').then((m) => m.LazyloginModule) },
  { path: 'audit', component: LayoutComponent, loadChildren: () => import('./Pages/lazyaudit/lazyaudit.module').then((m) => m.LazyauditModule) },
  { path: 'locks', component: LayoutComponent, loadChildren: () => import('./Pages/lazylockmanagement/lazylockmanagement.module').then((m) => m.LazylockmanagementModule) },
  { path: 'profile', component: LayoutComponent, loadChildren: () => import('./Pages/lazyprofile/lazyprofile.module').then((m) => m.LazyprofileModule) },
  { path: 'reports', component: LayoutComponent, loadChildren: () => import('./Pages/lazyreports/lazyreports.module').then((m) => m.LazyreportsModule) },
  { path: 'printexpo', component: LayoutComponent, loadChildren: () => import('./Pages/lazyprintexposure/lazyprintexposure.module').then((m) => m.LazyPrintExposureModule) },
  { path: 'printquery', component: LayoutComponent, loadChildren: () => import('./Pages/lazyprintinforequest/lazyprintinforequest.module').then((m) => m.LazyPrintInfoRequestModule) },
  { path: 'recalls', component: LayoutComponent, loadChildren: () => import('./Pages/lazyrecalls/lazyrecalls.module').then((m) => m.LazyRecallsModule) },

  { path: 'search', component: LayoutComponent, loadChildren: () => import('./Pages/lazysearch/lazysearch.module').then((m) => m.LazysearchModule) },
  { path: 'ssearch', component: LayoutComponent, loadChildren: () => import('./Pages/lazysupersearch/lazysupersearch.module').then((m) => m.LazysupersearchModule) },

  { path: 'systemsettings', component: LayoutComponent, loadChildren: () => import('./Pages/lazysystemsettings/lazysystemsettings.module').then((m) => m.LazysystemsettingsModule) },

  { path: 'exposure', component: LayoutComponent, loadChildren: () => import('./Pages/lazynewexposure/lazynewexposure.module').then((m) => m.LazynewexposureModule) },
  { path: 'query', component: LayoutComponent, loadChildren: () => import('./Pages/lazyinforequest/lazyinforequest.module').then((m) => m.LazyinforequestModule) },
  { path: 'permissionDenied', component: PermissionDeniedComponent, canActivate: [AuthGuard] },
  { path: 'qb', component: LayoutComponent, loadChildren: () => import('./test-qb/test-qb.module').then((m) => m.TestQbModule), canActivate: [AuthGuard] },
  // dashboard is using layout component with children of DashboardComponent. Activation is guarded by AuthGuard
  {
    path: 'dashboard', component: LayoutComponent,
    loadChildren: () => import('./Pages/lazydashboard/lazydashboard.module').then((m) => m.LazydashboardModule) 
  },
    // Data Services! !
    {
      path: 'data', component: LayoutComponent,
      loadChildren:() => import('./Pages/lazydata/lazydata.module').then((m)=>m.LazydataModule)
    },
  // Super Search !
  {
    path: 'ss', component: LayoutComponent,
    loadChildren:() => import('./Pages/lazysupersearch/lazysupersearch.module').then((m)=>m.LazysupersearchModule)
  },
  // This last entry is for page not found and must be last!
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
const extraOptions: ExtraOptions = {
  relativeLinkResolution: 'legacy'
};
@NgModule({
  imports: [RouterModule.forRoot(baseRoutes, extraOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
