
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OnlineService } from './Providers/OnlineService';
import { ToasterService } from './Providers/ToasterService';
import { AppConstants } from './app.constants';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private route: ActivatedRoute, private _cookieService: CookieService, public toastService: ToasterService, private spinner: NgxSpinnerService, public onlineService: OnlineService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url != null && req.url.indexOf('/token') > 0) {
      // var headersforToeknApi = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      return next.handle(req);
    }

    if (req.url != null) {
      if (
        req.url.indexOf("/GetAssemblyVersion") > 0 ||
        req.url.indexOf("/GetLoginMessage") > 0 ||
        req.url.indexOf("/GetOwnerName") > 0 ||
        req.url.indexOf('/ValidateUserEmail') > 0 ||
        req.url.indexOf('/ValidateResetPasswordCode') > 0 ||
        req.url.indexOf('/UpdatePasswordByUserEmail') > 0 ||
        req.url.indexOf('/GetPasswordConfiguration') > 0 ||
        req.url.indexOf('/assets/i18n/') >= 0
      ) {
        return next.handle(req);
      }
    }

    if (req.url != null && req.url.indexOf('/AddErrorLog') > 0) {
      return next.handle(req);
    }

    if (this._cookieService.get(AppConstants.CookieName.UserToken) != "") {
      const authHeader = "Bearer " + this._cookieService.get(AppConstants.CookieName.UserToken);
      const userId = this._cookieService.get(AppConstants.CookieName.UserId) == "" ? "0" : this._cookieService.get(AppConstants.CookieName.UserId);
      const centreId = (this._cookieService.get(AppConstants.CookieName.CenterId) == "" || this._cookieService.get(AppConstants.CookieName.CenterId) == "null") ? "0" : this._cookieService.get(AppConstants.CookieName.CenterId);
      /*
      var headers = new HttpHeaders({
          'Authorization': authHeader,
          'userId':userId,
          'centreId': centreId
        });
        */
      let headers = new HttpHeaders();
      headers = headers.set(AppConstants.Headers.Authorization, authHeader);
      headers = headers.set(AppConstants.Headers.UserId, userId);
      headers = headers.set(AppConstants.Headers.CentreId, centreId);

      req.headers.keys().forEach(hdr => {
        //console.log(hdr + "=" + req.headers.get(hdr));
        if (!headers.has(hdr)) {
          //console.log("Added: " + hdr + "=" + req.headers.get(hdr));
          headers = headers.set(hdr, req.headers.get(hdr));
        }
      });

      const clonedReq = req.clone({ headers });
      return next.handle(clonedReq).pipe(
        tap(
          succ => { },
          err => {
            if (err.status === 401) {
              const url = window.location.pathname;
              if (url.split('/')[1].toLocaleLowerCase() != "login") {
                this._cookieService.deleteAll('/');
                this.spinner.hide();
                const toasterValue = {
                  type: "warning",
                  message: "Authorization error. Refresh your browser and try again. If it continues, Clear your cookies for this site and close and re-open the browser to try again."
                }
                this.toastService.showSpinner(toasterValue);
                this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.pathname == '/login' ? '/home' : window.location.pathname } });
                // localStorage.setItem('returnUrl', window.location.pathname == '/login' ? '/home' : window.location.pathname); // Disabled 19/11/2021 DPR
              } else {
                this.spinner.hide();
                const toasterValue = {
                  type: "warning",
                  message: "Authorization error. Refresh your browser and try again. If it continues, Clear your cookies for this site and close and re-open the browser to try again."
                }
                this.toastService.showSpinner(toasterValue);
              }
            } else {
              if (err.url != null && err.url.indexOf('/users') > 0 && window.location.pathname.split('/')[1].toLocaleLowerCase() == "profile") {
                this.spinner.hide();
              }
              if (err.url == null) {
                this.spinner.hide();
              }
            }
          }));
    }
    else {
      this._cookieService.deleteAll('/');
      this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.pathname == '/login' ? '/home' : window.location.pathname } });
      // localStorage.setItem('returnUrl', window.location.pathname == '/login' ? '/home' : window.location.pathname); // Disabled 19/11/2021 DPR
    }
  }
}
