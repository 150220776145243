<div class="generic-button" style="min-width:120px">
  <div class="btn-substance">
    <button type="button" ejs-button cssClass="btnsubstance-decorate" id="info-save"
      [ngClass]="(isPrintExposure) ? 'printDisable' : 'printEnable'" (click)="onSelectGenericCodeClick($event)"
      title="Select Generic Substance">{{'GENERIC'}}</button>
  </div>

  <div class="drag">
    <ejs-dialog id="genericSubstanceSelectionDialog" #genericSubstanceSelectionDialog [isModal]='true'
      [visible]='genericSubstanceDialogVisible' allowDragging='true' [animationSettings]='animationSettings'
      showCloseIcon='true' [target]='target' [width]='dialogWidth'
      height=".90VH">
      <ng-template #header>
          <h4>{{genericTitle}}</h4>
      </ng-template>
      <ng-template #content>
        <div id="creategenericDialogContent" style="min-height: .5vh" >
          <ejs-treeview #genericSubstanceTree id="genericSubstanceTree" [fields]='genericTreeField'
            (nodeSelected)="genericCodeNodeSelected($event)">
          </ejs-treeview>
        </div>
        <div *ngIf="ShowNoGenericSubstanceWarning">
          <span>Generic substances are not available. Close your browser and try again.</span>
        </div>
      </ng-template>
      <ng-template #footerTemplate>
        <button ejs-button id="genericSave" class="add-genericdialog-save-btn mob-footer-alignbtn cancel-operation"
          (click)="onCloseGenericSubstancePickerButtonClick()" data-ripple="true">CLOSE</button>
      </ng-template>
    </ejs-dialog>
    <!-- [enableResize]='isDraggable' -->
  </div>
</div>