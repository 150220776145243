import { Injectable } from '@angular/core';
// import { DialogComponent } from '../Components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }
}
