<div class="col-xs-12 notices-content">
    <div class="page-title col-xs-12"><span class="material-icons-outlined">announcement</span>
    {{ 'common.notices' | translate }}</div>
    <div class="col-xs-12 code-grid-content">
<!-- 
<h3>Notices are still having an issue with the foreign key for centreId</h3>
<h3>It looks like the data has to be in the datasource before the view is rendered.</h3> -->

      <ejs-grid [dataSource]='noticesGridData' #noticesGrid id="noticesGrid" [editSettings]='noticesGridEditSettings'
        [allowPaging]=false [allowSelection]="true" gridLines="Both" [allowSorting]="true" [allowTextWrap]="true"
        [allowResizing]="true" enableAltRow="true" (actionComplete)="noticesGridActionComplete($event)"
        [toolbar]="noticesGridToolbarSettings">
    
        <e-columns>
          <e-column headerText='' width='80' minWidth='80' maxWidth='80' [commands]='noticesGridCommands'></e-column>
          <e-column field="notice_Id" headerText="ID" [isPrimaryKey]="true" textAlign="Left" clipMode="ellipsiswithtooltip"
            width='80' minWidth='60' maxWidth='100' [allowEditing]='false' [visible]="false"></e-column>
          <e-column field="centreId" headerText="{{ 'common.centre' | translate }}" textAlign="Left"
            clipMode="ellipsiswithtooltip" [visible]="true" minWidth='50' maxWidth="200"><ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.centre" | translate }}
              </span>
            </ng-template></e-column>
          <e-column field="notice_Tx" headerText="{{ 'common.message' | translate }}" textAlign="Left"
            clipMode="ellipsiswithtooltip" minWidth='240'>
            <ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.message" | translate }}
              </span>
            </ng-template></e-column>
          <e-column field="start_Dt" headerText="{{ 'common.startDate' | translate }}" textAlign="Left"
            clipMode="ellipsiswithtooltip" width='120' minWidth='50' editType='datepickeredit' format="dd MMM yyyy"
            type="dateTime"><ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.startDate" | translate }}
              </span>
            </ng-template></e-column>
          <e-column field="end_Dt" headerText="{{ 'common.endDate' | translate }}" textAlign="Left"
            clipMode="EllipsisWithTooltip" format="dd MMM yyyy" type="dateTime" editType='datepickeredit' minWidth='100'
            width='130'><ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.endDate" | translate }}
              </span>
            </ng-template></e-column>
          <e-column field="order" headerText="{{ 'common.order' | translate }}" textAlign="Center"
            clipMode="ellipsiswithtooltip" minWidth='50' width='100' editType="NumericEdit" [edit]="{params:{decimals:0}}"
            format="N0"><ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.order" | translate }}
              </span>
            </ng-template></e-column>
          <e-column field="active" headerText="{{ 'common.Active' | translate }}" textAlign="Center"
            clipMode="ellipsiswithtooltip" width='100' minWidth='50' maxWidth="100" editType='booleanedit'
            displayAsCheckBox="true"><ng-template #headerTemplate>
              <span class="e-headertext">
                {{ "common.Active" | translate }}
              </span>
            </ng-template></e-column>
        </e-columns>
      </ejs-grid>

<!-- <h3>centres</h3>
      <ejs-grid [dataSource]="centres">          
      </ejs-grid>
<h3>testDataForCentres</h3>
      <ejs-grid [dataSource]="testDataForCentres">        
    </ejs-grid>
    <hr/> -->
    <!-- <div class="control-section">
      <ejs-grid
        #grid id="grid"
        [dataSource]="data"
        [allowPaging]="true"
        [allowSorting]="true"
        [editSettings]="editSettings"
        [toolbar]="toolbarItems"
    
      >
        <e-columns>
          <e-column
            field="OrderID"
            headerText="Order ID"
            width="120"
            textAlign="Right"
            isPrimaryKey="true"
          ></e-column>
          <e-column
            field="customerID"
            headerText="Customer Name"
            width="150"
            foreignKeyValue="ContactName"
            foreignKeyField="CustomerID"
            [dataSource]="customers"
          ></e-column>
          <e-column
            field="Freight"
            headerText="Freight"
            width="150"
            format="C2"
            textAlign="Right"
            editType="numericedit"
          ></e-column>
          <e-column field="ShipName" headerText="Ship Name" width="170"></e-column>
    
        </e-columns>
      </ejs-grid>
      <ejs-grid [dataSource]="customers"></ejs-grid>
      <ejs-grid [dataSource]="data"></ejs-grid>
    </div> -->
    </div>
  </div>