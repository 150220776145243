import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { OnlineService } from "../../Providers/OnlineService";
import { AppConstants } from "../../app.constants";
import { DashboardService } from "./../../Services/dashboard.service";
import { PermissionService } from "./../../Services/permission.service";
@Component({
  selector: "dashboard-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],

})
  
export class CounterComponent implements OnInit {

  @Input() Type: CounterType = CounterType.MyCases;
  @Input() PanelId: string = "";
  
  @Output() OnClosePanel = new EventEmitter();
  
  public DisplayValue: string = "Loading...";
  // public DisplayValueFontSize: Number = this.DisplayValueFontSizeForLoading;
  public Count: number = 0;
  public IsLoading: boolean = true;
  public Name: string = "not set";
  public Status: string = 'Starting';
  public detailsClick: string ;
  
  public SavedPanelId: string;

  public DisplayValueFontSize(): number  {
    if (this.IsLoading)
      return 10;
    else
      return 15;
  }

  constructor(public dashboardService: DashboardService, private onlineService: OnlineService, private _cookieService: CookieService,
    private permissionService: PermissionService) {
    //
  }
  ngOnInit(): void {
    this.SavedPanelId = this.PanelId;
    const caseSearch = {
      CentreId: parseInt(this._cookieService.get(AppConstants.CookieName.CenterId), 10),
      UserId: parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10),
    };
    switch (this.Type) {
      case CounterType.MyCases:
        this.Name = "common.myCases";
        this.detailsClick = "MyCasesBtnClick";
        this.dashboardService.getMyCaseCount().subscribe({
          next: (data) => { this.Count = data; },
          error: (data) => { this.Count = -1; this.Status = data; },
          complete: () => {
            {
              this.DisplayValue = this.Count.toString();
              this.IsLoading = false;
            }
          },
        });
        break;

      case CounterType.MyCentreCases:
        this.Name = "common.myCentreCases";
        this.dashboardService.getCentreCaseCount().subscribe({
          next: (data) => { this.Count = data; },
          error: (data) => { this.Count = -1; this.Status = data; },
          complete: () => {
            {
              this.DisplayValue = this.Count.toString();
              this.IsLoading = false;
              // this.DisplayValueFontSize = 33;
            }
          },
        });
        break;

      case CounterType.Responses:
        this.Name = "common.responseNeeded";
        this.dashboardService.getResponsesNeededCount().subscribe({
          next: (data) => { this.Count = data; },
          error: (data) => { this.Count = -1; this.Status = data; },
          complete: () => {
            {
              this.DisplayValue = this.Count.toString();
              this.IsLoading = false;
            }
          },
        });
        break;

      case CounterType.ReviewCases:
        this.Name = "common.reviewCases";
        this.dashboardService.getReviewNeededCount().subscribe({
          next: (data) => { this.Count = data; },
          error: (data) => { this.Count = -1; this.Status = data; },
          complete: () => {
            {
              this.DisplayValue = this.Count.toString();
              this.IsLoading = false;
            }
          },
        });
        break;

      case CounterType.Surveillance:
        this.Name = "common.surveillanceCases";
        this.onlineService.getHomePageCasesCount(caseSearch).subscribe((homeCasesCounts: any) => {
          this.Count = homeCasesCounts.reviewCaseCount;
          this.DisplayValue = this.Count.toString();
          this.IsLoading = false;
        });
        break;

      case CounterType.MyExposures:
        this.Name = "My Exposures";
        break;
      case CounterType.MyQueries:
        this.Name = "My Queries";
        break;
      case CounterType.NetworkCases:
        this.Name = "Network Cases";
        this.dashboardService.getNetworkCaseCount().subscribe({
          next: (data) => { this.Count = data; },
          error: (data) => { this.Count = -1; this.Status = data; },
          complete: () => {
            {
              this.DisplayValue = this.Count.toString();
              this.IsLoading = false;
            }
          },
        });
        break;
      default:
        alert("Invalid counter type:" + this.Type);
        
        break;
    }
  }

  closeThisPanel() {
    this.OnClosePanel.emit(this.PanelId); // return the panelId so the dashboard can find it.
  }

  viewDetails() {
    // if (!this.permissionService.isHomePageHasViewPermission) { return; }
    switch (this.Type) {
      case CounterType.MyCases:
        window.history.pushState('', 'home-usercases', '/home/mycases');
        window.location.href = '/home/mycases';
        break;

        case CounterType.NetworkCases:          
        window.history.pushState('', 'home-usercases', '/home/networkcases');
        window.location.href = '/home/networkcases';
        break;

        case CounterType.MyCentreCases:          
        window.history.pushState('', 'home-usercases', '/home/centercases');
        window.location.href = '/home/centercases';
        break;

        case CounterType.ReviewCases:          
        window.history.pushState('', 'home-usercases', '/home/reviewcases');
        window.location.href = '/home/reviewcases';
        break;

        case CounterType.Responses:          
        window.history.pushState('', 'home-usercases', '/home/responsecases');
        window.location.href = '/home/responsecases';
        break;    
      default:
        alert(this.Type + " " + this.Name + " is probably not hooked up right yet :) " );
        break;

    } 
  
  }
  

}

export enum CounterType {
  MyCases,
  MyExposures,
  MyQueries,
  MyCentreCases,
  NetworkCases,
  ReviewCases,
  Responses,
  Surveillance,
}

export const CounterTypeNames: string[] = [
  "My Cases",
  "My Exposures",
  "My Queries",
  "My Centre Cases",
  "Network Cases",
  "Cases for me to Review",
  "My Responses Needed",
  "My Surveillance Cases"
];

