
export interface MySubstance {
  mysuB_ID: number;
  MYSUB_NAME: string;
  MYSUB_DESC: string;
  MYSUB_PROD: string;
  MYSUB_GENERIC: string;
  MYSUB_CREATED: Date;
  MYSUB_CREATOR: number;
  MYSUB_STATUS: number;
  MYSUB_AMT_EACH: number;
}

export enum MySubstanceStatus {
  New = 1, Validated = 2, Bad = 3
}
