import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { AppConstants } from '../../app.constants';
import { OnlineService } from './../../Providers/OnlineService';
import { SubstanceSearchService } from './../../Services/substance-search.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'multi-substance-selector',
  templateUrl: './multi-substance-selector.component.html',
  styleUrls: ['./multi-substance-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSubstanceSelectorComponent implements OnInit, AfterViewInit {

  @ViewChild('msSubstances', { static: false }) msSubstancesObj: MultiSelectComponent;
  msSubstancesPlaceholder = this.translate.instant('common.typeAndSelectSubstanceNames');

  private initialSearchResultSize = 25;

  private searchPredicate: Predicate;

  public selectAllText: string = 'Select all';
  showNPRSwitch: boolean = true;
  showAMTSwitch: boolean = true;
  showMySubSwitch: boolean = true;
  showPDXSwitch: boolean = true;

  includeNPR: boolean = true;
  includeAMT: boolean = true;
  includePDX: boolean = false;
  includeMy: boolean = true;

  mode: string = 'Box';
  msSubstancesData: any[] = [];
  public selectedSubstances?: number[] = [];

  query: Query = new Query().take(20);

  isSubsLoaded: boolean = false;

  // maps the remote data column to fields property
  remoteFields: Object = { text: 'Prod_Name', value: 'Prod_Id' };

  constructor(
    public onlineService: OnlineService,
    public substanceSearchService: SubstanceSearchService,
    public translate: TranslateService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    const $this = this;
    console.log('new requesting Substance Search Items List');
    $this.substanceSearchService.getSubstanceSearchFromIndexedDB().then(
      function (arg: any) {
        console.log('new Got Substance Search Items List');
        $this.msSubstancesData = arg; // substance DS;
        $this.isSubsLoaded = true;
      },
      (err: HttpErrorResponse) => {
        const errorLog = {
          CustomErrorMessage: err.message,
          ErrorMessage: err.message,
          InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description,
        };
        if ($this.onlineService.CheckErrorStatus(err)) {
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
          });
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
          });
        }
      }
    );
  }

  public onOpen(args) {
    let start = 1;
    let end: number = this.initialSearchResultSize - 1;
    const listElement: HTMLElement = (this.msSubstancesObj as any).list;
    listElement.addEventListener('scroll', () => {
      if (listElement.scrollTop + listElement.offsetHeight >= listElement.scrollHeight - 1) {
        const filterQuery = new Query().where(this.searchPredicate); // start with the search predicate
        new DataManager(this.msSubstancesData)
          .executeQuery(filterQuery.range(start, end))
          .then((event: any) => {
            start = end;
            end += 10;
            this.msSubstancesObj.addItem(event.result as {
              [key: string]: Object;
            }[]);
          })
          .catch((e: Object) => { });
      }
    });
  }

  onFiltering(e) {
    console.log(e);
    let pred: Predicate;
    let subString;
    let query: Query = new Query();
    if (e.text.includes(' ') && e.text.charAt(e.text.length - 1) !== ' ') {
        subString = e.text.split(' ');
        subString.map((value) => {
          if (pred) {
            pred = pred.and('Prod_Name', 'contains', value, true, true);
          } else {
            pred = new Predicate('Prod_Name', 'contains', value, true, true);
          }
          pred = this.addSubstanceSourceFilter(pred);
        });
        query = query.where(pred).take(this.initialSearchResultSize);
    } else {
      this.searchPredicate = new Predicate('Prod_Name', 'contains', e.text, true);
      this.searchPredicate = this.addSubstanceSourceFilter(this.searchPredicate);
      query = (e.text !== '') ? query.where(this.searchPredicate).take(this.initialSearchResultSize) : query;
    }
    e.updateData(this.msSubstancesData, query);
  }

  addSubstanceSourceFilter(pred: Predicate): Predicate {
    if (!this.includeAMT) { pred = pred.and('Source', 'notequal', parseInt(AppConstants.SubstanceSearch.Type.AMT, 10)); }
    if (!this.includeNPR) { pred = pred.and('Source', 'notequal', parseInt(AppConstants.SubstanceSearch.Type.NPR, 10)); }
    if (!this.includeMy)  { pred = pred.and('Source', 'notequal', parseInt(AppConstants.SubstanceSearch.Type.MySubstances, 10)); }
    if (!this.includePDX) { pred = pred.and('Source', 'notequal', parseInt(AppConstants.SubstanceSearch.Type.PDX, 10)); }
    return pred;
  }
}
