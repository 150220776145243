export const data = [
    { OrderID: 10248, customerID: 'VINET', Freight: 32.38, ShipName: 'Vins et alcools Chevalier' },
    { OrderID: 10249, customerID: 'TOMSP', Freight: 11.61, ShipName: 'Toms Spezialitäten' },
    { OrderID: 10250, customerID: 'HANAR', Freight: 65.83, ShipName: 'Hanari Carnes' },
    { OrderID: 10251, customerID: 'VICTE', Freight: 41.34, ShipName: 'Victuailles en stock' },
    { OrderID: 10252, customerID: 'SUPRD', Freight: 51.3, ShipName: 'Suprêmes délices' }
  ];
  export const customers = [
    { CustomerID: 'VINET', ContactName: 'Paul Henriot' },
    { CustomerID: 'TOMSP', ContactName: 'Karin Josephs' },
    { CustomerID: 'HANAR', ContactName: 'Mario Pontes' },
    { CustomerID: 'VICTE', ContactName: 'Mary Saveley' },
    { CustomerID: 'SUPRD', ContactName: 'Pascale Cartrain' }
  ];
  export const centresTestData = [{centreId:22,centreName:'test centre',extra:'yes'}, {centreId:23,centreName:'test centre 2'}, {centreId:24,centreName:'test centre 3'}, {centreId:25,centreName:'test centre 4'}, {centreId:26,centreName:'test centre 5'},
  {centreId:27,centreName:'test centre 6'}, {centreId:28,centreName:'test centre 7'}, {centreId:29,centreName:'test centre 8'}, {centreId:30,centreName:'test centre 9'}, {centreId:31,centreName:'test centre 10'}];

