import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { openDB } from 'idb';
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from '../app.constants';

require('indexeddb-getall-shim');

@Injectable()
export class OfflineService {
  isAnyOfflineExposure: boolean;

  constructor(private router: Router, private _cookieService: CookieService) {
    this.isAnyOfflineExposure = false;
  }

  CheckIndexDBHasExposure() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readwrite").objectStore("Exposure");

          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }

          const exposureRowCount = transaction.count();
          exposureRowCount.onsuccess = ()=> {
            if (exposureRowCount.result > 0) {
              this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, "true", 1, "/",null,true,"Strict");
            } else {
              this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, "false", 1, "/",null,true,"Strict");
            }
            resolve(exposureRowCount.result);
          };
        } else {
          resolve(null); // no rows
        }
      };
    });
  }

  CheckIndexDBHasInfoRequest() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.QueryOfflineTableName, "readwrite").objectStore(AppConstants.QueryOfflineDBName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }

          const queryRowCount = transaction.count();
          queryRowCount.onsuccess = ()=> {
            if (queryRowCount.result > 0) {
              this._cookieService.set(AppConstants.CookieName.AnyOfflineQuery, "true", 1, "/",null,true,"Strict");
            } else {
              this._cookieService.set(AppConstants.CookieName.AnyOfflineQuery, "false", 1, "/",null,true,"Strict");
            }
            resolve(queryRowCount.result);
          };
        } else {
          resolve(null);
        }
      };
    });
  }

  AddExposureOfflineDB(exposureInfo) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);
      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.contains(AppConstants.ExposureOfflineTableName)) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readwrite");
          transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }

          const objectStore = transaction.objectStore(AppConstants.ExposureOfflineTableName);
          const exposureDetail = JSON.stringify(exposureInfo);
          objectStore.add({
            TempCaseNo: exposureInfo.TempCaseNo,
            IsTempExposure: exposureInfo.IsTempExposure,
            IsSyncStatus: AppConstants.SyncStatus.New,
            exposureDetail: exposureDetail
          });
          this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, "true", 1, "/", null, true, "Strict");
          resolve("success");
        } else {
          resolve("Database does not exist.");
        }
      };
    });
  }

  AddInfoRequestOfflineDB(infoRequestInfo) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.contains(AppConstants.QueryOfflineTableName)) {
          const transaction = db.transaction(AppConstants.QueryOfflineTableName, "readwrite");
          transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }
          const objectStore = transaction.objectStore(AppConstants.QueryOfflineTableName);
          const exposureDetail = JSON.stringify(infoRequestInfo);
          objectStore.add({
            TempCaseNo: infoRequestInfo.TempCaseNo,
            IsTempExposure: infoRequestInfo.IsTempExposure,
            IsSyncStatus: AppConstants.SyncStatus.New,
            exposureDetail: exposureDetail
          });
          this._cookieService.set(AppConstants.CookieName.AnyOfflineQuery, "true", 1, "/", null, true, "Strict");
          resolve("success");
        } else {
          resolve("Database does not exist.");
        }
      };
    });
  }

  GetOfflineExposureDetail(TempCaseNo) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readwrite").objectStore("Exposure");
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }
          const index = transaction.index("TempCaseNo");
          // var selectedRecord = index.getAll(TempCaseNo);
          const selectedRecord = index.get(TempCaseNo);
          selectedRecord.onsuccess = (event)=> {
            // var data = selectedRecord.result[0];
            const data = selectedRecord.result;
            resolve(data);
          };
        } else {
          resolve(null); // Not sure about this. needs testing
        }
      };
    });
  }

  GetOfflineInfoRequestDetail(TempCaseNo) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);
      request.onsuccess = function(evt: any) {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.QueryOfflineTableName, "readwrite").objectStore(AppConstants.QueryOfflineTableName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }
          const index = transaction.index("TempCaseNo");
          const selectedRecord = index.getAll(TempCaseNo);
          selectedRecord.onsuccess = function(event) {
            const data = selectedRecord.result[0];
            resolve(data);
          };
        } else {
          resolve(null);
        }
      };
    });
  }

  GetAllOfflineExposure() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readonly").objectStore(AppConstants.ExposureOfflineTableName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }
          const selectedRecord = transaction.getAll();
          selectedRecord.onsuccess = (event)=> {
            const data = selectedRecord.result;
            resolve(data);
          };
        } else {
          resolve(null);
        }
      };
    });
  }
  /**
   * Gets all off line exposures
   * @returns a list of exposures
   * written to replace codeback hell in old GetAllOffLineExposure()! 
   */
  async GetAllOfflineExposures() {
    try {
      const db = await openDB(AppConstants.ExposureOfflineDBName, 1);
      const tx = db.transaction(AppConstants.ExposureOfflineTableName, 'readonly');
      const store = tx.objectStore(AppConstants.ExposureOfflineTableName);
      const allSavedItems = await store.getAll();
      db.close();
      return allSavedItems;
    } catch (error) {
      return error;
    }
  }

  GetAllOfflineQueries() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);
      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.QueryOfflineTableName, "readonly").objectStore(AppConstants.QueryOfflineTableName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }

          const selectedRecord = transaction.getAll();
          selectedRecord.onsuccess = (event)=> {
            const data = selectedRecord.result;
            resolve(data);
          };
        } else {
          resolve(null);
        }
      };
    });
  }
  GetOfflineExposureDetailByCallId(callId) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readonly").objectStore(AppConstants.ExposureOfflineTableName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }
          const selectedRecords = transaction.getAll();
          selectedRecords.onsuccess = (event)=> {
            const offlinePatientList = [];
            const initialData = selectedRecords.result;
            initialData.forEach((offlineExpo) => {
              const exposureDetails = JSON.parse(offlineExpo.exposureDetail);
              if (exposureDetails.callInfo.callId == callId) {
                const primarySub = exposureDetails.substanceList.find(item => item.substanceOrder === 1);
                let primarySubName = null;
                if (primarySub != undefined && primarySub != null) {
                  primarySubName = primarySub.substanceName;
                }

                const patientRec = {
                  ctrId: exposureDetails.callInfo.centreId,
                  exposureId: exposureDetails.exposureId,
                  caseId: exposureDetails.callInfo.caseNumber,
                  exposureStatus: exposureDetails.exposureStatusName,
                  patientGender: exposureDetails.patientGenderName,
                  patientAge: exposureDetails.patientAge + ' ' + exposureDetails.ageUnitName,
                  PatientName: exposureDetails.patientFirstName + ' ' + exposureDetails.patientLastName,
                  substanceName: primarySubName,
                  exposureLock: ''
                }
                offlinePatientList.push(
                  patientRec
                );
              }
            });
            resolve(offlinePatientList);
          };
        } else {
          resolve(null); // Not sure about this. needs testing
        }
      };
    });
  }

  UpdateOfflineExposure(exposureInfo) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {

          const objectStoreHandler = db.transaction(AppConstants.ExposureOfflineTableName, 'readwrite').objectStore(AppConstants.ExposureOfflineTableName);
          const indexHandler = objectStoreHandler.index('TempCaseNo');
          const selectedRecord = indexHandler.get(exposureInfo.TempCaseNo);

          const exposureDetail = JSON.stringify(exposureInfo);

          selectedRecord.onsuccess = (event)=> {
            if (selectedRecord.result != undefined && selectedRecord.result != null) { // event.target.result.length > 0) {
              //const data = selectedRecord.result[0];
              const data = selectedRecord.result;
              const id = data.id;
              const updateRecord = {
                id: data.id,
                TempCaseNo: exposureInfo.TempCaseNo,
                IsTempExposure: exposureInfo.IsTempExposure,
                IsSyncStatus: AppConstants.SyncStatus.Updated,
                exposureDetail: exposureDetail
              };
              // objectStoreHandler.put(updateRecord);
              // Put this updated object back into the database.
              const requestUpdate = objectStoreHandler.put(updateRecord);
               requestUpdate.onerror = (event)=> {
                 // Do something with the error
                resolve('Failed to update existing case in the offline database.');
               };
               requestUpdate.onsuccess = (event)=> {
                 // Success - the data is updated!
                resolve('success');
               };

            } else {
              const requestAddition = objectStoreHandler.add({
                TempCaseNo: exposureInfo.TempCaseNo,
                IsTempExposure: exposureInfo.IsTempExposure,
                IsSyncStatus: AppConstants.SyncStatus.New,
                exposureDetail: exposureDetail
              });
              requestAddition.onerror = (event)=> {
                // Do something with the error
                resolve('Failed to add existing case to the offline database.');
              };
              requestAddition.onsuccess = (event)=> {
                // Success - the data is updated!
                this._cookieService.set(AppConstants.CookieName.AnyOfflineExpo, "true", 1, "/",null,true,"Strict");
                resolve('success');
              };
            }
          };
          selectedRecord.onerror = (event)=> {
            resolve('An error occurred query the offline database for this case, please try again, if the issue continues consult your administrator.');
            //AddExposureOfflineDB(exposureInfo)
          };
        } else {
          resolve('Database does not exist.');
        }
      };
      /*
      request.onblocked = function (event) {
          db = "blocked...";
      };

      request.onerror = function (event) {
          db = "Error...";
      };
      */
    });
  }

  UpdateOfflineInfoRequest(infoRequestInfo){
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);

      request.onsuccess = function(evt: any) {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.QueryOfflineTableName, "readwrite").objectStore(AppConstants.QueryOfflineTableName);
          const index = transaction.index("TempCaseNo");
          const selectedRecord = index.getAll(infoRequestInfo.TempCaseNo);
          selectedRecord.onsuccess = function(event) {
            if (selectedRecord.result.length > 0) {
              const data = selectedRecord.result[0];
              const id = data.id;
              const infoRequestDetail = JSON.stringify(infoRequestInfo);
              const updateRecord = {
                id: data.id,
                TempCaseNo: infoRequestInfo.TempCaseNo,
                IsTempExposure: infoRequestInfo.IsTempExposure,
                IsSyncStatus: AppConstants.SyncStatus.Updated,
                exposureDetail: infoRequestDetail
              };
              transaction.put(updateRecord);
              resolve("success");
            } else {
              const infoRequestDetail = JSON.stringify(infoRequestInfo);
              transaction.add({
                TempCaseNo: infoRequestInfo.TempCaseNo,
                IsTempExposure: infoRequestInfo.IsTempExposure,
                IsSyncStatus: AppConstants.SyncStatus.New,
                exposureDetail: infoRequestDetail
              });
              resolve("success");
            }
          };
        } else {
          resolve("Database does not exist.");
        }
      };
    });
  }

  async DeleteOfflineExposure(CaseNoToDelete: IDBValidKey | IDBKeyRange) {
    console.log('Delete Offline Exposure ' + CaseNoToDelete + ' requested.');
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);

      request.onsuccess = (evt: any) => {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readwrite").objectStore(AppConstants.ExposureOfflineTableName);
          const index = transaction.index("TempCaseNo");
          // convert to string, as index is string
          CaseNoToDelete = CaseNoToDelete.toString();
          
          const selectedRecord = index.getAll(CaseNoToDelete);
          selectedRecord.onsuccess = (event) => {
            console.log('Delete Offline Exposure ' + CaseNoToDelete + ': Found ' + selectedRecord.result.length + ' records in Exposure Offline Store.');
            if (selectedRecord.result.length > 0) {              
            const id = selectedRecord.result[0].id;
              if (id != null) {
                const deleteSelectedRecord = transaction.delete(id);
                deleteSelectedRecord.onsuccess = (event) => {
                  console.log('Delete Offline Exposure ' + CaseNoToDelete + ': Success.')
                  resolve(true);
                };

                deleteSelectedRecord.onerror = (event) => {
                  console.log('Delete Offline Exposure ' + CaseNoToDelete + ': error:' + event.type)
                  resolve(event);
                }
              }
            } 
            // resolve(false); Don't resolve here, wait till the delete occurs 
          };
        } else {
          resolve(false);
        }
      };
    });
  }

  UpdateExposureSyncStatus() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);
      var OfflineData = [];
      request.onsuccess = function(evt: any) {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineTableName, "readwrite").objectStore(AppConstants.ExposureOfflineTableName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }

          const crequest = transaction.openCursor();
          crequest.onsuccess = function(event) {
            const cursor = this.result;
            if (cursor) {
              OfflineData.push(cursor.value);
              const index = transaction.index("TempCaseNo");
              const selectedRecord = index.getAll(cursor.value.TempCaseNo);
              selectedRecord.onsuccess = function() {
                const data = selectedRecord.result[0];
                data.IsSyncStatus = AppConstants.SyncStatus.InProgress;
                transaction.put(data);
              };

              cursor.continue();
            } else {
              resolve(OfflineData);
            }
          };
        } else {
          resolve(OfflineData);
        }
      };
    });
  }

  UpdateInfoRequestSyncStatus() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.QueryOfflineDBName, 1);
      const OfflineData = [];
      request.onsuccess = function(evt: any) {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.QueryOfflineTableName, "readwrite").objectStore(AppConstants.QueryOfflineTableName);
          const request = transaction.openCursor();
          request.onsuccess = function(event) {
            const cursor = this.result;
            if (cursor) {
              OfflineData.push(cursor.value);
              const index = transaction.index("TempCaseNo");
              const selectedRecord = index.getAll(cursor.value.TempCaseNo);
              selectedRecord.onsuccess = function(event) {
                const data = selectedRecord.result[0];
                data.IsSyncStatus = AppConstants.SyncStatus.InProgress;
                transaction.put(data);
              };

              cursor.continue();
            } else {
              db.close();
              resolve(OfflineData);
            }
          };
        } else {
          db.close();
          resolve(OfflineData);
        }
      };
    });
  }

  UpdateErrorStatus(TempCaseNo, errorMessage) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AppConstants.ExposureOfflineDBName, 1);
      const OfflineData = [];
      request.onsuccess = function(evt: any) {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transaction = db.transaction(AppConstants.ExposureOfflineDBName, "readwrite").objectStore(AppConstants.ExposureOfflineTableName);
          transaction.transaction.oncomplete = () => {
            db.close(); // close our connection to prevent blocking!
          }
          const index = transaction.index("TempCaseNo");
          const selectedRecord = index.getAll(TempCaseNo);
          selectedRecord.onsuccess = function() {
            const data = selectedRecord.result[0];
            data.IsSyncStatus = AppConstants.SyncStatus.Error;
            data["errorMessage"] = errorMessage;
            transaction.put(data);
            resolve(true);
          };
        } else {
          resolve(false);
        }
      };
    });
  }
}
