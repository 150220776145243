import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { OnlineService } from "./../../Providers/OnlineService";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  constructor(
    public onlineService: OnlineService,
    private titleService: Title
  ) {}

  public copyRightYear: string = "";
  public applicationVersion: string = " Checking... ";
  public assemblyVersion: string = " Retrieving Version... ";

  ngOnInit() {
    this.applicationVersion = environment.version;
    this.copyRightYear = "2017-" + new Date().getFullYear();
  }

  ngAfterViewInit() {
    this.onlineService.removeFocussedUrl();
  if(navigator.onLine) {
    this.onlineService.GetAssemblyVersion().subscribe(
      (result: any) => {
        this.assemblyVersion = "V " + result;
      },
      (err: HttpErrorResponse) => {
        if(err.status != 0 && err.status != 404 && err.status != 504) {
        const errorLog = {
          CentreId: null,
          LoggedByUserId: null,
          CustomErrorMessage: err.message,
          ErrorMessage: err.message,
          InnerException: (err.error != null && err.error.error_description == undefined && err.error.error_description == null)? null: err.error.error_description
        };
          this.onlineService.AddErrorLog(errorLog).subscribe(() => {
            console.log("Error logged:" + errorLog);
        });
       }
      }
    );
    }

  }
}
