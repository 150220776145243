<div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 management-rowgrids4 section-effects">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 management-subsection-header scenario-editicon scenario-edit-icon"
    id="scenarioIcon" title="Add/Edit Scenarios">
    <div class="col-xs-3" [ngStyle]="{'cursor' : isEditExposure ? 'pointer ' : 'default' }"
      (click)="onCreateScenarioClick()">
      <div class="icon-scenario TTES-Cls"></div>
      <div class="management-subsection-info-header">{{ 'common.Scenarios' | translate }}</div>
    </div>
    <div class="scenario-edit col-xs-9" *ngIf='!isViewExposure'>
      <div class="search-scenario">
        <form><!-- The autocomplete needs to be in it's own form to prevent issues with the parent form firing a delete -->
        <ejs-autocomplete id="acs" #acs [dataSource]="acData" placeholder='{{ "common.searchAndSelect" | translate }}' [fields]="acFields" highlight="true" floatLabelType="Always"
          (select)="acSelected($event)" (change)="acChanged($event)" [allowCustom]=false [autofill]=true [showPopupButton]=true
          (customValueSpecifier)="acEnterPressed($event)" popupWidth="auto" title="{{ 'common.searchByTypingTheNameOrPressTheCaretToOpenAList' | translate }}">
          <ng-template #groupTemplate="" let-data="">
            <!--set the value to groupTemplate property-->
            <span class="groupHeader">{{data.group}}</span>
          </ng-template>
          <ng-template #noRecordsTemplate>
            <span class='norecord'>{{ 'common.noMatchesFound' | translate }}, {{ 'common.pleaseRemoveSomeCharactersAndTryAgain' | translate }}. {{ 'common.alreadySelectedItemsAreNotAvailable' | translate }}.</span>
          </ng-template>
        </ejs-autocomplete>
      </form><!-- The autocomplete needs to be in it's own form to prevent issues with the parent form firing a delete -->
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 scenario-section">
    <ejs-grid #scenarioGrid id="scenarioGridId" [dataSource]='scenarioGridData' class="scenario-datagrid"
      [allowPaging]="false" gridLines='Both' width=100% [allowTextWrap]='true' 
      [pageSettings]="pageSettings" allowResizing="false"
      [editSettings]="editSettings" (actionComplete)='gridActionBegin($event)'
      (actionBegin)='actionBegin($event)' enableAltRow='true'>
      <e-columns>
        <e-column field='scenarioId' textAlign='Left' allowEditing='false'
          [commands]="commands" [visible]="!isViewExposure" [width]="20"></e-column>
        <e-column field='scenarioId' [visible]="false" textAlign='Left'
          clipMode='ellipsiswithtooltip' isPrimaryKey='true'></e-column>
        <e-column field='scenarioName' headerText="Scenario" textAlign='Left' clipMode='ellipsiswithtooltip'
            ></e-column>
      </e-columns>
      <ng-template #emptyRecordTemplate>
        <div><span>{{ 'common.noScenariosToDisplay' | translate }}</span>
        </div>
    </ng-template>
    </ejs-grid>
  </div>
</div>

