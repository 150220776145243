<style>
  .e-grid .e-rowcell.customcss{
    vertical-align: top;
    padding-top: 5px;
  }
  .processRecalldisabled{
    opacity: 0.6;
    cursor: not-allowed;
  }
</style>
<div #recallContainer class="recall-container col-12 lab-margin section-effects ">
  <div class="col-12 recall-info">
    <div class="col-12 recall-margin">
      <form action="" #recallForm="ngForm" name="recallForm"
        class="col-12 recall-form">
        <div class="col-12 recall-header"
          [ngStyle]="{'cursor' : isEditCase ? 'pointer ' : 'default' }" (click)='onCreateRecallClick()'>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 recall-info-header" id="call-head">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 recall-info-header-sections">
              <div class="material-icons hidden-xs">call</div>
              <div #recallHeader class="info-header hidden-xs">{{"common.recalls" |translate }}</div>
            </div>
          </div>
          <div class="col-xs-6 mobilePlacerRecall visible-xs">
            <div class="innerclass">
              <span class="mobexpo-icon material-icons ">call</span>
              <div #callerHeader class="mob-decorate-recall">{{"common.recalls" | translate }}</div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 recall-add-icons" style="margin-top: 18px;">
            <div class="recall-icons-section">
              <div class="add-icon-align" (click)='onCreateRecallClick()'>
                <div class="case-add-bg">
                  <button class="icon-T_Add recall-add focusableTwo"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 recall-info-row1 recall-pm-0 margin0 padding0">
          <div class="col-md-12 col-sm-12 col-xs-12 recall-pm-0 padding0">
            <ejs-grid #recallGrid [dataSource]='recallsGridData' id="recallGridId" class="recall-datagrid"
              gridLines='Both'  [allowExcelExport]='true' [allowSorting]="true" [allowMultiSorting]='true' [sortSettings]='sortOptions'
                (dataBound)='gridDataBound("recallGridObj")'
              [allowReordering]="true" [allowResizing]="true" (recordClick)='recallClick($event)' [selectionSettings]='selectionSettings'
              (actionComplete)='recallUnsaveDetection($event)' enableAltRow='true' (load)='loadRecallData($event)'
              (queryCellInfo)='queryCellInfoEvent($event)' (dataBound)='onRecallGridDataBound($event)'>
              <e-columns>
                <e-column headerText="" minWidth="10" width='8' headerTextAlign='center' [visible]="isEditCase">
                  <ng-template #template let-recallsGridData>
                    <div>
                       <button type="button" ngClass="(recallsGridData.recallStatusId !== AppConstants.RecallStatus.Closed || !isEditCase) ? 'processRecalldisabled':''"
                        class="e-lib e-btn e-control e-flat e-icon-btn e-edit-delete e-editbutton substance-icon-css"
                        title="Click to complete the Recall"
                        (click)="processRecall($event, recallsGridData)">
                         <span class="substance-icon-css e-icons e-edit"></span>
                       </button>
                    </div>
                 </ng-template>
                </e-column>
                <e-column field='tempRecallId' headerText="Id" textAlign='Left'
                  clipMode='ellipsiswithtooltip' [visible]="false"></e-column>
                <e-column field='recallId' headerText="RecallId" isPrimary='true' textAlign='Left'
                  clipMode='ellipsiswithtooltip' [visible]="false"></e-column>

                <e-column field='formattedRecallDateTime' headerText="Recall date/time"
                  [customAttributes]="customAttributes" textAlign='Right' width=40 clipMode='ellipsiswithtooltip'></e-column>
                <e-column field='recallNote' headerText="Recall note" textAlign='Left'
                  [customAttributes]="customAttributes" clipMode='ellipsiswithtooltip'></e-column>
                <e-column field='recallTargetName' headerText="Notification targets" textAlign='Left'
                  [customAttributes]="customAttributes" clipMode='ellipsiswithtooltip'width=80></e-column>
                <e-column field='recallPriorityName' headerText="Priority" textAlign='center'
                  [customAttributes]="customAttributes" width=30 clipMode='ellipsiswithtooltip'></e-column>
                <e-column field='recallStatusName' headerText="Status" textAlign='center'
                  [customAttributes]="customAttributes" width=40 clipMode='ellipsiswithtooltip'></e-column>
                <e-column field='formattedCompletedRecallDateTime' headerText="Completed"
                  [customAttributes]="customAttributes" textAlign='center' width=35 clipMode='ellipsiswithtooltip'>
                  <ng-template #template let-recallsGridData>
                    <span
                    *ngIf="(recallsGridData.recallStatusId == 5276)">{{recallsGridData.formattedCompletedRecallDateTime}}</span>
                  </ng-template>
                  </e-column>
                <e-column field='recallEnteredByName' headerText="Created by"
                  [customAttributes]="customAttributes" textAlign='center' width=70 clipMode='ellipsiswithtooltip'></e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ejs-dialog #addEditDialog id="addEditDialog" draggable="true" [allowDragging]="true" [isModal]='true' [minHeight]="recallDlgMinHeight"
  [animationSettings]='animationSettings' showCloseIcon='true' [enableResize]="true" [visible]='false'
  (close)="onCancelButtonClick()" (beforeClose)="editDialogBeforeClose($event)" (open)="onDialogOpen()" [width]='dialogWidth' class="addEditDialog">
  <ng-template #header>
    <div class="material-icons" style="
      margin-left: 8px;
      margin-top: 8px;
      margin-right: 8px;
      margin-bottom: 1px;
      float:left;">call</div>
    <div class="modal-title1">
      {{addEditDialogTitle}}
    </div>
  </ng-template>
  <ng-template #content>
    <form action="" #addEditForm="ngForm" name="addEditForm">
      <div class="modal-body recall-dialog-body" style="padding-bottom: 0 !important;">
        <div id="createCommentsDialogContent" >
          <div class="form-group mob-addrecall" style="display:flex; flex-flow: row wrap; justify-content:flex-start;">
            <div style="width: 210px; margin-right: 5px;">
              <ejs-datetimepicker #recallDateTimePicker id="recallDateTime" name="recallDateTime"
                [format]="DateTimeFormat" [timeFormat]="TimeFormat"
                [(value)]="currentRecall.recallDateTime"
                placeholder="Recall Date/Time" title="Please enter when this Recall notification should sent"
                cssClass="call-date" floatLabelType="Always"
                (change)="controlChange()" strictMode="true"></ejs-datetimepicker >
            </div>
            <div style="display:flex; max-width: 310px; min-width: 250px;">
              <div class="" style="margin-right: 5px;">
                <ejs-dropdownlist
                  #ddlPresetHours id='ddlPresetHours' name="ddlPresetHours"
                  [fields]='ddlFields' [dataSource]="ddlPresetHoursData"
                  [placeholder]="ddlPresetHoursPlaceholder"
                  floatLabelType='Always' popupWidth="Auto"
                  (change)='onddlPresetHoursChange($event)'
                  (open)="onddlPresetHoursOpen()">
                </ejs-dropdownlist>
              </div>
              <div class="">
                <ejs-dropdownlist
                  #ddlPresetDays id='ddlPresetDays' name="ddlPresetDays"
                  [fields]='ddlFields' [dataSource]="ddlPresetDaysData"
                  [placeholder]="ddlPresetDaysPlaceholder"
                  floatLabelType='Always' popupWidth="Auto"
                  (change)='onddlPresetDaysChange($event)'
                  (open)="onddlPresetDaysOpen()">
                </ejs-dropdownlist>
              </div>
            </div>
          </div>

          <div class="col-12 form-group mob-addrecall">
            <div>
              <label class="agentText comment-type" for="name" title="Select users to send reminders via notifications to recall the patient about this case">Notification Targets</label>
            </div>
            <div>
              <ejs-multiselect
                #msRecallTargets id='msRecallTargets'
                [dataSource]='ddlTargetUserData' [(value)]="currentRecall.recallTargetIdArray" [placeholder]='ddlTargetUserPlaceholder' [fields]='ddlRecallTargetsFields'
                [mode]='mode' [selectAllText]='selectAllText' showSelectAll='true' (change)="controlChange()">
              </ejs-multiselect>
            </div>
          </div>

          <div class="col-12 form-group mob-addrecall">
            <div>
              <label class="agentText comment-type" for="name">Enter any notes for the recall (max. 512 chars)</label>
            </div>
            <div>
              <style>
                #tbxRecallNotes textarea {
                  resize: none;
                }
              </style>
              <ejs-textbox #tbxRecallNotes id="tbxRecallNotes" name="tbxRecallNotes" [(value)]="currentRecall.recallNote" [multiline]='true' maxlength='512'
                value= 'currentRecall.recallNote'
                (input)='resetRecallNotesTbx($event)' (change)="controlChange()"></ejs-textbox>
            </div>
          </div>

          <div class="form-group mob-addrecall col-lg-6 col-md-6 col-sm-12 col-xs-12" style="width: 100px;">
            <ejs-dropdownlist
              #ddlRecallPriority id='ddlRecallPriority' name="ddlRecallPriority"
              [fields]='ddlFields' [dataSource]="ddlRecallPriorityData" [(value)]="currentRecall.recallPriorityId"
              [placeholder]="ddlRecallPriorityPlaceholder" (change)="controlChange()"
              floatLabelType='Always' popupWidth="Auto">
            </ejs-dropdownlist>
          </div>

          <div class="form-group mob-addrecall col-lg-6 col-md-6 col-sm-12 col-xs-12" style="margin-left: 1em;">
            <div class="">
              <label class="agentText comment-type" for="name">Status</label>
            </div>
            <div class="">
              {{recallStatusName}}
            </div>
          </div>

          <div *ngIf="currentRecall.recallDateTime == null || currentRecall.recallTargetIdArray.length == 0 || currentRecall.recallPriorityId == null" 
            class="col-12 form-group mob-addrecall">
            <div>
              <label class="mandatoryText comment-type" for="name">
                The following field(s) must be populated:
                {{currentRecall.recallDateTime == null ? ' - Recall Date/Time' : ''}} 
                {{currentRecall.recallTargetIdArray.length == 0 ? ' - Notification Targets' : ''}}
                {{currentRecall.recallPriorityId == null ? ' - Priority' : ''}}
              </label>
            </div>
          </div>

        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #footerTemplate>
    <button ejs-button id="btnDelete" *ngIf="isAddRecall == false" class="generic-dialog-btn pull-left"
      (click)="onDeleteButtonClick()" data-ripple="true">Delete</button>
    <button ejs-button id="addEditCancel" class="generic-dialog-btn"
      (click)="onCancelButtonClick()" data-ripple="true">Cancel</button>
    <button ejs-button id="addEditOk" class="dialog-ok-btn"
      (click)="onOkButtonClick()" data-ripple="true"
      [disabled]="(currentRecall.recallDateTime == null || currentRecall.recallTargetIdArray.length == 0 || currentRecall.recallPriorityId == null || !addEditModalFormObj.dirty)">{{addUpdateBtnText}}</button>
  </ng-template>
</ejs-dialog>

<style>
  #processDialog + .e-dlg-overlay {
    background-color: slategray;
    opacity: 0.1;
  }
</style>

<ejs-dialog #processDialog id="processDialog" draggable="true" [allowDragging]="true" [isModal]='true'
   [animationSettings]='animationSettings' showCloseIcon='true' [visible]='false'
  (close)="onProcessDialogClose()" [width]="(currentRecall != null && currentRecall.recallNote != null && currentRecall.recallNote != '') ? dialogWidth : 300">
  <ng-template #header>
    <div class="icon-recall" style="
              margin-left: 1px;
              margin-top: 1px;
              float:left;
              padding-right: 15px; "></div>
    <h4>Complete the Recall</h4>
  </ng-template>
  <ng-template #content>
    <div *ngIf="currentRecall != null && currentRecall.recallNote != null && currentRecall.recallNote != ''" class="modal-body recall-dialog-body">
      <div id="createCommentsDialogContent">
        <div class="col-md-12 col-sm-12 form-group mob-addrecall">
          <div class="col-md-12 col-sm-12">
            <label class="agentText comment-type" for="name">Note</label>
          </div>
          <div class="col-md-12 col-sm-12">
            {{currentRecall.recallNote}}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <button ejs-button id="processBtnCancel" class="generic-dialog-btn"
      (click)="onProcessCancelButtonClick()" data-ripple="true">Cancel</button>
    <button ejs-button id="processBtnOk" class="dialog-ok-btn"
      (click)="onProcessOkButtonClick()" data-ripple="true">Completed</button>
  </ng-template>
</ejs-dialog>
