import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxSpinnerComponent, NgxSpinnerModule } from 'ngx-spinner';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule, DateRangePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AutoCompleteModule, DropDownListModule, DropDownTreeModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ColumnChooserService, ContextMenuService, DetailRowService, EditService, ExcelExportService, FilterService, GridAllModule, GroupService, PageService, PdfExportService, ReorderService, ResizeService, SearchService, SelectionService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
// import { HttpModule } from '@angular/http';





import { AccumulationChartAllModule, AreaSeriesService, ChartAllModule, DateTimeService } from '@syncfusion/ej2-angular-charts';
import { ColorPickerModule, MaskedTextBoxModule, NumericTextBoxModule, TextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { MapsAllModule } from '@syncfusion/ej2-angular-maps';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
// import the DashboardLayoutModule for the Dashboard Layout component
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { QueryBuilderModule } from "@syncfusion/ej2-angular-querybuilder";

import { PendingChangesGuard } from './../auth/Pending-Changes-Guard';
import { AuthGuardService as AuthGuard } from './../auth/auth-guard.service';
import { MyHttpInterceptor } from './../httpinterceptor';

import { CaseNoLocksDialogComponent } from './../Components/case-nolocks-dialog/case-nolocks-dialog.component';
import { CaseRecallComponent } from './../Components/case-recall/case-recall.component';
import { CaseValidationComponent } from './../Components/case-validation/case-validation.component';
import { GenericSubstancePickerComponent } from './../Components/generic-substance-picker/generic-substance-picker.component';
import { MultiSubstanceSelectorComponent } from './../Components/multi-substance-selector/multi-substance-selector.component';
import { NoticeListComponent } from './../Components/notice-list/notice-list.component';
import { ScenarioComponent } from "./../Components/scenario/scenario.component";
import { SubstanceDetailComponent } from './../Components/substance-detail/substance-detail.component';
import { SubstanceSearchComponent } from './../Components/substance-search/substance-search.component';
import { SubstanceTreeComponent } from './../Components/substance-tree/substance-tree.component';

import { TranslateModule } from '@ngx-translate/core';

// Dashboard components
import { ChartComponent } from './../dashboard-components/chart/chart.component';
import { CounterComponent } from './../dashboard-components/counter/counter.component';
import { MapComponent } from './../dashboard-components/map/map.component';

@NgModule({
  declarations: [
    GenericSubstancePickerComponent,
    NoticeListComponent,
    SubstanceSearchComponent,
    SubstanceTreeComponent,
    CaseValidationComponent,
    CaseNoLocksDialogComponent,
    CaseRecallComponent,
    ScenarioComponent,
    MultiSubstanceSelectorComponent,
    SubstanceDetailComponent,
    // Dashboard elements
    CounterComponent,
    ChartComponent,
    MapComponent,
  ],
  imports: [
    CommonModule,
    GridAllModule,
    DropDownListModule,
    FormsModule,
    ReactiveFormsModule,
    CheckBoxModule,
    ButtonModule,
    SwitchModule,
    RadioButtonModule,
    DateTimePickerModule,
    DatePickerModule,
    AccordionModule,
    // HttpModule,
    HttpClientModule,
    AutoCompleteModule,
    RichTextEditorAllModule,
    MapsAllModule,
    TextBoxModule,
    MaskedTextBoxModule,
    NumericTextBoxModule,
    NgxSpinnerModule,
    DateRangePickerModule,
    UploaderModule,
    MultiSelectModule,
    ToastModule,
    DialogModule,
    TreeViewModule,
    ColorPickerModule,
    DropDownTreeModule,
    DashboardLayoutModule,
    ChartAllModule,
    AccumulationChartAllModule,
    QueryBuilderModule,

    TranslateModule.forChild({ extend: true }), // this extends the parent module's translation data to components in this module
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true
    },
    AuthGuard,
    PendingChangesGuard,
    EditService,
    PageService,
    SortService,
    FilterService,
    GroupService,
    ReorderService,
    ResizeService,
    ContextMenuService,
    PdfExportService,
    ToolbarService,
    SearchService,
    SelectionService,
    DetailRowService ,
    ColumnChooserService,
    AreaSeriesService,
    DateTimeService,
    ExcelExportService
  ],
  exports: [
    NoticeListComponent,
    GenericSubstancePickerComponent,
    SubstanceSearchComponent,
    SubstanceTreeComponent,
    CaseValidationComponent,
    CaseNoLocksDialogComponent,
    ScenarioComponent,
    CaseRecallComponent,
    MultiSubstanceSelectorComponent,
    SubstanceDetailComponent,
    CommonModule,
    GridAllModule,
    DropDownListModule,
    DropDownTreeModule,
    FormsModule,
    ReactiveFormsModule,
    CheckBoxModule,
    ButtonModule,
    SwitchModule,
    RadioButtonModule,
    DateTimePickerModule,
    DatePickerModule,
    AccordionModule,
    // HttpModule,
    HttpClientModule,
    AutoCompleteModule,
    RichTextEditorAllModule,
    TextBoxModule,
    MaskedTextBoxModule,
    MapsAllModule,
    NumericTextBoxModule,
    NgxSpinnerModule,
    DateRangePickerModule,
    UploaderModule,
    MultiSelectModule,
    ToastModule,
    DialogModule,
    TreeViewModule,
    ColorPickerModule,
    
    CounterComponent,
    ChartComponent,
    MapComponent,

    ChartAllModule,
    AccumulationChartAllModule,
    DashboardLayoutModule,
    QueryBuilderModule,

    NgxSpinnerComponent,

    TranslateModule,
   ]
})

export class SharemoduleModule { }
