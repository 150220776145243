import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { AppInsightsService } from '../../../Services/app-insights.service';
import { ThemeService } from '../../../Services/theme.service';
import { OfflineService } from './../../../Providers/OfflineService';
import { OnlineService } from './../../../Providers/OnlineService';
import { ToasterService, ToasterValue } from './../../../Providers/ToasterService';
import { SubstanceSearchService } from './../../../Services/substance-search.service';
import { AppConstants } from './../../../app.constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, AfterViewInit {
  returnUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public onlineService: OnlineService,
    public offlineService: OfflineService,
    private _cookieService: CookieService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private appInsightsService: AppInsightsService,
    public toastService: ToasterService,
    public themeService: ThemeService,
    public substanceSearchService: SubstanceSearchService,
    public translate: TranslateService
  ) {
  }

  public AvailableLanguages = AppConstants.LanguageChoices;
  public languageDDfields = { text: 'text', value: 'value' };
  public currentLanguage: string;

  @ViewChild('rememberPassword', { static: false })
  public rememberPasswordObj: CheckBoxComponent;
  @ViewChild('rememberComputer', { static: false })
  public rememberComputerObj: CheckBoxComponent;
  @ViewChild('rememberPasswordMobile', { static: false })
  public rememberPasswordMobileObj: CheckBoxComponent;
  @ViewChild('rememberComputerMobile', { static: false })
  public rememberComputerMobileObj: CheckBoxComponent;

  public primaryColorValue: string = '#f0BFD8';
  public primaryFontColorValue: string = '#232729';
  public secondaryColorValue: string = '#f0fee9';

  public BusyMessage: string = this.translate.instant('common.loading');
  public userName: string = '';
  public password: string = '';
  public invalidLoginError: boolean = false;
  public loginBtnActive: boolean = false;
  public cookieExpirationMaximumInDays: number = 365;
  public cookieExpirationMinimumInDays: number = 1;

  // forgot password page changes
  public showForgotPasswordPage: boolean = false;
  public showCodeVerifyPage: boolean = false;
  public showConfirmPasswordPage: boolean = false;

  public email: string = '';
  public code: string = '';
  public isValidEmail: boolean = true;
  public isFormDirty: boolean = false;
  public isValidCode: boolean = true;
  public newPassword: string = '';
  public confirmPassword: string = '';
  public isMailInValid: boolean = false;
  public pattern: any;
  public isForgotPassword: boolean = false;
  public UmsInvalidEmail: string = this.translate.instant('common.invalidEmailAddress');

  public showLoginPage: boolean = false;
  public showSecurityVerificationPage: boolean = false;
  public showResetPassword: boolean = false;
  public showSmsVerificationPage: boolean = false;
  public showEmailVerificationPage: boolean = false;
  public showEmailVerificationCodePage: boolean = false;
  public showSmsVerificationCodePage: boolean = false;

  public securityAnswer: string = '';
  public securityEmail: string = '';
  public securitySms: string = '';
  public securitySmscode1: string = '';
  public securitySmscode2: string = '';
  public securitySmscode3: string = '';
  public securitySmscode4: string = '';

  public isTwoFactorAuthentication: boolean = true;
  public isSecurityQuestionAuthentication: boolean = true;
  public isEmailAuthentication: boolean = false;
  public isSMSAuthentication: boolean = true;

  public savedFirstQuestion: string = '';
  public savedFirstQuestionAnswer: string = '';

  public savedSecondQuestion: string = '';
  public savedSecondQuestionAnswer: string = '';
  public clientQuestion: string = '';

  public invalidSecurityQuestion: boolean = false;
  public securityError: string = '';
  public isRememberPasswordChecked: boolean = false;
  public isRememberComputerChecked: boolean = false;

  public userId: number;

  public authenticationDurationType: string = '';
  public authenticationDurationValue: string;
  public twoFactorDurationDatePeriod: string = '';
  public navigationPeriod: number = AppConstants.Defaults.NavigationPeriod;

  public umsLoginDetail: object;

  public userQuestionAnswersArray = [];

  public applicationVersion: string = this.translate.instant('common.checking') + '...';
  public assemblyVersion: string = this.translate.instant('common.retrievingVersion') + '...';
  public loginMessage: string = this.translate.instant('common.welcome');
  public ownerName: string = this.translate.instant('common.retrieving');

  public hasUserSettings: boolean = false;

  public userData: object;
  public skipAuthentication: boolean = false;
  public publicComputerInfo: boolean = false;
  public copyRightYear: string = '';
  public isFirstLogin: boolean = false;
  public userSettings: object;
  public changePasswordTitle: string = this.translate.instant('common.changePassword');

  public LoginErrorInfo: string = this.translate.instant('common.invalidUsernameOrPassword') ;

  // Change password changes
  public showPasswordExpiryModal: boolean = false;
  private currentPwd: string = '';
  private newPwd: string = '';
  private confirmNewPwd: string = '';
  public isChangePwd: boolean = false;
  public isCurrentPwdView: boolean = false;
  public isNewPwdView: boolean = false;
  public isConfirmPwdView: boolean = false;
  public pwdRequirementShow: boolean = false;
  public pwdLengthChecked: boolean = false;
  public pwdUpperCaseChecked: boolean = false;
  public pwdLowerCaseChecked: boolean = false;
  public pwdNumberChecked: boolean = false;
  public pwdSpecialCharChecked: boolean = false;

  public hasUpperCase: boolean = true;
  public hasLowerCase: boolean = true;
  public hasSpecialChar: boolean = true;
  public hasNumber: boolean = true;
  public hasLength: number = 6;
  public pwdInfo: boolean = false;
  public isPasswordExist: boolean = false;
  public existingPassword: string = '';

  public globalUserDetails;
  public passwordString: string = 's';

  public userCookieSettings: any = [];
  public systemDurationsList: any = [];
  public systemUserSettings;
  public systemOverAllSettings;
  public globalPasswordSettings;
  public globalCentreList: any = [];
  public globalCodeValueList: any = [];
  public globalGenericList: any = [];


  setLanguageClick(languageSelected) {
    this.appInsightsService.logEvent('Login:Language Change', { language: languageSelected.value });
    const languageCode = languageSelected.value;
    this.translate.use(languageCode);
    localStorage.setItem(AppConstants.LocalStorage.Language, JSON.stringify(languageCode));
  }

  // Toast Handler:
  // TODO: migrate to a service that can be injected.
  // TODO: use constants for toaster type
  private ShowToastError(messageToShow: string, hideSpinner: boolean = true) {
    if (hideSpinner) {
      this.spinner.hide();
    }
    this.ShowToast(messageToShow, 'error');
  }

  private ShowToast(messageToShow: string, toastType: string) {
    const toasterValue = {
      type: toastType,
      message: messageToShow
    };
    this.toastService.showSpinner(toasterValue);
  }

  offlineExposureEntry() {
    alert(this.translate.instant('common.notYetCompleted!') + '!');
  }

  // Click handler for login button
  loginClick() {
    this.BusyMessage = this.translate.instant('common.startingAuthentication') + '...';
    this.spinner.show();

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    this._cookieService.set(AppConstants.CookieName.SkipCookieCallOnLogin, 'true',
      this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
      '/', null, true, "Strict");
    this.loginBtnActive = true;
    this.invalidLoginError = false;

    // check for a user and password
    if (this.userName !== '' && this.password !== '') {
      const loginData = {
        userName: this.userName,
        password: this.password,
        grant_type: 'password'
      };
      const loginUserCredentials = {
        UserName: this.userName,
        Password: this.password
      };
      this.userData = loginData;

      // is Remember me checked?
      this.isRememberPasswordChecked =
        this.rememberPasswordObj !== undefined && this.rememberPasswordObj.checked
          ? this.rememberPasswordObj.checked
          : this.rememberPasswordMobileObj !== undefined && this.rememberPasswordMobileObj.checked
            ? this.rememberPasswordMobileObj.checked
            : false;
      this.isRememberComputerChecked =
        this.rememberComputerObj !== undefined && this.rememberComputerObj.checked
          ? this.rememberComputerObj.checked
          : this.rememberComputerMobileObj !== undefined && this.rememberComputerMobileObj.checked
            ? this.rememberComputerMobileObj.checked
            : false;

      // this is the format to sent form data to generate token
      // const userFormData = 'grant_type=password&username=' + loginData.userName + '&password=' + loginData.password;

      this.BusyMessage = this.translate.instant('common.requestingAuthentication')+'...';
      this.onlineService.login(loginUserCredentials).subscribe(
        (data: any) => {
          this.BusyMessage = this.translate.instant('common.processingAuthentication')+'...';

          // token generation for valid user
          if (data.LoginStatus === true) {
            this.umsLoginDetail = data;
            this._cookieService.set(AppConstants.CookieName.UMSUserToken, this.umsLoginDetail['access_token'],
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict");
            this._cookieService.set(AppConstants.CookieName.UserToken, this.umsLoginDetail['access_token'],
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict");
            this._cookieService.set(AppConstants.CookieName.RememberMe, this.isRememberPasswordChecked.toString(),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict");
            this._cookieService.set(AppConstants.CookieName.PublicComputer, this.isRememberComputerChecked.toString(),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict");
            this._cookieService.set(AppConstants.CookieName.HasUserSettingsFlag, this.hasUserSettings.toString().toLocaleLowerCase(),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict");
            this._cookieService.set(AppConstants.CookieName.UserId, this.umsLoginDetail['UserId'],
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict");
            this.invalidLoginError = false;
            this.loginBtnActive = false;
            this.userSettingEvaluation(loginUserCredentials.UserName);
          } else {
            this.spinner.hide();
            this.invalidLoginError = true;
            this.LoginErrorInfo = data.LoginStatusInfo;
            this.loginBtnActive = false;
          }
        },
        (err: any) => { // type was HttpErrorResponse, but timeouterror was not in the enum?!?
          switch (err.name) {
            // DPR: It appears this was not a valid value, but it still happens... do we have the wrong type?
            case 'TimeoutError':
              this.LoginErrorInfo = this.translate.instant('common.timeoutConnectingToLoginService') + ',' + " " + this.translate.instant('common.checkYourInternetConnection') + '.';
              this.appInsightsService.logEvent('Login:TimeoutError', { error: err.message });
              break;
            case 'HttpErrorResponse':
              this.LoginErrorInfo = this.translate.instant('common.networkConnectionError') + ', ' + this.translate.instant('common.checkYourInternetConnection') + '. ' + (err.statusText != undefined ? err.statusText : '');
              this.appInsightsService.logEvent('Login:HttpErrorResponse', { error: err.message });
              break;
            default:
              this.LoginErrorInfo = err.name + ' - ' + err.statusText != undefined ? err.statusText : '';
              this.appInsightsService.logEvent('Login:Error', { error: err.message });
          }
          const auditDetail = {
            ModuleName: 'Login',
            OperationTypeName: 'Login',
            CentreId: 0,
            LoggedByUserId: this.userName,
            AuditMessage: this.translate.instant('common.loginFailedFor') + " " + this.userName + ' > ' + this.LoginErrorInfo
          };
          this.onlineService.AddAudit(auditDetail).subscribe(function () { });
          this.spinner.hide();
          this.invalidLoginError = true;
          this.loginBtnActive = false;
        }
      );
    } else {
      this.LoginErrorInfo = this.translate.instant( 'common.pleaseEnterBothUserNameAndPasswordToLogin');
      this.invalidLoginError = true;
      this.spinner.hide();
    }
  }

  // setup the user from the server's user settings
  userSettingEvaluation(nameOfLoginUser) {
    this.BusyMessage = this.translate.instant('common.retrievingSystemConfiguration') + '...';
    this.spinner.show();
    this.userId = parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10);

    // Get Login page settings - not sure why...
    // 21-july-2022 - This gets hit when should be online, so try to get settings to confirm
    this.onlineService.getLoginPageSetting(this.userId).subscribe(
      (cookieSettings: any) => {
        this.BusyMessage = this.translate.instant('common.evaluatingUserSettings') + '...';
        this.cookieExpirationMaximumInDays = 365;
        this.cookieExpirationMinimumInDays = 1;

        this.isRememberPasswordChecked =
          this._cookieService.get(AppConstants.CookieName.RememberMe).toLocaleLowerCase() === ''
            ? false
            : this._cookieService.get(AppConstants.CookieName.RememberMe).toLocaleLowerCase() === 'true'
              ? true
              : false;

        if (cookieSettings !== undefined && cookieSettings.userDetail != null) {
          this.globalUserDetails = cookieSettings.userDetail;

          this.userId = cookieSettings.userDetail.userId;
          this.onlineService.setCurrentUserInfo(cookieSettings.userDetail.firstName + ' ' + cookieSettings.userDetail.lastName
            , 'data:image/jpeg;base64,' + cookieSettings.userDetail.picture);
          this._cookieService.set(
            AppConstants.CookieName.UserId,
            (this.userId == null ? '' : this.userId).toString(), // null coalase in case it's null for some reason - it happened in dev!
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );
          this._cookieService.set(
            AppConstants.CookieName.LoginUserName,
            cookieSettings.userDetail.userName,
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );
          this._cookieService.set(
            AppConstants.CookieName.UserDisplayName,
            cookieSettings.userDetail.displayName,
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );
          this._cookieService.set(
            AppConstants.CookieName.PasswordLastChangedDate,
            cookieSettings.userDetail.passwordChangedDate,
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );

          this.systemOverAllSettings = cookieSettings;
          this.systemUserSettings = cookieSettings.userSettingsList;
          this.systemDurationsList = cookieSettings.durationList;
          this.userCookieSettings = cookieSettings.systemSettingsInfo;
          this.globalPasswordSettings = cookieSettings.passwordSettings;
          this.globalCentreList = cookieSettings.centreList;

          if (
            cookieSettings.systemSettingsInfo !== 0 &&
            cookieSettings.systemSettingsInfo != null &&
            cookieSettings.systemSettingsInfo !== undefined &&
            cookieSettings.systemSettingsInfo !== ''
          ) {
            cookieSettings.systemSettingsInfo.forEach(settings => {
              if (settings.key.toLocaleLowerCase() === AppConstants.CookieName.TwoFactorAuthenticationDuration.toLocaleLowerCase()) {
                this.authenticationDurationType = settings.key;
                this.authenticationDurationValue = settings.value;
              }
              if (settings.key.toLocaleLowerCase() === AppConstants.CookieName.CookiePersistenceTime.toLocaleLowerCase()) {
                this.cookieExpirationMinimumInDays = parseInt(settings.value, 10);
              }
              if (settings.key.toLocaleLowerCase() === AppConstants.CookieName.RememberMe.toLocaleLowerCase()) {
                this.cookieExpirationMaximumInDays = parseInt(settings.value, 10);
              }
              if (settings.key.toLocaleLowerCase() === AppConstants.CookieName.NavigationPeriod.toLocaleLowerCase()) {
                this.navigationPeriod = parseInt(settings.value, 10);
                const navigationPeriod: number = this.navigationPeriod * 1000;
                this._cookieService.set(
                  AppConstants.CookieName.NavigationPeriod,
                  navigationPeriod !== undefined ? navigationPeriod.toString() : AppConstants.Defaults.NavigationPeriod.toString(),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
              }
              if (
                settings.key.toLocaleLowerCase() === AppConstants.CookieName.RememberMe.toLocaleLowerCase() ||
                settings.key.toLocaleLowerCase() === AppConstants.CookieName.CookiePersistenceTime.toLocaleLowerCase()
              ) {
                this._cookieService.set(
                  AppConstants.CookieName.UMSUserToken,
                  this._cookieService.get(AppConstants.CookieName.UMSUserToken),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.UserToken,
                  this._cookieService.get(AppConstants.CookieName.UMSUserToken),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.RememberMe,
                  this._cookieService.get(AppConstants.CookieName.RememberMe),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.PublicComputer,
                  this._cookieService.get(AppConstants.CookieName.PublicComputer),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.HasUserSettingsFlag,
                  this._cookieService.get(AppConstants.CookieName.HasUserSettingsFlag),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.UserId,
                  this._cookieService.get(AppConstants.CookieName.UserId),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.LoginUserName,
                  this._cookieService.get(AppConstants.CookieName.LoginUserName),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
                this._cookieService.set(
                  AppConstants.CookieName.PasswordLastChangedDate,
                  this._cookieService.get(AppConstants.CookieName.PasswordLastChangedDate),
                  this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                  '/', null, true, "Strict"
                );
              }
            });
          } else {
            this._cookieService.set(
              AppConstants.CookieName.UMSUserToken,
              this._cookieService.get(AppConstants.CookieName.UMSUserToken),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.UserToken,
              this._cookieService.get(AppConstants.CookieName.UMSUserToken),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.RememberMe,
              this._cookieService.get(AppConstants.CookieName.RememberMe),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.PublicComputer,
              this._cookieService.get(AppConstants.CookieName.PublicComputer),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.HasUserSettingsFlag,
              this._cookieService.get(AppConstants.CookieName.HasUserSettingsFlag),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.UserId,
              this._cookieService.get(AppConstants.CookieName.UserId),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.LoginUserName,
              this._cookieService.get(AppConstants.CookieName.LoginUserName),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.UserDisplayName,
              cookieSettings.userDetail.displayName,
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.PasswordLastChangedDate,
              this._cookieService.get(AppConstants.CookieName.PasswordLastChangedDate),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this._cookieService.set(
              AppConstants.CookieName.NavigationPeriod,
              '60000',
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
          }
          // Moved this out of if systemUserSettings != null.
          this.systemDurationsList.forEach((settings) => {
            if (String(settings.durationId) === String(this.authenticationDurationValue)) {
              this.twoFactorDurationDatePeriod = settings.durationName;
              this._cookieService.set(
                'twoFactorDurationType',
                this.twoFactorDurationDatePeriod,
                this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                '/', null, true, "Strict"
              );
            }
          });

          // Check for password Expiration
          const passwordChangedDate = this._cookieService.get(AppConstants.CookieName.PasswordLastChangedDate);
          const passwordExpiryDate = new Date();
          passwordExpiryDate.setDate(passwordExpiryDate.getDate() - this.globalPasswordSettings.expirationDays);

          if (passwordExpiryDate >= new Date(passwordChangedDate)) {
            this.spinner.hide();
            this.showPasswordExpiryModal = true;
            this.newPwd = '';
            this.confirmNewPwd = '';
            this.changePasswordTitle = this.translate.instant('common.passwordExpired') + " " + '-' + " " + this.translate.instant('common.resetPassword');
            this.isChangePwd = false;
            this.pwdInfo = false;
            this.pwdRequirementShow = false;
            (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
            setTimeout(() => {
              const inputElement = document.getElementById('login-change-pwd-btn');
              if (inputElement != null && inputElement != undefined) {
                inputElement.focus();
              }
            }, 100);
            return;
          }
          const previousDate = new Date();
          previousDate.setDate(previousDate.getDate() - 1);

          const previousWeekDate = new Date();
          previousWeekDate.setDate(previousWeekDate.getDate() - 7);

          const previousMonth = new Date();
          previousMonth.setMonth(previousMonth.getMonth() - 1);

          let previousYear = new Date();
          previousYear = new Date(previousYear.getFullYear(), 0, -1);

          // set the last two factor authentication date cookie.
          if (this.systemUserSettings && this.systemUserSettings.lastTwoFactorAuthenticationDate != null
            && this.systemUserSettings.lastTwoFactorAuthenticationDate != '') {
            this._cookieService.set(
              'twoFactorLastLoginDate',
              new Date(this.systemUserSettings.lastTwoFactorAuthenticationDate).toUTCString(),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
          } else {
            this._cookieService.set(
              'twoFactorLastLoginDate',
              'null',
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
          }


          if (this.systemUserSettings != null) {

            if (this.systemUserSettings.authenticationTypeId != null) {


              if (
                this.systemUserSettings.lastTwoFactorAuthenticationDate == null ||
                (this.twoFactorDurationDatePeriod.toLocaleLowerCase() === 'yearly' &&
                  previousYear >= new Date(this.systemUserSettings.lastTwoFactorAuthenticationDate)) ||
                (this.twoFactorDurationDatePeriod.toLocaleLowerCase() === 'monthly' &&
                  previousMonth >= new Date(this.systemUserSettings.lastTwoFactorAuthenticationDate)) ||
                (this.twoFactorDurationDatePeriod.toLocaleLowerCase() === 'weekly' &&
                  previousWeekDate >= new Date(this.systemUserSettings.lastTwoFactorAuthenticationDate)) ||
                (this.twoFactorDurationDatePeriod.toLocaleLowerCase() === 'daily' &&
                  previousDate >= new Date(this.systemUserSettings.lastTwoFactorAuthenticationDate))
              ) {
                if (this.systemUserSettings.authenticationTypeName.toLocaleLowerCase() === 'security questions') {
                  this.BusyMessage = this.translate.instant('common.gettingSecurityQuestions') + '...';
                  this.onlineService.getUserSecurityQuestions(this.userId).subscribe((questions: any) => {
                    this.securityError = '';

                    if (questions.length > 0) {
                      this.showLoginPage = false;
                      this.showResetPassword = false;
                      this.showEmailVerificationPage = false;
                      this.showSmsVerificationPage = false;
                      this.showEmailVerificationCodePage = false;
                      this.showSmsVerificationCodePage = false;
                      this.showSecurityVerificationPage = true;

                      this.userQuestionAnswersArray = questions;

                      this.clientQuestion = this.savedFirstQuestion = questions[0].securityQuestionName;
                      this.savedFirstQuestionAnswer = questions[0].securityQuestionId;
                      if (questions.length >= 2) {
                        this.savedSecondQuestion = questions[1].securityQuestionName;
                        this.savedSecondQuestionAnswer = questions[1].securityQuestionId;
                      }
                      setTimeout(function () {
                        const securityQuestionsElement = document.getElementById('tbxSecurityAnswer');
                        const mobSecurityQuestionsElement = document.getElementById('tbxMobSecurityAnswer');
                        if (securityQuestionsElement != null && securityQuestionsElement != undefined) {
                          securityQuestionsElement.focus();
                        }
                        if (mobSecurityQuestionsElement != null && mobSecurityQuestionsElement != undefined) {
                          mobSecurityQuestionsElement.focus();
                        }
                      }, 100);

                      this.spinner.hide();
                    } else {
                      this.twoFactorAuthentication(nameOfLoginUser);
                    }
                  });
                }

                if (this.systemUserSettings.authenticationTypeName.toLocaleLowerCase() == 'email') {
                  this.BusyMessage = this.translate.instant('common.sendingTwoFactorAuthenticationEmail') + '...';
                  const userSettingInfo = {
                    UserId: this.userId,
                    CentreId: null,
                    CentreName: null,
                    AuthenticationTypeId: null,
                    AuthenticationTypeName: null,
                    EmailVerificationCode: null,
                    LastTwoFactorAuthenticationDate: null,
                    UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
                  };
                  this.onlineService.emailVerificationCodeEntry(userSettingInfo).subscribe((emailCode: any) => {
                    if (emailCode.isSuccess) {
                      this.showLoginPage = false;
                      this.showResetPassword = false;
                      this.showEmailVerificationPage = true;
                      this.showSmsVerificationPage = false;
                      this.showEmailVerificationCodePage = false;
                      this.showSmsVerificationCodePage = false;
                      this.showSecurityVerificationPage = false;
                      setTimeout(function () {
                        const securityEmailElement = document.getElementById('tbxSecurityEmail');
                        const mobSecurityEmailElement = document.getElementById('tbxMobSecurityEmail');
                        if (securityEmailElement != null && securityEmailElement != undefined) {
                          securityEmailElement.focus();
                        }
                        if (mobSecurityEmailElement != null && mobSecurityEmailElement != undefined) {
                          mobSecurityEmailElement.focus();
                        }
                      }, 500);
                      this.spinner.hide();
                    } else {
                      this.ShowToastError(this.translate.instant('common.somethingWentWrongWithEmailVerification') + '. ' + this.translate.instant('common.pleaseTryAgain'));
                      // TODO: Record error in error log
                    }
                  });
                }

                if (this.systemUserSettings.authenticationTypeName.toLocaleLowerCase() == 'sms') {
                  this.BusyMessage = this.translate.instant('common.sendingTwoFactorAuthenticationSMS') + '...';
                  const userSettingInfo = {
                    UserId: this.userId,
                    CentreId: null,
                    CentreName: null,
                    AuthenticationTypeId: null,
                    AuthenticationTypeName: null,
                    EmailVerificationCode: null,
                    LastTwoFactorAuthenticationDate: null,
                    UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
                  };
                  this.BusyMessage = this.translate.instant('common.twoFactorAuthenticationVerify') + '...';

                  this.onlineService.smsVerificationCodeEntry(userSettingInfo).subscribe((emailCode: any) => {
                    if (emailCode.isSuccess) {
                      this.showLoginPage = false;
                      this.showResetPassword = false;
                      this.showEmailVerificationPage = false;
                      this.showSmsVerificationPage = false;
                      this.showEmailVerificationCodePage = false;
                      this.showSmsVerificationCodePage = true;
                      this.showSecurityVerificationPage = false;
                      this.securitySmscode1 = '';
                      this.securitySmscode2 = '';
                      this.securitySmscode3 = '';
                      this.securitySmscode4 = '';
                      setTimeout(() => {
                        const securitySMSElement = document.getElementById('securitySmscode1');
                        const mobSecuritySMSElement = document.getElementById('mobSecuritySmscode1');
                        if (securitySMSElement != null && securitySMSElement != undefined) {
                          securitySMSElement.focus();
                          this.securitySmscode1 = '';
                          this.securitySmscode2 = '';
                          this.securitySmscode3 = '';
                          this.securitySmscode4 = '';
                        }
                        if (mobSecuritySMSElement != null && mobSecuritySMSElement != undefined) {
                          mobSecuritySMSElement.focus();
                          this.securitySmscode1 = '';
                          this.securitySmscode2 = '';
                          this.securitySmscode3 = '';
                          this.securitySmscode4 = '';
                        }
                      }, 500);
                      this.spinner.hide();
                    } else {
                      this.spinner.hide();
                      this.ShowToastError(
                        this.translate.instant('common.somethingWentWrongWithSMSVerification') + '. ' +
                        emailCode.errorMessage +
                        this.translate.instant('common.pleaseContactYourAdministratorOrSupportToCorrectTheSMSError') + '.');
                      // TODO: Log Error!
                    }
                  });
                }
              } else {
                if (this.skipAuthentication) {
                  this.BusyMessage = this.translate.instant('common.twoFactorAuthenticationNotRequired') + '...';
                  if (this.globalPasswordSettings != null && this.globalPasswordSettings.expirationDays != 0) {
                    this._cookieService.set(
                      'passwordExpiration',
                      this.globalPasswordSettings.expirationDays,
                      this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                      '/', null, true, "Strict"
                    );

                    this.hasLength = this.globalPasswordSettings.minimumCharacters != null ? this.globalPasswordSettings.minimumCharacters : this.hasLength;
                    this.hasUpperCase =
                      this.globalPasswordSettings.shouldContainUpperCase != null ? this.globalPasswordSettings.shouldContainLowerCase : this.hasUpperCase;
                    this.hasLowerCase =
                      this.globalPasswordSettings.shouldContainLowerCase != null ? this.globalPasswordSettings.shouldContainLowerCase : this.hasLength;
                    this.hasNumber =
                      this.globalPasswordSettings.shouldContainNumeric != null ? this.globalPasswordSettings.shouldContainNumeric : this.hasNumber;
                    this.hasSpecialChar =
                      this.globalPasswordSettings.shouldContainSpecialCharacter != null
                        ? this.globalPasswordSettings.shouldContainSpecialCharacter
                        : this.hasSpecialChar;

                    this.passwordString = this.hasLength > 1 ? 's' : '';

                    const passwordChangedDate = this._cookieService.get(AppConstants.CookieName.PasswordLastChangedDate);
                    const passwordExpiryDate = new Date();
                    passwordExpiryDate.setDate(passwordExpiryDate.getDate() - this.globalPasswordSettings.expirationDays);

                    if (passwordExpiryDate >= new Date(passwordChangedDate)) {
                      this.spinner.hide();
                      this.showPasswordExpiryModal = true;
                      this.newPwd = '';
                      this.confirmNewPwd = '';
                      this.changePasswordTitle = this.translate.instant('common.passwordExpired') + ' - ' + this.translate.instant('common.resetPassword');
                      this.isChangePwd = false;
                      this.pwdInfo = false;
                      this.pwdRequirementShow = false;
                      (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
                      setTimeout(() => {
                        const inputElement = document.getElementById('login-change-pwd-btn');
                        if (inputElement != null && inputElement != undefined) {
                          inputElement.focus();
                        }
                      }, 100);
                    } else {
                      this.router.navigateByUrl(this.returnUrl);
                    }
                  } else {
                    this.router.navigateByUrl(this.returnUrl);
                  }
                } else {
                  this.twoFactorAuthentication(nameOfLoginUser);
                }
              }
            } else {  // this.systemUserSettings.authenticationTypeId == null!
              this.hasUserSettings = false;
              this._cookieService.set(
                AppConstants.CookieName.HasUserSettingsFlag,
                this.hasUserSettings.toString(),
                this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
                '/', null, true, "Strict"
              );
              this.twoFactorAuthentication(nameOfLoginUser);
            }
          } else {  // this.systemUserSettings == null !!!
            this.hasUserSettings = false;
            this._cookieService.set(
              AppConstants.CookieName.HasUserSettingsFlag,
              this.hasUserSettings.toString(),
              this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            this.twoFactorAuthentication(nameOfLoginUser);
          }
        } else {
          // user details not retrieved
          this.ShowToastError(this.translate.instant('common.somethingWentWrongRetrievingUserDetails') + '. ' + this.translate.instant('common.refreshYourBrowser')
           + '. ' + this.translate.instant('common.ifStillAProblem') + ', ' + this.translate.instant('common.logout') + ', ' + this.translate.instant('common.closeYourBrowserAndStartAgain') + '.');
          // TODO: Log Error!
        }
      },
      (err: HttpErrorResponse) => {
        const errorLog = {
          CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
          LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
          CustomErrorMessage: err.message,
          ErrorMessage: err.message,
          InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
        };
        
        if (navigator.onLine) {

          this.onlineService.AddErrorLog(errorLog).subscribe(() => {
            this.ShowToastError(this.translate.instant('common.somethingWentWrongRetrievingLoginSettings') + '. ' + this.translate.instant('common.refreshYourBrowser')
             + '. ' + this.translate.instant('common.ifStillAProblem') + ', ' + this.translate.instant('common.logout') + ', ' + this.translate.instant('common.closeYourBrowserAndStartAgain') + '.');
            // TODO: Log Error!
        
          });
          this.ShowToastError(this.translate.instant('common.somethingWentWrongRetrievingLoginSettings') + '. ' + this.translate.instant('common.attemptingToFixAndRedirect')
           + '. ' + this.translate.instant('common.refreshYourBrowser') + ', ' + this.translate.instant( 'common.ifNotRedirected') + ', ' + this.translate.instant('common.logout')
           + ', ' + this.translate.instant('common.closeYourBrowserAndStartAgain') + '.');
          this._cookieService.delete(AppConstants.CookieName.LoginUserName); // kind of a hack to get this to not skip authentication when the server does not respond in time.
          window.location.href = '/';
        } else {
          const toasterValue = {
            type: 'warning',
            message: this.translate.instant('common.youAreWorkingOffline') + '. ' + this.translate.instant('common.pleaseReloadThePageToContinue')
          };
          this.toastService.showSpinner(toasterValue);
          window.location.reload();
          this.spinner.hide();
        }
      }
    ); // get over all settings api call end
  }

  onSecuritySmsCodeSubmit() {
    this.BusyMessage = this.translate.instant('common.smsCodeValidation')+ '...';
    this.spinner.show();
    const userSettingInfo = {
      UserId: parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10),
      CentreId: null,
      CentreName: null,
      AuthenticationTypeId: null,
      AuthenticationTypeName: null,
      EmailVerificationCode: null,
      SMSVerificationCode: parseInt(this.securitySmscode1 + this.securitySmscode2 + this.securitySmscode3 + this.securitySmscode4, 10),
      LastTwoFactorAuthenticationDate: null,
      UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
    };

    this.onlineService.smsVerificationCode(userSettingInfo).subscribe((smsVerificationCode: any) => {
      if (smsVerificationCode.isSuccess) {
        this.securitySmscode1 = '';
        this.showSmsVerificationCodePage = false;
        this.invalidSecurityQuestion = false;
        this.onlineService.twoFactorDateUpdate(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe((dateUpdate: any) => {
          this._cookieService.set(
            'twoFactorLastLoginDate',
            new Date().toUTCString(),
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );
          this.spinner.hide();
          this.twoFactorAuthentication(this._cookieService.get(AppConstants.CookieName.LoginUserName));
        });
      } else {
        const auditDetail = {
          ModuleName: 'Login',
          OperationTypeName: 'Login',
          CentreId: 0,
          LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
          AuditMessage: this.translate.instant('common.failedToVerifySmsCodeFor') + " " + this._cookieService.get(AppConstants.CookieName.LoginUserName)
        };
        this.onlineService.AddAudit(auditDetail).subscribe(function () { });
        this.spinner.hide();
        this.invalidSecurityQuestion = true;
        this.securityError = this.translate.instant('common.pleaseEnterValidCode ');
        setTimeout(function () {
          const securitySMSElement = document.getElementById('securitySmscode1');
          const mobSecuritySMSElement = document.getElementById('mobSecuritySmscode1');
          if (securitySMSElement != null && securitySMSElement != undefined) {
            securitySMSElement.focus();
          }
          if (mobSecuritySMSElement != null && mobSecuritySMSElement != undefined) {
            mobSecuritySMSElement.focus();
          }
        }, 500);
      }
    });
  }

  onSecurityEmailSubmit() {
    this.BusyMessage = this.translate.instant('common.emailCodeValidation')+ '...';
    this.spinner.show();
    const userSettingInfo = {
      UserId: parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10),
      CentreId: null,
      CentreName: null,
      AuthenticationTypeId: null,
      AuthenticationTypeName: null,
      EmailVerificationCode: this.securityEmail,
      SMSVerificationCode: null,
      LastTwoFactorAuthenticationDate: null,
      UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
    };

    this.onlineService.emailVerificationCode(userSettingInfo).subscribe((emailVerificationCode: any) => {
      if (emailVerificationCode.isSuccess) {
        this.securityEmail = '';
        this.showEmailVerificationPage = false;
        this.invalidSecurityQuestion = false;
        this.onlineService.twoFactorDateUpdate(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe((dateUpdate: any) => {
          this._cookieService.set(
            'twoFactorLastLoginDate',
            new Date().toUTCString(),
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );
          this.twoFactorAuthentication(this._cookieService.get(AppConstants.CookieName.LoginUserName));
          this.spinner.hide();

        });
      } else {
        const auditDetail = {
          ModuleName: 'Login',
          OperationTypeName: 'Login',
          CentreId: 0,
          LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
          AuditMessage: this.translate.instant('common.failedToVerifyEmailCodeFor')+ " " + this._cookieService.get(AppConstants.CookieName.LoginUserName)
        };
        this.onlineService.AddAudit(auditDetail).subscribe(function () { });
        this.spinner.hide();
        this.invalidSecurityQuestion = true;
        this.securityError = this.translate.instant('common.pleaseEnterValidCode ');
        setTimeout(function () {
          const securityEmailElement = document.getElementById('tbxSecurityEmail');
          const mobSecurityEmailElement = document.getElementById('tbxMobSecurityEmail');
          if (securityEmailElement != null && securityEmailElement != undefined) {
            securityEmailElement.focus();
          }
          if (mobSecurityEmailElement != null && mobSecurityEmailElement != undefined) {
            mobSecurityEmailElement.focus();
          }
        }, 500);
      }
    });
  }

  onSecurityQuestionsSubmit() {
    let currentQuestionCollections = null;
    const questionList = [];
    const data = [];
    this.BusyMessage = this.translate.instant('common.securityQuestionValidation') + '...';
    this.spinner.show();

    if (this.clientQuestion == this.savedFirstQuestion) {
      currentQuestionCollections = {
        SecurityQuestionId: this.savedFirstQuestionAnswer,
        SecurityQuestionName: this.savedFirstQuestion,
        SecurityQuestionAnswer: this.securityAnswer
      };
      questionList.push(currentQuestionCollections);
      data.push({
        SecurityQuestionId: this.savedFirstQuestionAnswer,
        SecurityQuestionName: this.savedFirstQuestion,
        SecurityQuestionAnswer: ''
      });
    }
    if (this.clientQuestion == this.savedSecondQuestion) {
      currentQuestionCollections = {
        SecurityQuestionId: this.savedSecondQuestionAnswer,
        SecurityQuestionName: this.savedSecondQuestion,
        SecurityQuestionAnswer: this.securityAnswer
      };
      questionList.push(currentQuestionCollections);
      data.push({
        SecurityQuestionId: this.savedSecondQuestionAnswer,
        SecurityQuestionName: this.savedSecondQuestion,
        SecurityQuestionAnswer: ''
      });
    }
    const userSecurityQuestion = {
      UserId: parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10),
      UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName),
      SecurityQuestionList: questionList
    };

    this.onlineService.getUserQuestionAnswer(userSecurityQuestion).subscribe((questionValidation: any) => {
      if (questionValidation.isSuccess) {
        this.showSecurityVerificationPage = false;
        this.securityAnswer = '';
        this.invalidSecurityQuestion = false;
        this.onlineService.twoFactorDateUpdate(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe((dateUpdate: any) => {
          this._cookieService.set(
            'twoFactorLastLoginDate',
            new Date().toUTCString(),
            this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
            '/', null, true, "Strict"
          );

          this.spinner.hide();
          this.twoFactorAuthentication(this._cookieService.get(AppConstants.CookieName.LoginUserName));
        });
      } else {
        const auditDetail = {
          ModuleName: 'Login',
          OperationTypeName: 'Login',
          CentreId: 0,
          LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
          AuditMessage: this.translate.instant('common.failedToVerifySecurityQuestionAnswerFor') + " " + this._cookieService.get(AppConstants.CookieName.LoginUserName)
        };
        this.onlineService.AddAudit(auditDetail).subscribe(function () { });
        this.spinner.hide();
        this.invalidSecurityQuestion = true;
        this.securityAnswer = '';
        this.securityError = this.translate.instant( 'common.notMatchedWith-') + " " + this.clientQuestion;

        if (this.savedSecondQuestion != '') {
          this.showLoginPage = false;
          this.showResetPassword = false;
          this.showEmailVerificationPage = false;
          this.showSmsVerificationPage = false;
          this.showEmailVerificationCodePage = false;
          this.showSmsVerificationCodePage = false;
          this.showSecurityVerificationPage = true;
          this.securityAnswer = ''; // clear this before asking for confirm!
          this.clientQuestion = this.savedSecondQuestion;
        }
        setTimeout(function () {
          const securityQuestionsElement = document.getElementById('tbxSecurityAnswer');
          const mobSecurityQuestionsElement = document.getElementById('tbxMobSecurityAnswer');
          if (securityQuestionsElement != null && securityQuestionsElement != undefined) {
            securityQuestionsElement.focus();
          }
          if (mobSecurityQuestionsElement != null && mobSecurityQuestionsElement != undefined) {
            mobSecurityQuestionsElement.focus();
          }
        }, 500);
      }
    });
  }

  onKeyPressChange(args) {
    this.invalidSecurityQuestion = false;
    if (args.currentTarget.id == 'securitySmscode1') {
      const securitySmscode2 = document.getElementById('securitySmscode2');
      if (securitySmscode2 != null && securitySmscode2 != undefined) {
        securitySmscode2.focus();
      }
    }
    if (args.currentTarget.id == 'securitySmscode2') {
      const securitySmscode3 = document.getElementById('securitySmscode3');
      if (securitySmscode3 != null && securitySmscode3 != undefined) {
        securitySmscode3.focus();
      }
    }
    if (args.currentTarget.id == 'securitySmscode3') {
      const securitySmscode4 = document.getElementById('securitySmscode4');
      if (securitySmscode4 != null && securitySmscode4 != undefined) {
        securitySmscode4.focus();
      }
    }
    if (args.currentTarget.id == 'mobSecuritySmscode1') {
      const mobSecuritySmscode2 = document.getElementById('mobSecuritySmscode2');
      if (mobSecuritySmscode2 != null && mobSecuritySmscode2 != undefined) {
        mobSecuritySmscode2.focus();
      }
    }
    if (args.currentTarget.id == 'mobSecuritySmscode2') {
      const mobSecuritySmscode3 = document.getElementById('mobSecuritySmscode3');
      if (mobSecuritySmscode3 != null && mobSecuritySmscode3 != undefined) {
        mobSecuritySmscode3.focus();
      }
    }
    if (args.currentTarget.id == 'mobSecuritySmscode3') {
      const mobSecuritySmscode4 = document.getElementById('mobSecuritySmscode4');
      if (mobSecuritySmscode4 != null && mobSecuritySmscode4 != undefined) {
        mobSecuritySmscode4.focus();
      }
    }
  }

  previousSecurityQuestion() {
    this.invalidSecurityQuestion = false;
    this.securityAnswer = '';
    this.showLoginPage = false;
    this.showSecurityVerificationPage = true;
    this.clientQuestion = this.savedFirstQuestion;
    setTimeout(function () {
      const securityQuestionsElement = document.getElementById('tbxSecurityAnswer');
      const mobSecurityQuestionsElement = document.getElementById('tbxMobSecurityAnswer');
      if (securityQuestionsElement != null && securityQuestionsElement != undefined) {
        securityQuestionsElement.focus();
      }
      if (mobSecurityQuestionsElement != null && mobSecurityQuestionsElement != undefined) {
        mobSecurityQuestionsElement.focus();
      }
    }, 500);
  }

  twoFactorAuthentication(userName) {
    this.BusyMessage = this.translate.instant('common.twoFactorAuthenticationCheck') + '...';
    this.spinner.show();

    // Save the user name to a cookie.
    this.saveUserNameToCookie(this);

    this.userSettings = this.systemUserSettings;
    const centersList = this.globalCentreList;

    let centreId = null;
    centreId = this.centreListToCookies(this, centreId, centersList);

    // add a audit entry
    const auditDetail = {
      ModuleName: 'Login',
      OperationTypeName: 'Login',
      CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
      LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
      AuditMessage: this._cookieService.get(AppConstants.CookieName.LoginUserName) + " " + this.translate.instant('common.loggedIn')
    };

    this.BusyMessage = this.translate.instant('common.auditingLogin');
    this.onlineService.AddAudit(auditDetail).subscribe(() => {
      this.setStatusToOnline();

      if (this.globalPasswordSettings != null && this.globalPasswordSettings.expirationDays !== 0) {
        this._cookieService.set(
          AppConstants.CookieName.PasswordExpirationDate,
          this.globalPasswordSettings.expirationDays,
          this.isRememberPasswordChecked ? this.cookieExpirationMaximumInDays : this.cookieExpirationMinimumInDays,
          '/', null, true, "Strict"
        );

        this.hasLength = this.globalPasswordSettings.minimumCharacters != null ? this.globalPasswordSettings.minimumCharacters : this.hasLength;
        this.hasUpperCase =
          this.globalPasswordSettings.shouldContainUpperCase != null ? this.globalPasswordSettings.shouldContainLowerCase : this.hasUpperCase;
        this.hasLowerCase =
          this.globalPasswordSettings.shouldContainLowerCase != null ? this.globalPasswordSettings.shouldContainLowerCase : this.hasLength;
        this.hasNumber = this.globalPasswordSettings.shouldContainNumeric != null ? this.globalPasswordSettings.shouldContainNumeric : this.hasNumber;
        this.hasSpecialChar =
          this.globalPasswordSettings.shouldContainSpecialCharacter != null
            ? this.globalPasswordSettings.shouldContainSpecialCharacter
            : this.hasSpecialChar;
        this.passwordString = this.hasLength > 1 ? 's' : '';

        const passwordChangedDate = this._cookieService.get(AppConstants.CookieName.PasswordLastChangedDate);

        const passwordExpiryDate = new Date();

        passwordExpiryDate.setDate(passwordExpiryDate.getDate() - this.globalPasswordSettings.expirationDays);
        this.BusyMessage = this.translate.instant('common.checkingPasswordExpiration') + '...';
        if (passwordExpiryDate >= new Date(passwordChangedDate)) {
          this.spinner.hide();
          this.showPasswordExpiryModal = true;
          this.newPwd = '';
          this.confirmNewPwd = '';
          this.changePasswordTitle = this.translate.instant('common.passwordExpired') + '-' + this.translate.instant( 'common.resetPassword');
          this.isChangePwd = false;
          this.pwdInfo = false;
          this.pwdRequirementShow = false;
          (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
          setTimeout(() => {
            const inputElement = document.getElementById('login-change-pwd-btn');
            if (inputElement != null && inputElement != undefined) {
              inputElement.focus();
            }
          }, 100);
        } else {
          this.BusyMessage = this.translate.instant('common.firstLoginCheck');

          // Before entering a new password the system must send 2FA via default auth type email

          // First login check for changing password!
          if (this.systemUserSettings == null && this.umsLoginDetail != undefined && this.umsLoginDetail != null) {

            this.BusyMessage = this.translate.instant('common.sendingTwoFactorAuthenticationEmail') + '...';
            const userSettingInfo = {
              UserId: this.umsLoginDetail['UserId'],
              CentreId: null,
              CentreName: null,
              AuthenticationTypeId: null,
              AuthenticationTypeName: null,
              EmailVerificationCode: null,
              LastTwoFactorAuthenticationDate: null,
              UserName: this.umsLoginDetail['userName']
            };
            this.onlineService.emailVerificationCodeEntry(userSettingInfo).subscribe((emailCode: any) => {
              if (emailCode.isSuccess) {
                this.showLoginPage = false;
                this.showResetPassword = false;
                this.showEmailVerificationPage = true;
                this.showSmsVerificationPage = false;
                this.showEmailVerificationCodePage = false;
                this.showSmsVerificationCodePage = false;
                this.showSecurityVerificationPage = false;
                setTimeout(function () {
                  const securityEmailElement = document.getElementById('tbxSecurityEmail');
                  const mobSecurityEmailElement = document.getElementById('tbxMobSecurityEmail');
                  if (securityEmailElement != null && securityEmailElement != undefined) {
                    securityEmailElement.focus();
                  }
                  if (mobSecurityEmailElement != null && mobSecurityEmailElement != undefined) {
                    mobSecurityEmailElement.focus();
                  }
                }, 500);
                this.spinner.hide();
              } else {
                this.ShowToastError(this.translate.instant('common.somethingWentWrongWithEmailVerification') + '. ' + this.translate.instant('common.pleaseTryAgain'));
                // TODO: Record error in error log
              }
            });
          } else {
            if (this.globalUserDetails != null && this.globalUserDetails.passwordChangedDate == null) {
              this.spinner.hide();
              this.showPasswordExpiryModal = true;
              this.newPwd = '';
              this.confirmNewPwd = '';
              this.changePasswordTitle = this.translate.instant('common.firstLogin') + ' - ' + this.translate.instant('common.changeYourPassword');
              this.isFirstLogin = true;
              this.isChangePwd = false;
              this.pwdInfo = false;
              this.pwdRequirementShow = false;
              (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
              setTimeout(() => {
                const inputElement = document.getElementById('login-change-pwd-btn');
                if (inputElement != null && inputElement != undefined) {
                  inputElement.focus();
                }
              }, 100);
            }
          }

          this.BusyMessage = this.translate.instant('common.retrievingUserSettings');
          if (this.hasUserSettings) {
            // get login code values including Code Values and Substance lists.
            this.BusyMessage = this.translate.instant('common.retrievingCodeValueListFromServer');
            this.appInsightsService.logEvent("login:"+ this.BusyMessage);
            this.onlineService.GetLoginCodeValues(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe(
              (codeValueList: any) => {
                this.BusyMessage = this.translate.instant('common.storingCodeValueList');
                this.appInsightsService.logEvent("login:"+ this.BusyMessage);
                this.globalCodeValueList = codeValueList.codeValueList;
                this.globalGenericList = codeValueList.genericSubstanceList;
                this.appInsightsService.logEvent(this.translate.instant('common.gotCodeValuesAtLogin'));
                this.onlineService.createCodeValuesDBPromise(this.globalCodeValueList)
                  .then(() => {
                    this.BusyMessage = this.translate.instant('common.codeValuesDatabaseCreated');
                    this.appInsightsService.logEvent(this.translate.instant('common.gettingGenericCodeAtLogin'));
                    this.BusyMessage = this.translate.instant('common.storingGenericSubstances');
                    this.onlineService.loginGetGenericSubstances(this.globalGenericList);
                    this.appInsightsService.logEvent(this.translate.instant('common.createExposureDatabaseAtLogin'));
                    this.BusyMessage = this.translate.instant('common.creatingOfflineExposureStorage');
                    this.appInsightsService.logEvent(this.translate.instant('common.login') + ': ' + this.BusyMessage);
                    this.onlineService.CreateExposureDB().then(() => {
                      this.appInsightsService.logEvent(this.translate.instant('common.createdExposureDatabaseAtLogin'));
                        
                      if (!this.showPasswordExpiryModal && !this.showEmailVerificationPage) {
                        this.router.navigateByUrl(this.returnUrl); // $this.router.navigate(['/profile']);
                      }
                    });
                  })
                  .catch(function (err) {
                    this.appInsightsService.logEvent(this.translate.instant('common.errorInCreatingCodeValuesDatabaseAtLogin') + " ",err.message);
                    const errorLog = {
                      CentreId: null,
                      LoggedByUserId: null,
                      CustomErrorMessage: err.message,
                      ErrorMessage: err.message,
                      InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                    };

                    this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                      this.ShowToastError(this.translate.instant('common.somethingWentWrongAddingAnErrorMessage') + '. ' + this.translate.instant('common.pleaseTryAgain'));
                      // TODO: Log Error
                    });
                  });
              },
              (err: HttpErrorResponse) => {
                const errorLog = {
                  CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                  LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                  CustomErrorMessage: err.message,
                  ErrorMessage: err.message,
                  InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                };
                if (navigator.onLine) {
                  this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                    this.ShowToastError(this.translate.instant('common.somethingWentWrongRetrievingCodeValues') + '. ' + this.translate.instant('common.refreshYourBrowser') + '. ' + 
                    this.translate.instant('common.ifStillAProblem') + ', ' + this.translate.instant('common.logout') + ', ' + this.translate.instant('common.closeYourBrowserAndStartAgain'));
                    // TODO: Log Error!
                  });
                } else {
                  const toasterValue = {
                    type: 'warning',
                    message: this.translate.instant('common.youAreWorkingOffline') + '. ' + this.translate.instant('common.pleaseReloadThePageToContinue')
                  };
                  this.toastService.showSpinner(toasterValue);
                  window.location.reload();
                  this.spinner.hide();
                }
              }
            );

            // get the substance search into the IndexedDB
            this.appInsightsService.logEvent("Getting SubSearch2 at login");
            this.LoadSubstanceSearch2();
            this.appInsightsService.logEvent("Done with SubSearch2 at login");
            
          } else {
            this.BusyMessage = this.translate.instant('common.retrievingCodeValueList');
            this.onlineService.GetLoginCodeValues(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe(
              (codeValueList: any) => {
                this.globalCodeValueList = codeValueList.codeValueList;
                this.globalGenericList = codeValueList.genericSubstanceList;

                this.onlineService.createCodeValuesDBPromise(this.globalCodeValueList)
                  .then(() => {
                    this.onlineService.loginGetGenericSubstances(this.globalGenericList);
                    this.onlineService.CreateExposureDB().then(() => {
                      // $this.spinner.hide();
                      if (!this.showPasswordExpiryModal && !this.showEmailVerificationPage) {
                        this.router.navigateByUrl(this.returnUrl);
                      }
                    });
                  })
                  .catch(function (err) {
                    const errorLog = {
                      CentreId: null,
                      LoggedByUserId: null,
                      CustomErrorMessage: err.message,
                      ErrorMessage: err.message,
                      InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                    };
                    this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                      this.ShowToastError(this.translate.instant('common.somethingWentWrongAddingAnErrorMessage') + '. ' + this.translate.instant('common.pleaseTryAgain'));
                      // TODO: Log Error
                    });
                  });
              },
              (err: HttpErrorResponse) => {
                const errorLog = {
                  CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                  LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                  CustomErrorMessage: err.message,
                  ErrorMessage: err.message,
                  InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                };
                if (navigator.onLine) {
                  this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                    this.ShowToastError(this.translate.instant('common.somethingWentWrongRetrievingCodeValues') + '. ' + this.translate.instant('common.refreshYourBrowser') + '. ' + this.translate.instant('common.ifStillAProblem') + ', ' + 
                    this.translate.instant('common.logout') + ', ' + this.translate.instant('common.closeYourBrowserAndStartAgain'));
                    // TODO: Log Error!
                  });
                } else {
                  const toasterValue = {
                    type: 'warning',
                    message: this.translate.instant('common.youAreWorkingOffline') + '. ' + this.translate.instant('common.pleaseReloadThePageToContinue')
                  };
                  this.toastService.showSpinner(toasterValue);
                  window.location.reload();
                  this.spinner.hide();
                }
              }
            );
            // get the substance search into the indexeddb
            this.LoadSubstanceSearch2();
          }
        }
      } else {
        if (this.hasUserSettings) {
          if (this.hasUserSettings && this.systemUserSettings == null) {
            this.spinner.hide();
            this.showPasswordExpiryModal = true;
            this.newPwd = '';
            this.confirmNewPwd = '';
            this.isFirstLogin = true;
            this.changePasswordTitle = this.translate.instant('common.firstLogin') + ' - ' + this.translate.instant('common.resetPassword');
            this.isChangePwd = false;
            this.pwdInfo = false;
            this.pwdRequirementShow = false;
            (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
            setTimeout(() => {
              const inputElement = document.getElementById('login-change-pwd-btn');
              if (inputElement != null && inputElement != undefined) {
                inputElement.focus();
              }
            }, 100);
          } else if (this.hasUserSettings) {
            this.onlineService.GetLoginCodeValues(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe(
              (codeValueList: any) => {
                this.globalCodeValueList = codeValueList.codeValueList;
                this.globalGenericList = codeValueList.genericSubstanceList;
                this.onlineService
                  .createCodeValuesDBPromise(this.globalCodeValueList)
                  .then(() => {
                    this.onlineService.loginGetGenericSubstances(this.globalGenericList);
                    this.onlineService.CreateExposureDB().then(() => {
                      // $this.spinner.hide();
                      this.router.navigate(['/profile']);
                    });
                  })
                  .catch(function (err) {
                    const errorLog = {
                      CentreId: null,
                      LoggedByUserId: null,
                      CustomErrorMessage: err.message,
                      ErrorMessage: err.message,
                      InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                    };
                    this.onlineService.AddErrorLog(errorLog).subscribe(function () {
                      this.ShowToastError(this.translate.instant('common.somethingWentWrongAddingAnErrorMessage') + '. ' + this.translate.instant('common.pleaseTryAgain'));
                      // TODO: Log Error
                    });
                  });
              },
              (err: HttpErrorResponse) => {
                const errorLog = {
                  CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                  LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                  CustomErrorMessage: err.message,
                  ErrorMessage: err.message,
                  InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                };
                if (navigator.onLine) {
                  this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                    this.ShowToastError(this.translate.instant('common.somethingWentWrongRetrievingCodeValues') + '. ' + this.translate.instant('common.refreshYourBrowser') + '. ' + 
                    this.translate.instant('common.ifStillAProblem') + ', ' + this.translate.instant('common.logout') + ', ' + this.translate.instant('common.closeYourBrowserAndStartAgain') + '.');
                    // TODO: Log Error!
                  });
                } else {
                  const toasterValue = {
                    type: 'warning',
                    message: this.translate.instant('common.youAreWorkingOffline') + '. ' + this.translate.instant('common.pleaseReloadThePageToContinue')
                  };
                  this.toastService.showSpinner(toasterValue);
                  window.location.reload();
                  this.spinner.hide();
                }
              }
            );
            // get the substance search into the indexeddb
            this.LoadSubstanceSearch2();
          }
        } else {  // No User settings!
          this.onlineService.GetLoginCodeValues(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10)).subscribe(
            (codeValueList: any) => {
              this.globalCodeValueList = codeValueList.codeValueList;
              this.globalGenericList = codeValueList.genericSubstanceList;
              this.onlineService
                .createCodeValuesDBPromise(this.globalCodeValueList)
                .then(() => {
                  this.onlineService.loginGetGenericSubstances(this.globalGenericList);
                  this.onlineService.CreateExposureDB().then(() => {
                    // $this.spinner.hide();
                    this.router.navigateByUrl(this.returnUrl);
                  });
                })
                .catch(function (err) {
                  const errorLog = {
                    CentreId: null,
                    LoggedByUserId: null,
                    CustomErrorMessage: err.message,
                    ErrorMessage: err.message,
                    InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
                  };
                  this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                    this.ShowToastError(this.translate.instant('common.somethingWentWrongAddingAnErrorMessage') + '. ' + this.translate.instant('common.pleaseTryAgain'));
                    // TODO:Log Error!
                  });
                });
            },
            (err: HttpErrorResponse) => {
              var errorLog = {
                CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                CustomErrorMessage: err.message,
                ErrorMessage: err.message,
                InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
              };
              if (navigator.onLine) {
                this.onlineService.AddErrorLog(errorLog).subscribe(function () {
                  // TODO: Log Error
                });
              } else {
                const toasterValue = {
                  type: 'warning',
                  message: this.translate.instant('common.youAreWorkingOffline') + '. ' + this.translate.instant('common.pleaseReloadThePageToContinue')
                };
                this.toastService.showSpinner(toasterValue);
                window.location.reload();
                this.spinner.hide();
              }
            }
          );
          // get the substance search into the indexeddb
          this.LoadSubstanceSearch2();
        }
      }
    },
      (err: any) => {
        alert(this.translate.instant('common.errorAuditingLogin'));

      });
  }

  private LoadSubstanceSearch2() {
    this.substanceSearchService.getSubstanceSearchData().then(
      (SubstanceSearchData: any) => {
        this.substanceSearchService.loginGetSubstanceSearch(SubstanceSearchData);
      });
  }
  // private LoadSubstanceSearch($this: this) {
  //   $this.substanceSearchService.getSubstanceSearchData().then(
  //     (SubstanceSearchData: any) => {
  //       $this.substanceSearchService.CreateSubstanceSearchItemDB(SubstanceSearchData)
  //         .then(function () {
  //           $this.substanceSearchService.CreateSubstanceSearchItemDB(SubstanceSearchData);
  //         })
  //         .catch(function (err) {
  //           const errorLog = {
  //             CentreId: null,
  //             LoggedByUserId: null,
  //             CustomErrorMessage: err.message,
  //             ErrorMessage: err.message,
  //             InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
  //           };
  //           $this.onlineService.AddErrorLog(errorLog).subscribe(function () {
  //             this.ShowToastError('Something went wrong adding an error message. Please try again');
  //             // TODO: Log Error
  //           });
  //         });
  //     },
  //     (err: HttpErrorResponse) => {
  //       const errorLog = {
  //         CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
  //         LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
  //         CustomErrorMessage: err.message,
  //         ErrorMessage: err.message,
  //         InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
  //       };
  //       const $this2 = this;
  //       if (navigator.onLine) {
  //         this.onlineService.AddErrorLog(errorLog).subscribe(function () {
  //           this.ShowToastError('Something went wrong retrieving Substance Search List. Refresh your browser. If still a problem, Sign Out, close your browser and start again.');
  //           // TODO: Log Error!
  //         });
  //       } else {
  //         const toasterValue = {
  //           type: 'warning',
  //           message: 'You are working offline. Please reload the page to continue'
  //         };
  //         $this2.toastService.showSpinner(toasterValue);
  //         window.location.reload();
  //         $this2.spinner.hide();
  //       }
  //     }
  //   );
  // }

  private setStatusToOnline() {
    localStorage.setItem(AppConstants.LocalStorage.OnLineStatus, 'true');
  }

  private centreListToCookies($this: this, centreId: any, centersList: any) {
    if ($this.systemUserSettings != null && $this.systemUserSettings.centreId != null) {
      centreId = $this.systemUserSettings.centreId;
      $this._cookieService.set(
        AppConstants.CookieName.CenterId,
        centreId,
        $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
        '/', null, true, "Strict"
      );
      if (centersList.length > 0) {
        for (var k = 0; k < centersList.length; k++) {
          if (centreId == centersList[k].centreId) {
            $this._cookieService.set(
              AppConstants.CookieName.CenterName,
              centersList[k].centreName,
              $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            $this._cookieService.set(
              AppConstants.CookieName.CenterInitial,
              centersList[k].centreInitial,
              $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            $this._cookieService.set(
              AppConstants.CookieName.CenterState,
              centersList[k].centreState,
              $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
            $this._cookieService.set(
              AppConstants.CookieName.CenterCountry,
              centersList[k].centreCountry,
              $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
              '/', null, true, "Strict"
            );
          }
        }
      }
    }
    if (centreId == null) {
      $this._cookieService.set(
        AppConstants.CookieName.CenterId,
        centersList[0].centreId,
        $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
        '/', null, true, "Strict"
      );
      $this._cookieService.set(
        AppConstants.CookieName.CenterName,
        centersList[0].centreName,
        $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
        '/', null, true, "Strict"
      );
      $this._cookieService.set(
        AppConstants.CookieName.CenterInitial,
        centersList[0].centreInitial,
        $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
        '/', null, true, "Strict"
      );
      $this._cookieService.set(
        AppConstants.CookieName.CenterState,
        centersList[0].centreState,
        $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
        '/', null, true, "Strict"
      );
      $this._cookieService.set(
        AppConstants.CookieName.CenterCountry,
        centersList[0].centreCountry,
        $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays,
        '/', null, true, "Strict"
      );
    }
    return centreId;
  }

  private saveUserNameToCookie($this: this) {
    $this._cookieService.set(
      AppConstants.CookieName.UserDisplayName,
      $this.globalUserDetails.firstName != null && this.globalUserDetails.firstName.trim() !== ''
        && $this.globalUserDetails.lastName != null && this.globalUserDetails.firstName.trim() !== ''
        ? $this.globalUserDetails.lastName + ', ' + $this.globalUserDetails.firstName
        : $this.globalUserDetails.firstName != null && this.globalUserDetails.firstName.trim() !== ''
          ? $this.globalUserDetails.firstName
          : $this.globalUserDetails.lastName != null && this.globalUserDetails.lastName.trim() !== ''
            ? $this.globalUserDetails.lastName
            : '',
      $this.isRememberPasswordChecked ? $this.cookieExpirationMaximumInDays : $this.cookieExpirationMinimumInDays, '/'
    );
  }

  ngAfterViewInit() {
    this.themeService.ApplyThemeFromCookie(document.querySelector('body'), null);
    this.onlineService.removeFocussedUrl();
    // populate from the server public items
    if (navigator.onLine) {
      this.onlineService.GetAssemblyVersion().subscribe(
        (result: any) => {
          this.assemblyVersion = 'V ' + result;
          let userNameElement = document.getElementById(AppConstants.CookieName.UserDisplayName);
          let mobileUserNameElement = document.getElementById(AppConstants.CookieName.UserDisplayName);
          if (userNameElement != null && userNameElement != undefined) {
            userNameElement.focus();
          }
          if (mobileUserNameElement != null && mobileUserNameElement != undefined) {
            mobileUserNameElement.focus();
          }
        },
        (err: HttpErrorResponse) => {
          var errorLog = {
            CentreId: null,
            LoggedByUserId: null,
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
          };
          const $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            $this.spinner.hide();
            $this.invalidLoginError = true;
            $this.loginBtnActive = false;
          });
        }
      );
      this.onlineService.GetLoginMessage().subscribe(
        (result: any) => {
          this.loginMessage = result;
        },
        (err: HttpErrorResponse) => {
          var errorLog = {
            CentreId: null,
            LoggedByUserId: null,
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
          };
          const $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            $this.spinner.hide();
          });
        }
      );
      this.onlineService.GetOwnerName().subscribe(
        (result: any) => {
          this.ownerName = result;
        },
        (err: HttpErrorResponse) => {
          var errorLog = {
            CentreId: null,
            LoggedByUserId: null,
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
          };
          const $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            $this.spinner.hide();
          });
        }
      );
    }
  }


  ngOnInit() {
    this.appInsightsService.logPageView('Login'); // add a page view!
    // get the browser langauge
    const browserShortLanguage = this.getBrowserShortLanguageCode();
    // get the saved language from local storage 
    let languageToUse = browserShortLanguage
    try {
      const savedLanguage = JSON.parse(localStorage.getItem(AppConstants.LocalStorage.Language));  
      languageToUse= savedLanguage ?? browserShortLanguage;
    } catch (error) {
      // check if old syntax without being json'ed and 2 characters long
      if (error instanceof SyntaxError && localStorage.getItem(AppConstants.LocalStorage.Language).length==2) {    
        languageToUse = localStorage.getItem(AppConstants.LocalStorage.Language);
      }
      else {
        languageToUse = browserShortLanguage; // default to browser language
      }
    }   
    
    // const languageToUse = savedLanguage ?? browserShortLanguage;
    this.currentLanguage = languageToUse;
    // set the language
    this.translate.use(languageToUse ? languageToUse : AppConstants.DefaultLangaugeCode);    
  // save to local storage correctly as json
    localStorage.setItem(AppConstants.LocalStorage.Language, JSON.stringify(languageToUse));
    this.copyRightYear = '2017-' + new Date().getFullYear(); // TODO: Replace getFullYear with actual dates.
    this.applicationVersion = environment.version;
    this.titleService.setTitle(this.translate.instant('common.signInto') + " " + 'ToxAware' + '®');
    // check for a return url in local storage - Disabled 19/11/2021 DPR
    // localStorage.getItem('returnUrl') == '' || localStorage.getItem('returnUrl') == undefined ? localStorage.setItem('returnUrl', '') : '';
    // if (localStorage.getItem('returnUrl') != '') {
    //   this.router.navigate([], { relativeTo: this.route, queryParams: { returnUrl: localStorage.getItem('returnUrl') }, queryParamsHandling: 'merge' });
    // }

    // check for return Url in Query parameters. If not found, go home.
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    // check for cookies existing and have non-blank or null value
    if (
      this._cookieService.get(AppConstants.CookieName.UserToken) != '' &&
      this._cookieService.get(AppConstants.CookieName.LoginUserName) != '' &&
      this._cookieService.get(AppConstants.CookieName.CenterId) != '' &&
      this._cookieService.get(AppConstants.CookieName.UserId) != '' &&
      this._cookieService.get(AppConstants.CookieName.UserDisplayName) != '' &&
      this._cookieService.get(AppConstants.CookieName.CenterCountry) != '' &&
      this._cookieService.get(AppConstants.CookieName.CenterInitial) != '' &&
      this._cookieService.get(AppConstants.CookieName.CenterName) != '' &&
      this._cookieService.get(AppConstants.CookieName.CenterId) != 'null' &&
      this._cookieService.get(AppConstants.CookieName.CenterState) != ''
    ) {
      this.skipAuthentication = true;

      // check if online and
      if (navigator.onLine && localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
        this.userSettingEvaluation(this._cookieService.get(AppConstants.CookieName.LoginUserName));
      } else {
        this.spinner.show();
        this.router.navigateByUrl(this.returnUrl);
      }
    }

    this.showLoginPage = true;
    this.showSecurityVerificationPage = false;
    this.showResetPassword = false;
    this.showEmailVerificationPage = false;
    this.showSmsVerificationPage = false;
    this.showEmailVerificationCodePage = false;
    this.showSmsVerificationCodePage = false;
  }

  private getBrowserShortLanguageCode() {
    let browserLanguage = window.navigator.languages ? window.navigator.languages[0] : null;
    browserLanguage = browserLanguage || window.navigator.language; // || window.navigator.browserLanguage || window.navigator.userLanguage;

    // get a 2 letter language
    let browserShortLanguage = browserLanguage;
    if (browserShortLanguage.indexOf('-') !== -1)
      browserShortLanguage = browserShortLanguage.split('-')[0];
    if (browserShortLanguage.indexOf('_') !== -1)
      browserShortLanguage = browserShortLanguage.split('_')[0];
    return browserShortLanguage;
  }

  publicComputerInfoClick() {
    if (this.publicComputerInfo == false) {
      this.publicComputerInfo = true;
    } else {
      this.publicComputerInfo = false;
    }
  }

  resendEmailcodeClick() {
    this.BusyMessage = this.translate.instant('common.resendingEmail') + '...';
    this.spinner.show();

    var userSettingInfo = {
      UserId: this.userId,
      CentreId: null,
      CentreName: null,
      AuthenticationTypeId: null,
      AuthenticationTypeName: null,
      EmailVerificationCode: null,
      LastTwoFactorAuthenticationDate: null,
      UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
    };

    this.onlineService.emailVerificationCodeEntry(userSettingInfo).subscribe((emailCode: any) => {
      if (emailCode.isSuccess) {
        this.spinner.hide();
        var toasterValue = {
          type: 'success',
          message: this.translate.instant('common.verificationCodeSentSuccessfully')
        };
        this.toastService.showSpinner(toasterValue);
      } else {
        this.ShowToastError(this.translate.instant('common.somethingWentWrongSendingTheVerificationCode') + '. ' + this.translate.instant('common.pleaseTryAgain'));
        // TODO: Log Error!
      }
      this.spinner.hide();
    });
  }

  resendSMScodeClick() {
    this.BusyMessage = this.translate.instant('common.resendingSmsCode') + '...';
    this.spinner.show();
    var userSettingInfo = {
      UserId: this.userId,
      CentreId: null,
      CentreName: null,
      AuthenticationTypeId: null,
      AuthenticationTypeName: null,
      EmailVerificationCode: null,
      LastTwoFactorAuthenticationDate: null,
      UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
    };

    this.onlineService.smsVerificationCodeEntry(userSettingInfo).subscribe((smsCode: any) => {
      if (smsCode.isSuccess) {
        this.spinner.hide();
        var toasterValue = {
          type: 'success',
          message: this.translate.instant('common.verificationCodeSentSuccessfully')
        };
        this.toastService.showSpinner(toasterValue);
      } else {
        this.ShowToastError(this.translate.instant('common.somethingWentWrongWithTheSmsVerification') + '. ' +  this.translate.instant('common.pleaseTryAgain'));
        // TODO: Log Error!
      }
      this.spinner.hide();
    });
  }

  loginBack() {
    const $this = this;

    var auditDetail = {
      ModuleName: 'Login',
      OperationTypeName: 'Logout',
      CentreId: $this._cookieService.get(AppConstants.CookieName.CenterId),
      LoggedByUserId: $this._cookieService.get(AppConstants.CookieName.UserId),
      AuditMessage: $this._cookieService.get(AppConstants.CookieName.LoginUserName) + " " + this.translate.instant('common.loggedOutOf') + " " + 'ToxAware'
    };
    this.BusyMessage = this.translate.instant('common.auditingLogin') + '...';
    $this.onlineService.AddAudit(auditDetail).subscribe(function () {
      $this._cookieService.deleteAll('/');
      $this.showLoginPage = true;
      $this.showSecurityVerificationPage = false;
      $this.showResetPassword = false;
      $this.showEmailVerificationPage = false;
      $this.showSmsVerificationPage = false;
      $this.showEmailVerificationCodePage = false;
      $this.showSmsVerificationCodePage = false;
    });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key.toLocaleLowerCase() == 'enter' && event.keyCode == 13) {
      if (
        this.password != '' &&
        this.userName != '' &&
        !this.showSmsVerificationCodePage &&
        !this.showEmailVerificationPage &&
        !this.showSecurityVerificationPage &&
        !this.showPasswordExpiryModal &&
        !this.showCodeVerifyPage &&
        !this.showForgotPasswordPage
      ) {
        this.loginClick();
        event.preventDefault();
      }
      if (this.showSecurityVerificationPage) {
        this.onSecurityQuestionsSubmit();
        event.preventDefault();
      }
      if (this.showEmailVerificationPage) {
        this.onSecurityEmailSubmit();
        event.preventDefault();
      }
      if (this.showSmsVerificationCodePage) {
        this.onSecuritySmsCodeSubmit();
        event.preventDefault();
      }
      if (this.showPasswordExpiryModal) {
        if (this.isForgotPassword == false) {
          this.saveUserPasswordClick();
          event.preventDefault();
        } else if (this.isForgotPassword) {
          this.confirmPasswordClick();
          event.preventDefault();
        }
      }
      if (this.showForgotPasswordPage && this.isValidEmail && this.email != '') {
        this.emailSubmitClick();
        event.preventDefault();
      }
      if (this.showCodeVerifyPage && this.code != '') {
        this.codeSubmitClick();
        event.preventDefault();
      }
    }
  }

  // Password change changes

  NewPwdFocusIn(args) {
    this.pwdRequirementShow = false;
    var pwd = this.newPwd;
    var confirmPwd = this.confirmNewPwd;
    let pattern1 = '^(?=.{';
    let pattern2 = ',}).+$';
    var pwdLength = new RegExp(pattern1 + this.hasLength + pattern2);
    var pwdUpperCase = /^(?=.*[A-Z]).+$/;
    var pwdLowerCase = /^(?=.*[a-z]).+$/;
    var pwdNumber = /^(?=.*\d).+$/;
    var pwdSpecialChar = /^(?=.*(_|[^\w])).+$/;
    this.isChangePwd = false;
    var pwdchange = true;

    if (this.existingPassword == this.newPwd && this.isPasswordExist) {
      this.pwdRequirementShow = false;
      this.isChangePwd = false;
    } else {
      this.isPasswordExist = false;
      this.existingPassword = '';

      if (pwdLength.test(pwd)) this.pwdLengthChecked = true;
      else {
        this.pwdLengthChecked = false;
        pwdchange = false;
      }
      if (this.hasUpperCase) {
        if (pwdUpperCase.test(pwd)) this.pwdUpperCaseChecked = true;
        else {
          this.pwdUpperCaseChecked = false;
          pwdchange = false;
        }
      } else {
        this.pwdUpperCaseChecked = true;
      }

      if (this.hasLowerCase) {
        if (pwdLowerCase.test(pwd)) this.pwdLowerCaseChecked = true;
        else {
          this.pwdLowerCaseChecked = false;
          pwdchange = false;
        }
      } else {
        this.pwdLowerCaseChecked = true;
      }

      if (this.hasNumber) {
        if (pwdNumber.test(pwd)) this.pwdNumberChecked = true;
        else {
          this.pwdNumberChecked = false;
          pwdchange = false;
        }
      } else {
        this.pwdNumberChecked = true;
      }

      if (this.hasSpecialChar) {
        if (pwdSpecialChar.test(pwd)) this.pwdSpecialCharChecked = true;
        else {
          this.pwdSpecialCharChecked = false;
          pwdchange = false;
        }
      } else {
        this.pwdSpecialCharChecked = true;
      }

      if (pwd.length == 0) {
        pwdchange = false;
        this.pwdRequirementShow = false;
        (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = this.translate.instant('common.pleaseEnterYourNewPassword');
      } else {
        this.pwdRequirementShow = true;
        (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = '';
      }
      if (confirmPwd.length == 0) {
        pwdchange = false;
        (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = this.translate.instant('common.pleaseConfirmYourNewPassword');
      } else (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
      if (pwd != confirmPwd && confirmPwd.length != 0) {
        pwdchange = false;
        (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = this.translate.instant('common.passwordsMismatch');
      }
      if (this.pwdSpecialCharChecked && this.pwdNumberChecked && this.pwdLowerCaseChecked && this.pwdUpperCaseChecked && this.pwdLengthChecked) {
        this.pwdInfo = true;
      } else {
        this.pwdInfo = false;
      }

      this.isChangePwd = pwdchange;
    }
  }

  ConfirmPwdFocusIn(args) {
    var confirmPwd = this.confirmNewPwd;
    var pwd = this.newPwd;
    var pwdchange = true;
    if (this.existingPassword == this.newPwd && this.isPasswordExist) {
      this.pwdRequirementShow = false;
      this.isChangePwd = false;
    } else {
      if (confirmPwd.length == 0) {
        pwdchange = false;
        (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = this.translate.instant('common.pleaseConfirmYourNewPassword')
      } else (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
      if (pwd != confirmPwd && confirmPwd.length != 0) {
        pwdchange = false;
        (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = this.translate.instant('common.passwordsMismatch');
      }
      this.isChangePwd = pwdchange;
    }
  }

  onCurrentPwdViewClick(args) {
    if (this.isCurrentPwdView) this.isCurrentPwdView = false;
    else this.isCurrentPwdView = true;
  }
  onNewPwdViewClick(args) {
    if (this.isNewPwdView) this.isNewPwdView = false;
    else this.isNewPwdView = true;
  }
  onConfirmPwdViewClick(args) {
    if (this.isConfirmPwdView) this.isConfirmPwdView = false;
    else this.isConfirmPwdView = true;
  }

  saveUserPasswordClick() {
    if (this.isChangePwd && this.pwdInfo) {
      this.spinner.show();
      this.BusyMessage = this.translate.instant('common.savingUserPassword') + '...';

      let userDetail = {
        UserId: parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10),
        Password: this.newPwd,
        UserName: this._cookieService.get(AppConstants.CookieName.LoginUserName)
      };
      this.existingPassword = this.newPwd;
      if (this.isFirstLogin) {
        this.onlineService.setPasswordOnFirstLogin(userDetail).subscribe(
          (status: any) => {
            if (status.isSuccess) {
              this.spinner.hide();
              this.loginBackOnChangePassword();
              this.isFirstLogin = false;
              var toasterValue = {
                type: 'success',
                message: this.translate.instant('common.passwordHasBeenUpdatedSuccessfully')
              };
              this.toastService.showSpinner(toasterValue);
            } else {
              this.spinner.hide();
              if (status.isPasswordExist) {
                this.isPasswordExist = true;
                this.pwdRequirementShow = false;
                this.isChangePwd = false;
                (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = status.errorMessage;
                this.spinner.hide();
              } else {
                this.ShowToastError(this.translate.instant('common.anErrorOccurred') + '. ' + status.ErrorMessage + '. ' + this.translate.instant('common.pleaseTryAgain'));
                // TODO: Log Error
              }
            }
          },
          (err: HttpErrorResponse) => {
            this.ShowToastError(this.translate.instant('common.somethingWentWrong') + '. ' + this.translate.instant('common.pleaseTryAgain'));
            // TODO: Log Error
          }
        );
      } else {
        this.onlineService.setPassword(userDetail).subscribe(
          (status: any) => {
            if (status.isSuccess) {
              this.spinner.hide();
              this.loginBackOnChangePassword();
              var toasterValue = {
                type: 'success',
                message: this.translate.instant('common.passwordHasBeenUpdatedSuccessfully')
              };
              this.toastService.showSpinner(toasterValue);
            } else {
              this.spinner.hide();
              if (status.isPasswordExist) {
                this.isPasswordExist = true;
                this.pwdRequirementShow = false;
                this.isChangePwd = false;
                (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = status.errorMessage;
                this.spinner.hide();
              } else {
                this.ShowToastError(this.translate.instant('common.somethingWentWrongWithLoginBackOnChangePassword') + '. ' +  this.translate.instant('common.pleaseTryAgain'));
                // TODO: Log Error
              }
            }
          },
          (err: HttpErrorResponse) => {
            this.ShowToastError(this.translate.instant('common.somthingWentWrongSettingPassword') + '. ' +  this.translate.instant('common.pleaseTryAgain'));
            // TODO: Log Error
          }
        );
      }
    }
  }

  changePasswordCancelClick() {
    this.showPasswordExpiryModal = false;
    // password form reset
    this.newPwd = '';
    this.confirmNewPwd = '';
    this.isChangePwd = false;
    this.isCurrentPwdView = false;
    this.isNewPwdView = false;
    this.isConfirmPwdView = false;
    this.pwdRequirementShow = false;
    this.pwdLengthChecked = false;
    this.pwdUpperCaseChecked = false;
    this.pwdLowerCaseChecked = false;
    this.pwdNumberChecked = false;
    this.pwdSpecialCharChecked = false;
    this.hasUpperCase = true;
    this.hasLowerCase = true;
    this.hasSpecialChar = true;
    this.hasNumber = true;
    this.hasLength = 6;
    this.isPasswordExist = false;
    (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
    (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = '';
    // password form reset
  }

  loginBackOnChangePassword() {
    this.showPasswordExpiryModal = false;
    this._cookieService.deleteAll('/');
    // password form reset
    this.newPwd = '';
    this.confirmNewPwd = '';
    this.isChangePwd = false;
    this.pwdInfo = false;
    this.isCurrentPwdView = false;
    this.isNewPwdView = false;
    this.isConfirmPwdView = false;
    this.pwdRequirementShow = false;
    this.pwdLengthChecked = false;
    this.pwdUpperCaseChecked = false;
    this.pwdLowerCaseChecked = false;
    this.pwdNumberChecked = false;
    this.pwdSpecialCharChecked = false;
    this.hasUpperCase = true;
    this.hasLowerCase = true;
    this.hasSpecialChar = true;
    this.hasNumber = true;
    this.hasLength = 6;
    this.isPasswordExist = false;
    (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
    (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = '';
    // password form reset

    this.showLoginPage = true;
    this.showSecurityVerificationPage = false;
    this.showResetPassword = false;
    this.showEmailVerificationPage = false;
    this.showSmsVerificationPage = false;
    this.showEmailVerificationCodePage = false;
    this.showSmsVerificationCodePage = false;
    this.showCodeVerifyPage = false;
    this.showForgotPasswordPage = false;
    this.isForgotPassword = false;
    this.isFormDirty = false;
    this.isValidEmail = true;
    this.isValidCode = true;
    this.isForgotPassword = false;
    this.email = '';
    this.code = '';
  }

  // To Forgot password page
  forgotPasswordClick() {
    this.showForgotPasswordPage = true;
    this.showLoginPage = false;
    this.isMailInValid = false;
    this.email = '';
    this.isValidEmail = true;
    this.isFormDirty = false;

    this.showSecurityVerificationPage = false;
    this.showResetPassword = false;
    this.showEmailVerificationPage = false;
    this.showSmsVerificationPage = false;
    this.showEmailVerificationCodePage = false;
    this.showSmsVerificationCodePage = false;
  }

  // To Find the field is dirty or not
  focusOutFunction(args) {
    if (args == '') {
      this.isFormDirty = true;
      this.isMailInValid = false;
      this.isValidEmail = true;
      this.isValidCode = true;
    } else {
      this.isFormDirty = false;
      this.pattern = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      this.isValidEmail = this.pattern.test(args);
      this.isMailInValid = false;
    }
  }

  // To validate the email
  emailSubmitClick() {
    this.spinner.show();
    this.isMailInValid = false;
    var data = {
      Email: this.email
    };
    this.onlineService.IsValidEmail(data).subscribe(
      status => {
        if (status.isSuccess) {
          this.spinner.hide();
          this.showCodeVerifyPage = true;
          this.code = '';
          this.showForgotPasswordPage = false;
          this.showLoginPage = false;
          this.isFormDirty = false;
        } else {
          this.spinner.hide();
          this.isMailInValid = true;
          this.UmsInvalidEmail = status.errorMessage;
        }
      },
      (err: HttpErrorResponse) => {
        this.ShowToastError(this.translate.instant('common.somethingWentWrong') + '. ' + this.translate.instant('common.pleaseTryAgain'));
        // TODO: Log Error
      }
    );
  }

  // To validate the Password Reset Code
  codeSubmitClick() {
    this.spinner.show();
    var data = {
      Email: this.email,
      ResetPasswordCode: this.code
    };
    this.onlineService.IsValidCode(data).subscribe(
      status => {
        if (status.isSuccess) {
          this.showCodeVerifyPage = false;
          this.showForgotPasswordPage = false;
          this.showLoginPage = true;
          this.showSecurityVerificationPage = false;
          this.showResetPassword = false;
          this.showEmailVerificationPage = false;
          this.showSmsVerificationPage = false;
          this.showEmailVerificationCodePage = false;
          this.showSmsVerificationCodePage = false;
          // this.showConfirmPasswordPage=true;
          this.onlineService.GetPasswordConfiguration().subscribe(
            (passwordSettings: any) => {
              this.spinner.hide();
              if (passwordSettings != null && passwordSettings.expirationDays != 0) {
                this.hasLength = passwordSettings.minimumCharacters != null ? passwordSettings.minimumCharacters : this.hasLength;
                this.hasUpperCase = passwordSettings.shouldContainUpperCase != null ? passwordSettings.shouldContainUpperCase : this.hasUpperCase;
                this.hasLowerCase = passwordSettings.shouldContainLowerCase != null ? passwordSettings.shouldContainLowerCase : this.hasLength;
                this.hasNumber = passwordSettings.shouldContainNumeric != null ? passwordSettings.shouldContainNumeric : this.hasNumber;
                this.hasSpecialChar =
                  passwordSettings.shouldContainSpecialCharacter != null ? passwordSettings.shouldContainSpecialCharacter : this.hasSpecialChar;
              }
              this.passwordString = this.hasLength > 1 ? 's' : '';

              this.showPasswordExpiryModal = true;
              this.isForgotPassword = true;
              this.changePasswordTitle = this.translate.instant('common.resetPassword');
              this.newPwd = '';
              this.confirmNewPwd = '';
              this.isChangePwd = false;
              this.pwdInfo = false;
              this.pwdRequirementShow = false;
              (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
              setTimeout(() => {
                let inputElement = document.getElementById('login-change-pwd-btn');
                if (inputElement != null && inputElement != undefined) {
                  inputElement.focus();
                }
              }, 100);
            },
            (err: HttpErrorResponse) => {
              this.ShowToastError(this.translate.instant('common.somethingWentWrongGettingPassWordConfigurations') + '. ' + this.translate.instant('common.pleaseTryAgain'));
              // TODO: Log Error
            }
          );
          this.isFormDirty = false;
        } else {
          this.spinner.hide();
          this.isValidCode = false;
          this.isForgotPassword = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.isForgotPassword = false;
        this.ShowToastError(this.translate.instant('common.somethingWentWrongWithForgotPassword') + '. ' + this.translate.instant('Please try again'));
        // TODO: Log Error
      }
    );
  }

  // To resend the code
  resendCodeClick() {
    this.spinner.show();
    var data = {
      Email: this.email
    };
    this.onlineService.IsValidEmail(data).subscribe(
      status => {
        if (status.isSuccess) {
          this.spinner.hide();
          this.isValidCode = true;
          var toasterValue = {
            type: 'success',
            message: this.translate.instant('common.passwordResetCodeHasBeenSentToYourEmailAddress')
          };
          this.toastService.showSpinner(toasterValue);
        } else {
          this.ShowToastError(this.translate.instant('common.invalidEmailAddress') +', ' + this.translate.instant('common.pleaseCheckYourEmailAddress'));
          // TODO: Log Error
        }
      },
      (err: HttpErrorResponse) => {
        this.ShowToastError(this.translate.instant('common.somethingWentWrong') + '. ' + this.translate.instant('common.pleaseTryAgain'));
        // TODO: Log Error
      }
    );
  }

  // To change password
  confirmPasswordClick() {
    if (this.isChangePwd && this.pwdInfo) {
      this.spinner.show();
      var data = {
        Password: this.newPwd,
        Email: this.email
      };

      this.onlineService.ForgotPassword(data).subscribe(
        status => {
          if (status.isSuccess) {
            this.spinner.hide();
            var toasterValue = {
              type: 'success',
              message: this.translate.instant('common.passwordHasBeenUpdatedSuccessfully')
            };
            this.toastService.showSpinner(toasterValue);
            this.showCodeVerifyPage = false;
            this.showForgotPasswordPage = false;
            this.showLoginPage = true;
            this.showConfirmPasswordPage = false;
            this.showPasswordExpiryModal = false;
            this.isForgotPassword = false;
            this.isFormDirty = false;
            this.isValidEmail = true;
            this.isValidCode = true;
            // password form reset
            this.newPwd = '';
            this.confirmNewPwd = '';
            this.isChangePwd = false;
            this.isCurrentPwdView = false;
            this.isNewPwdView = false;
            this.isConfirmPwdView = false;
            this.pwdRequirementShow = false;
            this.pwdLengthChecked = false;
            this.pwdUpperCaseChecked = false;
            this.pwdLowerCaseChecked = false;
            this.pwdNumberChecked = false;
            this.pwdSpecialCharChecked = false;
            this.hasUpperCase = true;
            this.hasLowerCase = true;
            this.hasSpecialChar = true;
            this.hasNumber = true;
            this.hasLength = 6;
            this.isPasswordExist = false;
            (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
            (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = '';
            // password form reset
          } else {
            this.ShowToastError(this.translate.instant('common.somethingWentWrong') + '. ' + this.translate.instant('common.pleaseTryAgain'));
            // TODO: Log Error!
          }
        },
        (err: HttpErrorResponse) => {
          this.ShowToastError(this.translate.instant('common.somethingWentWrong') + '. ' + this.translate.instant('common.pleaseTryAgain'));
          // TODO: Log Error!
        }
      );
    }
  }

  // Resetting all to initial values
  backToLoginClick() {
    this.showCodeVerifyPage = false;
    this.showForgotPasswordPage = false;
    this.showLoginPage = true;
    this.showConfirmPasswordPage = false;
    this.isFormDirty = false;
    this.isValidEmail = true;
    this.isValidCode = true;
    this.isForgotPassword = false;
    this.isMailInValid = false;
    this.email = '';
    this.code = '';
    this.showPasswordExpiryModal = false;
    this._cookieService.deleteAll('/');
    // password form reset
    this.newPwd = '';
    this.confirmNewPwd = '';
    this.isChangePwd = false;
    this.isCurrentPwdView = false;
    this.isNewPwdView = false;
    this.isConfirmPwdView = false;
    this.pwdRequirementShow = false;
    this.pwdLengthChecked = false;
    this.pwdUpperCaseChecked = false;
    this.pwdLowerCaseChecked = false;
    this.pwdNumberChecked = false;
    this.pwdSpecialCharChecked = false;
    this.hasUpperCase = true;
    this.hasLowerCase = true;
    this.hasSpecialChar = true;
    this.hasNumber = true;
    this.hasLength = 6;
    this.isPasswordExist = false;
    (<HTMLInputElement>document.getElementById('errConfirmPwd')).innerText = '';
    (<HTMLInputElement>document.getElementById('errMsgNewPwd')).innerText = '';
    // password form reset
    this.showSecurityVerificationPage = false;
    this.showResetPassword = false;
    this.showEmailVerificationPage = false;
    this.showSmsVerificationPage = false;
    this.showEmailVerificationCodePage = false;
    this.showSmsVerificationCodePage = false;
  }

  clearCookiesLocalStorageAndIndexedDb() {
    this._cookieService.deleteAll('/');

    localStorage.clear();

    const indexedDbStoreNames = [AppConstants.GenericSubstanceSearchDBName, AppConstants.CodeValueDBName, AppConstants.SubstanceSearchDBName, AppConstants.ExposureOfflineDBName, AppConstants.QueryOfflineDBName]

    // indexedDbStoreNames.forEach((dbName) => {
    //   this.deleteIndexedDBStores(dbName);
    // });

    Promise.all([this.deleteIndexedDBStores(AppConstants.GenericSubstanceSearchDBName), this.deleteIndexedDBStores(AppConstants.CodeValueDBName), this.deleteIndexedDBStores(AppConstants.SubstanceSearchDBName), this.deleteIndexedDBStores(AppConstants.ExposureOfflineDBName), this.deleteIndexedDBStores(AppConstants.QueryOfflineDBName)])
      .catch((err) => { alert(this.translate.instant('common.errorRemovingDatabases') + ':' + err + '. ' + this.translate.instant('common.closeYourBrowserAndTryAgain')) })
      .then(() => {
        const toasterValue = {
          type: 'success',
          message: 'ToxAware' + " " + this.translate.instant('common.dataClearHasBeenRequested') + '.'
        };
        this.toastService.showSpinner(toasterValue);
      });
  }

  deleteIndexedDBStores(databaseName) {
    // check for cases before deleting
    switch (databaseName) {
      case AppConstants.ExposureOfflineDBName:
        // are there any cases in the DB?      
        this.offlineService.GetAllOfflineExposures().then((response: any) => {
          if (response != undefined && response.length > 0) {
            // show confirm!   
            if (!confirm(this.translate.instant('common.thereAre') + " " + response.length + " " + this.translate.instant('common.unsavedExposuresInYourOfflineStorage') + '. ' +
             this.translate.instant('common.areYouSureYouWantToDeleteThem') + '?')) {
              this.toastService.showSpinner({ type: 'info', message: this.translate.instant('common.exposureOfflineStoreWasNotDeleted') + '. '});
            }
          }
          else {
            return this.deleteIndexedDB(databaseName);
          }
        }
        );
        break;
      case AppConstants.QueryOfflineDBName:
        // are there any cases in the DB?      
        this.offlineService.GetAllOfflineQueries().then((response: any) => {
          if (response != undefined && response.length > 0) {
            // show confirm!   
            if (!confirm(this.translate.instant('common.thereAre') + " " + response.length + " " + this.translate.instant('common.unsavedQueriesInYourOfflineStorage') + '. ' +
             this.translate.instant('common.areYouSureYouWantToDeleteThem') + '?')) {
             this.toastService.showSpinner({ type: 'info', message: this.translate.instant('common.queryOfflineStoreWasNotDeleted') + '.'});
            }
          }
          else {
            return this.deleteIndexedDB(databaseName);
          }
        }
        );
        break;
      default:
        // Not a case storing IndexedDB, so just delete!
        break;
    }
    return this.deleteIndexedDB(databaseName);
  }

  deleteIndexedDB(databaseName) {
    return new Promise((resolve, reject) => {
        const req = indexedDB.deleteDatabase(databaseName);
        req.onerror = (err) => {
          // console.log('Unable to delete database ' + databaseName + ' from indexedDb');
          const toasterValue = {
            type: 'error',
            message: this.translate.instant('common.unableToDeleteDatabase') + " " + databaseName + " " + this.translate.instant('common.fromIndexedDatabaseDueToError') + '. ' +
            this.translate.instant('common.pleaseCloseAndRestartYourBrowser') + '.'
          };
          this.toastService.showSpinner(toasterValue);
          reject(toasterValue.message);
        };
        req.onblocked = (err) => {
          // console.log('Unable to delete database ' + databaseName + ' due to the operation being blocked');
          const toasterValue = {
            type: 'error',
            message: this.translate.instant('common.unableToDeleteDatabase') + " " + databaseName + " "  + this.translate.instant('common.dueToTheOperationBeingBlocked') + '. ' +
            this.translate.instant('common.pleaseCloseAndRestartYourBrowser') + '.'
          };
          this.toastService.showSpinner(toasterValue);
          reject(toasterValue.message);
        };
        req.onsuccess = () => {
          this.toastService.show(new ToasterValue(this.toastService.Type.Info, this.translate.instant('common.deletedIndexedDatabase') + " " + databaseName + '.'));
          resolve('success');
        }      
    });
  }
}
