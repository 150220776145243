import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Grid, GridComponent } from '@syncfusion/ej2-angular-grids';
import { ButtonPropsModel, DialogComponent,createSpinner, hideSpinner } from '@syncfusion/ej2-angular-popups';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, fromEvent } from 'rxjs';
import { AppConstants } from '../../app.constants';
import { LockInfo } from '../../models/LockInfoModel';
import { HomeService } from './../../Pages/home/home.service';
import { OnlineService } from './../../Providers/OnlineService';
import { ToasterService } from './../../Providers/ToasterService';
import { PermissionService } from "./../../Services/permission.service";
import { ThemeService } from './../../Services/theme.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

// HeaderComponent class
// Call on initialize
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  value: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public onlineService: OnlineService,
    public homeService: HomeService,
    private _cookieService: CookieService,
    public toastService: ToasterService,
    public themeService: ThemeService,
    private _hotkeysService: HotkeysService,
    public permissionService: PermissionService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
  ) {
    // Check for being online
    // TODO: Candidate for refactor out of component into service?
    this.navigationServiceInstance = this.onlineService.connectionDetectionStatus.subscribe((isOnline) => {
      this.newIsOnline = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
      if (this.newIsOnline == "true" && isOnline == "true") {
        this.onlineFunction();
      } else if (this.newIsOnline == "false" && isOnline == "false") {
        this.offlineFunction();
      } else {
        // localStorage.setItem(AppConstants.LocalStorage.OnLineStatus, 'true');
      }
    });

    // event handler for storage events?
    this.subscription = fromEvent(window, "storage").subscribe((e) => {
      this.newIsOnline = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
      if (this.newIsOnline == "true" && e["key"] == AppConstants.LocalStorage.OnLineStatus && e["newValue"] == "true") {
        this.onlineFunction();
      } else if (this.newIsOnline == "false" && e["key"] == AppConstants.LocalStorage.OnLineStatus && e["newValue"] == "false") {
        this.offlineFunction();
      } else {
      }
    });

    // subscribe to current Display name to get name & icon for user
    // to update user display name in header
    this.onlineService.currentDisplayName.subscribe((userDisplayName) => {
      this.UserDisplayName = userDisplayName != "" ? userDisplayName : this._cookieService.get(AppConstants.CookieName.UserDisplayName);
      this.onlineService.currentUserProfilePic.subscribe((userProfileImage) => {
        this.profileImageSrc = this.newIsOnline && userProfileImage !== null && userProfileImage !== "" ? userProfileImage : AppConstants.DefaultProfileImage;
      });
    });

    // check for the language in localstorage, if not found, set to default.
    this.SavedLanguageCode = JSON.parse(localStorage.getItem(AppConstants.LocalStorage.Language)) ?? this.SavedLanguageCode;
    // get page permissions for any page with header? Should the be in the main template? or maybe an authenticated template?
    this.getPagePermission();
  }

  setLanguageClick(languageCode) {
    this.translate.use(languageCode);
    localStorage.setItem(AppConstants.LocalStorage.Language, JSON.stringify(languageCode));
  }

  // Logout: Locks outstanding
  @ViewChild("locksOutDialog", { static: false })
  public locksOutDialog: DialogComponent;
  public locksOutstanding: LockInfo[] = [];
  // the locksoutdlgbuttons are set in the logout() function so that the translations are available
  public locksOutDlgButtons: ButtonPropsModel[] = [
    { click: this.logoutCancelClick.bind(this), buttonModel: { content: this.translate.instant('common.Cancel'), cssClass: "close-Dlg-Buttons" } },
    { click: this.logoutReleaseLocksClick.bind(this), buttonModel: { content: this.translate.instant('common.releaseLocksAndLogout'), cssClass: "close-Dlg-Buttons" } },
    { click: this.logoutKeepLocksClick.bind(this), buttonModel: { content: this.translate.instant('common.keepLocksAndLogOut'), cssClass: "close-Dlg-Buttons" } },
  ];
  public locksOutWidth = "600px";
  @ViewChild("locksOutstandingGrid", { static: false })
  public locksOutstandingGrid: Grid;

  public flyOutOpen: boolean = false;

 
  public SavedLanguageCode = AppConstants.DefaultLangaugeCode;
  public LanguageChoices = AppConstants.LanguageChoices;
  public selectedTab: number = 1;
  public centreList: object[];
  public parentData: number;
  public selectedCenterDetails: number;
  public themeValue = "1";
  public UserDisplayName = "";
  public hasUserSettings = false;
  public LockedInfoRequestId: string = null;
  public LockedExposureId: string = null;
  public LockedByUserName: string = null;
  public showLockWarningModal = false;

  public UserId = this._cookieService.get(AppConstants.CookieName.UserId);
  public profileImageSrc = AppConstants.DefaultProfileImage;
  public isRemembered: string = this._cookieService.get(AppConstants.CookieName.RememberMe);

  public newIsOnline: string = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
  public pingAPIIntervalId;
  public subscription: Subscription;
  public navigationServiceInstance: any = "";

  public caseLauncherInfoExpoId = "";

  public themeList: object[] = [
    { Id: "1", AppTheme: "Light" },
    { Id: "2", AppTheme: "Dark" },
    { Id: "3", AppTheme: "Mid-Light" },
    { Id: "4", AppTheme: "Mid-Dark" },
  ];

  
  public themeFields: object = { text: "AppTheme", value: "Id" };

  @ViewChild("launcherMultipleCasesGrid", { static: false })
  public launcherMultipleCasesGrid: GridComponent;
  public caseLauncherCaseInfoData: object[] = [];
  public caseTimeFormatOptions = { type: "dateTime", format: "dd/MM/yyyy HH:mm" };

  // Case Launcher Dialog variables
  public showCaseLauncherDialog = false;

  ngOnDestroy() {
    this.navigationServiceInstance.unsubscribe();
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.onlineService.doesConnectionExist();
    this.hasUserSettings = this._cookieService.get(AppConstants.CookieName.HasUserSettingsFlag).toLocaleLowerCase() == "false" ? false : true;
    this.translate.use(this.SavedLanguageCode);
  }

  logoutCancelClick() {
    this.locksOutDialog.hide();
  }
  logoutReleaseLocksClick() {
    //alert(this.translate.instant('common.releaseAndLogout') + '!');

    //Create an array of CaseLockInfo
    //Call service
    //then 
    // if: on success toast
    // otherwise: failure toast
    // finally: completeLogout
    console.log(this.locksOutstanding);
    this.spinner.show();
    
    this.onlineService.deleteAllLocks(this.locksOutstanding).subscribe(
      (caseLockDeletionStatuses: any[]) => {
        
        const overAllStatusFailure = caseLockDeletionStatuses.some(function (caseLockDeletionStatus: any, i: any) {
          return caseLockDeletionStatus.isSuccess === false;
        });

        let toasterValue = {
          type: "info",
          message: this.translate.instant('common.caseLocksSuccessfullyRemoved'),
        };
        if (overAllStatusFailure){
          toasterValue = {
            type: 'warning',
            message: this.translate.instant('common.somethingWentWrongDeletingAllLocks') + '.'
          };
        }
        
        this.toastService.showSpinner(toasterValue);

        this.completeLogout();
      },
      (err: HttpErrorResponse) => {
        if (this.onlineService.CheckErrorStatus(err)) {
          this.spinner.hide();
          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) === 'true') {
            this.onlineService.doesConnectionExist();
          }
        } else {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description
          };
          const $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            const toasterValue = {
              type: 'error',
              message: this.translate.instant('common.somethingWentWrongDeletingAllLocks') + '. ' + this.translate.instant('common.pleaseTryAgain')
            };
            $this.toastService.showSpinner(toasterValue);
            $this.spinner.hide();
          });
        }

        this.completeLogout();
      }
    );
  }
  logoutKeepLocksClick() {
    console.log(this.locksOutstanding);
    //alert(this.translate.instant('common.keepLocksAndLogOut') + '!');
    this.completeLogout();
  }

  // Language Events 

 
//   import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

// // ...

// export class YourComponent {
//   // Flag to control closing behavior
//   isLanguageChanged = false;

//   // Reference to the dropdownlist
//   @ViewChild('languageDD') languageDD: DropDownListComponent;

//   // ...

//   onBeforeClose(args: any) {
//     // If language has changed, allow the dropdown to close
//     if (this.isLanguageChanged) {
//       this.isLanguageChanged = false;
//     } else {
//       args.cancel = true;
//     }
//   }

//   languageChanged(event: any) {
//     // Set the flag when the language changes
//     this.isLanguageChanged = true;
//     // Your other language change logic here...
//   }

//   // ...
// }

  languageChanged(args) {
    this.SavedLanguageCode = args.value;
    this.translate.use(this.SavedLanguageCode);
    localStorage.setItem(AppConstants.LocalStorage.Language, JSON.stringify(this.SavedLanguageCode));
  }
  
  // onBeforeClose(args: any) {
  //   // Prevent closing the dropdown if no item is selected
  //   if (args && args.event && args.event.type === 'keydown') {
  //     args.cancel = true;
  //   }
  // }
  onBeforeClose(args: any) {
    if (args && args.event && args.event.type === 'keydown' && !this.value) {
      args.cancel = true;
    }
  }

  completeLogout() {
    // alert (this.translate.instant('common.logOut') + '!');
    const auditDetail = {
      ModuleName: AppConstants.Module.Login,
      OperationTypeName: "Logout",
      CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
      LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
      AuditMessage: this._cookieService.get(AppConstants.CookieName.LoginUserName) + this.translate.instant('common.loggedOut'),
    };
    this.onlineService.AddAudit(auditDetail).subscribe(
      () => {
        this.onlineService.logout().subscribe(
          (results) => {
            if (results.isSuccess) {
              this._cookieService.deleteAll('/');
              const SavedLanguageCode = JSON.parse(localStorage.getItem(AppConstants.LocalStorage.Language));
              localStorage.clear();
              localStorage.setItem(AppConstants.LocalStorage.Language, JSON.stringify(SavedLanguageCode));
              // Navigate to login
              window.location.href = AppConstants.Urls.RootLogin;
              this.router.navigate([AppConstants.Urls.RootLogin], { queryParams: { returnUrl: window.location.pathname } });
            } else {
              alert(this.translate.instant("common.logoutFailed") + " " + results.message);
            }
          }
        );
        
      },
      (err) => {
        alert(err);
      }
    );
  }

  private updatePermissions() {
    this.permissionService.GetPermissionsPromise(this.UserId).then(() => {
      this.createHotkeys();
    });
    // this.onlineService.getPagePermission(parseInt(this.UserId, 10)).subscribe((pagePermissionList: any) => {
    //   pagePermissionList.forEach((specificPagePermission) => {

    //     if (specificPagePermission.moduleName == 'Exposure') {
    //       if (specificPagePermission['canAdd']) {
    //         this.permissionService.isExposurePageHasAddPermission = true;
    //       } else {
    //         this.permissionService.isExposurePageHasAddPermission = false;
    //       }
    //       this.permissionService.isExposurePageHasViewPermission = specificPagePermission['isVisible'];
    //       this.permissionService.isExposurePageHasEditPermission = specificPagePermission['canEdit'] != null ? specificPagePermission['canEdit'] : false;
    //     }
    //     if (specificPagePermission.moduleName == 'Info Request') {
    //       if (specificPagePermission['canAdd']) {
    //         this.permissionService.isInfoRequestPageHasAddPermission = true;
    //       } else {
    //         this.permissionService.isInfoRequestPageHasAddPermission = false;
    //       }
    //       this.permissionService.isInfoRequestPageHasViewPermission = specificPagePermission['isVisible'];
    //       this.permissionService.isInfoRequestPageHasEditPermission = specificPagePermission['canEdit'] != null ? specificPagePermission['canEdit'] : false;
    //     }
    //     if (specificPagePermission.moduleName == 'Audit') {
    //       if (specificPagePermission['isVisible']) {
    //         this.permissionService.isAuditPageHasViewPermission = true;
    //       } else {
    //         this.permissionService.isAuditPageHasViewPermission = false;
    //       }
    //     }
    //     if (specificPagePermission.moduleName == 'Locks') {
    //       if (specificPagePermission['isVisible']) {
    //         this.permissionService.isLocksPageHasViewPermission = true;
    //       } else {
    //         this.permissionService.isLocksPageHasViewPermission = false;
    //       }
    //     }
    //     if (specificPagePermission.moduleName == 'Code Admin') {
    //       if (specificPagePermission['isVisible']) {
    //         this.permissionService.isSystemSettingsPageHasViewPermission = true;
    //       } else {
    //         this.permissionService.isSystemSettingsPageHasViewPermission = false;
    //       }
    //     }
    //     if (specificPagePermission.moduleName == 'Case List') {
    //       if (specificPagePermission['isVisible']) {
    //         this.permissionService.isHomePageHasViewPermission = true;
    //       } else {
    //         this.permissionService.isHomePageHasViewPermission = false;
    //       }
    //     }
    //   });

    // });
  }

  onlineFunction() {
    this.newIsOnline = "true";
    if (window.location.pathname.indexOf("login") <= -1) {
      this.updatePermissions();

      this.onlineService.loggedInUserDetails(this._cookieService.get(AppConstants.CookieName.UserId)).subscribe(
        (userInfo: any) => {
          if (userInfo.picture != null && userInfo.picture != "") {
            if (userInfo.picture !== AppConstants.DefaultProfileImage) {
              this.profileImageSrc = "data:image/jpeg;base64," + userInfo.picture;
            } else {
              this.profileImageSrc = userInfo.picture;
            }
          }
          this.UserDisplayName = userInfo.firstName; //userInfo.lastName + ', ' + userInfo.firstName;
        },
        (err: HttpErrorResponse) => {
          if (err.status == 0 || err.status == 404 || err.status == 504) {
            this.profileImageSrc = AppConstants.DefaultProfileImage;
            // this.permissionService.isExposurePageHasAddPermission = true; //TODO: Check: allow add permission when unable to get permissions. Not sure this is good.
          }
        }
      );

      const toasterValue = {
        type: "info",
        message: this.translate.instant('common.workingOnline'),
      };
      this.toastService.showSpinner(toasterValue);
    }
    const headerUsernameEle = document.getElementById("headerUsername");
    if (headerUsernameEle != undefined || headerUsernameEle != null) {
      headerUsernameEle.style.cursor = "pointer";
    }
    const profilepicEle = document.getElementById("profile-image-section");
    if (profilepicEle != undefined || profilepicEle != null) {
      profilepicEle.style.cursor = "pointer";
    }
  }

  offlineFunction() {
    this.newIsOnline = "false";
    //TODO: Right place? Should this be moved to the permissionService?
    this.permissionService.isReportsPageHasViewPermission = false;
    this.permissionService.isReviewsPageHasViewPermission = false;
    this.permissionService.isSearchPageHasViewPermission = false;
    this.permissionService.isLocksPageHasViewPermission = false;
    this.permissionService.isAuditPageHasViewPermission = false;
    this.permissionService.isSystemSettingsPageHasViewPermission = false;

    this.permissionService.isHomePageHasViewPermission = true;
    this.permissionService.isExposurePageHasAddPermission = true;
    this.permissionService.isInfoRequestPageHasAddPermission = true;

    const toasterValue = {
      type: "info",
      message: this.translate.instant('common.workingOffline'),
    };
    this.toastService.showSpinner(toasterValue);
    const headerUsernameEle = document.getElementById("headerUsername");
    if (headerUsernameEle != undefined || headerUsernameEle != null) {
      headerUsernameEle.style.cursor = "default";
    }
    const profilepicEle = document.getElementById("profile-image-section");
    if (profilepicEle != undefined || profilepicEle != null) {
      profilepicEle.style.cursor = "default";
    }
    event.stopPropagation();

    this.createHotkeys();
  }

  clickedInside($event: Event) {
    document.getElementById("LeftMenu").classList.remove("hide");
    $event.preventDefault();
    $event.stopPropagation();
  }

  UserIconClick($event: Event) {
    const userMenuContainer = document.getElementById("UserMenuContainer");
    if (userMenuContainer.offsetHeight == 0) {
      userMenuContainer.style.display = "block";
      userMenuContainer.classList.remove("hide"); // remove the hide if it exists
    } else {
      userMenuContainer.style.display = "none";
    }
  }

  OpenLeftMenu($event) {
    const leftmenucontainer = document.getElementById("LeftMenu");
    if (!leftmenucontainer.contains($event.target)) {
      document.getElementById("LeftMenu").classList.add("hide");
    } else if (leftmenucontainer.contains($event.target)) {
      document.getElementById("LeftMenu").classList.add("hide");
    } else {
      document.getElementById("LeftMenu").classList.remove("hide");
    }
  }

  @HostListener("document:click", ["$event"]) clickedOutside($event) {
    const leftmenucontainer = document.getElementById("LeftMenu");
    const mbMenuItemCaseLauncher = document.getElementById("mbMenuItemCaseLauncher");
    const languageDD = document.getElementById("languageDD");

    if (!leftmenucontainer.contains($event.target)) {
      document.getElementById("LeftMenu").classList.add("hide");
    } else if (mbMenuItemCaseLauncher.contains($event.target) || languageDD.contains($event.target) ) {
      // Don't do anything if user clicks inside the case launcher
      // menu is closed within the performLaunchInfoExpoById() function
    } else if (leftmenucontainer.contains($event.target)) {
      document.getElementById("LeftMenu").classList.add("hide");
    } else {
      document.getElementById("LeftMenu").classList.remove("hide");
    }

    const userMenuElement = document.getElementById("UserMenuContainer");
    const profileSection = document.getElementById("profile-image-section");
    const userImage = profileSection.getElementsByTagName("img")[0];

    if (!profileSection.contains($event.target)) {
      if (!userMenuElement.contains($event.target) || !userImage.contains($event.target)) {
        document.getElementById("UserMenuContainer").classList.add("hide");
      } else if (userMenuElement.contains($event.target)) {
        document.getElementById("UserMenuContainer").classList.add("hide");
      } else {
        document.getElementById("UserMenuContainer").classList.remove("hide");
      }
    }
  }

  Logout() {
    // Check if Online
    if (this.newIsOnline != "" && this.newIsOnline == "true") {
      // Check for any locks before logout
      const caseLocksParameters = {
        CentreId: null,
        CallId: null,
        CaseId: null,
        UserId: this._cookieService.get(AppConstants.CookieName.UserId),
      };

      // request the locks from the server
      this.onlineService.getLocks(caseLocksParameters).subscribe(
        (locks: any) => {
          // check for locks
          if (locks.length > 0) {
            // put locks into array for display
            locks.forEach((lock) => {
              const newLock: LockInfo = {
                callId: lock.callId,
                callLockUserId: lock.callLockUserId,
                centreId: lock.centreId,
                centreInitial: lock.centreInitial,
                centreName: lock.centreName,
                exposureId: lock.exposureId,
                exposureLockUserId: lock.exposureLockUserId,
                isCallLock: lock.isCallLock,
                isExposureLock: lock.isExposureLock,
                lockType: lock.lockType,
                userId: lock.userId,
              };
              this.locksOutstanding.push(newLock);
            });
            // Show the Dialog
            this.locksOutDlgButtons = [
              { click: this.logoutCancelClick.bind(this), buttonModel: { content: this.translate.instant('common.Cancel'), cssClass: "close-Dlg-Buttons" } },
              { click: this.logoutReleaseLocksClick.bind(this), buttonModel: { content: this.translate.instant('common.releaseLocksAndLogout'), cssClass: "close-Dlg-Buttons" } },
              { click: this.logoutKeepLocksClick.bind(this), buttonModel: { content: this.translate.instant('common.keepLocksAndLogOut'), cssClass: "close-Dlg-Buttons" } },
            ];
            this.locksOutDialog.show();
            this.locksOutstandingGrid.refresh();
            this.locksOutstandingGrid.hideScroll();
          } else {
            this.completeLogout();
          }
        },
        (err: any) => {
          alert( this.translate.instant('common.anErrorOccurredWhileAttemptingToGetTheLocks') + ('.') + (" ") +  this.translate.instant('common.refreshThePageAndTryAgain') + ('.'));
        }
      );
    } else {
      alert(this.translate.instant('common.unableToLogoutDueToBeingOffline') + ('.') + (" ") + this.translate.instant('common.tryAgainWhenYouAreOnline'));
    }
  }

  // TODO: Refactor this out to a service somehow. Maybe needs to be in a container above this?
  getPagePermission() {
    // for offline
    this.onlineService.exposurePagePermission.subscribe((exposurePagePermission) => {
      this.permissionService.isExposurePageHasAddPermission = exposurePagePermission;
    });
    this.onlineService.homePagePermission.subscribe((homePagePermission) => {
      this.permissionService.isHomePageHasViewPermission = homePagePermission;
    });
    this.onlineService.reportsPagePermission.subscribe((reportsPagePermission) => {
      this.permissionService.isReportsPageHasViewPermission = reportsPagePermission;
    });
    this.onlineService.searchPagePermission.subscribe((searchPagePermission) => {
      this.permissionService.isSearchPageHasViewPermission = searchPagePermission;
    });
    this.onlineService.auditPagePermission.subscribe((auditPagePermission) => {
      this.permissionService.isAuditPageHasViewPermission = auditPagePermission;
    });
    this.onlineService.reviewsPagePermission.subscribe((reviewsPagePermission) => {
      this.permissionService.isReviewsPageHasViewPermission = reviewsPagePermission;
    });
    this.onlineService.locksPagePermission.subscribe((locksPagePermission) => {
      this.permissionService.isLocksPageHasViewPermission = locksPagePermission;
    });
    this.onlineService.systemSettingsPagePermission.subscribe((systemSettingsPagePermission) => {
      this.permissionService.isSystemSettingsPageHasViewPermission = systemSettingsPagePermission;
    });
    this.onlineService.infoRequestPagePermission.subscribe((infoRequestPagePermission) => {
      this.permissionService.isInfoRequestPageHasAddPermission = infoRequestPagePermission;
    });
  }

  ngAfterViewInit() {
    const themeCookie = this._cookieService.get(AppConstants.CookieName.ColorTheme);
    this.themeService.ApplyThemeFromCookie(document.querySelector("body"), themeCookie);
    // Check for online status.
    if (this.newIsOnline !== "" && this.newIsOnline === "true") {
      // online
      this.onlineService.loggedInUserDetails(this._cookieService.get(AppConstants.CookieName.UserId)).subscribe(
        (userInfo: any) => {
          // set the theme from the user details theme
          this.themeService.ApplyTheme(document.querySelector("body"), userInfo.themeSettings, true);

          if (userInfo.picture != null && userInfo.picture != "") {
            if (userInfo.picture !== AppConstants.DefaultProfileImage) {
              this.profileImageSrc = "data:image/jpeg;base64," + userInfo.picture;
            } else {
              this.profileImageSrc = userInfo.picture;
            }
          }
          this.UserDisplayName = userInfo.firstName;
          const headerUsernameEle = document.getElementById("headerUsername");
          if (headerUsernameEle != undefined || headerUsernameEle != null) {
            headerUsernameEle.style.cursor = "pointer";
          }
          const profilepicEle = document.getElementById("profile-image-section");
          if (profilepicEle != undefined || profilepicEle != null) {
            profilepicEle.style.cursor = "pointer";
          }
        },
        (err: HttpErrorResponse) => {
          // Set the theme from the cookie if we can't get the user's settings.
          this.themeService.ApplyThemeFromCookie(document.querySelector("body"), themeCookie);

          if (err.status == 0 || err.status == 404 || err.status == 504) {
            const headerUsernameEle = document.getElementById("headerUsername");
            if (headerUsernameEle != undefined || headerUsernameEle != null) {
              headerUsernameEle.style.cursor = "default";
            }
            const profilepicEle = document.getElementById("profile-image-section");
            if (profilepicEle != undefined || profilepicEle != null) {
              profilepicEle.style.cursor = "default";
            }
          }
        }
      );
      this.updatePermissions();
    } else {
      // Offline
      this.themeService.ApplyThemeFromCookie(document.querySelector("body"), themeCookie);
      //TODO: Question: Is this the correct place to do this, maybe the permission service would be better?
      this.permissionService.isExposurePageHasAddPermission = true; // give offline user the option to add exposures.
      const headerUsernameEle = document.getElementById("headerUsername");
      if (headerUsernameEle != undefined || headerUsernameEle != null) {
        headerUsernameEle.style.cursor = "default";
      }
      const profilepicEle = document.getElementById("profile-image-section");
      if (profilepicEle != undefined || profilepicEle != null) {
        profilepicEle.style.cursor = "default";
      }
    }

    // createSpinner() method is used to create spinner
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById("btnCaseLauncher"),
    });
    hideSpinner(document.getElementById("btnCaseLauncher"));
  }

  caseLauncher_onKeydown(event: any) {
    if (event.keyCode === AppConstants.KEY_CODE.RETURN_KEY || event.keyCode === AppConstants.KEY_CODE.NumpadEnter) {
      this.performLaunchInfoExpoById();
    }
  }
  launchInfoExpoByIdClick($event) {
    this.performLaunchInfoExpoById();
  }

  performLaunchInfoExpoById() {
    // showSpinner(document.getElementById('btnCaseLauncher'));
    document.getElementById("LeftMenu").classList.add("hide");

    // Get id from text box
    const caseId: string = this.caseLauncherInfoExpoId;
    this.onlineService
      .getCasesById(caseId, this._cookieService.get(AppConstants.CookieName.CenterId), this._cookieService.get(AppConstants.CookieName.UserId))
      .subscribe((caseInfo: any) => {
        //centerId = userInfo.centerId;
        this.caseLauncherCaseInfoData = caseInfo;
        if (this.caseLauncherCaseInfoData) {
          if (this.caseLauncherCaseInfoData.length > 0) {
            //If only one record was found, do the launch
            //If more than one, display a list and get user to launch
            if (this.caseLauncherCaseInfoData.length == 1) {
              this.performCaseLaunch(this.caseLauncherCaseInfoData[0]);
            } else {
              this.displayCaseLauncherDialog();
            }
          } else {
            //No queries or exposures could be found with this Id
            //this.displayCaseLauncherDialog();
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noMatchingIdCouldBeFound'),
            };
            this.toastService.showSpinner(toasterValue);
          }
        } else {
          //Something about the Case Id is invalid
          //Could be the center initial
          //Could be an alphabetical character in the numeric
          //Output message

          //this.displayCaseLauncherDialog();

          const toasterValue = {
            type: "error",
            message: this.translate.instant('common.invalidId') + (':') + (" ") + this.translate.instant('common.pleaseCheckEnteredValue'),
          };
          this.toastService.showSpinner(toasterValue);
        }
        hideSpinner(document.getElementById("btnCaseLauncher"));
      });
  }

  displayCaseLauncherDialog() {
    this.showCaseLauncherDialog = true;
  }

  selectCaseLauncherCancelClick() {
    this.showCaseLauncherDialog = false;
  }

  caseLauncherDoubleClick($event) {
    if ($event != null && $event != undefined && $event != "" && $event.rowData != undefined) {
      this.performCaseLaunch($event.rowData);
    }
  }

  performCaseLaunch(caseData: any) {
    let caseId = caseData.caseId;
    let status = caseData.statusName;

    if (this.newIsOnline != "" && this.newIsOnline == "true") {
      let UrlList = { OpenedUrlList: [] };
      let isLoad = true;
      let caseBaseInfo = {
        CentreId: caseData.CentreId,
        CaseId: caseData.CaseId,
        CallId: caseData.CallId,
      };

      // Handle the different types by opening the correct url
      switch (caseData.caseType) {
        case "E":
          if (this.permissionService.isExposurePageHasEditPermission || this.permissionService.isExposurePageHasViewPermission) {
            if (
              window.localStorage[AppConstants.LocalStorage.UrlList] != undefined && window.localStorage[AppConstants.LocalStorage.UrlList] != null
                ? JSON.parse(window.localStorage[AppConstants.LocalStorage.UrlList]).OpenedUrlList.length > 0
                  ? true
                  : false
                : false
            ) {
              UrlList = JSON.parse(window.localStorage[AppConstants.LocalStorage.UrlList]);

              if (UrlList.OpenedUrlList.length > 0) {
                for (let i = 0; i < UrlList.OpenedUrlList.length; i++) {
                  if (
                    (status == "Open" &&
                      !this.permissionService.isExposurePageHasEditPermission &&
                      this.permissionService.isExposurePageHasViewPermission &&
                      UrlList.OpenedUrlList[i] == "view/" + caseId) ||
                    (status == "Closed" && UrlList.OpenedUrlList[i] == this.translate.instant('common.view') + (" ") + caseId) ||
                    (status == "Open" && this.permissionService.isExposurePageHasEditPermission && UrlList.OpenedUrlList[i] == "edit/" + caseId)
                  ) {
                    isLoad = false;
                  }
                }
                /* checking whether the user has permission to edit or view the exposure */
                if (this.permissionService.isExposurePageHasEditPermission || !this.permissionService.isExposurePageHasViewPermission) {
                  if (status == "Closed") {
                    if (isLoad == true) {
                      window.open(window.location.origin + "/exposure/view/" + caseId, "view/" + caseId);
                      if (window.location.href === "about:blank") {
                        (window.location.href = window.location.origin + "/exposure/view/" + caseId), "view/" + caseId;
                      }
                      window.focus();
                      UrlList.OpenedUrlList.push("view/" + caseId);
                    } else {
                      window.open("", "view/" + caseId);
                      if (window.location.href === "about:blank") {
                        window.location.href = window.location.origin + "/exposure/view/" + caseId;
                      }
                      window.focus();
                    }
                  } else if (status == "Open") {
                    if (isLoad == true) {
                      this.onlineService.GetLockInfo(caseBaseInfo).subscribe(function (response) {
                        if (response != null) {
                          if (response.isExposureLock) {
                            if (response.exposureLockUserId == this._cookieService.get(AppConstants.CookieName.UserId)) {
                              window.open(window.location.origin + "/exposure/edit/" + caseId, "_blank");
                              UrlList.OpenedUrlList.push("edit/" + caseId);
                            } else {
                              this.LockedExposureId = caseId;
                              (this.LockedByUserName =
                                response.exposureLockUserFirstName != "" && response.exposureLockUserLastName != ""
                                  ? response.exposureLockUserLastName + ", " + response.exposureLockUserFirstName
                                  : response.exposureLockUserFirstName != ""
                                  ? response.exposureLockUserFirstName
                                  : response.exposureLockUserLastName != ""
                                  ? response.exposureLockUserLastName
                                  : ""),
                                (this.showLockWarningModal = true);
                              setTimeout(() => {
                                const inputElement = document.getElementById("searchlock-nobtnid");
                                if (inputElement != null && inputElement != undefined) {
                                  inputElement.focus();
                                }
                              }, 100);
                            }
                          } else {
                            window.open(window.location.origin + "/exposure/edit/" + caseId, "_blank");
                            UrlList.OpenedUrlList.push("edit/" + caseId);
                          }
                        } else {
                          window.open(window.location.origin + "/exposure/edit/" + caseId, "_blank");
                          UrlList.OpenedUrlList.push("edit/" + caseId);
                        }
                      });
                    } else {
                      window.open("", "edit/" + caseId);
                    }
                    if (window.location.href === "about:blank") {
                      window.location.href = window.location.origin + "/exposure/edit/" + caseId;
                    }
                    window.focus();
                  }
                } else {
                  /* if exposure has view permission alone */
                  if (this.permissionService.isExposurePageHasViewPermission) {
                    if (isLoad == true) {
                      window.open(window.location.origin + "/exposure/view/" + caseId, "_blank");
                      UrlList.OpenedUrlList.push("view/" + caseId);
                    } else {
                      window.open("", "view/" + caseId);
                      if (window.location.href === "about:blank") {
                        window.location.href = window.location.origin + "/exposure/view/" + caseId;
                      }
                      window.focus();
                    }
                  }
                }
              }
            } else {
              /* checking whether the user has permission to edit or view the exposure */
              if (this.permissionService.isExposurePageHasEditPermission || !this.permissionService.isExposurePageHasViewPermission) {
                if (status == "Closed") {
                  if (isLoad == true) {
                    window.open(window.location.origin + "/exposure/view/" + caseId, "view/" + caseId);
                    if (window.location.href === "about:blank") {
                      (window.location.href = window.location.origin + "/exposure/view/" + caseId), "view/" + caseId;
                    }
                    window.focus();
                    UrlList.OpenedUrlList.push("view/" + caseId);
                  } else {
                    window.open("", "view/" + caseId);
                  }
                  if (window.location.href === "about:blank") {
                    window.location.href = window.location.origin + "/exposure/view/" + caseId;
                  }
                  window.focus();
                } else if (status == "Open") {
                  if (isLoad == true) {
                    this.onlineService.GetLockInfo(caseBaseInfo).subscribe(
                      function (response) {
                        if (response != null) {
                          if (response.isExposureLock) {
                            if (response.exposureLockUserId == this._cookieService.get(AppConstants.CookieName.UserId)) {
                              window.open(window.location.origin + "/exposure/edit/" + caseId, "_blank");
                              UrlList.OpenedUrlList.push("edit/" + caseId);
                            } else {
                              this.LockedExposureId = caseId;
                              (this.LockedByUserName =
                                response.exposureLockUserFirstName != "" && response.exposureLockUserLastName != ""
                                  ? response.exposureLockUserLastName + ", " + response.exposureLockUserFirstName
                                  : response.exposureLockUserFirstName != ""
                                  ? response.exposureLockUserFirstName
                                  : response.exposureLockUserLastName != ""
                                  ? response.exposureLockUserLastName
                                  : ""),
                                (this.showLockWarningModal = true);
                              setTimeout(() => {
                                const inputElement = document.getElementById("searchlock-nobtnid");
                                if (inputElement != null && inputElement != undefined) {
                                  inputElement.focus();
                                }
                              }, 100);
                            }
                          } else {
                            window.open(window.location.origin + "/exposure/edit/" + caseId, "_blank");
                            UrlList.OpenedUrlList.push("edit/" + caseId);
                          }
                        } else {
                          window.open(window.location.origin + "/exposure/edit/" + caseId, "_blank");
                          UrlList.OpenedUrlList.push("edit/" + caseId);
                        }
                      },
                      (err: HttpErrorResponse) => {
                        if (this.onlineService.CheckErrorStatus(err)) {
                          //                          this.spinner.hide();
                          //                          this.showOnlineInfoDialog = true;
                          //                          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == 'true') {
                          //                            this.onlineService.doesConnectionExist();
                          //                          }
                        } else {
                          let errorLog = {
                            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                            CustomErrorMessage: err.message,
                            ErrorMessage: err.message,
                            InnerException:
                              err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
                          };
                          this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                            const toasterValue = {
                              type: "error",
                              message: this.translate.instant('common.somethingWentWrongAddingErrorLog') + ('.') + (" ") + this.translate.instant('common.pleaseTryAgain'),
                            };
                            this.toastService.showSpinner(toasterValue);
                          });
                        }
                      }
                    );
                  } else {
                    window.open("", "edit/" + caseId);
                  }
                  if (window.location.href === "about:blank") {
                    window.location.href = window.location.origin + "/exposure/edit/" + caseId;
                  }
                  window.focus();
                }
              } else {
                /* if exposure has view permission alone */
                if (this.permissionService.isExposurePageHasViewPermission) {
                  if (isLoad == true) {
                    window.open(window.location.origin + "/exposure/view/" + caseId, "_blank");
                    UrlList.OpenedUrlList.push("view/" + caseId);
                  } else {
                    window.open("", "view/" + caseId);
                    if (window.location.href === "about:blank") {
                      window.location.href = window.location.origin + "/exposure/view/" + caseId;
                    }
                    window.focus();
                  }
                }
              }
            } // else condition close brace of if URL list not stored in local storage.

            localStorage.setItem(AppConstants.LocalStorage.UrlList, JSON.stringify(UrlList));
          } else {
            const toasterValue = {
              type: "error",
              message: this.translate.instant('common.permissionDeniedToAccessExposures') + '!',
            };
            this.toastService.showSpinner(toasterValue);
          }
          break;
        default:
          // if not type zero (exposure), then the case must be info request
          if (this.permissionService.isInfoRequestPageHasEditPermission || this.permissionService.isInfoRequestPageHasViewPermission) {
            if (
              window.localStorage[AppConstants.LocalStorage.UrlList] != undefined && window.localStorage[AppConstants.LocalStorage.UrlList] != null
                ? JSON.parse(window.localStorage[AppConstants.LocalStorage.UrlList]).OpenedUrlList.length > 0
                  ? true
                  : false
                : false
            ) {
              UrlList = JSON.parse(window.localStorage[AppConstants.LocalStorage.UrlList]);

              if (UrlList.OpenedUrlList.length > 0) {
                for (let i = 0; i < UrlList.OpenedUrlList.length; i++) {
                  if (
                    (status == "Open" &&
                      !this.permissionService.isInfoRequestPageHasEditPermission &&
                      this.permissionService.isInfoRequestPageHasViewPermission &&
                      UrlList.OpenedUrlList[i] == "view/" + caseId) ||
                    (status == "Closed" && UrlList.OpenedUrlList[i] == "view/" + caseId) ||
                    (status == "Open" && this.permissionService.isInfoRequestPageHasEditPermission && UrlList.OpenedUrlList[i] == "edit/" + caseId)
                  ) {
                    isLoad = false;
                  }
                }
                /* checking whether the user has permission to edit or view the InfoRequest */
                if (this.permissionService.isInfoRequestPageHasEditPermission || !this.permissionService.isInfoRequestPageHasViewPermission) {
                  if (status == "Closed") {
                    if (isLoad == true) {
                      this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                    } else {
                      this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                    }
                  } else if (status == "Open") {
                    if (isLoad == true) {
                      this.onlineService.GetLockInfo(caseBaseInfo).subscribe((response) => {
                        if (response != null) {
                          if (response.isInfoRequestLock) {
                            if (response.InfoRequestLockUserId == this._cookieService.get(AppConstants.CookieName.UserId)) {
                              this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                            } else {
                              this.LockedInfoRequestId = caseId;
                              (this.LockedByUserName =
                                response.infoRequestLockUserFirstName != "" && response.infoRequestLockUserLastName != ""
                                  ? response.infoRequestLockUserLastName + ", " + response.infoRequestLockUserFirstName
                                  : response.infoRequestLockUserFirstName != ""
                                  ? response.infoRequestLockUserFirstName
                                  : response.infoRequestLockUserLastName != ""
                                  ? response.infoRequestLockUserLastName
                                  : ""),
                                (this.showLockWarningModal = true);
                              setTimeout(() => {
                                const inputElement = document.getElementById("homelock-nobtnid");
                                if (inputElement != null && inputElement != undefined) {
                                  inputElement.focus();
                                }
                              }, 100);
                            }
                          } else {
                            this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                          }
                        } else {
                          this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                        }
                      });
                    } else {
                      this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                    }
                  }
                } else {
                  /* if info request has view permission alone */
                  if (this.permissionService.isInfoRequestPageHasViewPermission) {
                    if (isLoad == true) {
                      this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                    } else {
                      this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                    }
                  }
                }
              }
            } else {
              /* checking whether the user has permission to edit or view the info request */
              if (this.permissionService.isInfoRequestPageHasEditPermission || !this.permissionService.isInfoRequestPageHasViewPermission) {
                if (status == "Closed") {
                  if (isLoad == true) {
                    this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                  } else {
                    this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                  }
                } else if (status == "Open") {
                  if (isLoad == true) {
                    this.onlineService.GetLockInfo(caseBaseInfo).subscribe(
                      (response) => {
                        if (response != null) {
                          if (response.isInfoRequestLock) {
                            if (response.infoRequestLockUserId == this._cookieService.get(AppConstants.CookieName.UserId)) {
                              this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                            } else {
                              this.LockedInfoRequestId = caseId;
                              (this.LockedByUserName =
                                response.infoRequestLockUserFirstName != "" && response.infoRequestLockUserLastName != ""
                                  ? response.infoRequestLockUserLastName + ", " + response.infoRequestLockUserFirstName
                                  : response.infoRequestLockUserFirstName != ""
                                  ? response.infoRequestLockUserFirstName
                                  : response.infoRequestLockUserLastName != ""
                                  ? response.infoRequestLockUserLastName
                                  : ""),
                                (this.showLockWarningModal = true);
                              setTimeout(() => {
                                const inputElement = document.getElementById("homelock-nobtnid");
                                if (inputElement != null && inputElement != undefined) {
                                  inputElement.focus();
                                }
                              }, 100);
                            }
                          } else {
                            this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                          }
                        } else {
                          this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                        }
                      },
                      (err: HttpErrorResponse) => {
                        if (this.onlineService.CheckErrorStatus(err)) {
                          //                          this.spinner.hide();
                          //                          this.showOnlineInfoDialog = true;
                          if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == "true") {
                            this.onlineService.doesConnectionExist();
                          }
                        } else {
                          const errorLog = {
                            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                            CustomErrorMessage: err.message,
                            ErrorMessage: err.message,
                            InnerException:
                              err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description,
                          };
                          this.onlineService.AddErrorLog(errorLog).subscribe(() => {
                            const toasterValue = {
                              type: "error",
                              message: this.translate.instant('common.somethingWentWrongAddingErrorLog') + '.' + " " + this.translate.instant('common.pleaseTryAgain'),
                            };
                            this.toastService.showSpinner(toasterValue);
                          });
                        }
                      }
                    );
                  } else {
                    this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, true, true);
                  }
                }
              } else {
                /* if info request has view permission alone */
                if (this.permissionService.isInfoRequestPageHasViewPermission) {
                  if (isLoad == true) {
                    this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                  } else {
                    this.OpenInfoRequest(caseId, UrlList.OpenedUrlList, false, true);
                  }
                }
              }
            } // else condition close brace of if URL list not stored in local storage.

            localStorage.setItem(AppConstants.LocalStorage.UrlList, JSON.stringify(UrlList));
          } else {
            const toasterValue = {
              type: "error",
              message: this.translate.instant('common.permissionDeniedToAccessQueriesRequests') + '!',
            };
            this.toastService.showSpinner(toasterValue);
          }
          break;
      }
    } // online mode condition close brace
    this.selectCaseLauncherCancelClick();
  }

  /* View Info Request by Case ID, checking for a valid open. */
  OpenInfoRequest(caseId: number, UrlList: string[], IsEdit: boolean, IsFocused) {
    const openType = IsEdit ? "edit" : "view";
    const location: string = "/query/" + openType + "/";
    let locationToOpen = window.location.origin + location + caseId;
    const windowAlreadyOpen = this.onlineService.IsWindowViewAlreadyOpen(caseId, UrlList, true);
    if (windowAlreadyOpen) {
      locationToOpen = ""; // blank should bring the already existing window to the front.
    }
    const openedWindow = window.open(locationToOpen, openType + "/" + caseId);
    if (openedWindow.location.href === "about:blank") {
      (openedWindow.location.href = window.location.origin + location + caseId), openType + "/" + caseId;
    }
    if (IsFocused) {
      window.focus();
    }
    if (!windowAlreadyOpen) {
      UrlList.push(openType + "/" + caseId);
    }
  }

  createHotkeys() {
    this.AddHotkey(
      new Hotkey(
        "alt+k",
        (event: KeyboardEvent): boolean => {
          this._hotkeysService.cheatSheetToggle.next();
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.showHotkeyCheatsheet')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+`",
        (event: KeyboardEvent): boolean => {
          window.open(window.location.origin + "/home", "_self");
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.openHomeDashboard')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+t",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isReportsPageHasViewPermission) {
            window.open(window.location.origin + "/reports", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToReportsPage'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.openReportsPage') // + (this.isReportsPageHasViewPermission ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+f",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isSearchPageHasViewPermission) {
            window.open(window.location.origin + "/search", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToSearchPage'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements isSearchPageHasViewPermission
        this.translate.instant('common.openSearchPage') // + (this.isSearchPageHasViewPermission ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+i",
        (event: KeyboardEvent): boolean => {
          if (this.newIsOnline === "true") {
            const tbxExpoInfoIdSearchs = document.getElementById("tbxExpoInfoIdSearch") as HTMLInputElement;
            tbxExpoInfoIdSearchs.focus();
            tbxExpoInfoIdSearchs.select();
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.IdSeachRequiresSystemToBeOnline'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.viewByCaseId') + '(' + this.translate.instant('common.focus') + ')' // + (this.newIsOnline === 'true' ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+q",
        (event: KeyboardEvent): boolean => {
          if (!this.hasUserSettings) {
            window.open(window.location.origin + "/query", "_blank");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.addingAQueryRequiresCorrectUserPermissions'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.openNewQuery') // + (!this.hasUserSettings ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+e",
        (event: KeyboardEvent): boolean => {
          if (!this.hasUserSettings) {
            window.open(window.location.origin + "/exposure", "_blank");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.addingAnExposureRequiresCorrectUserPermissions'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.openNewExposure') // + (!this.hasUserSettings ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+p",
        (event: KeyboardEvent): boolean => {
          if (this.newIsOnline === "true") {
            window.open(window.location.origin + "/profile", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.profilePageRequiresSystemToBeOnline'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.goToMyProfile') // + (this.newIsOnline === 'true' ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+r",
        (event: KeyboardEvent): boolean => {
          if (this.newIsOnline === "true") {
            window.open(window.location.origin + "/recalls", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.recallsPageRequiresSystemToBeOnline'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.goToRecalls') // + (this.newIsOnline === 'true' ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+l",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isLocksPageHasViewPermission) {
            window.open(window.location.origin + "/locks", "_blank");
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        "" // Go into locks management
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+h alt+m",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isHomePageHasViewPermission === true) {
            window.open(window.location.origin + "/home/mycases", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToMyCases'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.viewAllMyCases') // + (this.isHomePageHasViewPermission === true ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+h alt+c",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isHomePageHasViewPermission === true) {
            window.open(window.location.origin + "/home/centercases", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToCentreCases'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.viewAllMyCentreCases') // + (this.isHomePageHasViewPermission === true ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+h alt+n",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isHomePageHasViewPermission === true) {
            window.open(window.location.origin + "/home/networkcases", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToNetworkCases'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.viewAllMyNetworkCases') // + (this.isHomePageHasViewPermission === true ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+h alt+r",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isHomePageHasViewPermission === true) {
            window.open(window.location.origin + "/home/reviewcases", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToReviews'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.viewAllCasesReadyToReview') // + (this.isHomePageHasViewPermission === true ? '' : '(Disabled)')
      )
    );
    this.AddHotkey(
      new Hotkey(
        "alt+h alt+s",
        (event: KeyboardEvent): boolean => {
          if (this.permissionService.isHomePageHasViewPermission === true) {
            window.open(window.location.origin + "/home/responsecases", "_self");
          } else {
            const toasterValue = {
              type: "info",
              message: this.translate.instant('common.noAccessToResponses'),
            };
            this.toastService.showSpinner(toasterValue);
          }
          return false; // Prevent bubbling
        },
        ["INPUT", "TEXTAREA", "SELECT"], // allow shortcut execution in these html elements
        this.translate.instant('common.viewAllMyResponseNeeded') // + (this.isHomePageHasViewPermission === true ? '' : '(Disabled)')
      )
    );
  }
  AddHotkey(hk: Hotkey) {
    if (!this.hotkeyCheckIfExists(hk.combo[0])) {
      this._hotkeysService.add(hk);
    }
  }
  hotkeyCheckIfExists(hkyStr: string): boolean {
    let result = false;
    const data = this._hotkeysService.hotkeys.find(function (ele) {
      return ele.combo[0] === hkyStr;
    });
    if (data) {
      result = true;
    }
    return result;
  }
  setDifferentDescription() {
    let data = this._hotkeysService.hotkeys.find(function (ele) {
      return ele.combo[0] === "alt+z";
    });
    if (data) {
      data.description = this.translate.instant('common.testCaseDisabled');
    }
  }
  showLangFlyout() {
    this.flyOutOpen = true;
  }
}
