import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastBeforeOpenArgs, ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { ToasterService, ToasterValue } from './Providers/ToasterService';
import { AppInsightsService } from './Services/app-insights.service';

import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { SwPush, SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from './app.constants';

import defaultLanguage from '../assets/i18n/en.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ToxAware';
  loading: boolean = false;
  updateAvailable: boolean = false;

  constructor(
    appInsightsService: AppInsightsService,
    public toasterService: ToasterService,
    private router: Router,
    private updates: SwUpdate,
    private push: SwPush,
    public translate: TranslateService  // create a translate service
  ) {
    // Set the default language for translation strings, and the current language. This fixes the glitch showing the id instead of the text.
    translate.setTranslation('en', defaultLanguage);
    translate.setDefaultLang('en');

    appInsightsService.logPageView('Start-up');

    // log all push messages to the console!
    this.push.messages.subscribe((message) => console.log(message));

    this.router.events.subscribe((event) => {
      const previousLocation = window.location.pathname;
      if (event instanceof NavigationStart) {
        this.loading = previousLocation === '/login' || previousLocation.includes('/login') ? false : true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      } else if (event instanceof NavigationCancel) {
        this.loading = false;
      }
    });
  }

  @ViewChild('toaster', { static: false }) toastObj: ToastComponent;
  cssClass: string = '';
  icon: string = '';
  toastTitle: string = '';
  timeOut: number;
  public position = { X: 'Right' };

  ngOnInit() {
    this.toasterService.GetToasterMessage.subscribe((toasterValue: ToasterValue) => {
      this.showToast(toasterValue);
    });
  }
  
  showToast(toasterValue: ToasterValue) {
    this.cssClass = '';
    this.icon = '';
    this.toastTitle = '';
    switch (toasterValue.type) {
      case 'profile':
        this.cssClass = 'e-toast-warning';
        this.icon = 'e-warning toast-icons';
        this.toastTitle = 'Profile Needs Attention!';
        this.timeOut = 0;
        break;
      case 'success':
        this.cssClass = 'e-toast-success';
        this.icon = 'e-success toast-icons';
        this.toastTitle = this.translate.instant('common.success');
        this.timeOut = AppConstants.Toasts.SuccessTimeToDisplay;
        break;
      case 'successNoTimeout':
        this.cssClass = 'e-toast-success';
        this.icon = 'e-success toast-icons';
        this.toastTitle = this.translate.instant('common.success');
        this.timeOut = 0;
        break;
      case 'warning':
        this.cssClass = 'e-toast-warning';
        this.icon = 'e-warning toast-icons';
        this.toastTitle = this.translate.instant('common.Warning');
        this.timeOut = AppConstants.Toasts.WarningTimeToDisplay;
        break;
      case 'error':
        this.cssClass = 'e-toast-danger';
        this.icon = 'e-error toast-icons';
        this.toastTitle = this.translate.instant('common.error');
        this.timeOut = AppConstants.Toasts.ErrorTimeToDisplay;
        break;
      case 'info':
        this.cssClass = 'e-toast-info';
        this.icon = 'e-info toast-icons';
        this.toastTitle = this.translate.instant('common.information');
        this.timeOut =  AppConstants.Toasts.InfoTimeToDisplay;
        break;
    }
    if (this.cssClass !== '' && toasterValue.message !== '') {
      this.toastObj.show({
        title: this.toastTitle,
        content: toasterValue.message,
        cssClass: this.cssClass,
        icon: this.icon,
        showCloseButton: true,
        timeOut: this.timeOut,
        showProgressBar: true,
        animation: {
          show: { effect: 'FadeZoomIn' },
          hide: {effect:'FadeZoomOut'}
        }
      });
    }
  }

  onBeforeOpen(e: ToastBeforeOpenArgs) {
    e.cancel = this.preventDuplicate(e);
  }

  preventDuplicate(e: ToastBeforeOpenArgs) {
    const toastEle: HTMLElement = e.element;
    const toasts: HTMLCollection = e.toastObj.element.children;
    for (let i = 0; i < toasts.length; i++) {
      if ((toasts[i] as HTMLElement).querySelector('.e-toast-content').isEqualNode(toastEle.querySelector('.e-toast-content'))) {
        return true;
      }
    }
    return false;
  }
}
