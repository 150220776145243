import { Injectable } from '@angular/core';

@Injectable()
export class HomeService {

  constructor() { }

  // center count based on Id
  public centerCountBindingInHome(centerCount){
    return centerCount;
 }

}
