// Angular Imports
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppUpdateService } from '../app/Services/updateCheck.service';

// App Imports
import { environment } from '../environments/environment';
import { FooterComponent } from './Layout/footer/footer.component';
import { HeaderComponent } from './Layout/header/header.component';
import { LayoutComponent } from './Layout/layout/layout.component';
import { HomeService } from './Pages/home/home.service';
import { LoginComponent } from './Pages/lazylogin/login/login.component';
import { PageNotFoundComponent } from './Pages/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './Pages/permissiondenied/permissiondenied.component';
import { OfflineService } from './Providers/OfflineService';
import { OnlineService } from './Providers/OnlineService';
import { ToasterService } from './Providers/ToasterService';
import { AppInsightsService } from './Services/app-insights.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PendingChangesGuard } from './auth/Pending-Changes-Guard';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { MyHttpInterceptor } from './httpinterceptor';

// Syncfusion Modules
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
// import the DashboardLayoutModule for the Dashboard Layout component
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { QueryBuilderModule } from "@syncfusion/ej2-angular-querybuilder";
// Busy Spinner
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
// utilities
import { CookieService } from 'ngx-cookie-service';

// Hot Keys
import { HotkeyModule, IHotkeyOptions } from 'angular2-hotkeys';
const hotKeyOptions: IHotkeyOptions = {
  disableCheatSheet: true, // disable the cheat sheet popover dialog? Default: false
  cheatSheetHotkey: 'alt+k', // key combination to trigger the cheat sheet. Default: '?'
  cheatSheetCloseEsc: true, // use also ESC for closing the cheat sheet. Default: false
  cheatSheetCloseEscDescription: 'Hide Hotkeys Mapping', // description for the ESC key for closing the cheat sheet (if enabed). Default: 'Hide this help menu'
  cheatSheetDescription: 'Show Hotkeys Mapping', // description for the cheat sheet hot key in the cheat sheet. Default: 'Show / hide this help menu'
};

// shared module
import { SharemoduleModule } from './sharemodule/sharemodule.module';

// testing
import { TestQBComponent } from './test-qb/test-qb.component';

// translation service (ngx-translate)
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConstants } from './app.constants';
// import { BrowserCheckComponent } from './components/browser-check/browser-check.component';
// import { ScenarioComponent } from './Components/scenario/scenario.component';


// Tranlation: AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    PermissionDeniedComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    // BrowserCheckComponent,
    // TestQBComponent,
    // ScenarioComponent,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    // Service worker is using custom code to run two service workers. See sw-master.js and sw-custom.js
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('/sw-master.js', { enabled: environment.production }),
    AppRoutingModule,
    BrowserAnimationsModule,

    ToastModule,
    CheckBoxModule,
    ButtonModule,
    GridAllModule,
    AccordionModule,
    DashboardLayoutModule,
    QueryBuilderModule,
    
    NgxSpinnerModule,
    // HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    // MatDialogModule,
    HotkeyModule.forRoot(hotKeyOptions), // adding HotkeysModule
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        defaultLanguage: AppConstants.DefaultLangaugeCode,
        extend: true
      }
    ), // add translate module
    SharemoduleModule,
  ],

  // exports: [DragDropModule],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    AuthGuard,
    PendingChangesGuard,
    OnlineService,
    CookieService,
    OfflineService,
    ToasterService,
    NgxSpinnerService,
    AppInsightsService,
    HomeService,
    AppUpdateService,
  ],
  bootstrap: [AppComponent],
// entryComponents: [DialogComponent]
})
export class AppModule { }
