import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Notice } from '../../../models/NoticeModel';
import { Column, CommandModel, EditSettingsModel, GridComponent, IEditCell, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { OnlineService } from './../../../Providers/OnlineService';
import { ToasterService } from './../../../Providers/ToasterService';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from '../../../app.constants';
import { CookieService } from 'ngx-cookie-service';


import { customers, data } from "./../../../test-data";

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss']
})
export class NoticesComponent implements OnInit {

  @Input() centres: object;  
  // public centres:Observable<any>;
  // Notices properties

  // Test data
  public data: object[] = data;
  public customers: object[] = customers;
  public editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true };
  public toolbarItems: ToolbarItems[] = ['Add', 'Edit', 'Delete'];
  public testDataForCentres = [{ centreId: 0, centreName: "All" }];



  // end test data

  public noticesGridEditSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal',
    allowEditOnDblClick: true
  };
  public noticesGridToolbarSettings = ['Add', 'Update', 'Cancel'];

  public noticesGridCommands: CommandModel[] = [
    {
      type: 'Edit',
      buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' }
    },
    {
      type: 'Delete',
      buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' }
    },
    {
      type: 'Save',
      buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' }
    },
    {
      type: 'Cancel',
      buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' }
    }
  ];

  @ViewChild('noticesGrid', { static: false })
  public noticesGridObj: GridComponent;
  public noticesGridData = [];
  public noticesEditParams?: IEditCell;
  showOnlineInfoDialog: boolean;
  // END Notices properties

  @ViewChild('grid', { static: false }) public gridObj: GridComponent;
  constructor(public onlineService: OnlineService,
    private spinner: NgxSpinnerService,
    public toastService: ToasterService,
    private _cookieService: CookieService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.populateNotices();
  }

  // Notices  
  private populateNotices() {
    this.onlineService.GetNotices().then((result) => 
    {
      this.noticesGridData = result;
    // let column: Column = this.noticesGridObj.getColumnByField("centreId");//.edit.params.dataSource = this.centres;
    // let column2: Column = this.gridObj.getColumnByField("customerID");
    // let params = column.edit.params;//.edit.params.dataSource = list;
    // params.dataSource = this.centres;
  }
    );
  }
  noticesGridActionBegin(args: any) {
    // do nothing
  }
  loadnoticesGrid(arg) {
    (<any>this.noticesGridObj).defaultLocale.EmptyRecord = 'No notices to display';
    // this.touchStartEvent();
  }
  noticesGridActionComplete(args: any) {
    let editedNotice: Notice;

    if (args.requestType === 'save' || args.requestType === 'delete') {
      let notice = args.data;
      if (Array.isArray(args.data)) {
        notice = args.data[0];
      }
      editedNotice = <Notice>{
        notice_Id: notice['notice_Id'],
        notice_Tx: notice['notice_Tx'],
        centreId: notice['centreId'],
        start_Dt: notice['start_Dt'],
        end_Dt: notice['end_Dt'],
        order: notice['order'],
        active: notice['active']
      };
      // what is the action?
      switch (args.requestType) {
        // case 'beginEdit':
        // case 'add':
        //   // Set the Dialog options
        //   if (args.dialog) {
        //     args.dialog.header = 'My Substance:' + args.rowData['mysuB_NAME'].toString();
        //   }
        //   break;
        case 'save':
          if (args.action === 'add') {
            this.onlineService.createNotice(editedNotice).subscribe((result: any) => {
              if (result.isSuccess) {
                this.toastService.showSpinner({ type: 'success', message: 'Notice Added.' });
              }
              else {
                this.toastService.showSpinner({ type: 'error', message: 'Unable to Add Notice. Please refresh the page and try again.' });
              }
            },
              (err: HttpErrorResponse) => {
                this.toastService.showSpinner({ type: 'error', message: 'Notice ' + editedNotice.notice_Tx + ' was not added.' });
                alert(err);
              });
          } else {
            this.onlineService.updateNotice(editedNotice).subscribe((result: any) => {
              if (result.isSuccess) {
                this.toastService.showSpinner({ type: 'success', message: 'Notice updated.' });
              }
              else {
                this.toastService.showSpinner({ type: 'error', message: 'Unable to save changes. Please refresh the page and try again.' });
              }
            },
              (err: HttpErrorResponse) => {
                this.toastService.showSpinner({ type: 'error', message: 'Notice ' + editedNotice.notice_Tx + ' was not updated.' });
                alert(err);
              });
          }
          break;
        case 'delete':
          this.onlineService.deleteNotice(editedNotice).subscribe((result: any) => {
            if (result.mysuB_STATUS !== AppConstants.MySubstance.Status.Error) {
              this.toastService.showSpinner({ type: 'success', message: 'Notice ' + result.mysuB_NAME + ' deleted.' });
            } else {
              this.toastService.showSpinner({ type: 'error', message: 'Notice ' + editedNotice.notice_Tx + ' was not deleted. Please refresh the page and try again.' });
              // this.mySubstancesGridObj.refresh();
            }
          },
            (err: HttpErrorResponse) => {
              this.toastService.showSpinner({ type: 'error', message: 'Notice ' + editedNotice.notice_Tx + ' was not deleted.' });
              alert(err);
              // this.mySubstancesGridObj.refresh();
            });
          break;
        default:
          break;
      }
      console.log(args);
    }
  }
}
