<div style="background-color: burlywood;">
<div class="ccontainer" style="display: flex;  flex-direction: column;  align-items: stretch;">

    <div class="count-header">{{Name | translate}}</div>
    <!-- <ejs-chart style='display:block' align='center' id='chartContainer' [title]='chartTitle'
        [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [tooltip]='tooltip' (load)='load($event)'
        [chartArea]='chartArea' width='100%' height="100%">
        <e-series-collection>
            <e-series [dataSource]='data' type='Line' xName='x' yName='y' name='Germany' width=2 [marker]='marker'>
            </e-series>
            <e-series [dataSource]='data1' type='Line' xName='x' yName='y' name='England' width=2 [marker]='marker'>
            </e-series>
        </e-series-collection>
    </ejs-chart> -->

</div>
</div>