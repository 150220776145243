export const environment = {
  version: require('../../package.json').version,
  production: true,
  AppInsights: {
    instrumentationKey: "1e57e278-de24-4aa4-bfa1-a91025785b56",
    applicationName: "ToxAware Dev.ToxAware.net"
  },
  // Don't forget the trailing slash!!!!
  apiUrl: "https://ToxAwareDev3.AzureWebSites.net/",
  umsUrl: "https://ToxAwareDev3.AzureWebSites.net/", // "https://toxawareums.azurewebsites.net/",
  substanceLookupUrl: "https://substancesearch-nsw.azurewebsites.net/API/SubstanceSearch", // "https://nprau20190114041103.azurewebsites.net/odata/Substances"   //"https://nprau20190114041103.azurewebsites.net/odata/Substances"
};
