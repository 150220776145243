import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutoComplete } from '@syncfusion/ej2-angular-dropdowns';
import { CommandClickEventArgs, CommandModel, EditSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss']
})
export class ScenarioComponent implements OnInit {
  @Input()
  set isEditExposure(edit: boolean) {
    this._isEditExposure = edit;
    this.editSettings={ allowAdding: this.isEditExposure, allowEditing: false, allowDeleting: this.isEditExposure };
  }
  get isEditExposure():boolean {
    return this._isEditExposure;
  }  
  private _isEditExposure: boolean = false;

  @Input()   set isViewExposure(edit: boolean) {
    this._isViewExposure = edit;
    this.editSettings={ allowAdding: this.isEditExposure, allowEditing: false, allowDeleting: this.isEditExposure,showDeleteConfirmDialog:false  };
  }
  get isViewExposure():boolean {
    return this._isViewExposure;
  }  
  private _isViewExposure: boolean = false;

  @Input() pageSettings: PageSettingsModel;
  @Input() editSettings:EditSettingsModel = { allowAdding: this.isEditExposure, allowEditing: false, allowDeleting: this.isEditExposure,showDeleteConfirmDialog:false };

  @Input()
  set scenarioGridData(scenarioGridData: object[]) {
    this._scenarioGridData = scenarioGridData;
    if (this.scenarioCollections.length > 0) {
      // this.populateTreeViewData();
    }
    if (this.scenarioGridData && this.scenarioGridData.length > 0) {
      this.selection = []; // clear selection
      this._scenarioGridData.forEach((e) => {
        this.selection.push(e['scenarioId']);
      });
    }
  }
  get scenarioGridData(): object[] {
    return this._scenarioGridData;
  }
  
  private _scenarioGridData: object[];
  @Output() scenarioGridDataChange: EventEmitter<object[]> = new EventEmitter<object[]>();

  @Input() scenarioTitle: string = '';
  @Input()
  set scenarioCollections(scenarioCollections: object[]) {
    this._scenarioCollections = scenarioCollections;    
    if (scenarioCollections && scenarioCollections.length > 0) {
      // this.populateTreeViewData();
      this.populateSelectList();
    }
  }  
  get scenarioCollections() { return this._scenarioCollections; }

  @Input() scenarioUnSaveChangeDetectionInGrid: boolean = false;
  @Output() scenarioUnSaveChangeDetectionInGridChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {    
    // Nothing to do
   }

  ngAfterViewInit() {
    // Nothing to do
  }

  private _scenarioCollections = [];

  public showScenarioModal: boolean = false;

  public treeViewData = [];

  public selection =  []; 

    // scenario dialog properties
    @ViewChild("scenarioDialog", { static: false })
    public scenarioDialog: DialogComponent;
    public scenarioDlgButtons: ButtonPropsModel[] = [
      { click: this.scenarioCancelClick.bind(this), buttonModel: { content: "Apply", cssClass: "close-Dlg-Buttons" } },
    ];
    public scenarioIsModal: boolean = true;
    public scenarioWidth: string = "95%";
    // public scenarioHeight: string = "48vh";
    public scenarioDialogDragging: boolean = true;
    public scenarioDlgAnimationSettings: object = { effect: "FadeZoom", duration: 200 };
    public scenarioCloseIcon: boolean = true;
  
  public acData = [];
  @ViewChild("acs", { static: false })
  public acsObj: AutoComplete;
  public acFields = { groupBy: "group", value: 'name' };

  public commands:CommandModel[] = [ {type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' },title: 'Delete'}];
  
  @ViewChild("scenarioGrid", { static: false })
  public scenarioGridObj: GridComponent;

  public target: string = "body"; // Dialog target

  ngOnInit(): void {
    // nothing to do
  }

  populateSelectList() {
    this.acData = this.getACData();
  }

  acSelected(args) {
    args.cancel = true; // don't bubble!
    if (this.scenarioGridData == undefined) { this.scenarioGridData = []; } // handle undefined!
    this.scenarioGridData.push({ scenarioId: args.itemData['id'], scenarioName: args.itemData['name'] });
    this.scenarioGridDataChange.emit(this.scenarioGridData);
    this.scenarioGridObj.refresh();
    this.refreshSearchData(null);
    this.acsObj.clear(); // clear the selection
    this.acsObj.value = null;
    this.acsObj.focusIn();
  }

  acChanged(args) {
    args.cancel = true; // don't bubble!
    if (args.e && args.e.type === 'keydown' && (args.e as KeyboardEvent).code === '13') { 
      args.e.preventDefault(); 
  } 
    // console.log(args);
    this.acsObj.value=null; // clear the selection
  }
  // create data for AutoComplete
  getACData() {
    const acData = []; // empty array
    if (this.scenarioCollections && this.scenarioCollections.length > 0) {
      this.scenarioCollections.forEach((item) => {
        const scenarios = item['Scenario'];
        scenarios.forEach((scenario) => {
          if (!this.scenarioGridData || this.scenarioGridData.find((e) => e['scenarioId'] == scenario['id']) == undefined) {
            acData.push({
              id: scenario['id'], group: item['name'], name: scenario['Value'], desc: scenario['Description'] ?? 'No Description'
            });
          }
        });
      });
    }
    return acData;
  }

  // Custom Event handler. Should never be called if allowCustom is false
  public acEnterPressed(e) {
    e.cancelBubble = true; // no bubble please!
    e.preventDefault(); // no default action please!
    this.acsObj.value=null; // clear the selection
    alert("Invalid Entry");
  }

  /**
   * Grids action begin - handle the beginning action of grid
   * @param args 
   */
  gridActionBegin(args) {
    // console.debug('gridActionBegin', args);
    if ((args.requestType == "refresh" && args.rows != undefined && args.rows.length > 0) || (args.requestType == "save" && args.action == "edit")) {
      this.markGridDataDirty();
    }
    if (args.requestType == "delete") {
      this.refreshSearchData(args);
      this.markGridDataDirty();
    }
  }

  refreshSearchData(args) {
    this.acData = this.getACData();
  }

  markGridDataDirty() {
    this.scenarioUnSaveChangeDetectionInGrid = true;
    this.scenarioUnSaveChangeDetectionInGridChange.emit(this.scenarioUnSaveChangeDetectionInGrid);
  }

  loadScenarioData(args) {
    // (<any>this.scenarioGridObj).defaultLocale.EmptyRecord = "No scenarios to display";
    // moved to empty record template
  }

  onCreateScenarioClick() {
    if (this.isViewExposure) {
      return;
    }
    this.FocusInSearch();
    // if (this.scenarioObj) { this.scenarioObj.FocusInSearch(); }
      // this.showScenarioModal = true;
      // this.scenarioTitle = " <div class='icon-scenario modal-icon ' ></div><div class='modal-title1 '>Scenario</div>";
      // this.scenarioDialog.show();

    // setTimeout(() => {
    //   this.acData = this.getACData();
    //   console.log('selection=' + this.selection);
    //   }, 10);
    }

  scenarioCancelClick() {
    this.showScenarioModal = false;
    this.scenarioDialog.hide();
  }

  dialogOpened() {
  }

  public FocusInSearch() {
    this.acsObj.focusIn();
    this.acsObj.showPopup();
  }


  public actionBegin(args) {
    // console.log(args);
  }

  public populateData(args) {
    this.acData = this.getACData();
  }  

  public removeClick(args) {
    // console.log(args);
  }

  gridDataBound(args) {
    // this.scenarioGridObj.refreshColumns(); // This makes the grid re-adjust the columns to fit!
  }
}
