import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

import { HttpErrorResponse } from '@angular/common/http';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { CookieService } from 'ngx-cookie-service';
import { ToasterService } from '../../Providers/ToasterService';
import { AppConstants } from "../../app.constants";
import { OnlineService } from './../../Providers/OnlineService';

@Component({
  selector: "generic-substance-picker",
  templateUrl: './generic-substance-picker.component.html',
  styleUrls: ['./generic-substance-picker.component.scss']
})
export class GenericSubstancePickerComponent implements OnInit {
  @Output() substanceSelected = new EventEmitter();

  @ViewChild('genericSubstanceSelectionDialog', { static: false })
  genericSubstanceSelectionDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild('container', { static: false }) container: ElementRef;

  public isPrintExposure: boolean = false; // not used, but in html. Remove at some point.
  public genericSubstanceDialogVisible: boolean = false;
  public genericSubstanceDialogHeader: string = 'Pick a Generic Substance';
  public animationSettings: Object = { effect: 'Zoom', duration: 100, delay: 0 };
  public target: string = 'body';
  public dialogWidth: string = '80%';
  public isDraggable: boolean = true;

  public genericTitle: string = 'Generic Substance Selection';

  public ShowNoGenericSubstanceWarning: boolean = true;
  public genericTreeCollections: Object[] = [];
  public genericTreeField: Object;

  @ViewChild('genericTree', { static: false })
  public genericTreeViewObj: TreeViewComponent;

  constructor(public onlineService: OnlineService, public toastService: ToasterService, private _cookieService: CookieService) {}

  ngOnInit() {
    this.genericTreeField = {
      dataSource: this.genericTreeCollections,
      id: 'substanceId',
      text: 'substanceName',
      hasChildren: 'hasChild',
      parentID: 'substanceGroup',
      tooltip: 'Title'
    };
  }

  populateGenericSubstance() {
    var $this = this;
    $this.ShowNoGenericSubstanceWarning = false;
    // $this.BusyMessage='Loading Generic Substances...';
    // $this.spinner.show();
    $this.genericTitle = 'Populating Generic Substances...';
    var treeObj: any = document.getElementById('genericSubstanceTree');
    var treeViewObj = treeObj.ej2_instances[0];

    if ($this.genericTreeCollections.length == 0) {
      $this.onlineService.GetGenericSubstanceFromIndexedDB().then(
        function(arg: any) {
          if (arg != 'Database does not exist' && arg.length > 0) {
            $this.genericTreeCollections = arg;
            for (var i = 0; i < arg.length; i++) {
              arg[i]['hasChild'] = arg[i].substanceCode != null && arg[i].substanceGroup != null ? false : true;
              arg[i]['Title'] = arg[i].substanceDescription == null ? arg[i].substanceName : arg[i].substanceName + ' : ' + arg[i].substanceDescription;
            }

            if (treeViewObj)
              treeViewObj.fields = {
                dataSource: $this.genericTreeCollections,
                id: 'substanceId',
                text: 'substanceName',
                hasChildren: 'hasChild',
                parentID: 'substanceGroup',
                tooltip: 'Title'
              };

            // $this.genericTreeViewObj.refresh();
            // $this.spinner.hide();
          } else {
            if (arg == 'Database does not exist') {
              $this.ShowNoGenericSubstanceWarning = true;
              // $this.spinner.hide();
            } else if (arg.length == 0) {
              $this.ShowNoGenericSubstanceWarning = true;
              // $this.spinner.hide();
            } else {
              this.ShowToastMessage('error', 'Something went wrong retrieving Generic Substances. Close the Selection window and try again.');
              // $this.spinner.hide();
            }
          }
          $this.genericTitle = 'Select Generic Substance';
        },
        (err: HttpErrorResponse) => {
          var errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description == undefined && err.error.error_description == null ? null : err.error.error_description
          };
          var $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function() {
            var toasterValue = {
              type: 'error',
              message: 'Something went wrong adding to error log.'
            };
            $this.toastService.showSpinner(toasterValue);
            // $this.spinner.hide();
          });
        }
      );
    } else {
      $this.genericTitle = 'Select Generic Substance';
      // $this.genericTreeViewObj.refresh();
      // $this.spinner.hide();
    }
  }

  onSelectGenericCodeClick(e: any) {
    //show the dialog
    this.genericSubstanceSelectionDialog.show();
    //populate
    this.populateGenericSubstance();
  }

  genericCodeNodeSelected($event) {
    var selectedRecord: any = this.genericTreeCollections.filter(function(value: any) {
      return value.substanceId == $event.nodeData.id;
    });
    if (selectedRecord[0].hasChild == false) {
      this.substanceSelected.emit([selectedRecord[0].substanceCode, selectedRecord[0].substanceName]); // return an event!
      this.genericSubstanceSelectionDialog.hide();
    }
  }
  ShowToastMessage(ToastType: string, ToastMessage: string) {
    alert(ToastMessage);
    //toastService.showSpinner({ToastType,ToastMessage});
  }
  onCloseGenericSubstancePickerButtonClick() {
    this.genericSubstanceSelectionDialog.hide();
  }
}
