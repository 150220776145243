import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnlineService } from '../Providers/OnlineService';
import { ToasterService } from '../Providers/ToasterService';
import { AppConstants } from '../app.constants';
import { MySubstance } from '../models/mySubstanceModel';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SubstanceSearchService {

  constructor(private httpClient: HttpClient,
    private onlineService: OnlineService,
    private _cookieService: CookieService,
    public toastService: ToasterService,
    public translate: TranslateService) {
  }

  public SubstanceSearchItemList;

  /**
   * Logins get substance search - Get the Substance Search Data and load into IndexedDB
   * @param substanceSearchList
   * @returns
   */
  loginGetSubstanceSearch(substanceSearchList) {
    return new Promise((resolve) => {
      this.createSubstanceSearchDB(substanceSearchList);
      resolve(substanceSearchList);
    });
  }

  createSubstanceSearchDB(data) {
    const request = indexedDB.open(AppConstants.SubstanceSearchDBName, 1);

    request.onupgradeneeded = (evt: any)=> {
      this.CreateSubstanceSearchTable(evt);
    };

    request.onsuccess =  (evt) => {
      const db = request.result;
      if (db.objectStoreNames.length > 0) {
        const transaction = db.transaction(AppConstants.SubstanceSearchTableName, 'readwrite');
        const objectStore = transaction.objectStore(AppConstants.SubstanceSearchTableName);
        const objectStoreRequest = transaction.objectStore(AppConstants.SubstanceSearchTableName).clear();
        objectStoreRequest.onsuccess = function (event) {
          for (let t = 0; t < data.length; t++) {
            objectStore.add({
              Prod_Id: data[t].prod_Id,
              Prod_Name: data[t].prod_Name,
              Med_Id: data[t].med_Id,
              Med_Name: data[t].med_Name,
              Source: data[t].source,
              Status: data[t].status,
              Amt_Each: data[t].amt_Each,
              Update_Dt: data[t].update_Dt
            });
          }
        };
      } else {
        indexedDB.deleteDatabase(AppConstants.SubstanceSearchDBName);
        this.createSubstanceSearchDB(data);
      }
    };
  }


  getSubstanceSearchData(source?: string, status?: string) {
    return new Promise((resolve) => {
      this.httpClient.get(this.onlineService.url.getSubstanceSearchUrl).subscribe(
        (data: any) => {
          // switch (source) {
          //   case AppConstants.SubstanceSearch.Type.All:

          //     // return this.getSubstanceSearchFromIndexedDB();
          //     break;
          //     case AppConstants.SubstanceSearch.Type.NPR:
          //     break;
          //     case AppConstants.SubstanceSearch.Type.AMT:
          //     break;
          //     case AppConstants.SubstanceSearch.Type.PDX:
          //     break;
          //   default:
          //     break;
          // }
          this.SubstanceSearchItemList = data;
          this.CreateSubstanceSearchItemDB(this.SubstanceSearchItemList).then(function (result) {
            resolve(result);
          });
        },
        (error) => {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: error.message,
            ErrorMessage: error.message,
            InnerException: error.error.error_description === undefined && error.error.error_description == null ? null : error.error.error_description,
          };
          const $this = this;
          this.onlineService.AddErrorLog(errorLog).subscribe(function () {
            const toasterValue = {
              type: 'error',
              message: this.translate.instant('common.errorGettingSubstanceSearchList'),
            };
            $this.toastService.showSpinner(toasterValue);
          });
        }
      );
    });
  }

  //   return new Promise((resolve) => {

  //   });

  // }
  getSubstanceSearchFromIndexedDB() {
    return new Promise((resolve) => {
      // this.getSubstanceSearchItemsList();
      let subSearchData = [];
      // attempt to open DB by name
      const request = indexedDB.open(AppConstants.SubstanceSearchDBName, 1);
      request.onsuccess =  (evt)=> {
        const db = request.result;
        if (db.objectStoreNames.length > 0) {
          const transactRequest = db.transaction(AppConstants.SubstanceSearchTableName, 'readonly').objectStore(AppConstants.SubstanceSearchTableName);

          const records = transactRequest.getAll();
          records.onsuccess =  (event)=> {
            subSearchData = records.result; // event.target.result;
            resolve(subSearchData);
          };
        } else {
          this.CreateSubstanceSearchItemDB(this.SubstanceSearchItemList).then(function (result) {
            resolve(result);
          });
        }

      };

      // unable to open the db
      request.onerror = (evt)=> {
        alert(this.translate.instant('common.unableToLoadSubstanceSearchFromIndexedDB'));
      };
    });
  }

  CreateSubstanceSearchItemDB(SubstanceSearchItemList) {
    return new Promise((resolve) => {
      indexedDB.deleteDatabase(AppConstants.SubstanceSearchDBName); // Cleanup in case DB already exists
      const request = indexedDB.open(AppConstants.SubstanceSearchDBName, 1);

      request.onupgradeneeded =(evt: any)=> {
        this.CreateSubstanceSearchTable(evt);
      };
      request.onsuccess = (evt)=> {
        const db = request.result;
        if (db.objectStoreNames.contains(AppConstants.SubstanceSearchTableName)) {
          this.BindSubstanceSearchItem(SubstanceSearchItemList).then(function (result) {
            resolve(result);
          });
        } else {
          indexedDB.deleteDatabase(AppConstants.SubstanceSearchDBName);
          resolve(this.translate.instant('common.noDBForMySubstances') + AppConstants.SubstanceSearchDBName);
        }
      };
      request.onerror = (evt)=> {
        alert(evt);
      };
    });
  }

  private CreateSubstanceSearchTable(evt: any) {
    const objectStore = evt.currentTarget.result.createObjectStore(AppConstants.SubstanceSearchTableName, { keyPath: 'Prod_Id', autoIncrement: true });
    objectStore.createIndex('Prod_Id', 'Prod_Id', { unique: true });
    objectStore.createIndex('Prod_Name', 'Prod_Name', { unique: false });
    objectStore.createIndex('Med_Id', 'Med_Id', { unique: false });
    objectStore.createIndex('Med_Name', 'Med_Name', { unique: false });
    objectStore.createIndex('Source', 'Source', { unique: false });
    objectStore.createIndex('Amt_Each', 'Amt_Each', { unique: false });
    objectStore.createIndex('Status', 'Status', { unique: false });
    objectStore.createIndex('Update_Dt', 'Update_Dt', { unique: false });
  }

  BindSubstanceSearchItem(SubstanceSearchItemList) {
    return new Promise((resolve) => {
      try {
        const request = indexedDB.open(AppConstants.SubstanceSearchDBName, 1);
        request.onsuccess = (evt: any) => {
          const db = request.result;
          if (db.objectStoreNames.contains(AppConstants.SubstanceSearchTableName)) {
            const transaction = db.transaction(AppConstants.SubstanceSearchTableName, 'readwrite');
            transaction.oncomplete = (event) => { console.debug(AppConstants.SubstanceSearchTableName + ' Transaction complete:' + event); };
            transaction.onerror = (event) => { console.debug(AppConstants.SubstanceSearchTableName + ' Error:' + event); };
            transaction.onabort = (event) => { console.debug(AppConstants.SubstanceSearchTableName + ' Abort:' + event); };

            const objectStore = transaction.objectStore(AppConstants.SubstanceSearchTableName);
            const objectStoreRequest = transaction.objectStore(AppConstants.SubstanceSearchTableName).clear();
            objectStoreRequest.onsuccess = (event) => { console.debug(AppConstants.SubstanceSearchTableName + ' success:' + event); };
            objectStoreRequest.onerror = (event) => { console.debug(AppConstants.SubstanceSearchTableName + ' Error:' + event); };

            objectStoreRequest.onsuccess = (event) => {
              for (let t = 0; t < SubstanceSearchItemList.length; t++) {
                // console.debug('Adding ' + SubstanceSearchItemList[t].prod_Id + ' to ' + AppConstants.SubstanceSearchTableName);
                objectStore.add({
                  Prod_Id: SubstanceSearchItemList[t].prod_Id,
                  Prod_Name: SubstanceSearchItemList[t].prod_Name,
                  Med_Id: SubstanceSearchItemList[t].med_Id,
                  Med_Name: SubstanceSearchItemList[t].med_Name,
                  Source: SubstanceSearchItemList[t].source,
                  Amt_Each: (SubstanceSearchItemList[t].amt_Each == null ? null : SubstanceSearchItemList[t].amt_Each.toString()),
                  Status: SubstanceSearchItemList[t].status,
                  Update_Dt: SubstanceSearchItemList[t].update_Dt
                });
              }
              transaction.commit();
              resolve(SubstanceSearchItemList);
            };
          } else {
            indexedDB.deleteDatabase(AppConstants.SubstanceSearchDBName);
            this.CreateSubstanceSearchItemDB(this.SubstanceSearchItemList).then(function (result) {
              resolve(result);
            });
          }
        };
      } catch (error) {
        alert("An Error Occurred during substance search items local database loading. Please logout and close your browser and try again." + error.message);
      }
    });
  }

  
  getMySubstanceSearchItemsList() {
    return new Promise((resolve) => {
      this.httpClient.get(this.onlineService.url.getMySubstanceSearchUrl).subscribe(
        (data: any) => {
          this.SubstanceSearchItemList = data;
          this.CreateMySubstanceSearchItemDB(this.SubstanceSearchItemList).then(function (result) {
            resolve(result);
          });
        },
        (error) => {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: error.message,
            ErrorMessage: error.message,
            InnerException: error.error.error_description === undefined && error.error.error_description == null ? null : error.error.error_description,
          };
          this.onlineService.AddErrorLog(errorLog).subscribe(()=> {
            const toasterValue = {
              type: 'error',
              message: this.translate.instant('common.errorRetrievingMySubstances'),
            };
            this.toastService.showSpinner(toasterValue);
          });
        }
      );
    });
  }

  CreateMySubstanceSearchItemDB(SubstanceSearchItemList) {
    return new Promise((resolve) => {
      
      indexedDB.deleteDatabase(AppConstants.MySubstanceSearchDBName); // Cleanup in case DB already exists
      const request = indexedDB.open(AppConstants.MySubstanceSearchTableName, 1);

      request.onupgradeneeded = (evt: any) => {
        this.CreateMySubstanceSearchTable(evt);
      };
      request.onsuccess =  () => {
        const db = request.result;
        if (db.objectStoreNames.contains(AppConstants.MySubstanceSearchTableName)) {
          this.BindMySubstanceSearchItem(SubstanceSearchItemList).then(function (result) {
            resolve(result);
          });
        } else {
          indexedDB.deleteDatabase(AppConstants.MySubstanceSearchDBName);
          resolve(this.translate.instant('common.noDbExistsForMySubstances') + AppConstants.MySubstanceSearchDBName);
        }
      };
    });
  }

  private CreateMySubstanceSearchTable(evt: any) {
    const objectStore = evt.currentTarget.result.createObjectStore(AppConstants.MySubstanceSearchTableName, { keyPath: 'Prod_Id', autoIncrement: true });
    objectStore.createIndex('Prod_Id', 'Prod_Id', { unique: true });
    objectStore.createIndex('Prod_Name', 'Prod_Name', { unique: false });
    objectStore.createIndex('Med_Id', 'Med_Id', { unique: false });
    objectStore.createIndex('Med_Name', 'Med_Name', { unique: false });
    objectStore.createIndex('Source', 'Source', { unique: false });
    objectStore.createIndex('Status', 'Status', { unique: false });
    objectStore.createIndex('Amt_Each', 'Amt_Each', { unique: false });
  }

  BindMySubstanceSearchItem(SubstanceSearchItemList) {
    return new Promise((resolve) => {
      const request = indexedDB.open(AppConstants.MySubstanceSearchDBName, 1);
      request.onsuccess = (evt: any)=> {
        const db = request.result;
        if (db.objectStoreNames.contains(AppConstants.MySubstanceSearchTableName)) {
          const transaction = db.transaction(AppConstants.MySubstanceSearchTableName, 'readwrite');
          const objectStore = transaction.objectStore(AppConstants.MySubstanceSearchTableName);
          const objectStoreRequest = transaction.objectStore(AppConstants.MySubstanceSearchTableName).clear();
          objectStoreRequest.onsuccess = (event)=> {
            for (let t = 0; t < SubstanceSearchItemList.length; t++) {
              objectStore.add({
                Prod_Id: SubstanceSearchItemList[t].prod_Id,
                Prod_Name: SubstanceSearchItemList[t].prod_Name,
                Med_Id: SubstanceSearchItemList[t].med_Id,
                Med_Name: SubstanceSearchItemList[t].med_Name,
                Source: SubstanceSearchItemList[t].source,
                Status: SubstanceSearchItemList[t].status,
                Amt_Each: SubstanceSearchItemList[t].amt_Each
              });
            }
            resolve(SubstanceSearchItemList);
          };
        } else {
          indexedDB.deleteDatabase(AppConstants.MySubstanceSearchDBName);
          this.CreateMySubstanceSearchItemDB(SubstanceSearchItemList);
        }
      };
    });
  }
  GetMySubstances() {
    return new Promise((resolve) => {
      this.httpClient
        .get(this.onlineService.url.getMySubstancesUrl)
        .pipe(map((res) => res))
        .subscribe(
          (data: any) => { resolve(data); },
          (error) => { resolve(error); }
        );
    });
  }


  // Substance Search Methods
  // public GetSubstanceSearchUpdates(updateDate: DateTime) {
  //   // if null, the get the max update date from the indexedDB
  //   if (updateDate == null) {
  //     const req = indexedDB.open(AppConstants.SubstanceSearchDBName, 1);
  //     req.onsuccess = (evt: any) => {
  //       // attempt to open DB by name
  //       const db = req.result;
  //       if (db.objectStoreNames.length > 0) {
  //         const transactRequest = db.transaction(AppConstants.SubstanceSearchTableName, 'readonly').objectStore(AppConstants.SubstanceSearchTableName);
  //         // find the max value of updateDate
  //         const index = transactRequest.index('Update_Dt');
  //         const request = index.openCursor(null, 'prev');
  //         request.onsuccess = (event) => {
  //           const cursor = request.result;
  //           if (cursor) {
  //             const itemUpdateDate = new DateTime(cursor.value.Update_Dt);
  //             if (itemUpdateDate > updateDate || updateDate == null) {
  //               updateDate = itemUpdateDate;
  //               console.debug('Update Date: ' + updateDate);
  //             }
  //             cursor.continue();
  //           }
  //         }
  //         // now we have an update date, return the updates promise
  //         console.debug('Update Date: ' + updateDate);
  //         return this.httpClient.post(this.onlineService.url.getSubstanceSearchUpdatesUrl, updateDate);
  //       }
  //     }           
  //   }
  //   return this.httpClient.post(this.onlineService.url.getSubstanceSearchUpdatesUrl, updateDate);
  // }

  public GetSubstanceSearchUpdates(updateDate: Date): Promise<Observable<any>> {
    return new Promise((resolve, reject) => {
      if (updateDate == null) {
        const req = indexedDB.open(AppConstants.SubstanceSearchDBName, 1);
        req.onsuccess = (evt: any) => {
          const db = req.result;
          if (db.objectStoreNames.length > 0) {
            const transactRequest = db.transaction(AppConstants.SubstanceSearchTableName, 'readonly').objectStore(AppConstants.SubstanceSearchTableName);
            const index = transactRequest.index('Update_Dt');
            const request = index.openCursor(null, 'prev');
            request.onsuccess = (event) => {
              const cursor = request.result;
              if (cursor) {
                const itemUpdateDate = new Date(cursor.value.Update_Dt);
                if (itemUpdateDate > updateDate || updateDate == null) {
                  updateDate = itemUpdateDate;
                  console.debug('Update Date: ' + updateDate);                    
                }                
              }             
              // resolve the promise with number of items added
              // get the updates from the server
              const getUpdates=this.GetSubstanceSearchUpdatesFromServer(updateDate);
              getUpdates.subscribe((data: any) => {             
                const updatedSubs = this.UpdateIndexedDB(db, data);
                resolve(updatedSubs);
              });
              // this.GetSubstanceSearchUpdatesFromServer(updateDate).subscribe((data: any) => {
             
              //   resolve(data);}
            }
          }
        }
        req.onerror = (evt: any) => {          
          reject(evt.target.error);          
        }
      } else {
        resolve(this.httpClient.get(`${this.onlineService.url.getSubstanceSearchUpdatesUrl}?updateDate=${updateDate.toUTCString()}`));
      }
    });
  }

  private UpdateIndexedDB(db: IDBDatabase, subsToUpdate: any) {

    // process the data record by record             
    subsToUpdate.forEach((element) => {
      const findTransaction = db.transaction(AppConstants.SubstanceSearchTableName, 'readwrite');
      const findObjectStore = findTransaction.objectStore(AppConstants.SubstanceSearchTableName);
      const findRequest = findObjectStore.get(element.prod_Id); 
      findRequest.onsuccess = (event) => {
        const result = findRequest.result;
        if (result) {
            const updateRequest = findObjectStore.put({
            Prod_Id: element.prod_Id,
            Prod_Name: element.prod_Name,
            Med_Id: element.med_Id,
            Med_Name: element.med_Name,
            Source: element.source,
            Status: element.status,
            Amt_Each: element.amt_Each,
            Update_Dt: element.update_Dt
            }); 
          updateRequest.onerror = (event) => {
            console.debug('Error updating record: ' + event);
          };
          updateRequest.onsuccess = (event) => {
            console.debug('Updated record: ' + event);
          };
        }
      };      
      // handle error for find request - this is not a fatal error
      findRequest.onerror = (event) => {

        console.debug('Error getting record: ' + event);
        const addTransaction = db.transaction(AppConstants.SubstanceSearchTableName, 'readwrite');
        const addObjectStore = addTransaction.objectStore(AppConstants.SubstanceSearchTableName);

        const addRequest = addObjectStore.add({
          Prod_Id: element.prod_Id,
          Prod_Name: element.prod_Name,
          Med_Id: element.med_Id,
          Med_Name: element.med_Name,
          Source: element.source,
          Status: element.status,
          Amt_Each: element.amt_Each,
          Update_Dt: element.update_Dt
        });
        addRequest.onerror = (event) => {
          console.debug('Error adding record: ' + event);
        };
        addRequest.onsuccess = (event) => {
          console.debug('Added record: ' + event);
        };
      };
    });
    return subsToUpdate;
  }

  GetSubstanceSearchUpdatesFromServer(updateDate: Date):Observable<any> {
    const updateDateStr = updateDate == null ? null : updateDate.toUTCString();
    return this.httpClient.get(`${this.onlineService.url.getSubstanceSearchUpdatesUrl}?updateDate=${updateDateStr}`);
  }
  
  // MySubstance Methods
  public AddMySubstance(newSubstance: MySubstance):Observable<MySubstance> {
    return this.httpClient.post(this.onlineService.url.addMySubstanceUrl, newSubstance) as Observable<MySubstance>;
  }
  public EditMySubstance(newSubstance: MySubstance): Observable<MySubstance> {
    return this.httpClient.post(this.onlineService.url.editMySubstanceUrl, newSubstance) as Observable<MySubstance>;
  }
  public DeleteMySubstance(newSubstance: MySubstance) {
    return this.httpClient.post(this.onlineService.url.deleteMySubstanceUrl, newSubstance);
  }
  public GetMySubstanceDetails(substanceId: string) {
    return this.httpClient.get(this.onlineService.url.getMySubstanceDetailsUrl + "?substanceId=" + substanceId);
  }
}
