import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-substance-tree',
  templateUrl: './substance-tree.component.html',
  styleUrls: ['./substance-tree.component.scss']
})
export class SubstanceTreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
   //define the filtering data
   public filterData: Object[] = [
    { id: 1, name: 'Australia', hasChild: true, expanded: true },
         { id: 2, pid: 1, name: 'New South Wales' , hasChild: true},
         {id:1000011000168105, pid:2, name:'Pantoprazole Heartburn Relief (Apohealth) 20 mg enteric tablet' },
 {id:1000061000168108, pid:2, name:'Rivaroxaban (Apo) 15 mg tablet' },
 {id:1000101000168106, pid:2, name:'Levonorgestrel-1 (Apo) 1.5 mg tablet' },
 {id:1000191000168100, pid:2, name:'Palonosetron (Apo) 250 microgram/5 mL injection, 5 mL vial' },
 {id:1000241000168100, pid:2, name:'Tadalafil (Apo) 10 mg tablet' },
 {id:1000291000168108, pid:2, name:'Tadalafil (Apo) 5 mg tablet' },
 {id:1000341000168106, pid:2, name:'Tadalafil PHT (Apo) 20 mg tablet' },
 {id:1000431000168108, pid:2, name:'Rivastigmine 15 (Apo) 13.3 mg/24 hours patch' },
 {id:1000501000168100, pid:2, name:'Tadalafil (Apo) 2.5 mg tablet' },
 {id:1000531000168107, pid:2, name:'Citalopram (Apotex) 20 mg tablet' },
 {id:1000561000168104, pid:2, name:'Tadalafil (Apo) 20 mg tablet' },
 {id:1000621000168108, pid:2, name:'Flecacor 50 mg tablet' },
 {id:1000661000168103, pid:2, name:'Flecacor 100 mg tablet' },
 {id:1000691000168105, pid:2, name:'Citalopram (Apotex) 40 mg tablet' },
 {id:1000721000168101, pid:2, name:'Capecitabine (Apo) 150 mg tablet' },
 {id:1000761000168106, pid:2, name:'Olanzapine (Apotex) 5 mg tablet' },
 {id:1000791000168104, pid:2, name:'Pregabalin (Apo) 100 mg capsule' },
 {id:1000941000168105, pid:2, name:'Olanzapine (Apotex) 7.5 mg tablet' },
 {id:1000971000168103, pid:2, name:'Pregabalin (Apo) 50 mg capsule' },
 {id:1001141000168100, pid:2, name:'Valaciclovir 1000 (Apo) 1 g tablet' },
 {id:1001241000168108, pid:2, name:'Pregabalin (Apo) 225 mg capsule' },
 {id:1001451000168106, pid:2, name:'Tramadol (Apotex) 50 mg capsule' },
 {id:1001491000168101, pid:2, name:'Naloxone Min-I-Jet (UCB) 800 microgram/2 mL injection, 2 mL syringe' },
 {id:1001521000168104, pid:2, name:'Olanzapine (Apotex) 10 mg tablet' },
 {id:1001571000168103, pid:2, name:'Naloxone Min-I-Jet (UCB) 2 mg/5 mL injection, 5 mL syringe' },
 {id:1001621000168100, pid:2, name:'Olanzapine (Apotex) 15 mg tablet' },
 {id:1001711000168100, pid:2, name:'Olanzapine (Apotex) 20 mg tablet' },
 {id:1001791000168109, pid:2, name:'Amlodipine/Atorvastatin 10/40 (Apotex) tablet' },
 {id:1001831000168103, pid:2, name:'Amlodipine/Atorvastatin 10/80 (Apotex) tablet' },
 {id:1001871000168100, pid:2, name:'Amlodipine/Atorvastatin 10/20 (Apotex) tablet' },
 {id:1001901000168100, pid:2, name:'Pregabalin (Apo) 200 mg capsule' },
 {id:1002061000168109, pid:2, name:'Amlodipine/Atorvastatin 5/20 (Apotex) tablet' },
 {id:1002141000168109, pid:2, name:'Amlodipine/Atorvastatin 5/80 (Apotex) tablet' },
 {id:1002211000168100, pid:2, name:'Rivastigmine (Apo) 4.5 mg capsule' },
 {id:1002261000168102, pid:2, name:'Amlodipine/Atorvastatin 5/40 (Apotex) tablet' },
 {id:1002301000168105, pid:2, name:'Amlodipine/Atorvastatin 5/10 (Apotex) tablet' },
 {id:1002501000168101, pid:2, name:'Amlodipine/Atorvastatin 10/10 (Apotex) tablet' },
 {id:1002531000168108, pid:2, name:'Rivastigmine (Apo) 1.5 mg capsule' },
 {id:1002721000168102, pid:2, name:'Rivastigmine (Apo) 3 mg capsule' },
 {id:1002811000168101, pid:2, name:'Olanzapine (Apotex) 2.5 mg tablet' },
 {id:1002951000168108, pid:2, name:'Nevirapine (Apo) 200 mg tablet' },
 {id:1002981000168101, pid:2, name:'Rivastigmine (Apo) 6 mg capsule' },
 {id:1003021000168106, pid:2, name:'Kepcet 1 g tablet' },
 {id:1003061000168101, pid:2, name:'Levactam 1 g tablet' },
 {id:1003101000168103, pid:2, name:'Levetiracetam (Accord) 1 g tablet' },
 {id:1003141000168101, pid:2, name:'Levetiracetam (AN) 1 g tablet' },
 {id:1003271000168102, pid:2, name:'Levetiracetam (Chemmart) 1 g tablet' },
 {id:1003341000168103, pid:2, name:'Levetiracetam (Generic Health) 1 g tablet' },
 {id:1003381000168108, pid:2, name:'Levetiracetam (GH) 1 g tablet' },
 {id:1003421000168104, pid:2, name:'Levetiracetam (GN) 1 g tablet' },
 {id:1003461000168109, pid:2, name:'Levetiracetam (LAPL) 1 g tablet' },
 {id:1003501000168109, pid:2, name:'Levetiracetam (Lupin) 1 g tablet' },
 {id:1003541000168106, pid:2, name:'Levetiracetam (MPPL) 1 g tablet' },
 {id:1003581000168101, pid:2, name:'Levetiracetam (Pfizer) 1 g tablet' },
 {id:1003631000168103, pid:2, name:'Levetiracetam (Sandoz) 1 g tablet' },
 {id:1003671000168100, pid:2, name:'Levetiracetam (SCP) 1 g tablet' },
 {id:1003711000168101, pid:2, name:'Levetiracetam (SZ) 1 g tablet' },
 {id:1003751000168100, pid:2, name:'Levetiracetam (Terry White Chemists) 1 g tablet' },
 {id:1003791000168105, pid:2, name:'Levitaccord 1 g tablet' },
 {id:1003831000168104, pid:2, name:'Losartan (Apo) 25 mg tablet' },
 {id:1003941000168104, pid:2, name:'Losartan (Apo) 50 mg tablet' },
 {id:1004041000168102, pid:2, name:'Rizatriptan (Apo) 5 mg orally disintegrating tablet' },
 {id:1004101000168100, pid:2, name:'Midazolam (Apo) 5 mg/mL injection, ampoule' },
 {id:1004151000168101, pid:2, name:'Midazolam (Apo) 5 mg/5 mL injection, 5 mL ampoule' },
 {id:1004181000168108, pid:2, name:'Midazolam (Apo) 15 mg/3 mL injection, 3 mL ampoule' },
 {id:1004211000168107, pid:2, name:'Midazolam (Apo) 50 mg/10 mL injection, 10 mL ampoule' },
 {id:1004251000168108, pid:2, name:'Olmesartan (Apo) 20 mg tablet' },
 {id:1004281000168101, pid:2, name:'Olmesartan (Apo) 40 mg tablet' },
 {id:1004321000168106, pid:2, name:'Tenofovir (Apo) 300 mg tablet' },
 {id:1004361000168101, pid:2, name:'losartan potassium 100 mg tablet' },
 {id:1004371000168107, pid:2, name:'Losartan (Apo) 100 mg tablet' },
 {id:1004481000168102, pid:2, name:'ropivacaine hydrochloride 20 mg/10 mL injection, ampoule' },
 {id:1004491000168104, pid:2, name:'Naropin 0.2% 20 mg/10 mL injection, 10 mL ampoule' },
 {id:1004531000168104, pid:2, name:'ropivacaine hydrochloride 200 mg/100 mL injection, bag' },
 {id:1004541000168108, pid:2, name:'Naropin 0.2% 200 mg/100 mL injection, 100 mL bag' },
 {id:1004581000168103, pid:2, name:'ropivacaine hydrochloride 40 mg/20 mL injection, ampoule' },
 {id:1004591000168100, pid:2, name:'Naropin 0.2% 40 mg/20 mL injection, 20 mL ampoule' },
 {id:1004631000168100, pid:2, name:'ropivacaine hydrochloride 400 mg/200 mL injection, bag' },
 {id:1004641000168109, pid:2, name:'Naropin 0.2% 400 mg/200 mL injection, 200 mL bag' },
 {id:1004681000168104, pid:2, name:'ropivacaine hydrochloride 200 mg/100 mL + fentanyl 200 microgram/100 mL injection, bag' },
 {id:1004691000168101, pid:2, name:'Naropin 0.2% with Fentanyl 200 microgram/100 mL injection, 100 mL bag' },
 {id:1004731000168108, pid:2, name:'ropivacaine hydrochloride 200 mg/100 mL + fentanyl 400 microgram/100 mL injection, bag' },
 {id:1004741000168104, pid:2, name:'Naropin 0.2% with Fentanyl 400 microgram/100 mL injection, 100 mL bag' },
 {id:1004781000168109, pid:2, name:'ropivacaine hydrochloride 400 mg/200 mL + fentanyl 400 microgram/200 mL injection, bag' },
 {id:1004791000168107, pid:2, name:'Naropin 0.2% with Fentanyl 400 microgram/200 mL injection, 200 mL bag' },
 {id:1004831000168101, pid:2, name:'ropivacaine hydrochloride 400 mg/200 mL + fentanyl 800 microgram/200 mL injection, bag' },
 {id:1004841000168105, pid:2, name:'Naropin 0.2% with Fentanyl 800 microgram/200 mL injection, 200 mL bag' },
 {id:1004881000168100, pid:2, name:'ropivacaine hydrochloride 150 mg/20 mL injection, ampoule' },
 {id:1004891000168102, pid:2, name:'Naropin 0.75% 150 mg/20 mL injection, 20 mL ampoule' },
 {id:1004931000168105, pid:2, name:'ropivacaine hydrochloride 75 mg/10 mL injection, ampoule' },
 {id:1004941000168101, pid:2, name:'Naropin 0.75% 75 mg/10 mL injection, 10 mL ampoule' },
 {id:1004981000168106, pid:2, name:'ropivacaine hydrochloride 100 mg/10 mL injection, ampoule' },
 {id:1004991000168109, pid:2, name:'Naropin 1% 100 mg/10 mL injection, 10 mL ampoule' },
 {id:1005031000168105, pid:2, name:'ropivacaine hydrochloride 200 mg/20 mL injection, ampoule' },
 {id:1005041000168101, pid:2, name:'Naropin 1% 200 mg/20 mL injection, 20 mL ampoule' },
 {id:1005081000168106, pid:2, name:'Ropibam 0.2% 20 mg/10 mL injection, 10 mL ampoule' },
 {id:1005111000168101, pid:2, name:'Ropibam 0.2% 200 mg/100 mL injection, 100 mL bag' },
 {id:1005141000168102, pid:2, name:'Ropibam 0.2% 40 mg/20 mL injection, 20 mL ampoule' },
 {id:1005171000168109, pid:2, name:'Ropibam 0.2% 400 mg/200 mL injection, 200 mL bag' },
 {id:1005201000168108, pid:2, name:'Ropibam 0.75% 150 mg/20 mL injection, 20 mL ampoule' },
     ];
     public fields: Object = { dataSource: this.filterData, value: 'id', parentValue: 'pid', text: 'name', hasChildren: 'hasChild' };
     public height: string = '220px';
     public watermark: string = 'Select an item';
     public filterPlaceholder: string = 'Search';
     public showCheckBox = false;
}
