import { Injectable } from '@angular/core';
import { AppConstants } from '../app.constants';
import { OnlineService } from './../../app/Providers/OnlineService';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public isExposurePageHasAddPermission: boolean = false;
  public isExposurePageHasEditPermission: boolean = false;
  public isExposurePageHasDeletePermission: boolean = false;
  public isExposurePageHasViewPermission: boolean = false;

  public isInfoRequestPageHasViewPermission: boolean = false;
  public isInfoRequestPageHasAddPermission: boolean = false;
  public isInfoRequestPageHasEditPermission: boolean = false;
  public isInfoRequestPageHasDeletePermission: boolean = false;

  public isReportsPageHasViewPermission: boolean = false;

  public isSearchPageHasViewPermission: boolean = false;

  public offlineHomePageViewPermission: boolean = false;
  public offlineExposurePageAddEditPermission: boolean = false;

  public isHomePageHasViewPermission: boolean = false;
  public isHomePageHasAddPermission: boolean = false;
  public isHomePageHasEditPermission: boolean = false;
  public isHomePageHasDeletePermission: boolean = false;

  public isReviewsPageHasViewPermission: boolean = false;
  public isReviewsPageHasAddPermission: boolean = false;
  public isReviewsPageHasEditPermission: boolean = false;
  public isReviewsPageHasDeletePermission: boolean = false;

  public isAuditPageHasViewPermission: boolean = false;
  public isLocksPageHasViewPermission: boolean = false;
  public isSystemSettingsPageHasViewPermission: boolean = false;

  public permissionsLoaded: Date=new Date(1970,1,1);

  constructor(
    private onlineService: OnlineService,
  ) {

  }

  GetPermissionsPromise(UserId: string) {
    return new Promise((resolve) => {
      const now = new Date();
      const diff = now.getTime() - this.permissionsLoaded.getTime();

      // check for the age of the permissions before requesting again.
      // DPR - Disabled timed check, it was causing problems.
      // if (diff > AppConstants.PermissionsMaxAgeBeforeReload) {
        this.onlineService.getPagePermission(parseInt(UserId, 10)).subscribe((pagePermissionList: any) => {
          pagePermissionList.forEach((specificPagePermission) => {
            if (specificPagePermission.moduleName == AppConstants.Module.Reports) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isReportsPageHasViewPermission = true;
              } else {
                this.isReportsPageHasViewPermission = false;
              }
            }
            if (specificPagePermission.moduleName == AppConstants.Module.Exposure) {
              if (specificPagePermission[AppConstants.Permission.Add]) {
                this.isExposurePageHasAddPermission = true;
              } else {
                this.isExposurePageHasAddPermission = false;
              }
              this.isExposurePageHasAddPermission = specificPagePermission[AppConstants.Permission.Add] != null ? specificPagePermission[AppConstants.Permission.Add] : false;
              this.isExposurePageHasEditPermission = specificPagePermission[AppConstants.Permission.Edit] != null ? specificPagePermission[AppConstants.Permission.Edit] : false;
              this.isExposurePageHasDeletePermission = specificPagePermission[AppConstants.Permission.Delete] != null ? specificPagePermission[AppConstants.Permission.Delete] : false;
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isExposurePageHasViewPermission = true;
              } else {
                this.isExposurePageHasViewPermission = false;
              }
            }
            if (specificPagePermission.moduleName == AppConstants.Module.CaseList) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isHomePageHasViewPermission = true;
              } else {
                this.isHomePageHasViewPermission = false;
              }
              this.isHomePageHasAddPermission = specificPagePermission[AppConstants.Permission.Add] != null ? specificPagePermission[AppConstants.Permission.Add] : false;
              this.isHomePageHasEditPermission = specificPagePermission[AppConstants.Permission.Edit] != null ? specificPagePermission[AppConstants.Permission.Edit] : false;
              this.isHomePageHasDeletePermission = specificPagePermission[AppConstants.Permission.Delete] != null ? specificPagePermission[AppConstants.Permission.Delete] : false;
            }
            if (specificPagePermission.moduleName == AppConstants.Module.Reviews) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isReviewsPageHasViewPermission = true;
              } else {
                this.isReviewsPageHasViewPermission = false;
              }
              this.isReviewsPageHasAddPermission = specificPagePermission[AppConstants.Permission.Add] != null ? specificPagePermission[AppConstants.Permission.Add] : false;
              this.isReviewsPageHasEditPermission = specificPagePermission[AppConstants.Permission.Edit] != null ? specificPagePermission[AppConstants.Permission.Edit] : false;
              this.isReviewsPageHasDeletePermission = specificPagePermission[AppConstants.Permission.Delete] != null ? specificPagePermission[AppConstants.Permission.Delete] : false;
            }
            if (specificPagePermission.moduleName == AppConstants.Module.Search) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isSearchPageHasViewPermission = true;
              } else {
                this.isSearchPageHasViewPermission = false;
              }
            }
            if (specificPagePermission.moduleName == AppConstants.Module.Query) {
              if (specificPagePermission[AppConstants.Permission.Add]) {
                this.isInfoRequestPageHasAddPermission = true;
              } else {
                this.isInfoRequestPageHasAddPermission = false;
              }
              this.isInfoRequestPageHasViewPermission = specificPagePermission[AppConstants.Permission.View];
              this.isInfoRequestPageHasEditPermission = specificPagePermission[AppConstants.Permission.Edit] != null ? specificPagePermission[AppConstants.Permission.Edit] : false;
            }
            if (specificPagePermission.moduleName == AppConstants.Module.Audit) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isAuditPageHasViewPermission = true;
              } else {
                this.isAuditPageHasViewPermission = false;
              }
            }
            if (specificPagePermission.moduleName == AppConstants.Module.Locks) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isLocksPageHasViewPermission = true;
              } else {
                this.isLocksPageHasViewPermission = false;
              }
            }
            if (specificPagePermission.moduleName == AppConstants.Module.CodeAdmin) {
              if (specificPagePermission[AppConstants.Permission.View]) {
                this.isSystemSettingsPageHasViewPermission = true;
              } else {
                this.isSystemSettingsPageHasViewPermission = false;
              }
            }
          });
          resolve(true);
          this.permissionsLoaded = new Date();
        },          
        (error) => {
          resolve(false);
          console.error("Error retrieving permissions:'" + error +"'. Are you online?");
        });
      // } else {
      //   resolve(true); 
      // }
    });
  }
}
