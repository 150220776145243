import { Component, OnInit } from '@angular/core';
import { ILoadEventArgs, MapAjax, MapsTheme } from '@syncfusion/ej2-maps';
// import { ILoadEventArgs, MapAjax, MapsTheme } from '@syncfusion/ej2/maps';

@Component({
  selector: 'dashboard-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor() { ; }

  ngOnInit(): void {
    ;
  }

  // Map data
  public zoomSettings: Object = { enable: false };
  public maplegendSettings: Object = { visible: false };
  public mapLoad = (args: ILoadEventArgs) => {
    let theme: string = location.hash.split('/')[1];
    theme = theme ? theme : 'Material';
    args.maps.theme = <MapsTheme>(theme.charAt(0).toUpperCase() + theme.slice(1)).replace(/-dark/i, 'Dark');
  }
  public  layers: object[] = [
      {
          shapeData: new MapAjax('./source/dashboard-layout/worldmap.json'),
          shapePropertyPath: 'continent',
          shapeDataPath: 'continent',
          dataSource: new MapAjax('./source/dashboard-layout/datasource.json'),
          shapeSettings: {
              colorValuePath: 'color',
          },
          markerSettings: [
              {
                  visible: true,
                  dataSource: [
                      { latitude: 37.6276571, longitude: -122.4276688, name: 'San Bruno' },
                      { latitude: 33.5302186, longitude: -117.7418381, name: 'Laguna Niguel' },
                      { latitude: 40.7424509, longitude: -74.0081468, name: 'New York' },
                      { latitude: -23.5268201, longitude: -46.6489927, name: 'Bom Retiro' },
                      { latitude: 43.6533855, longitude: -79.3729994, name: 'Toronto' },
                      { latitude: 48.8773406, longitude: 2.3299627, name: 'Paris' },
                      { latitude: 52.4643089, longitude: 13.4107368, name: 'Berlin' },
                      { latitude: 19.1555762, longitude: 72.8849595, name: 'Mumbai' },
                      { latitude: 35.6628744, longitude: 139.7345469, name: 'Minato' },
                      { latitude: 51.5326602, longitude: -0.1262422, name: 'London' }
                  ],
                  shape: 'Image',
                  imageUrl: 'https://ej2.syncfusion.com/demos/src/maps/images/ballon.png',
                  height: 20,
                  width: 20,
                  offset: {
                      y: -10,
                      x: 0
                  },
                  tooltipSettings: {
                      visible: true,
                      valuePath: 'name'
                  },
                  animationDuration: 0
              },
          ]
      },
  ];

}
