import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CookieService } from 'ngx-cookie-service';
import { ToasterService } from '../../Providers/ToasterService';
import { OnlineService } from './../../Providers/OnlineService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'case-validation',
  templateUrl: './case-validation.component.html',
  styleUrls: ['./case-validation.component.scss']
})
export class CaseValidationComponent implements OnInit, OnChanges {
  @Input() DisplayCaseValidationDialog = false;
  @Output() DisplayCaseValidationDialogChange = new EventEmitter<any>();
  @Input() DisplayValidationPrompt = false;
  @Output() DisplayValidationPromptChange = new EventEmitter<any>();
  @Input() ValidationErrors = [];
  @Output() ValidationErrorsChange = new EventEmitter<any>();

  @Output() closeCaseEvent = new EventEmitter<string>();

  @ViewChild('genericCaseValidationDialog', { static: false })
  genericCaseValidationDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild('container', { static: false }) container: ElementRef;

  
  public genericValidationDialogVisible: boolean = false;
  public animationSettings: Object = { effect: 'Zoom', duration: 100, delay: 0 };
  public target: string = 'body';
  public dialogWidth: string = '30%';
  public isDraggable: boolean = true;
  

  public validationTitle: string = this.translate.instant('common.caseValidation');
  public validationHeader: string = this.translate.instant('common.validationHasBeenSuccessful') + ',' + " " + this.translate.instant('common.howeverIssuesHaveBeenFound') + '.';
  public validationSubHeader: string = this.translate.instant('common.click') + " " + '"' + this.translate.instant('common.continue') + '"' + this.translate.instant('common.toCloseOr') + '"' +this.translate.instant('common.Cancel') + '"' + this.translate.instant('common.toRevisitTheCase') + '.';

  constructor(public onlineService: OnlineService, public toastService: ToasterService, private _cookieService: CookieService, public translate: TranslateService) {}

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    // for (const propName in changes) {
    //   const chng = changes[propName];
    // }
  }

  public onValidationCancelButtonClick() {
    this.cleanValidationComponent();
  }

  public onValidationOkButtonClick() {
    this.cleanValidationComponent();
  }

  public onValidationContinueButtonClick() {
    this.closeCaseEvent.emit();
    this.cleanValidationComponent();
  }

  dialogClose() {
    this.cleanValidationComponent();
  }

  private cleanValidationComponent() {
    this.ValidationErrors = [];
    this.DisplayValidationPrompt = false;
    this.DisplayCaseValidationDialog = false;

    this.ValidationErrorsChange.emit(this.ValidationErrors);
    this.DisplayValidationPromptChange.emit(this.DisplayValidationPrompt);
    this.DisplayCaseValidationDialogChange.emit(this.DisplayCaseValidationDialog);
  }
}
