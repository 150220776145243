import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { OnlineService } from '../Providers/OnlineService';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient
    , private _cookieService: CookieService,private cfr: ComponentFactoryResolver,
    private onlineService: OnlineService) {
    this.apiURL=onlineService.apiUrl;
    this.url = {
      MyCaseCount: this.apiURL + 'api/dashboard/MyCaseCount',
      MyCentreCaseCount: this.apiURL + 'api/dashboard/MyCentreCaseCount',
      ResponsesNeededCount: this.apiURL + 'api/dashboard/ResponsesNeededCount',
      ReviewNeededCount: this.apiURL + 'api/dashboard/ReviewNeededCount',
      NetworkCaseCount: this.apiURL + 'api/dashboard/NetworkCaseCount',
    }
  }

  public apiURL: string = "ToxAwareSoftware.com/ToxAware/API/NotInitialized"; // get the api path from the environment
  
  // URLs for use
  public url;

  // methods for dashboard-components to retrieve data
  public getMyCaseCount(): Observable<any> {
    return this.httpClient.get(this.url.MyCaseCount);
  }
  public getCentreCaseCount(): Observable<any> {
      return this.httpClient.get(this.url.MyCentreCaseCount);
  }
  public getNetworkCaseCount(): Observable<any> {
    return this.httpClient.get(this.url.NetworkCaseCount);
}
  public getResponsesNeededCount(): Observable<any> {
      return this.httpClient.get(this.url.ResponsesNeededCount);
  }
  public getReviewNeededCount(): Observable<any> {
    return this.httpClient.get(this.url.ReviewNeededCount);
  }

  // Component Creation
  private counter() {
    return () =>
      import('./../dashboard-components/counter/counter.component').then(
        m => m.CounterComponent
      );
  }

  private chart() {
    return () =>
      import('./../dashboard-components/chart/chart.component').then(
        m => m.ChartComponent
      );
  }

  // support methods for component creation
  loadComponent(vcr: ViewContainerRef) {
    vcr.clear();

    return this.forChild(vcr, {
      loadChildren: this.counter()
    });
  }

  forChild(vcr: ViewContainerRef, cl: ComponentLoader) {
    return from(cl.loadChildren()).pipe(
      map((component: any) => this.cfr.resolveComponentFactory(component)),
      map((componentFactory) => vcr.createComponent(componentFactory))
    );
  }
}
export interface ComponentLoader {
  loadChildren: () => Promise<any>;
}