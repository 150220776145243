import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckBoxSelectionService, DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Grid, GridComponent, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent, DialogUtility, Tooltip } from '@syncfusion/ej2-angular-popups';
import { EventHandler } from '@syncfusion/ej2-base';
import { CookieService } from 'ngx-cookie-service';
import { OnlineService } from '../../Providers/OnlineService';
import { ToasterService } from '../../Providers/ToasterService';
import { AppConstants } from '../../app.constants';

class Recall {
  tempRecallId?: number;
  recallId?: number;
  recallDateTime?: Date;
  formattedRecallDateTime?: string;
  recallTargetId?: string;
  recallTargetIdArray?: number[];
  recallTargetName?: string;
  recallStatusId?: number;
  recallStatusName?: string;
  recallEnteredById?: number;
  recallEnteredByName?: string;
  recallPhone?: string;
  recallNote?: string;
  recallPriorityId?: number;
  recallPriorityName?: string;
  recallCompletedById?: number;
  recallCompletedByName?: string;
  recallCompletedDateTime?: Date;
  formattedCompletedRecallDateTime?: string;
  deleted: boolean;

  constructor() {
    this.tempRecallId = null;
    this.recallId = null;
    this.recallDateTime = null;
    this.formattedRecallDateTime = null;
    this.recallTargetId = null;
    this.recallTargetIdArray = [];
    this.recallTargetName = null;
    this.recallStatusId = null;
    this.recallStatusName = null;
    this.recallEnteredById = null;
    this.recallEnteredByName = null;
    this.recallPhone = '';
    this.recallNote = '';
    this.recallPriorityId = null;
    this.recallPriorityName = null;
    this.recallCompletedById = null;
    this.recallCompletedByName = null;
    this.recallCompletedDateTime = null;
    this.formattedCompletedRecallDateTime = null;
    this.deleted = false;
  }
}

@Component({
  selector: 'case-recall',
  templateUrl: './case-recall.component.html',
  styleUrls: ['./case-recall.component.scss'],
  providers: [CheckBoxSelectionService]
})

export class CaseRecallComponent implements OnInit {

  // public testSelection: string[] = [];
  // testSetSelection(id: string) {
  //   this.testSelection = [id];
  // }
  // testRefresh() {
  //   this.msRecallTargetsObj.refresh();
  //   this.test1Obj.refresh();
  // }
  // @ViewChild('test1', { static: false })
  // test1Obj: MultiSelectComponent;



  @Input() isViewCase: boolean = true;
  @Input() isEditCase: boolean = false;
  @Input() recallsGridData: Recall[] = [];
  @Output() recallsGridDataChange = new EventEmitter<any>();
  @Input() deletedRecallsData: Recall[] = [];
  @Output() deletedRecallsDataChange = new EventEmitter<any>();

  public newIsOnline: string = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
  public DateTimeFormat: string = 'dd/MM/yyyy HH:mm';
  public TimeFormat: string = 'HH:mm';

  @ViewChild('recallForm', { static: false }) gridFormObj: any;

  @ViewChild('recallGrid', { static: false })
  public recallGridObj: GridComponent;
  public selectionSettings;
  public recallUnsavedChangeDetectionInGrid: boolean = false;
  public customAttributes: Object;

  @ViewChild('addEditDialog', { static: false })
  public addEditDialogObj: DialogComponent;
  public recallDlgMinHeight = '430px';

  // General Dialog variables
  public animationSettings: Object = { effect: 'Zoom', duration: 100, delay: 0 };
  public dialogWidth: string = '600px';
  public ddlFields: Object = { text: 'Value', value: 'id' };
  public ddlRecallTargetsFields: Object = { text: 'name', value: 'id' };

  // Add/Edit Dialog variables
  public isAddRecall: boolean = false;
  // @ViewChild('addEditForm') addEditRecallModalFormObj: any;
  public addEditDialogTitle: string = '';
  addUpdateBtnText: string = '';
  public recallModalDataUnSavedSkip: boolean = true;
  public recallStatusName: string = 'New';
  public currentRecall: Recall = new Recall();
  public originalRecall: string = '';
  @ViewChild('addEditForm', { static: false }) addEditModalFormObj: any;

  gridDataBound(gridOBJName: string) {
    this[gridOBJName].autoFitColumns();
  }

  @ViewChild('ddlPresetHours', { static: false })
  ddlPresetHoursObj: DropDownListComponent;
  public ddlPresetHoursData: { [key: string]: Object }[] = [
    { id: 2, Value: this.translate.instant('common.2HoursFromNow') },
    { id: 3, Value: this.translate.instant('common.3HoursFromNow') },
    { id: 4, Value: this.translate.instant('common.4HoursFromNow') },
    { id: 6, Value: this.translate.instant('common.6HoursFromNow') },
    { id: 8, Value: this.translate.instant('common.8HoursFromNow') },
    { id: 12, Value: this.translate.instant('common.12HoursFromNow') },
    { id: 24, Value: this.translate.instant('common.24HoursFromNow') }
  ];
  public ddlPresetHoursPlaceholder: string = 'Preset Hours';

  @ViewChild('ddlPresetDays', { static: false })
  ddlPresetDaysObj: DropDownListComponent;
  public ddlPresetDaysData: { [key: string]: Object }[] = [
    { id: 1, Value: '1 Day from now' },
    { id: 2, Value: '2 Days from now' },
    { id: 3, Value: '3 Days from now' },
    { id: 4, Value: '4 Days from now' },
    { id: 5, Value: '5 Days from now' },
    { id: 6, Value: '6 Days from now' },
    { id: 7, Value: '7 Days from now' },
    { id: 14, Value: '14 Days from now' },
    { id: 30, Value: '30 Days from now' },
  ];
  public ddlPresetDaysPlaceholder: string = 'Preset Days';

  @ViewChild('msRecallTargets', { static: false })
  msRecallTargetsObj: MultiSelectComponent;
  public ddlTargetUserData: any[] = [];
  public ddlTargetUserPlaceholder: string = 'Click here to select target users';
  public mode: string = 'Box';
  public selectAllText: string = 'Select All';


  public recallStatusData: any[] = [];

  @ViewChild('ddlRecallPriority', { static: false })
  ddlRecallPriorityObj: DropDownListComponent;
  public ddlRecallPriorityData: any[] = [];
  public ddlRecallPriorityPlaceholder: string = 'Priority';

  @ViewChild('tbxRecallNotes', { static: false })
  public tbxRecallNotesObj: TextBoxComponent;

  // The following object is used for
  // simple confirmation dialogs
  public ConfirmationDialogObj;

  @ViewChild('processDialog', { static: false })
  public processDialogObj: DialogComponent;

  public prioritySortComparer = (reference: string, comparer: string, refrenceObj: any, comparerObj: any) => {
    const referenceOrderNo = this.ddlRecallPriorityData.find(obj => {
      return obj.Value === reference;
    });
    const comparerOrderNo = this.ddlRecallPriorityData.find(obj => {
      return obj.Value === comparer;
    });

    if (referenceOrderNo.Order < comparerOrderNo.Order) {
      return -1;
    }
    if (referenceOrderNo.Order > comparerOrderNo.Order) {
      return 1;
    }
    return 0;
  }

  public sortOptions: object;

  constructor(
    public onlineService: OnlineService,
    public toastService: ToasterService,
    private _cookieService: CookieService,
    private route: ActivatedRoute,
    public translate: TranslateService
    ) {

  }

  ngOnInit() {
    this.customAttributes = {class: 'customcss'};
    this.selectionSettings = { enableToggle: false };
    this.sortOptions = { columns: [{ field: 'recallStatusName', direction: 'Descending' }, { field: 'formattedRecallDateTime', direction: 'Ascending' }] };
  }

  ngAfterViewInit() {
    this.GetCodeValuesFromIndexedDB();
    this.GetUserTargets();    
  }
  
  GetCodeValuesFromIndexedDB() {
    const isCreateExposure = this.isViewCase || this.isEditCase ? false : true;
    const dataSourceListResult = Promise.all([this.onlineService.GetDataSourceList(isCreateExposure)])
      .then((value: any)=> {
        if (value.length > 0 && value[0] !== undefined) {
          const arg = value[0];
          // $this.ejDateTimePicker.focusIn();
          this.ddlRecallPriorityData = arg.RecallPriority;
          this.recallStatusData = arg.RecallStatus;
        } else {
          this.onlineService.getCodeValueList().then(
            function (result: any) {
              if (result === 'No DataBase Exist') {
                const toasterValue = {
                  type: 'error',
                  message: 'CodeValues did not load. Logout and log back in to reload the CodeValues.',
                };
                this.toastService.showSpinner(toasterValue);
              } else {
                this.GetCodeValuesFromIndexedDB();
              }
            },
            (error) => {
              const errorLog = {
                CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
                LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
                CustomErrorMessage: error.message,
                ErrorMessage: error.message,
                InnerException: error.error.error_description === undefined && error.error.error_description == null ? null : error.error.error_description,
              };
              if (this.newIsOnline !== '' && this.newIsOnline === 'true') {
                
                this.onlineService.AddErrorLog(errorLog).subscribe(function () {
                  const toasterValue = {
                    type: 'error',
                    message: 'Something went wrong writing error log. Please try again',
                  };
                  this.toastService.showSpinner(toasterValue);
                });
              } else {
                const toasterValue = {
                  type: 'warning',
                  message: 'You are working offline. Please reload the page to continue',
                };
                this.toastService.showSpinner(toasterValue);
                window.location.reload();
              }
            }
          );
        }
      })
      .catch(function (err) {
        const toasterValue = {
          type: 'error',
          message: 'CodeValues did not load. Logout and log back in to reload the CodeValues.',
        };
        this.toastService.showSpinner(toasterValue);
      });
  }

  GetUserTargets() {
    //TODO: What to do if offline
    // if (this.isEditCase) {
      this.onlineService.getActiveUserList().subscribe(
        (userList: any) => {
          // this.BusyMessage = "Loading Active Users...";
          userList.forEach((userLabel) => {
            // I changed the name from Value to name because the multiselect was not working - DPR 22 jan 2024
            this.ddlTargetUserData.push({ id: userLabel.userId, name: userLabel.displayName});
          });
          this.msRecallTargetsObj.refresh(); // This is needed to refresh the multiselect or it doesn't show data
        },
        (err: HttpErrorResponse) => {
          const errorLog = {
            CentreId: this._cookieService.get(AppConstants.CookieName.CenterId),
            LoggedByUserId: this._cookieService.get(AppConstants.CookieName.UserId),
            CustomErrorMessage: err.message,
            ErrorMessage: err.message,
            InnerException: err.error.error_description === undefined && err.error.error_description == null ? null : err.error.error_description,
          };
          this.onlineService.AddErrorLog(errorLog).subscribe( ()=> {
            const toasterValue = {
              type: 'error',
              message: 'Something went wrong getting Active Users. Please try again',
            };
            this.toastService.showSpinner(toasterValue);
          });
        }
      );
    // }
  }

  queryCellInfoEvent(args: QueryCellInfoEventArgs) {
       // Add tooltip based on condition
       if (args.column.field === 'formattedRecallDateTime' && args.data['recallEnteredByName'] !== null &&  args.data['recallEnteredByName'] !== '') {
          const tooltip: Tooltip = new Tooltip({
          content: 'Entered By: ' + args.data['recallEnteredByName'].toString()
          }, args.cell as HTMLElement);
       }
       if (args.column.field === 'formattedCompletedRecallDateTime' &&
           args.data['recallCompletedByName'] !== null &&  args.data['recallCompletedByName'] !== '') {
          const tooltip: Tooltip = new Tooltip({
          content: 'Completed By: ' + args.data['recallCompletedByName'].toString()
        }, args.cell as HTMLElement);
       }
  }

  onDialogOpen() {
    const tbxRecallNotes = this.tbxRecallNotesObj.element;
    tbxRecallNotes.style.height = '22px';
    if (tbxRecallNotes.clientHeight < tbxRecallNotes.scrollHeight) {
      tbxRecallNotes.style.height = (tbxRecallNotes.scrollHeight - 8) + 'px';
    }
  }

  public initializeRecallNotesTbx(): void {
    const tbxRecallNotes = this.tbxRecallNotesObj.element;
    this.tbxRecallNotesObj.addAttributes({rows: '1'});
    tbxRecallNotes.style.height = 'auto';
    tbxRecallNotes.style.height = '22px';
    if (tbxRecallNotes.clientHeight < tbxRecallNotes.scrollHeight) {
      tbxRecallNotes.style.height = (tbxRecallNotes.scrollHeight - 8) + 'px';
    }
  }
  public resetRecallNotesTbx($event): void {
    const tbxRecallNotes = this.tbxRecallNotesObj.element;

    if (tbxRecallNotes.clientHeight < tbxRecallNotes.scrollHeight) {
      tbxRecallNotes.style.height = (tbxRecallNotes.scrollHeight - 8) + 'px';
    } else if (tbxRecallNotes.clientHeight === tbxRecallNotes.scrollHeight) {
      const originalStyleHeight = tbxRecallNotes.style.height;
      const originalScrollHeight = tbxRecallNotes.scrollHeight;
      tbxRecallNotes.style.height = '22px';
      if (tbxRecallNotes.scrollHeight > originalScrollHeight) {
        tbxRecallNotes.style.height = (tbxRecallNotes.scrollHeight - 8) + 'px';
        tbxRecallNotes.style.overflowY = 'hidden';
        tbxRecallNotes.style.height = originalStyleHeight;
      } else {
        tbxRecallNotes.style.height = (tbxRecallNotes.scrollHeight - 8) + 'px';
      }
    }
    if ($event != null) {
      this.currentRecall.recallNote = $event.value;
      this.recallDlgCheckForChanges();
    }
  }

  loadRecallData(arg) {
    (<any>this.recallGridObj).defaultLocale.EmptyRecord = this.translate.instant('common.noRecallsToDisplay');
    this.touchStartRecallEvent();
  }

  onRecallGridDataBound(event: any) {
    setTimeout(() => {
      let openRecallModalWithId = this.route.snapshot.params.displayRecall !== '' ? parseInt(this.route.snapshot.params.displayRecall, 10) : null;
      if (openRecallModalWithId) {
        this.autoOpenProcessRecall(openRecallModalWithId);
      }
    }, 250);
  }

  touchStartRecallEvent() {
    // const comp = this;
    const oldwireEvents = Grid.prototype.wireEvents; // override the default method
    Grid.prototype.wireEvents = function () {
      EventHandler.add(this.getContent(), 'touchstart', dblTap, this);
      return oldwireEvents.apply(this, []);
    };

    const oldUnwireEvents = Grid.prototype.unwireEvents; // override the default method
    Grid.prototype.unwireEvents = function () {
      EventHandler.remove(this.getContent(), 'touchstart', dblTap);
      return oldUnwireEvents.apply(this, []);
    };

    let timer = null;
    function dblTap(args) {
      if (getUserAgent()) {
        if (!timer) {
          timer = setTimeout(function () {
            timer = null;
          }, 300);
        } else {
          clearTimeout(timer);
          timer = null;
          const rowInfo = this.getRowInfo(args.target);
          if (this.element.id === 'recallGridId') {
            this.recallClick(rowInfo);
          }
        }
      }
    }
    function getUserAgent() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipod|ipad/.test(userAgent);
    }
  }

  onCreateRecallClick() {
    if (this.isEditCase) {
      this.recallStatusName = 'New';
      this.isAddRecall = true;
      this.addEditDialogTitle = 'Add Recall';
      this.addUpdateBtnText = 'Add';
      this.currentRecall = new Recall();
      this.currentRecall.recallPriorityId = AppConstants.RecallPriority.Medium;
      this.currentRecall.recallTargetIdArray.push(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10));
      // this.testSelection.push(parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10));
      this.originalRecall = JSON.stringify(this.currentRecall);
      this.initializeRecallNotesTbx();
      this.ddlPresetDaysObj.value = null;
      this.ddlPresetHoursObj.value = null;

      this.addEditModalFormObj.form.markAsPristine();
      this.recallModalDataUnSavedSkip = false;
      this.addEditDialogObj.show();
    }
  }

  recallClick($event) {
    setTimeout(() => {
      this.performRecallEdit($event);
    }, 100);
  }

  performRecallEdit($event) {
    if (this.isEditCase && !this.addEditDialogObj.visible) {
      const selectedData = $event.rowData;

      if (selectedData.recallStatusId !== AppConstants.RecallStatus.Closed) {

        this.ddlPresetDaysObj.value = null;
        this.ddlPresetHoursObj.value = null;
        this.isAddRecall = false;
        this.addEditDialogTitle = 'Edit Recall';
        this.addUpdateBtnText = 'Update';
        if (selectedData !== undefined) {

          this.currentRecall = ({
            tempRecallId: selectedData.tempRecallId,
            recallId: selectedData.recallId,
            recallDateTime: selectedData.recallDateTime,
            formattedRecallDateTime: selectedData.formattedRecallDateTime,
            recallTargetId: selectedData.recallTargetId,
            recallTargetIdArray: selectedData.recallTargetIdArray,
            recallTargetName: selectedData.recallTargetName,
            recallStatusId: selectedData.recallStatusId,
            recallStatusName: selectedData.recallStatusName,
            recallEnteredById: selectedData.recallEnteredById,
            recallEnteredByName: selectedData.recallEnteredByName,
            recallPhone: selectedData.recallPhone,
            recallNote: selectedData.recallNote,
            recallPriorityId: selectedData.recallPriorityId,
            recallPriorityName: selectedData.recallPriorityName,
            recallCompletedById: selectedData.recallCompletedById,
            recallCompletedByName: selectedData.recallCompletedByName,
            recallCompletedDateTime: selectedData.recallCompletedDateTime,
            formattedCompletedRecallDateTime: selectedData.formattedCompletedRecallDateTime,
            deleted: selectedData.deleted
          });

          this.originalRecall = JSON.stringify(this.currentRecall);
        }

        this.resetRecallNotesTbx(null);
        this.recallModalDataUnSavedSkip = false;
        this.recallStatusName = selectedData.recallStatusName;
        this.addEditDialogObj.show();
      } else {
        const toasterValue = {
          type: 'warning',
          message: 'This Recall has been completed and can no longer be edited.',
        };
        this.toastService.showSpinner(toasterValue);
      }
    }
  }

  controlChange() {
    //setTimeout(() => { this.recallDlgCheckForChanges(); }, 100);
    this.recallDlgCheckForChanges();
  }

  recallDlgCheckForChanges() {
    const changedRecall = JSON.stringify(this.currentRecall);
    const recallDiff = changedRecall.localeCompare(this.originalRecall);
    // console.log('commentDiff: ' + commentDiff);
    if (recallDiff === 0) {
      this.addEditModalFormObj.form.markAsPristine();
    } else {
      this.addEditModalFormObj.form.markAsDirty();
    }
  }

  recallUnsaveDetection(args) {
    if ((args.requestType === 'refresh' && args.rows !== undefined && args.rows.length > 0) || (args.requestType === 'save' && args.action === 'edit')) {
      this.recallUnsavedChangeDetectionInGrid = true;
    }
  }

  onddlPresetHoursOpen(): void {
    this.ddlPresetDaysObj.value = null;
  }

  onddlPresetDaysOpen(): void {
    this.ddlPresetHoursObj.value = null;
  }

  onddlPresetHoursChange(args): void {
    if (args.value != null) {
      this.currentRecall.recallDateTime = new Date();
      this.currentRecall.recallDateTime.setHours( this.currentRecall.recallDateTime.getHours() + args.value );
      // const $this = this;
      // setTimeout(() => {
      //   this.ddlPresetHoursObj.value = null;
      // }, 500);
    }
  }

  onddlPresetDaysChange(args): void {
    if (args.value != null) {
      this.currentRecall.recallDateTime = new Date();
      this.currentRecall.recallDateTime.setDate( this.currentRecall.recallDateTime.getDate() + args.value );
    }
  }

  editDialogBeforeClose($event) {
    if ($event.isInteracted) {
      $event.cancel= true;
      this.onCancelButtonClick();
    }
  }

  public onCancelButtonClick() {
    if (this.addEditDialogObj.visible && !this.recallModalDataUnSavedSkip) {
      this.recallDlgCheckForChanges();
      if (this.addEditModalFormObj !== undefined && this.addEditModalFormObj.pristine) {
        this.cleanAndCloseDialog();
      } else {
        this.ConfirmationDialogObj = DialogUtility.confirm({
          title: '<div class="icon-recall modal-icon"></div><div class="modal-title1">Unsaved Changes</div>',
          // content: '<div class="utility-dialog-content-margins"><div class="icon-T_Warning col-md-2 delete-warning-icon pull-left"></div><p>You have unsaved changes! If you leave, your changes will be lost.</p></div>',
          content: '<div class="utility-dialog-content-margins"><p>You have unsaved changes! If you leave, your changes will be lost.</p></div>',
          okButton: {  text: 'Leave', click: this.cleanAndCloseDialog.bind(this), cssClass: 'save-Dlg-Buttons pull-right' },
          cancelButton: {  text: 'Stay', click: this.cancelDialogUtility.bind(this), cssClass: 'close-Dlg-Buttons' },
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' },
          cssClass: 'confirmationDialogUtility',
          position: { X: 'center', Y: 'center' }
        });
      }
    } else {
      this.cleanAndCloseDialog();
    }
  }

  private cleanAndCloseDialog() {
    this.addEditDialogObj.hide();
    this.recallGridObj.clearSelection();
    this.currentRecall = new Recall();
    this.originalRecall = JSON.stringify(this.currentRecall);
    this.addEditModalFormObj.form.markAsPristine();
    this.cancelDialogUtility();
  }

  public onDeleteButtonClick() {
    this.ConfirmationDialogObj = DialogUtility.confirm({
      title: '<div class="icon-Delete modal-icon"></div><div class="modal-title1">Delete Recall</div>',
      content: '<div class="utility-dialog-content-margins"><p>Are you sure you want to delete this Recall?</p></div>',
      okButton: {  text: 'Yes', click: this.onConfirmDeleteOkClick.bind(this), cssClass: 'save-Dlg-Buttons pull-right' },
      cancelButton: {  text: 'No', click: this.cancelDialogUtility.bind(this), cssClass: 'close-Dlg-Buttons' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
      cssClass: 'confirmationDialogUtility',
      position: { X: 'center', Y: 'center' }
    });
  }

  cancelDialogUtility() {
    // Don't add further code
    if (this.ConfirmationDialogObj !== undefined) {
      this.ConfirmationDialogObj.hide();
    }
  }

  onConfirmDeleteOkClick() {
    this.ConfirmationDialogObj.hide();
    this.currentRecall.deleted = true;
    this.deletedRecallsData = [...this.deletedRecallsData, this.currentRecall];
    this.recallsGridData.splice(this.recallGridObj.selectedRowIndex, 1);
    this.deletedRecallsDataChange.emit(this.deletedRecallsData);
    this.recallsGridDataChange.emit(this.recallsGridData);
    this.recallGridObj.refresh();
    this.addEditDialogObj.hide();
  }

  onOkButtonClick() {
    if (this.isAddRecall) {
      if (this.recallsGridData === undefined) {
        this.recallsGridData = [];
      }
      let tempId;

      if (this.recallsGridData.length > 0) {
        tempId = Math.max.apply(
          Math,
          this.recallsGridData.map(function (value: any) {
            return value.tempRecallId;
          })
        );
      } else {
        tempId = 0;
      }

      this.currentRecall.tempRecallId = this.recallsGridData.length === 0 ? 0 : tempId + 1;
      this.currentRecall.recallEnteredByName = this._cookieService.get(AppConstants.CookieName.UserDisplayName);
    }
    const datePipe = new DatePipe('en-US');
    this.currentRecall.formattedRecallDateTime = datePipe.transform(this.currentRecall.recallDateTime, 'dd/MM/yyyy HH:mm');
    this.currentRecall.recallTargetId = this.currentRecall.recallTargetIdArray.toString();
    this.currentRecall.recallTargetName = this.msRecallTargetsObj.text;
    const statusResult = this.recallStatusData.find(obj => {
      return obj.Value === 'Pending';
    });
    this.currentRecall.recallStatusId = statusResult.id;
    this.currentRecall.recallStatusName = statusResult.Value;
    this.currentRecall.recallPriorityName = this.ddlRecallPriorityObj.text;
    if (this.currentRecall.recallNote !== null) {
      this.currentRecall.recallNote = this.currentRecall.recallNote.trim();
      if (this.currentRecall.recallNote === '') {
        this.currentRecall.recallNote = null;
      }
    }

    if (this.isAddRecall) {
      this.recallsGridData.push(this.currentRecall);
    } else {
      let selectedRecalldata: any = this.recallsGridData.filter( (value: any)=> {
        return value.tempRecallId == this.currentRecall.tempRecallId;
      });

      const selecteddata = selectedRecalldata[0];
      selecteddata.tempRecallId = this.currentRecall.tempRecallId;
      selecteddata.recallId = this.currentRecall.recallId;
      selecteddata.recallDateTime = this.currentRecall.recallDateTime;
      selecteddata.formattedRecallDateTime = this.currentRecall.formattedRecallDateTime;
      selecteddata.recallTargetId = this.currentRecall.recallTargetId;
      selecteddata.recallTargetIdArray = this.currentRecall.recallTargetIdArray;
      selecteddata.recallTargetName = this.currentRecall.recallTargetName;
      selecteddata.recallStatusId = this.currentRecall.recallStatusId;
      selecteddata.recallStatusName = this.currentRecall.recallStatusName;
      selecteddata.recallEnteredById = this.currentRecall.recallEnteredById;
      selecteddata.recallEnteredByName = this.currentRecall.recallEnteredByName;
      selecteddata.recallPhone = this.currentRecall.recallPhone;
      selecteddata.recallNote = this.currentRecall.recallNote;
      selecteddata.recallPriorityId = this.currentRecall.recallPriorityId;
      selecteddata.recallPriorityName = this.currentRecall.recallPriorityName;
      selecteddata.recallCompletedById = this.currentRecall.recallCompletedById;
      selecteddata.recallCompletedByName = this.currentRecall.recallCompletedByName;
      selecteddata.recallCompletedDateTime = this.currentRecall.recallCompletedDateTime;
      selecteddata.formattedCompletedRecallDateTime = this.currentRecall.formattedCompletedRecallDateTime;
      selecteddata.deleted = this.currentRecall.deleted;
    }

    // this.ReSortGridData();

    this.recallsGridDataChange.emit(this.recallsGridData);

    this.recallGridObj.refresh();
    this.cleanAndCloseDialog();
  }

  processRecall($event, selectedRecall) {
    if (this.isEditCase) {
      const index = parseInt(selectedRecall.index, 10);
      const selectedData = this.recallsGridData[index];
      if (selectedData.recallStatusId !== AppConstants.RecallStatus.Closed) {
        this.currentRecall = selectedData;
        this.processDialogObj.show();
      } else {
        const toasterValue = {
          type: 'warning',
          message: 'This Recall has already been completed.',
        };
        this.toastService.showSpinner(toasterValue);
      }
    }
    $event.stopImmediatePropagation();
  }

  autoOpenProcessRecall(recallId: number) {
    // Find the recall with id
    const recall = this.recallsGridData.find(obj => {
      return obj.recallId === recallId;
    });
    if (recall) {
      if (recall.recallStatusId !== AppConstants.RecallStatus.Closed) {
        this.currentRecall = recall;
        this.processDialogObj.show();
      } else {
        const toasterValue = {
          type: 'warning',
          message: 'This Recall has already been completed.',
        };
        this.toastService.showSpinner(toasterValue);
      }
    }
  }

  public onProcessCancelButtonClick() {
    this.onProcessDialogClose();
  }

  public onProcessDialogClose() {
    this.processDialogObj.hide();
  }

  onProcessOkButtonClick() {
    const statusResult = this.recallStatusData.find(obj => {
      return obj.Value === 'Completed';
    });
    this.currentRecall.recallStatusId = statusResult.id;
    this.currentRecall.recallStatusName = statusResult.Value;
    const datePipe = new DatePipe('en-US');
    this.currentRecall.recallCompletedByName = this._cookieService.get(AppConstants.CookieName.UserDisplayName);
    this.currentRecall.recallCompletedDateTime = new Date();
    this.currentRecall.formattedCompletedRecallDateTime = datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm');

    // this.ReSortGridData();

    this.recallGridObj.refresh();
    this.onProcessDialogClose();
  }

  ReSortGridData() {
    // At this point once a recall has been either added or edited, sort the array again
    // this.recallsGridData.sort(function(a, b) {
    //   // Turn your strings into dates, and then subtract them
    //   // to get a value that is either negative, positive, or zero.
    //   return (a.recallDateTime.getTime() - b.recallDateTime.getTime());
    // });

    // Sort by status descending and date/time ascending
    this.recallsGridData.sort(
      function(a, b) {
         if (a.recallStatusName === b.recallStatusName) {
            return a.recallDateTime.getTime() - b.recallDateTime.getTime();
         }
         return b.recallStatusName > a.recallStatusName ? 1 : -1;
      });
  }
}
