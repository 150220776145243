<style>
  .e-substance-source {
    font-size: 10px;
  }
</style>

<div style="display: flex; flex-direction: row;flex-wrap:wrap">
  <!-- needs 'if' multi patient clause put in.  Button put in to bring substances through in multi case exposure
  and click event to open dialog for what substances to be passed -->
  <!-- <div ejs-button cssClass="" (click)='' class="reloadSingle body div  small " style="padding: 3px; height: 33px;"  >
  <span class="material-icons-outlined" title="Populate substance">
    compare_arrows
    </span></div> -->

  <div *ngIf="isSubsLoaded == false" style="width: 33px">    
    <img class="substance-busy" src="../../../assets/icons/Gear.png"
      title="{{'common.subSearchListLoadingTip' | translate}}" />
  </div>
  <div style="flex-grow: 1">

    
<ejs-autocomplete id='substanceAutoCompleteOnline' #substanceAutoCompleteOnline [dataSource]='onlineDataManager'
[style.display]="isSubsLoaded ? 'none' : 'block'"
placeholder="{{ 'common.searchSubstancePlaceholder' | translate }}" [fields]='onlineFields' [autofill]="true"
[suggestionCount]="suggestionCount"  floatLabelType="Auto" popupWidth="85%" filterType="Contains"
title="{{ 'common.searchSubsTitle' | translate }}"
[minLength]="minLengthSubstanceAutoComplete" [(value)]="SubstanceSearchAutocompleteValue" sortOrder="Ascending"
[query]='onlineQuery' (select)="onSubstanceSelected($event)" (filtering)="onFilteringOnline($event)"
(open)=onOpenOnline($event)>
<ng-template #itemTemplate let-data>
  <div>
    <div class="substance-name" title="Click or Tap to select {{ data.Prod_Name }}">
      <img class="substance-src-img"
        src="./assets/icons/SearchSource-{{ data.Source == '' ? 0 : data.Source }}.png" />&nbsp;{{
      data.Prod_Name }}
    </div>
  </div>
</ng-template>
<ng-template #noRecordsTemplate>
  <span class="norecord">{{ 'common.searchSubsNoSubsFound' | translate }}</span>
</ng-template>
</ejs-autocomplete>
    <ejs-autocomplete id="SubstanceAutoComplete" #SubstanceAutoComplete [dataSource]="SubstanceAutoCompleteData"
      [style.display]="isSubsLoaded ? 'block' : 'none'"
      placeholder="{{ 'common.searchSubstancePlaceholder' | translate }}" [fields]="remoteFields" [autofill]="true"
      [suggestionCount]="suggestionCount" floatLabelType="Auto" popupWidth="85%" filterType="Contains"
      title="{{ 'common.searchSubsTitle' | translate }}"
      [minLength]="minLengthSubstanceAutoComplete" [(value)]="SubstanceSearchAutocompleteValue" sortOrder="Ascending"
      [query]='query' (filtering)="onFiltering($event)" (change)="onSearchSubstanceChange()"
      (select)="onSubstanceSelected($event)" (keyup.enter)="onSubstanceEntered(SubstanceAutoComplete)"
      (open)=onOpen($event)>
      <ng-template #itemTemplate let-data>
        <div>
          <div class="substance-name" title="Click or Tap to select {{ data.Prod_Name }}">
            <img class="substance-src-img"
              src="./assets/icons/SearchSource-{{ data.Source == '' ? 0 : data.Source }}.png" />&nbsp;{{
            data.Prod_Name }}
          </div>
        </div>
      </ng-template>
      <ng-template #noRecordsTemplate>
        <span class="norecord">{{ 'common.searchSubsNoSubsFound' | translate }}</span>
      </ng-template>
    </ejs-autocomplete>
  </div>
  <div style="display:flex; flex-direction: column; flex-wrap: wrap; max-height: 60px;width: 150px;">
    <ejs-switch *ngIf="showNPRSwitch" onLabel='NPR' offLabel='NPR' title="Include the Australian National Poisons Registry" cssClass="search-switch" [(checked)]="includeNPR" class="search-switch"></ejs-switch >
    <ejs-switch *ngIf="showAMTSwitch"onLabel='AMT' offLabel='AMT' title="Include the Australian Medicinal Terminology" cssClass="search-switch" [(checked)]="includeAMT" class="search-switch"></ejs-switch >
    <ejs-switch *ngIf="showMySubSwitch"onLabel='MySubs' offLabel="MySubs" title="Include My Substances" cssClass="search-switch" [(checked)]="includeMy" class="search-switch"></ejs-switch >
    <ejs-switch *ngIf="showPDXSwitch"onLabel='PDX' offLabel="PDX" title="Include POISINDEX" cssClass="search-switch" [(checked)]="includePDX" class="search-switch" [disabled]="true"></ejs-switch >
  </div>
  <button ejs-button (click)="UpdateSubstances()" type="button" title="Update Substances"><img src="./assets/icons/UpdateSubs.jpg" width='16px' alt="Update Substances" /></button>
</div>

<ejs-dialog #confirmDialog [buttons]="confirmDlgButtons" [visible]="hidden" [header]="confirmHeader"
  [isModal]="confirmIsModal" [target]="target" [width]="confirmWidth" [height]="confirmHeight"
  [allowDragging]="confirmDialogDragging" [animationSettings]="confirmDlgAnimationSettings"
  [showCloseIcon]="confirmCloseIcon" [enableResize]="true" (open)="dialogOpen()" (close)="dialogClose()"
  (overlayClick)="dialogClose()" minHeight="400px">
  <ng-template #content>
    <span class="search-Dlg-Txt">
      <h3>
        {{ 'common.theSubstance' | translate }}&nbsp;
        <b><i>''{{ SubstanceAutoComplete.value }}''</i></b>
        &nbsp;{{ 'common.enteredNotFound' | translate }}
      </h3>
      <div><label for="desc">''{{ SubstanceAutoComplete.value }}'' {{ 'common.substanceDescription' | translate }}:</label>
        <ejs-richtexteditor id="desc" #desc
          placeholder="{{ 'common.enterDescFor' | translate }}{{ SubstanceAutoComplete.value }}"
          style="width: 100%; background: var(--secondaryColor)" [enableResize]="true" height='250px' width='100%'
          [toolbarSettings]="toolbarSettings" [(value)]="newSubstanceDescription"></ejs-richtexteditor>
        <div class="control-label">Amount of Active Ingredient in Mg per Each:</div>
        <div><ejs-numerictextbox id="mySubsAmountEach" [format]="n4" [decimals]="4" [validateDecimalOnType]="true"
            [min]="0.0001" [max]="99999.9999" watermarkText="{{ 'common.AmtEachSubLabel' | translate }}"
            title="{{ 'common.AmtEachSubLabel' | translate }}, {{ 'common.upTo' | translate }} 99999.9999" placeholder="{{ 'common.AmtEachSubLabel' | translate }}" [allowNull]="true"
            [(value)]="newSubstanceAmtEach"></ejs-numerictextbox></div>
      </div>
      <h2>
        {{ 'common.WantToAdd' | translate }}
        <b><i>''{{ SubstanceAutoComplete.value }}''</i></b>&nbsp;
        {{ 'common.asNewSubstance' | translate }}?
      </h2>
    </span>
  </ng-template>
</ejs-dialog>
