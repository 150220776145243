<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" loadingText="{ 'common.loading' | translate }..." type="ball-spin-clockwise"
  ><p style="color: white">{{ BusyMessage }}</p>
</ngx-spinner>

<header>
  <div id="material-tabs" class="hidden-xs ">
    <a id="tab1-tab" (click)="tabClicked(1)" href="javascript:;" [ngClass] ="{ active: selectedTab == 1 }"> {{ 'common.systemConfiguration' | translate }}</a>
    <a id="tab2-tab" (click)="tabClicked(2)" href="javascript:;" [ngClass] ="{ active: selectedTab == 2 }"> {{ 'common.codeValues' | translate }}</a>
    <a id="tab3-tab" (click)="tabClicked(3)" href="javascript:;" [ngClass] ="{ active: selectedTab == 3 }"> {{ 'common.validations' | translate }}</a>
    <a id="tab4-tab" (click)="tabClicked(4)" href="javascript:;" [ngClass] ="{active : selectedTab == 4}"> {{ 'common.notices' | translate }}</a>
    <a id="tab5-tab" (click)="tabClicked(5)" href="javascript:;" [ngClass] ="{active : selectedTab == 5}"> {{ 'common.mySubstances' | translate }}</a>
    <!-- <span class="yellow-bar"></span>--> <!-- Replaced by background colour on style active -->
  </div>
</header>

<div class="tab-content">
  <div id="tab1" [ngStyle]="{ display: selectedTab == 1 ? 'block' : 'none', opacity: '1' }" class="tab">
    <div *ngIf="isSystemSettingsPageHasViewPermission" class="col-lg-12 col-md-12 col-sm-12 system-setting-container">
      <span  class="page-title"><span class="material-icons-outlined" style="padding-right: 5px;">
        tune
        </span>{{ 'common.systemConfiguration' | translate }}</span>
        <div *ngIf="isSystemSettingsPageHasEditPermission || isSystemSettingsPageHasAddPermission"
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="pull-right desktopCls">
            <button ejs-button iconCss="icon-Save" class="system-settings-savebtn focusableSearch" cssClass="e-warning save-btn"
              [disabled]="systemSettingSaveButtonDisabled" (click)="saveSystemSettingsClick()">{{ 'common.saveSettings' | translate }}
            </button>
          </div>
        
          <div class="pull-right tabmobCls">
            <button ejs-button iconCss="icon-Save" class="system-settings-savebtn focusableSearch" cssClass="e-warning save-btn"
              [disabled]="
                        systemSettingButtonDisable
                      " (click)="saveSystemSettingsClick()" title="{{ 'common.saveSettings' | translate }}"></button>
          </div>
        </div>
        <div class="col-xs-12">
          
          <ejs-grid [dataSource]='systemSettingsGridData' #systemSettingsGrid id="systemSettingsGrid" [editSettings]='systemSettingsEditSettings' [allowPaging]=false
            [allowSelection]="false" gridLines="Both" [allowSorting]="true" [allowTextWrap]="true" [allowResizing]="true"
            enableAltRow="true" (actionComplete)="systemSettingActionComplete($event)" >
            <e-columns>
              <e-column headerText='' width='30' [commands]='systemSettingsGridCommands'></e-column>
              <e-column field="key" headerText="{{ 'common.setting' | translate }}" textAlign="Left" [isPrimaryKey]="true" [visible]="false"></e-column>
              <e-column field="name" headerText="{{ 'common.name' | translate }}" textAlign="Left" [allowEditing]=false></e-column>
              <e-column field="description" headerText="{{ 'common.description' | translate }}" textAlign="Left" [allowEditing]=false></e-column>
              <e-column field="value" headerText="{{ 'common.value' | translate }}" textAlign="Left" [edit]='systemSettingsValueEditOptions'></e-column>
              <e-column field="type" headerText="{{ 'common.type' | translate }}" textAlign="Left" [allowEditing]=false [visible]=false></e-column>
              <e-column field="options" headerText="{{ 'common.options' | translate }}" textAlign="Left" [allowEditing]=false [visible]=false></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      <!-- </form> -->

      <div *ngIf="isSystemSettingsPageHasEditPermission || isSystemSettingsPageHasAddPermission" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="pull-right desktopCls">
          <button
            ejs-button
            iconCss="icon-Save"
            class="system-settings-savebtn focusableSearch"
            cssClass="e-warning save-btn"
            [disabled]="systemSettingSaveButtonDisabled"
            (click)="saveSystemSettingsClick()"
          >{{ 'common.saveSettings' | translate }}
          </button>
        </div>
       
        <div class="pull-right tabmobCls">
          <button
            ejs-button
            iconCss="icon-Save"
            class="system-settings-savebtn focusableSearch"
            cssClass="e-warning save-btn"
            [disabled]="
              systemSettingButtonDisable
            "
            (click)="saveSystemSettingsClick()"
            title="{{ 'common.saveSettings' | translate }}"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <!-- end of tab1-->

  <!-- start of tab2 code values -->
  <div id="tab2" [ngStyle]="{ display: selectedTab == 2 ? 'block' : 'none', opacity: '1' }" class="tab">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 code-value-content">
          <span  class="page-title"><span class="material-icons-outlined" style="
           padding-right: 5px;">engineering</span>{{ 'common.codeValues' | translate }}</span>
        </div>
      <div class="col-lg-8 col-sm-8 col-xs-12">
        <ejs-dropdownlist
          id="code-value"
          #codeValueDropdown
          (select)="CodeValueChangeEvent($event)"
          [placeholder]="CodeValueWaterMark"
          floatLabelType="Always"
          name="codeValue"
          [fields]="CodeTypeFields"
          popupWidth="Auto"
          style="width: 40%;"
          (close)="onClose($event)"
        ></ejs-dropdownlist>
      </div>

      <div *ngIf="isSystemSettingsPageHasAddPermission" class="col-lg-4 col-sm-4 col-xs-12 add-btn-align">
        <div class="codevalue-add-icon-align pull-right" (click)="onCreateCodeValueClick()">
          <div class="codevalue-add-bg">
            <button ejs-button iconcss="icon-T_Add header-icon" class="focusableSearch e-warning" ng-reflect-icon-css="icon-T_Add header-icon"  title="{{ 'common.addNewCodeValue' | translate }}" style="margin-bottom:14px;"  (click)="onCreateCodeValueClick()">{{ 'common.newCodeValue' | translate }}</button>
            <!-- <span class="icon-T_Add codevalue-add" title="Add new code value">Add Code Value</span> -->
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 code-grid-content">
        <ejs-grid
          #codeValueGrid
          [dataSource]="codeValueGridData"
          class="codevalue-datagrid"
          id="codeValueGrid"
          [allowPaging]="true"
          [allowSelection]="false"
          gridLines="Both"
          [allowSorting]="true"
          [pageSettings]="pageSettings"
          (recordDoubleClick)="CodeValueDoubleClick($event)"
          [allowTextWrap]="true"
          [allowResizing]="true"
          enableAltRow="true"
          (load)="loadCodeValues($event)"
        >
          <e-columns>
            <e-column
              field="tempCodeId"
              headerText="{{ 'common.codeId' | translate }}"
              textAlign="Left"
              clipMode="ellipsiswithtooltip"
              [visible]="false"
              [isPrimaryKey]="true"
            ></e-column>
            <e-column field="type" headerText="{{ 'common.codeType' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" [visible]="false"></e-column>
            <e-column headerText="{{ 'common.Action' | translate }}" textAlign="Left" width="100" height="20" [allowEditing]="false">
              <ng-template #template let-codeValueGridData>
                <div style="height: 20px !important">
                  <span
                    class="icon-Sorting-Z-A code-value-icon-css"
                    *ngIf="codeValueGridData.IsFirstRow == false"
                    (click)="orderUpArrowClick(codeValueGridData)"
                  ></span>
                  <span
                    class="icon-SortingA-Z code-value-icon-css"
                    *ngIf="codeValueGridData.IsLastRow == false"
                    (click)="orderDownArrowClick(codeValueGridData)"
                  ></span>
                </div>
              </ng-template>
            </e-column>
            <e-column field="order" headerText="{{ 'common.order' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" [visible]="false"></e-column>
            <e-column field="value" headerText="{{ 'common.codeName' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip"> </e-column>
            <e-column field="description" headerText="{{ 'common.description' | translate }}" textAlign="Left" clipMode="EllipsisWithTooltip"></e-column>
            <e-column field="code" headerText="{{ 'common.externalCode' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip"> </e-column>
            <e-column
              field="startDateTime"
              format="yMd"
              headerText="{{ 'common.startDate' | translate }}"
              [allowSorting]="false"
              textAlign="Right"
              clipMode="ellipsiswithtooltip"
            >
            </e-column>
            <e-column field="endDateTime" headerText="{{ 'common.endDate' | translate }}" textAlign="Right" clipMode="ellipsiswithtooltip"> </e-column>
            <e-column field="group" [visible]="false" headerText="{{ 'common.codeGroup' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip"></e-column>
            <e-column field="groupValue" headerText="{{ 'common.codeGroup' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip"> </e-column>
            <e-column field="id" headerText="{{ 'common.internalCodeId' | translate }}" textAlign="Right" clipMode="ellipsiswithtooltip" [visible]="true"></e-column>
            <e-column headerText="{{ 'common.isDefault' | translate }}?" textAlign="Left" [allowEditing]="false">
              <ng-template #template let-codeValueGridData>
                <div>
                  <span *ngIf="codeValueGridData.isDefault == true"> {{ 'common.Yes' | translate }}</span>
                  <span *ngIf="codeValueGridData.isDefault == false"> {{ 'common.No' | translate }}</span>
                </div>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div
        *ngIf="isSystemSettingsPageHasEditPermission || isSystemSettingsPageHasAddPermission"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mobile-footer-margin"
      >
        <div class="pull-right desktopCls">
          <br />
          <button ejs-button iconCss="icon-Save" cssClass="e-warning save-codevalue-btn" class="fucusableSystemBut" (click)="saveCodeValuesClick()">{{ 'common.saveCodeValues' | translate }}</button>
        </div>
        <div class="pull-right tabmobCls">
          <button
          class="focusableSearch"
            ejs-button
            iconCss="icon-Save"
            cssClass="e-warning save-codevalue-btn"
            (click)="saveCodeValuesClick()"
            title="{{ 'common.saveCodeValues' | translate }}"
            style="background-color: var(--secondaryColor) !important"
            tabindex="0"
          ></button>
        </div>
      </div>
    </div>
  <!-- end of tab2-->
  <!-- Validations -->
  <div id="tab3" [ngStyle]="{ display: selectedTab == 3 ? 'block' : 'none', opacity: '1' }" class="tab">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 code-value-content">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <span  class="page-title"><span class="material-icons-outlined" style="
          padding-right: 5px;">
          task_alt
          </span>{{ 'common.validations' | translate }}</span>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 code-grid-content">
        <ejs-grid
          #validationsGrid
          [dataSource]="validationsGridData"
          class="validations-datagrid"
          id="validationsGrid"
          [allowPaging]="true"
          [allowSelection]="true"
          gridLines="Both"
          [allowSorting]="true"
          [pageSettings]="pageSettings"
          [allowTextWrap]="true"
          [allowResizing]="true"
          enableAltRow="true"
          (load)="loadValidations($event)"
        >
          <e-columns>
            <e-column field="id" headerText="{{ 'common.Id' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" [visible]="false" [isPrimaryKey]="true"></e-column>
            <e-column field="name" headerText="{{ 'common.name' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" [visible]="true"></e-column>
            <e-column field="description" headerText="{{ 'common.description' | translate }}" textAlign="Left" clipMode="EllipsisWithTooltip"></e-column>
            <e-column
              field="active"
              headerText="{{ 'common.Active' | translate }}"
              textAlign="Center"
              clipMode="ellipsiswithtooltip"
              editType="booleanedit"
              width="60"
              type="boolean"
              [visible]="false"
            ></e-column>
            <e-column field="type" headerText="Type" textAlign="Right" clipMode="ellipsiswithtooltip" [visible]="false"></e-column>
            <e-column field="type" headerText="{{ 'common.validationType' | translate }}" type="" [allowSorting]="false">
              <ng-template #template let-data>                
                <div>                  
                  <ejs-dropdownlist
                    id="typeDropdown"
                    #typeDropdown
                    style="margin: 0;"
                    cssClass="{{data.typeFixed == true ? 'fixedDropDownList' : ''}}"
                    floatLabelType="Always"
                    name="typeDropdown"
                    [fields]="ValidationTypefields"
                    [dataSource]="validationRuleTypeData"
                    popupWidth="Auto"
                    (close)="onClose($event)"
                    (select)="ValidationTypeValueChangeEvent($event, data.id)"
                    [value]="data.type"
                    [enabled]="!isValidationBusy"
                    [readonly]="data.typeFixed == true"
                    

                    title="{{data.typeFixed == true ?  ('common.thisCaseTypeIsFixedForThisValidationRule' | translate) : (' common.selectACaseTypeForTheValidation '| translate) }}" 
                  >
                  </ejs-dropdownlist> 
                </div>
              </ng-template>
            </e-column>
            <e-column field="warning" headerText="Warning" textAlign="Right" clipMode="ellipsiswithtooltip" [visible]="false"></e-column>
            <e-column field="active" headerText="{{ 'common.Active' | translate }}?" editType="booleanedit" width="60" type="boolean" textAlign="Center" [allowSorting]="false">
              <ng-template #template let-data>
                <div> <!-- Pass the id on call to method -->
                  <ejs-checkbox
                    #checkbox
                    [checked]="data.active"
                    (change)="validationActiveChanged($event,data.id)"
                    title="{{ 'common.isThisValidationActive' | translate }}? {{ 'common.tickedMeansActive' | translate }}"
                    [disabled]="isValidationBusy"
                    >'data.type'</ejs-checkbox
                  >
                </div>
              </ng-template>
            </e-column>
            <e-column field="fatal" headerText="{{ 'common.required' | translate }}" editType="booleanedit" width="60" type="boolean" textAlign="Center" [allowSorting]="false">
              <ng-template #template let-data>
                <div>
                  <ejs-checkbox
                    #checkbox
                    [checked]="data.fatal"
                    (change)="validationFatalChanged($event,data.id)"
                    title="{{ 'common.isThisValidationRuleRequiredOrAWarning' | translate }}? {{ 'common.tickedMeansRequired' | translate }}"
                    [disabled]="isValidationBusy"
                  ></ejs-checkbox>
                </div>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
  <!-- Notices -->
  <div id="tab4" [ngStyle]="{ display: selectedTab == 4 ? 'block' : 'none', opacity: '1' }" class="tab">
  <app-notices [centres]="centres"></app-notices>
    </div>
    <!-- My Substances -->
  <div id="tab5" [ngStyle]="{ display: selectedTab == 5 ? 'block' : 'none', opacity: '1' }" class="tab">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-subs-content">
      <div class="page-title col-xs-12"><span class="material-icons-outlined">
          self_improvement
          </span>{{ 'common.mySubstances' | translate }}®</div>
          <div class="pull-right">
            <ejs-checkbox label="{{ 'common.new' | translate }}" [(checked)]="mySubsIncludeNew" style="margin-right: 1em;" (change)="mySubstanceUpdateFilter()"></ejs-checkbox>
            <ejs-checkbox label="{{ 'common.accepted' | translate }}" [(checked)]="mySubsIncludeAccepted" style="margin-right: 1em;" (change)="mySubstanceUpdateFilter()"></ejs-checkbox>
            <ejs-checkbox label="{{ 'common.replaced' | translate }}" [(checked)]="mySubsIncludeReplaced" style="margin-right: 1em;" (change)="mySubstanceUpdateFilter()"></ejs-checkbox>
            <ejs-checkbox label="{{ 'common.deleted' | translate }}" [(checked)]="mySubsIncludeDeleted" style="margin-right: 1em;" (change)="mySubstanceUpdateFilter()"></ejs-checkbox>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 code-grid-content">
            <ejs-grid #mySubstancesGrid [dataSource]="mySubstancesGridData" class="mySubstances-datagrid" id="mySubstancesGrid"
              [allowPaging]="true" [allowSelection]="false" gridLines="Both" [allowSorting]="true" [pageSettings]="pageSettings"
              [allowTextWrap]="true" [allowResizing]="true" [allowGrouping]="false" enableAltRow="true"
              [editSettings]='MySubstancesEditSettings' (load)="loadMySubstances($event)"
              (actionBegin)="mySubsGridActionBegin($event)" (actionComplete)="mySubsGridActionComplete($event)">
          <e-columns>
            <e-column headerText='' width='80' minWidth='80' maxWidth='80' [commands]='mySubsCommands'></e-column>
            <e-column field="mysuB_ID" headerText="ID" [isPrimaryKey]="true" textAlign="Left" clipMode="ellipsiswithtooltip"
              width='80' minWidth='60' maxWidth='100' [allowEditing]='false'></e-column>
            <e-column field="mysuB_NAME" headerText="{{ 'common.name' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" [visible]="true"
            minWidth='160' ></e-column>
            <e-column field="mysuB_DESC" headerText="{{ 'common.description' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" minWidth='300' [disableHtmlEncode]=false>
                <ng-template #editTemplate let-data>
                    <ejs-richtexteditor name="mysuB_DESC" [(ngModel)]="data.mysuB_DESC" [toolbarSettings]="mySubsDescriptionRTEToolBar" ></ejs-richtexteditor>
                </ng-template>
            </e-column>
            <e-column field="mysuB_AMT_EACH" headerText="{{ 'common.mySubsAmountEach' | translate }}" textAlign="Right" clipMode="ellipsiswithtooltip"
                minWidth='100' width='100' [allowEditing]='true' editType='numericedit' [edit]='{ params: { format: "###.####", min: 0.0001 } }' format="n4"></e-column>
            <e-column field="mysuB_CREATOR_NAME" headerText="{{ 'common.creator' | translate }}" textAlign="Left" clipMode="ellipsiswithtooltip" width='120' minWidth='50'
              [allowEditing]='false'></e-column>
            <e-column field="mysuB_CREATED" headerText="{{ 'common.created' | translate }}" textAlign="Left" clipMode="EllipsisWithTooltip"
              format="dd MMM yyyy HH:mm" type="dateTime" editType='datetimepickeredit' minWidth='100' width='130' [allowEditing]='false' ></e-column>
            <e-column field="mysuB_UPDATED" headerText="{{ 'common.lastUpdated' | translate }}" textAlign="Left" clipMode="EllipsisWithTooltip"
              format="dd MMM yyyy HH:mm" type="dateTime" editType='datetimepickeredit' minWidth='100' width='130' [allowEditing]='false' ></e-column>
            <e-column field="mysuB_USED_COUNT" headerText="{{ 'common.usageCount' | translate }}" textAlign="Center" clipMode="ellipsiswithtooltip"
              minWidth='50' width='100' [allowEditing]='false'></e-column>
            <e-column field="mysuB_STATUS" headerText="{{ 'common.status' | translate }}" textAlign="Center" clipMode="ellipsiswithtooltip"
              width='100' minWidth='80' editType='dropdownedit' [dataSource]='mySubsStatuses' foreignKeyValue='value' foreignKeyField='field' [allowEditing]='true'></e-column>
          </e-columns>
        </ejs-grid>
        <!-- No longer needed, a view SubstanceSearchWithMySubs is used instead, so no need to add to substanceSearch table. -->
        <!-- <div class="pull-right desktopCls">
          <button ejs-button iconCss="icon-Update" class="system-settings-savebtn focusableSearch" title="{{ 'common.updateSourceFromDatabase' | translate }}"
            cssClass="e-warning save-btn" (click)='updateSearchSourceMySub()'>{{ 'common.updateMySubstancesSearchSource' | translate }}</button>
        </div> -->

      </div>
    </div>
  </div>
</div>
<!-- end of tab content-->

<!--************************* Modals ******************************-->
<div class="modal fade" id="codeValueModal" role="dialog" [ngStyle]="{ display: showCodeValueModal ? 'block' : 'none', opacity: '1' }">
  <div class="modal-dialog systemsetting-dialog">
    <div class="modal-content systemsetting-dialog-content">
      <div class="modal-header systemsetting-header-dialog">
        <button type="button" class="close" data-dismiss="modal" (click)="onCancelCodeValueClick()">&times;</button>
        <span class="material-icons">
          integration_instructions          
          <div class="modal-title mob-modal-title">{{ CodeValueTitle }}</div></span>
        <span class="hide">{{ tempCodeId }}</span>
        <span class="hide">{{ codeId }}</span>
      </div>
      <form action="" #codeValueForm="ngForm" name="codeValueForm">
        <div class="modal-body systemsetting-dialog-body">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="e-float-input text-margin code-value-text">
              <input
                type="text"
                maxlength="255"
                autofocus
                required
                id="codeNameField"
                [(ngModel)]="codeValue"
                name="codeValue"
                (blur)="IsNullField($event.target)"
              />
              <span class="e-float-line"></span>
              <label class="e-float-text header-label required-indicator">{{ 'common.codeName' | translate }}</label>
            </div>
            <span *ngIf="(codeValue == '' || codeValue == null) && isCodeValueFormDirty" class="e-error">{{ 'common.pleaseFillCodeName' | translate }}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="e-float-input text-margin">
              <input type="text" maxlength="512" required [(ngModel)]="codeDescription" name="Description" />
              <span class="e-float-line"></span>
              <label class="e-float-text header-label">{{ 'common.description' | translate }}</label>
            </div>
          </div>
          <div class="code-value-fields col-lg-6 col-md-6 col-sm-12 code-group">
            <ejs-dropdownlist
              id="ddltooltip"
              #codeGroupDropdown
              [fields]="CodeGroupfields"
              [zIndex]="10000"
              [placeholder]="CodeGroupWaterMark"
              name="codeGroup"
              popupWidth="Auto"
              (close)="onClose($event)"
            ></ejs-dropdownlist>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 code-value-code">
            <div class="e-float-input text-margin">
              <input type="text" maxlength="50" required [(ngModel)]="code" name="Code" />
              <span class="e-float-line"></span>
              <label class="e-float-text header-label">{{ 'common.externalCode' | translate }}</label>
            </div>
          </div>
          <div class="code-value-fields col-lg-6 col-md-6 col-sm-12 start-date">
            <ejs-datepicker
              id="CodeStartDatePicker"
              [format]="DateFormat"
              #StartDate
              placeholder="{{ 'common.startDate' | translate }}"
              name="codeStartDateTime"
              [(ngModel)]="codeStartDateTime"
              [zIndex]="10000"
              (change)="CodeValueStartDateChange($event)"
            ></ejs-datepicker>
            <div *ngIf="codeValueFormObj.dirty && codeStartDateTime != null && isLessStartDate" class="e-error">{{ 'common.startDateShouldBeLesserThanEndDate' | translate }}</div>
            <div *ngIf="!isValidStartDate" class="e-error">{{ 'common.pleaseSelectValidStartDate' | translate }}</div>
          </div>
          <div class="code-value-fields col-lg-6 col-md-6 col-sm-12 end-date">
            <ejs-datepicker
              id="CodeEndDatePicker"
              [format]="DateFormat"
              #EndDate
              placeholder="{{ 'common.endDate' | translate }}"
              name="codeEndDateTime"
              [(ngModel)]="codeEndDateTime"
              [zIndex]="10000"
              (change)="CodeValueEndDateChange($event)"
            ></ejs-datepicker>
            <div *ngIf="codeValueFormObj.dirty && codeEndDateTime != null && isGreaterEndDate" class="e-error">{{ 'common.endDateShouldBeGreaterThanStartDate' | translate }}</div>
            <div *ngIf="!isValidEndDate" class="e-error">{{ 'common.pleaseSelectValidEndDate' | translate }}</div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="IsActiveCodeValue">
            <ejs-checkbox
              label="Is Default?"
              [checked]="codeValueIsDefault"
              [(ngModel)]="codeValueIsDefault"
              name="codeValueIsDefault"
              name="codeValueIsDefault"
            ></ejs-checkbox>
          </div>
        </div>
      </form>

      <div class="modal-footer systemsetting-dialog-footer">
        <button ejs-button data-ripple="true" (click)="onCancelCodeValueClick()" class="common-dialog-cancel-btn cancel-operation">{{ 'common.Cancel' | translate }}</button>
        <button
          ejs-button
          data-ripple="true"
          *ngIf="!IsEditCodeValue"
          (click)="saveCodeValueClick()"
          class="common-dialog-save-btn save-operation"
          [disabled]="codeValue == '' || codeValue == null || !isValidStartDate || !isValidEndDate || isLessStartDate || isGreaterEndDate"
        >
          {{ 'common.save' | translate }}
        </button>
        <button
          ejs-button
          data-ripple="true"
          *ngIf="IsEditCodeValue"
          (click)="updateCodeValueClick()"
          class="common-dialog-save-btn save-operation"
          [disabled]="codeValue == '' || codeValue == null || !isValidStartDate || !isValidEndDate || isLessStartDate || isGreaterEndDate"
        >
          {{ 'common.Update' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="isDefaultWarningModal" role="dialog" [ngStyle]="{ display: showIsDefaultWarningModal ? 'block' : 'none', opacity: '1' }">
  <div class="modal-dialog systemsetting-warning-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="onCancelIsDefaultClick()">&times;</button>
        <h4 class="modal-title mob-modal-title">{{ 'common.Warning' | translate }}</h4>
      </div>
      <div class="modal-body code-value-body">
        <p>
          {{ 'common.areYouSureYouWantToOverrideTheDefaultCodeValueForCodeType' | translate }} <span>{{ codeType }}</span
          >?
        </p>
      </div>
      <div class="modal-footer">
        <button data-ripple="true" (click)="onCancelIsDefaultClick()" class="common-dialog-cancel-btn">{{ 'common.No' | translate }}</button>
        <button data-ripple="true" (click)="saveIsDefaultClick()" class="common-dialog-save-btn">{{ 'common.Yes' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="EndDateWarningModal" role="dialog" [ngStyle]="{ display: showEndDateWarningDialog ? 'block' : 'none', opacity: '1' }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="CancelEndDateWarningClick()">&times;</button>
        <h4 class="modal-title mob-modal-title">{{ 'common.Warning' | translate }}</h4>
      </div>
      <div class="modal-body code-value-body">
        <p>{{ 'common.areYouSureYouWantToMakeThisCodeValueInactiveSinceItIsCurrentlyBeingUsedAsDefault' | translate }}?</p>
      </div>
      <div class="modal-footer">
        <button data-ripple="true" (click)="CancelEndDateWarningClick()" class="common-dialog-cancel-btn">{{ 'common.No' | translate }}</button>
        <button data-ripple="true" (click)="saveInactiveEndDateClick()" class="common-dialog-save-btn">{{ 'common.Yes' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!-- CodeValue  permission denied modal -->

<div class="modal fade" id="permissionDeniedModal" role="dialog" [ngStyle]="{ display: showPermissionDenied ? 'block' : 'none', opacity: '1' }">
  <div class="modal-dialog permission-dialog">
    <div class="modal-content permission-dialog-content">
      <div class="modal-header permission-header-dialog">
        <button type="button" class="close" (click)="permissionDeniedCancelClick()" data-dismiss="modal">&times;</button>
        <h4 class="modal-title mob-modal-title">{{ permisionTitle }}</h4>
      </div>
      <div class="modal-body permission-dialog-body">
        <div class="msgcenter">
          <div class="icon-T_Warning col-sm-2 col-md-2 col-xs-12 delete-warning-icon pull-left"></div>
          <div class="col-sm-10 col-md-10 col-xs-12 statustext pull-left">{{ permisionAlertContent }}</div>
        </div>
      </div>
      <div class="modal-footer permission-dialog-footer">
        <div class="permission-btn">
          <button class="permission-cancel-btn" (click)="permissionDeniedCancelClick()" data-ripple="true">{{ 'common.Ok' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="CodeValueUnSavedWarningModal"
  role="dialog"
  [ngStyle]="{ display: showCodeValueDataUnsavedDailog ? 'block' : 'none', opacity: '1' }"
>
  <div class="modal-dialog common-confirmation-dialog">
    <div class="modal-content common-confirmation-dialog-content">
      <div class="modal-header status-header-dialog">
        <button type="button" class="close" (click)="CodeValueUnSavedWarningCancelClick()" data-dismiss="modal">&times;</button>
        <div class="icon-T_Warning col-md-2 delete-warning-icon pull-left"></div>
        <h4 class="modal-title mob-modal-title">{{ 'common.Warning' | translate }}</h4>
      </div>
      <div class="modal-body common-confirmation-dialog-body">
        <div class="msgcenter">
          
          <div class="col-md-10 unsavedwarningtext pull-left">{{ 'common.youHaveUnsavedChanges' | translate }}! {{ 'common.ifYouLeaveYourChangesWillBeLost' | translate }}.</div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="focusableSearch e-warning e-control e-btn e-lib" (click)="CodeValueUnSavedWarningCancelClick()" data-ripple="true">{{ 'common.Stay' | translate }}</button>
        <button class="focusableSearch e-warning e-control e-btn e-lib" id="codevalue-leave-btnid" autofocus (click)="CodeValueUnSavedWarningYesClick()" data-ripple="true">{{ 'common.Leave' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="pageNotFound" role="dialog" [ngStyle]="{ display: showOnlineInfoDialog ? 'block' : 'none', opacity: '1' }">
  <div class="modal-dialog common-confirmation-dialog">
    <div class="modal-content common-confirmation-dialog-content">
      <div class="modal-header status-header-dialog">
        <button type="button" class="close" (click)="onlineInfoCancelClick()" data-dismiss="modal">&times;</button>
        <h4 class="modal-title mob-modal-title">{{ 'common.Warning' | translate }}</h4>
      </div>
      <div class="modal-body common-confirmation-dialog-body">
        <div class="msgcenter">
          <div class="icon-T_Warning col-md-2 delete-warning-icon pull-left"></div>
          <div class="col-md-10 statustext pull-left">{{ offlinePageName }} {{ 'common.isNotAvailableOffline' | translate }}. {{ 'common.returningToOfflineHome' | translate }}.</div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="offline-ok-btn" (click)="onlineInfoOkClick()" data-ripple="true">{{ 'common.Ok' | translate }}</button>
      </div>
    </div>
  </div>
</div>
