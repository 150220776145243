<style>
  .e-substance-source {
    font-size: 10px;
  }
</style>

<div style="display: flex; flex-direction: row;flex-wrap:wrap">
  <div *ngIf="isSubsLoaded == false" style="width: 33px">
    <img class="substance-busy" src="../../../assets/icons/Gear.png"
      title="Substance Search List is loading, please wait..." />
  </div>
  <div style="flex-grow: 1">
    <ejs-multiselect
      #msSubstances id='msSubstances'
      [dataSource]='msSubstancesData' [fields]='remoteFields' [openOnClick]='false' [(value)]="selectedSubstances"
      [mode]='mode' [selectAllText]='selectAllText' showSelectAll='true' floatLabelType='Always' [closePopupOnSelect]="true"
      [allowFiltering]='true' (filtering)='onFiltering($event)' (open)='onOpen($event)' [query]='query'
      [placeholder]="msSubstancesPlaceholder">
      <ng-template #itemTemplate let-data>
        <div>
          <div class="substance-name" title="Click or Tap to select {{ data.Prod_Name }}">
            <img class="substance-src-img"
              src="./assets/icons/SearchSource-{{ data.Source == '' ? 0 : data.Source }}.png" />&nbsp;{{
            data.Prod_Name }}
          </div>
        </div>
      </ng-template>
      <ng-template #noRecordsTemplate>
          <span class='norecord'>No Substances match your query</span>
      </ng-template>
    </ejs-multiselect>
  </div>
  <div style="display:flex; flex-direction: column; flex-wrap: wrap; max-height: 60px;width: 150px;">
    <ejs-switch *ngIf="showNPRSwitch" onLabel='NPR' offLabel='NPR' title="Include the Australian National Poisons Registry" cssClass="search-switch" [(checked)]="includeNPR" class="search-switch"></ejs-switch >
    <ejs-switch *ngIf="showAMTSwitch"onLabel='AMT' offLabel='AMT' title="Include the Australian Medicinal Terminology" cssClass="search-switch" [(checked)]="includeAMT" class="search-switch"></ejs-switch >
    <ejs-switch *ngIf="showMySubSwitch"onLabel='MySubs' offLabel="MySubs" title="Include My Substances" cssClass="search-switch" [(checked)]="includeMy" class="search-switch"></ejs-switch >
    <ejs-switch *ngIf="showPDXSwitch"onLabel='PDX' offLabel="PDX" title="Include POISINDEX" cssClass="search-switch" [(checked)]="includePDX" class="search-switch"></ejs-switch >
  </div>
</div>