import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonPropsModel, DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { CookieService } from 'ngx-cookie-service';
import { OnlineService } from '../../Providers/OnlineService';
import { ToasterService } from '../../Providers/ToasterService';
import { AppConstants } from '../../app.constants';
import { SubstanceSearchService } from './../../Services/substance-search.service';
import { TranslateService } from '@ngx-translate/core';
import { MySubstance } from '../../models/mySubstanceModel';

@Component({
  selector: 'substance-detail',
  templateUrl: './substance-detail.component.html',
  styleUrls: ['./substance-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SubstanceDetailComponent implements OnInit {

  @Input() substanceTitle: string = null;
  @Input() substanceText: string = null;
  @Input() substanceArgs: any = null; // This is the product code
  @Input() substanceSource: any = null;
  @Input() substanceSources: any[] = null;

  public newIsOnline: string = localStorage.getItem(AppConstants.LocalStorage.OnLineStatus);
  public target: string = 'body';
  public isDraggable: boolean = true;
  public dialogWidth: string = '650';
  public dlgButtons: ButtonPropsModel[] = [
    { click: this.onCloseMySubDetailDialog.bind(this), buttonModel: { content: this.translate.instant('common.Close') } },
  ];
  public MySubDetailDialogVisible: boolean = false;
  public animationSettings = { effect: 'Zoom', duration: 100, delay: 0 };

  // Add Substance Dialog settings
  @ViewChild('MySubDetail', { static: false })
  public MySubDetailDialog: DialogComponent;
  public substanceDetails: MySubstance;
  
  constructor(
    public onlineService: OnlineService,
    public toastService: ToasterService,
    private _cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    public substanceSearchService: SubstanceSearchService,
    public translate: TranslateService
    ) {
      this.substanceDetails = this.defaultSubstanceDetails; // Set the default substance details so the dialog can be created
  }

  public defaultSubstanceDetails: MySubstance = {
    mysuB_ID: 0,
    MYSUB_NAME: 'Loading...',
    MYSUB_DESC: 'Loading...',
    MYSUB_PROD: '',
    MYSUB_GENERIC: '',
    MYSUB_CREATED: undefined,
    MYSUB_CREATOR: 0,
    MYSUB_STATUS: 0,
    MYSUB_AMT_EACH: null
  };

  ngOnInit() { // Nothing to see here!
  }

  onCloseMySubDetailDialog() {
    this.MySubDetailDialogVisible = false;
  }

  getMySubstanceDetails() {
    // get the substance details
    this.substanceSearchService.GetMySubstanceDetails(this.substanceArgs).subscribe((data: any) => {
      if (data) {
        this.substanceDetails.MYSUB_CREATED= data.mysuB_CREATED;
        this.substanceDetails.MYSUB_CREATOR= data.mysuB_CREATOR;
        this.substanceDetails.MYSUB_DESC= data.mysuB_DESC;
        this.substanceDetails.MYSUB_GENERIC= data.mysuB_GENERIC;
        this.substanceDetails.MYSUB_NAME= data.mysuB_NAME;
        this.substanceDetails.MYSUB_PROD= data.mysuB_PROD;
        this.substanceDetails.MYSUB_AMT_EACH= data.mysuB_AMT_EACH;
        this.substanceDetails.mysuB_ID= data.mysuB_ID;      
        this.substanceDetails.MYSUB_STATUS= data.mysuB_STATUS;        
      }
    }, (error) => {
      alert(this.translate.instant('common.errorLoadingSubstanceDetails') + " " + error.message);
    });
}

  onSubstanceClick() {
    let alertMsg = '';
    if (this.newIsOnline !== '' && this.newIsOnline === 'true') {
      // is there a substance source?
      if (this.substanceSource !== undefined && this.substanceSource !== null && this.substanceSource !== '') {
        // is there a list of sources?
        if (this.substanceSources !== undefined && this.substanceSources !== null && this.substanceSources.length > 0) {
          // Search for the source
          const source = this.substanceSources.find((obj) => {
            return obj.id == this.substanceSource; // id is number and substanceSource is string
          });
          if (source) {
            if (source.active) {
              // show the my substances dialog if from my substances
              if (source.id == AppConstants.SubstanceSearchSources.MySubstances) {
                this.MySubDetailDialogVisible = true; // Show the dialog
                return;
              }
              
              if (source.baseURL !== undefined && source.baseURL !== null && source.baseURL !== '') {

                if (this.substanceArgs !== undefined && this.substanceArgs !== null && this.substanceArgs !== '') {
                  const w = window.open(source.baseURL + this.substanceArgs);
                  if (!w) {alert(this.translate.instant('common.unableToOpenNewWindowPopupBlockerDisable'));}
                } else {
                  // Open dialog message saying product code is not set on this substance
                  alertMsg = this.translate.instant('common.unableToLoadDetailsProdIdNotDefined');
                }
              } else {
                // Open dialog message saying no URL provided
                alertMsg = this.translate.instant('common.unableToLoadSubDetails') + " " + source.name + " " + this.translate.instant('common.linkUrlUnset').trim();
              }
            } else {
              // Open dialog message saying no URL provided
              alertMsg = this.translate.instant('common.unableToLoadSubDetails') + " " + source.name +  " " + this.translate.instant('common.linkIsInactive').trim();
            }
          } else {
            // Open dialog message saying unable to find substance source
            alertMsg = this.translate.instant('common.unableToFindSubstanceSource');
          }
          } else {
            // Open dialog message saying sources are unavailable at this time
            alertMsg = this.translate.instant('common.sourceUnavailable');
          }
        } else {
          // Open dialog message saying source id is not set on this substance
          alertMsg = this.translate.instant('common.sourceIdNotDefinedThisSub');
        }
    } else {
      // Open dialog message saying unavailable while offline
      alertMsg = this.translate.instant('common.unavailableOffline');
    }
    if (alertMsg !== '') {
      let fullMsg = '<div class="utility-dialog-content-margins"><div class="icon-T_Warning col-md-2 delete-warning-icon pull-left"></div><p>';
      fullMsg += alertMsg + '</p></div>';

      DialogUtility.alert({
        title: '<div class="icon-Substance modal-icon"></div><div class="modal-title1">' + this.translate.instant('common.unableToLinkToSubDetails') + '</div>',
        content: fullMsg,
        okButton: {  text: this.translate.instant('common.Ok'), cssClass: 'save-Dlg-Buttons pull-right' },
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
        cssClass: 'substanceSourceDialogUtility',
        position: { X: 'center', Y: 'center' }
      });
    }
  }
}
