import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// import { CookieService } from 'angular2-cookie/core';
import { CookieService } from 'ngx-cookie-service';
import { OnlineService } from './../Providers/OnlineService';
import { ToasterService } from './../Providers/ToasterService';
import { AppConstants } from './../app.constants';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public router: Router, private _cookieService: CookieService, public onlineService: OnlineService, public toastService: ToasterService) { }

  public twoFactorDurationDatePeriod: string = '';
/**
 * Determines whether activate can proceed
 * @param route 
 * @param state 
 * @returns true if activate 
 */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {    
    if (!this.isAuthenticated(route, state)) {
      this.router.navigate([AppConstants.Urls.RootLogin]);
      return false;
    } else {
      if (localStorage.getItem(AppConstants.LocalStorage.OnLineStatus) == AppConstants.FALSE) {
        if (window.location.pathname.split('/')[1].toLocaleLowerCase() != AppConstants.Urls.Login && window.location.pathname.split('/')[1].toLocaleLowerCase() != 'home'
          && window.location.pathname.split('/')[1].toLocaleLowerCase() != 'exposure') {
          let offlinePageName = window.location.pathname.split('/')[1].toLocaleLowerCase() == 'profile' ? 'Profile'
            : window.location.pathname.split('/')[1].toLocaleLowerCase() == 'reports' ? 'Reports'
              : window.location.pathname.split('/')[1].toLocaleLowerCase() == 'locks' ? 'Locks'
                : window.location.pathname.split('/')[1].toLocaleLowerCase() == 'systemsettings' ? 'System Settings'
                  : window.location.pathname.split('/')[1].toLocaleLowerCase() == 'audit' ? 'Audit'
                    : window.location.pathname.split('/')[1].toLocaleLowerCase() == 'search' ? 'Search' : '';

          const toasterValue = {
            type: 'info',
            message: 'You are offline, ' + offlinePageName + ' is not available offline'
          };
          this.toastService.showSpinner(toasterValue);
        }
      }
      const hasUserSettings = this._cookieService.get(AppConstants.CookieName.HasUserSettingsFlag);
      const url = window.location.pathname;

      if (hasUserSettings.toLocaleLowerCase() == AppConstants.TRUE) {
        if (url.split('/')[1].toLocaleLowerCase() == AppConstants.Urls.Home) {
          return true;
        }
        return false;
      } else {
        const userId = parseInt(this._cookieService.get(AppConstants.CookieName.UserId), 10);
        const hasUserSettings = this._cookieService.get(AppConstants.CookieName.HasUserSettingsFlag);

        const lastTwoFactorAuthenticationDate = this._cookieService.get(AppConstants.CookieName.TwoFactorLastLoginDate);
        this.twoFactorDurationDatePeriod = this._cookieService.get(AppConstants.CookieName.TwoFactorDurationType);

        if (hasUserSettings.toLocaleLowerCase() == AppConstants.FALSE) {
          if (state.url == '/profile') {
            return true; // allow access to profile page.
          }
          const previousDate = new Date();
          previousDate.setDate(previousDate.getDate() - 1);

          const previousWeekDate = new Date();
          previousWeekDate.setDate(previousWeekDate.getDate() - 7);

          const previousMonth = new Date();
          previousMonth.setMonth(previousMonth.getMonth() - 1);

          const previousYear = new Date();
          previousYear.setDate(previousYear.getDate() - 365); // = new Date(previousYear.getFullYear(), previousYear.getMonth(), previousYear.getDate());

          if (lastTwoFactorAuthenticationDate.toLocaleLowerCase() == AppConstants.NULL || lastTwoFactorAuthenticationDate.toLocaleLowerCase() == ''
            || (this.twoFactorDurationDatePeriod.toLocaleLowerCase() == AppConstants.TwoFactorPeriods.Yearly  && previousYear >= new Date(lastTwoFactorAuthenticationDate))
            || (this.twoFactorDurationDatePeriod.toLocaleLowerCase() == AppConstants.TwoFactorPeriods.Monthly && previousMonth >= new Date(lastTwoFactorAuthenticationDate))
            || (this.twoFactorDurationDatePeriod.toLocaleLowerCase() == AppConstants.TwoFactorPeriods.Weekly && previousWeekDate >= new Date(lastTwoFactorAuthenticationDate))
            || (this.twoFactorDurationDatePeriod.toLocaleLowerCase() == AppConstants.TwoFactorPeriods.Daily && previousDate >= new Date(lastTwoFactorAuthenticationDate))) {
            if (lastTwoFactorAuthenticationDate.toLocaleLowerCase() == AppConstants.NULL) {
              this.router.navigate(['/profile']);  // navigate to profile to setup two factor authentication
              return true;
            } else {
              this.router.navigate([AppConstants.Urls.RootLogin]);
            }
          }
        }

        const passwordChangedDate = this._cookieService.get(AppConstants.CookieName.PasswordLastChangedDate);
        const passwordExpiration = this._cookieService.get(AppConstants.CookieName.PasswordExpirationDate);

        const passwordExpiryDate = new Date();
        passwordExpiryDate.setDate(passwordExpiryDate.getDate() - parseInt(passwordExpiration, 10));

        if (parseInt(passwordExpiration, 10) != 0 && passwordExpiryDate >= new Date(passwordChangedDate)) {
          this.router.navigate([AppConstants.Urls.RootLogin]);
        } else {
          return true;
        }
      }
    }
  }


    /**
     * Determines whether user is already authenticated
     * @param route 
     * @param state 
     * @returns true if authenticated 
     *      
     * Check for the User Auth Token and User Id cookies.
     */
    private isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (this._cookieService.get(AppConstants.CookieName.UserAuthenticationToken) != '' && this._cookieService.get(AppConstants.CookieName.UserId) != '') {
        if (navigator.onLine) {
          this.onlineService.setCookieValueFromServer();
        }
        return true;
      }
  
      // this.router.navigate([AppConstants.Urls.RootLogin], { queryParams: { returnUrl: state.url } }); // DR: This was redirecting before attempting to allow offline use!
      // localStorage.setItem('returnUrl', state.url);
      return false;
      // return !this.jwtHelper.isTokenExpired(token);
    }
}
