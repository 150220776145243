import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ToasterService {
    private openNavSource: BehaviorSubject<any> = new BehaviorSubject<any>("");

  public  Type = {
    Info:'info'
  }
    constructor() {
    }

    public showSpinner(toasterValue:ToasterValue) {
        this.openNavSource.next(toasterValue);
    }
    public show(toasterValue:ToasterValue) {
        this.openNavSource.next(toasterValue);
    }
    get GetToasterMessage():Observable<ToasterValue> {
        return this.openNavSource.asObservable();
    }

}

export class ToasterValue {
    public message: string = '';
  public type: string = '';
  constructor(type: string, message: string) {
    this.message = message;
    this.type = type;
  }
}
