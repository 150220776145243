import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { OnlineService } from '../Providers/OnlineService';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class PingApiService {

  public navigationPeriod: number = parseInt(
    this._cookieService.get(AppConstants.CookieName.NavigationPeriod) != '' && this._cookieService.get(AppConstants.CookieName.NavigationPeriod) != undefined
      ? this._cookieService.get(AppConstants.CookieName.NavigationPeriod)
      : AppConstants.Defaults.NavigationPeriod.toString(), 10
  );
  public pingAPIIntervalId: number = 0;

  constructor(public onlineService: OnlineService, public _cookieService: CookieService) { }

  // Ping API methods - Checks for the API being available.
  pingAPI() {
    const pingAPIIntervalId = setInterval(
      () => {
        if (pingAPIIntervalId !== undefined && this._cookieService.get(AppConstants.CookieName.CanPingOnlineStatus) === 'true') {
          this.onlineService.doesConnectionExist();
        }
      }, this.navigationPeriod);
    return pingAPIIntervalId;
  }

  clearPingAPI() {
    if (this.pingAPIIntervalId) {
      clearInterval(this.pingAPIIntervalId);
    }
  }
}
